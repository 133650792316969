import React, { Component } from "react";
import { v4 as uuid } from "uuid";
import { reportContainer } from "./report-container";
import { picklistNames } from "~/core/picklist";
import { unitNames } from "~/core/units";
import { withMasked } from "~/hocs";
import { constants, model } from "../data";
import { getFieldGuidArray } from "../utils";
import { messages } from "../i18n-messages";
import { DialogBox, DialogBoxFooterType } from "~/core";
import { injectIntl } from "react-intl";
import { keywords } from "../keywords";
import { loadsheetPreventionStatusCodes } from "~/action-panel/components/common/status-codes";
import { errorCodeMessages } from "~/i18n-error-messages";
import { FieldAPI, ReportAPI, ReportBooksAPI } from "@ai360/core";
import { IReportType } from "../../admin/setup/report-books/data/interfaces";
import {
    ICropYieldData,
    IFileNameFormat,
    IMessageOptions,
    IReportTypePage,
    ITotalingUnit,
} from "../data/interfaces";
import { ConnectedComponent } from "react-redux";
import { RecSummary } from "~/recs-events/recs/model";
import { IQuickSummary } from "~/customer-data/models";
import WithReportErrors from "./with-report-errors";
import { NEW_REPORTS } from "../data/constants";

interface IErrorMessage {
    message: string;
    messageOptions?: IMessageOptions;
}

interface IReportStatus extends ReportBooksAPI.IField {
    fieldName: string;
    [model.PROPS_REPORT_STATUS]: string;
}

export interface IReportData {
    [model.PROPS_AGE_OF_SAMPLE]: string;
    [model.PROPS_ALL_CUSTOMERS_FIELDS_YN]: boolean;
    [model.PROPS_CROPPING_SEASON_GUID_LIST]: string[];
    [model.PROPS_BATCH_GUID]: string;
    [model.PROPS_BREAK_EVEN_CROP_DATA]: ICropYieldData[];
    [model.PROPS_CROP_DATA]: ICropYieldData[];
    [model.PROPS_CROP_MAP_ORIENTATION]: string;
    [model.PROPS_CROP_GUID]: string;
    [model.PROPS_END_DATE]: string;
    [model.PROPS_FIELD_ITEM_LIST]: IReportStatus[] | string[];
    [model.PROPS_LAB_ACCOUNT]: string;
    [model.PROPS_MULTI_FIELD_REC]: boolean;
    [model.PROPS_NEW_REPORT_TYPE_NAME_LIST]: string[];
    [model.PROPS_REPORT_TYPE_GUID_LIST]: string[];
    [model.PROPS_REPORT_TYPE_PAGE_SELECTION]: string;
    [model.PROPS_START_DATE]: string;
    [model.PROPS_START_POSITION]: number;
    [model.PROPS_SELECTED_BASEMAP]: string;
    [model.PROPS_SELECTED_FILENAME_FORMAT]: string;
    [model.PROPS_SHOW_REC_SUMMARY]: boolean;
    [model.PROPS_SHOW_SCOUTING_DETAILS]: boolean;
    [model.PROPS_SHOW_YIELD_POLYGON]: boolean;
    [model.PROPS_SELECTED_EVENT_GUID_LIST]: string[];
    [model.PROPS_SELECTED_REC_GUID_LIST]: string[];
    [model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID]: string;
    [model.PROPS_USE_CUSTOM_IMAGERY]: boolean;
    [model.PROPS_USE_OVERVIEW_MAP]: boolean;
    [model.PROPS_USE_PRODUCT_RECS]: boolean;
    [model.PROPS_USE_IMPORT_DATE]: boolean;
    [model.PROPS_CUSTOMER_GUIDS]: string[];
    [model.PROPS_NON_FIELD_FEATURE_GUIDS]: string[];
}

interface IWithReportProps {
    clearReportHubData: () => void;
    createReport: (request: { model: IReportData; reportType?: string }) => void;
    createReports: (request: { model: IReportData; reportType?: string }[]) => void;
    createReportSuccess: (payload: []) => void;
    deleteSelectedReports: () => void;
    fetchPicklistData: (options: { picklistKey: number; picklistKey1: number }) => void;
    fetchUnitData: (options: { unitTypeKey: number }) => void;
    getCropDropdown: () => void;
    getFileNameFormat: () => void;
    getUserFileNameFormatPreference: () => void;
    getReportPref: (options: Record<string, any>) => void;
    getReportPreferences: () => void;
    getReportTypeList: (reportTypePayload: { url: string }) => void;
    getReportTypePageList: (reportTypePagePayload: { url: string }) => void;
    getYieldByAnalysisCategory: () => void;
    getYieldByCompareByOption: () => void;
    mergeReportData: (payload: { reportType?: string; reportData: Record<string, any> }) => void;
    needs: (...args: any[]) => number;
    resetReportData: () => void;
    resetReportPreferencesCache: () => void;
    setReportDataValue: (payload: Record<string, any>) => void;
    updateYieldByAnalysisCategory: () => void;
    updateYieldByCompareByOptions: () => void;
    crops: FieldAPI.ICrops[];
    fileNameFormats: IFileNameFormat[];
    seasons: FieldAPI.ISeason[];
    recGeneralGuidToRecMap: Map<string, RecSummary>;
    reportData: { [key: string]: IReportData };
    reportPreferences: any;
    reportTypes: IReportType[];
    reportTypePages: IReportTypePage[];
    requestIds: { [key: number]: string };
    selectedFields: ReportBooksAPI.IField[];
    selectedFieldCount: number;
    totalingUnit: ITotalingUnit[];
    defaultFileNameFormat: string;
    defaultMultiFieldRec: boolean;
    selectedEventGuidSet: Set<string>;
    selectedRecGuidSet: Set<string>;
    intl: any;
    cropPurpose: any[];
    quickSummary: IQuickSummary;
    selectedCustomerGuids: Set<string>;
    selectedNonFieldFeatureGuids: Set<string>;
}

interface IWithReportState {
    createReportHubProgress: boolean;
    errorMessages: IErrorMessage[];
    selectedReportTypeNames: string[];
    useAllFields: boolean;
    showUseAllFieldsDialog: boolean;
}

export const withReport = <P extends IWithReportProps>(
    ChildComponent: React.ComponentType<P>,
    service: {
        REPORT_TYPE_LIST_URL: string;
        REPORT_TYPE_PAGE_LIST_URL: string;
    },
    useRedux = false
): ConnectedComponent<any, Omit<unknown, never>> => {
    class ReportWrapper extends Component<IWithReportProps, IWithReportState> {
        private selectedReportTypes: string[];
        private createReportRequestId: number;
        private createReportRequestIds: number[];
        private readonly reportData: {
            [model.PROPS_AGE_OF_SAMPLE]: string;
            [model.PROPS_ALL_CUSTOMERS_FIELDS_YN]: boolean;
            [model.PROPS_BATCH_GUID]: string;
            [model.PROPS_BREAK_EVEN_CROP_DATA]: ICropYieldData[];
            [model.PROPS_CROP_DATA]: ICropYieldData[];
            [model.PROPS_CROP_GUID]: string;
            [model.PROPS_CROPPING_SEASON_GUID_LIST]: string[];
            [model.PROPS_CROP_MAP_ORIENTATION]: string;
            [model.PROPS_DISPLAY_TYPE]: string;
            [model.PROPS_END_DATE]: string;
            [model.PROPS_FIELD_ITEM_LIST]: IReportStatus[];
            [model.PROPS_LAB_ACCOUNT]: string;
            [model.PROPS_MULTI_FIELD_REC]: boolean;
            [model.PROPS_NEW_REPORT_TYPE_NAME_LIST]: string[];
            [model.PROPS_REPORT_TYPE_GUID_LIST]: string[];
            [model.PROPS_REPORT_TYPE_PAGE_SELECTION]: string;
            [model.PROPS_SELECTED_BASEMAP]: string;
            [model.PROPS_SELECTED_FILENAME_FORMAT]: string;
            [model.PROPS_SELECTED_EVENT_GUID_LIST]: string[];
            [model.PROPS_SELECTED_REC_GUID_LIST]: string[];
            [model.PROPS_SELECTED_REC_GUIDS]: string[];
            [model.PROPS_SHOW_REC_SUMMARY]: boolean;
            [model.PROPS_SHOW_SCOUTING_DETAILS]: boolean;
            [model.PROPS_SHOW_YIELD_POLYGON]: boolean;
            [model.PROPS_START_DATE]: string;
            [model.PROPS_START_POSITION]: number;
            [model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID]: string;
            [model.PROPS_USE_CUSTOM_IMAGERY]: boolean;
            [model.PROPS_USE_IMPORT_DATE]: boolean;
            [model.PROPS_USE_OVERVIEW_MAP]: boolean;
            [model.PROPS_USE_PRODUCT_RECS]: boolean;
            [model.PROPS_CUSTOMER_GUIDS]: string[];
            [model.PROPS_NON_FIELD_FEATURE_GUIDS]: string[];
        };

        constructor(props: P) {
            super(props);
            this.state = {
                createReportHubProgress: false,
                errorMessages: [],
                selectedReportTypeNames: [],
                useAllFields: false,
                showUseAllFieldsDialog: false,
            };

            this.selectedReportTypes = [];
            this.createReportRequestId = null;
            this.createReportRequestIds = [];
            this.reportData = {
                [model.PROPS_AGE_OF_SAMPLE]: props.reportPreferences.ageOfSample,
                [model.PROPS_ALL_CUSTOMERS_FIELDS_YN]: false,
                [model.PROPS_BATCH_GUID]: null,
                [model.PROPS_BREAK_EVEN_CROP_DATA]: [],
                [model.PROPS_CROP_DATA]: [],
                [model.PROPS_CROP_GUID]: "",
                [model.PROPS_CROPPING_SEASON_GUID_LIST]: [],
                [model.PROPS_CROP_MAP_ORIENTATION]:
                    props.reportPreferences[model.PROPS_CROP_MAP_ORIENTATION],
                [model.PROPS_END_DATE]: null,
                [model.PROPS_DISPLAY_TYPE]: "",
                [model.PROPS_FIELD_ITEM_LIST]: [],
                [model.PROPS_LAB_ACCOUNT]: null,
                [model.PROPS_MULTI_FIELD_REC]: props.defaultMultiFieldRec,
                [model.PROPS_NEW_REPORT_TYPE_NAME_LIST]: [],
                [model.PROPS_REPORT_TYPE_GUID_LIST]: [],
                [model.PROPS_REPORT_TYPE_PAGE_SELECTION]:
                    props.reportPreferences[model.PROPS_REPORT_TYPE_PAGE_SELECTION],
                [model.PROPS_SELECTED_BASEMAP]: props.reportPreferences.selectedBasemap,
                [model.PROPS_SELECTED_FILENAME_FORMAT]: props.defaultFileNameFormat,
                [model.PROPS_SELECTED_EVENT_GUID_LIST]: [],
                [model.PROPS_SELECTED_REC_GUID_LIST]: [],
                [model.PROPS_SELECTED_REC_GUIDS]: [],
                [model.PROPS_SHOW_REC_SUMMARY]: props.reportPreferences.showRecSummary,
                [model.PROPS_SHOW_SCOUTING_DETAILS]: props.reportPreferences.showScoutingDetails,
                [model.PROPS_SHOW_YIELD_POLYGON]: props.reportPreferences.showYieldPolygon,
                [model.PROPS_START_DATE]: "",
                [model.PROPS_START_POSITION]: null,
                [model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID]: null,
                [model.PROPS_USE_CUSTOM_IMAGERY]:
                    props.reportPreferences[model.PROPS_USE_CUSTOM_IMAGERY],
                [model.PROPS_USE_IMPORT_DATE]: props.reportPreferences.useImportDate,
                [model.PROPS_USE_OVERVIEW_MAP]: props.reportPreferences.useOverviewMap,
                [model.PROPS_USE_PRODUCT_RECS]: props.reportPreferences.useProductRecs,
                [model.PROPS_CUSTOMER_GUIDS]: [],
                [model.PROPS_NON_FIELD_FEATURE_GUIDS]: [],
            };
        }

        _initializeReportData = (): void => {
            const { REPORT_TYPE_LIST_URL, REPORT_TYPE_PAGE_LIST_URL } = service;
            const reportTypePayload = {
                url: REPORT_TYPE_LIST_URL,
            };
            const reportTypePagePayload = {
                url: REPORT_TYPE_PAGE_LIST_URL,
            };
            this.props.needs([
                this.props.getCropDropdown(),
                this.props.getReportPref({}),
                this.props.getReportTypeList(reportTypePayload),
                this.props.getReportTypePageList(reportTypePagePayload),
                this.props.fetchPicklistData({
                    picklistKey: picklistNames.getPickListCode(
                        picklistNames.PICKLIST_CROPPING_SEASON
                    ),
                    picklistKey1: picklistNames.getPickListCode(
                        picklistNames.PICKLIST_CROP_PURPOSE
                    ),
                }),
                this.props.fetchUnitData({
                    unitTypeKey: unitNames.getUnitCode(unitNames.UNIT_WEIGHT),
                }),
                this.props.getFileNameFormat(),
                this.props.getUserFileNameFormatPreference(),
            ]);
            if (useRedux) {
                this.props.mergeReportData({ reportData: this.reportData });
            }
        };

        _appendReportStatus = (selectedFields: ReportBooksAPI.IField[]): IReportStatus[] => {
            return selectedFields.map((field) => {
                return {
                    ...field,
                    [model.PROPS_REPORT_STATUS]: keywords.uploading,
                };
            });
        };

        _proccessCreateReportRequest = (nextProps: P): void => {
            if (nextProps.requestIds && this.createReportRequestId) {
                if (nextProps.requestIds[this.createReportRequestId] === "SUCCESS") {
                    this.selectedReportTypes = [];
                    this.createReportRequestId = null;
                    this.setState(
                        {
                            createReportHubProgress: true,
                            selectedReportTypeNames: [],
                        },
                        () => {
                            this.props.resetReportPreferencesCache();
                            this.props.getReportPreferences();
                        }
                    );
                } else if (nextProps.requestIds[this.createReportRequestId] === "FAILURE") {
                    this.setState({ createReportHubProgress: true });
                    this.createReportRequestId = null;
                }
            }
            if (this.createReportRequestIds.length > 0 && nextProps.requestIds) {
                const allSuccess = this.createReportRequestIds.every(
                    (requestId) => nextProps.requestIds[requestId] === "SUCCESS"
                );
                const someFailed = this.createReportRequestIds.some(
                    (requestId) => nextProps.requestIds[requestId] === "FAILURE"
                );
                if (allSuccess) {
                    this.selectedReportTypes = [];
                    this.createReportRequestIds = [];
                    this.setState(
                        {
                            createReportHubProgress: true,
                            selectedReportTypeNames: [],
                        },
                        () => {
                            this.props.resetReportPreferencesCache();
                            this.props.getReportPreferences();
                        }
                    );
                } else if (someFailed) {
                    this.setState({ createReportHubProgress: true });
                    this.createReportRequestIds = [];
                }
            }
        };

        selectReportType = ({ guid, name }: { guid: string; name: string }): void => {
            const selectedReportTypeIndex = this.selectedReportTypes.indexOf(guid);
            const selectedReportTypeNames = [...this.state.selectedReportTypeNames];
            if (selectedReportTypeIndex > -1) {
                selectedReportTypeNames.splice(selectedReportTypeNames.indexOf(name), 1);
                this.selectedReportTypes.splice(selectedReportTypeIndex, 1);
            } else {
                selectedReportTypeNames.push(name);
                this.selectedReportTypes.push(guid);
            }
            this.props.mergeReportData({
                reportType: name,
                reportData: {},
            });
            this.setState({
                selectedReportTypeNames,
            });
        };

        sortFieldsByCustomerFarmField(
            selectedField: ReportBooksAPI.IField[]
        ): ReportBooksAPI.IField[] {
            return selectedField
                .map(({ customerName, farmName, name }, index) => ({
                    index,
                    value: `${customerName}${farmName}${name}`.replace(/\s/g, ""),
                }))
                .sort((a, b) =>
                    b.value.localeCompare(a.value, undefined, {
                        numeric: true,
                        sensitivity: "base",
                    })
                )
                .map((el) => selectedField[el.index]);
        }

        createReport = (): void => {
            if (useRedux) {
                return this._reduxCreateReport();
            }
            return this._originalCreateReport();
        };

        _prepareReportData = (reportData: IReportData, errorMessages: IErrorMessage[]) => {
            if (
                errorMessages.find((em) => em.message === messages.dateOrderError) != null &&
                reportData[model.PROPS_START_DATE] &&
                reportData[model.PROPS_END_DATE]
            ) {
                const startDate = new Date(reportData[model.PROPS_START_DATE]);
                const endDate = new Date(reportData[model.PROPS_END_DATE]);
                if (startDate > endDate) {
                    errorMessages.push({
                        message: messages.dateOrderError,
                        messageOptions: {},
                    });
                }
            }
            if (!reportData[model.PROPS_SELECTED_BASEMAP]) {
                reportData[model.PROPS_SELECTED_BASEMAP] = constants.DEFAULT_SELECTED_BASEMAP;
            }
            if (!reportData[model.PROPS_START_POSITION]) {
                reportData[model.PROPS_START_POSITION] = 1;
            }
            reportData[model.PROPS_USE_CUSTOM_IMAGERY] =
                reportData[model.PROPS_USE_CUSTOM_IMAGERY] || false;
        };

        _validateReportData = (selectedReportTypeNames, errorMessages, allReportData) => {
            if (this.props.selectedFields.length === 0 && !this.state.useAllFields) {
                if (
                    selectedReportTypeNames.length === 1 &&
                    selectedReportTypeNames.includes(
                        ReportAPI.ReportNames.SOIL_TESTING_REQUIRED_REPORT
                    )
                ) {
                    this.setState({ showUseAllFieldsDialog: true });
                    return;
                } else {
                    errorMessages.push({
                        message: messages.selectFieldError,
                        messageOptions: {},
                    });
                }
            }
            if (this.selectedReportTypes.length === 0) {
                errorMessages.push({
                    message: messages.selectReportTypeError,
                    messageOptions: {},
                });
            }
            if (
                this.props.recGeneralGuidToRecMap?.size > 0 &&
                this.props.selectedRecGuidSet?.size > 0 &&
                selectedReportTypeNames.includes(keywords.loadsheet)
            ) {
                const customerFarmFieldInfo: string[] = [];
                const isRecProcessing = Array.from(this.props.selectedRecGuidSet).some(
                    (recGeneralGuid) => {
                        const recSummary = this.props.recGeneralGuidToRecMap.get(recGeneralGuid);
                        const isProcessing = loadsheetPreventionStatusCodes.includes(
                            recSummary.importedStatus
                        );
                        if (isProcessing) {
                            const recField = this.props.selectedFields.find(
                                (f) => f.fieldGuid === recSummary.fieldGuid
                            );
                            customerFarmFieldInfo.push(
                                [recField.customerName, recField.farmName, recField.name]
                                    .filter((x) => x?.trim().length > 0)
                                    .join(" / ")
                            );
                        }
                        return isProcessing;
                    }
                );
                if (isRecProcessing) {
                    errorMessages.push({
                        message: errorCodeMessages[2865],
                        messageOptions: {
                            codeValue: `\n ${customerFarmFieldInfo.join("\n")}`,
                        },
                    });
                }
            }
            selectedReportTypeNames.forEach((reportType) => {
                const reportData = allReportData[reportType];
                this._prepareReportData(reportData, errorMessages);
            });
            this.setState({
                errorMessages,
                createReportHubProgress: false,
            });
        };

        _reduxCreateReport = (): void => {
            this.props.clearReportHubData();
            const errorMessages: IErrorMessage[] = [];
            const { selectedReportTypeNames } = this.state;
            const allReportData = JSON.parse(JSON.stringify(this.props.reportData));
            this._validateReportData(selectedReportTypeNames, errorMessages, allReportData);

            if (errorMessages.length === 0) {
                this._sendCreateReportRequests(selectedReportTypeNames, allReportData);
            }
        };

        _sendCreateReportRequests = (selectedReportTypeNames, allReportData) => {
            const batchGuid = uuid();
            const createReportData = [];
            selectedReportTypeNames.forEach((reportType, index) => {
                if (NEW_REPORTS.includes(selectedReportTypeNames[index])) {
                    // New reports already have everything except the field list in the report data
                    const reportData = this.props.reportData[selectedReportTypeNames[index]];
                    reportData[model.PROPS_BATCH_GUID] = batchGuid;
                    reportData[model.PROPS_FIELD_GUIDS] = this.props.selectedFields.map(
                        (f) => f.fieldGuid
                    );
                    createReportData.push({
                        model: reportData,
                        reportType: selectedReportTypeNames[index],
                    });
                    return;
                }
                const reportData = allReportData[reportType];
                if (reportData[model.PROPS_CROPPING_SEASON_GUID]) {
                    reportData[model.PROPS_CROPPING_SEASON_GUID_LIST] = [
                        reportData[model.PROPS_CROPPING_SEASON_GUID],
                    ];
                }
                reportData[model.PROPS_BATCH_GUID] = batchGuid;
                if (reportData[model.PROPS_REPORT_IMAGE_CONFIG]) {
                    reportData[model.PROPS_REPORT_IMAGE_CONFIG] = JSON.stringify(
                        reportData[model.PROPS_REPORT_IMAGE_CONFIG]
                    );
                }
                if (reportData[model.PROPS_REPORT_TYPE_PAGE_SELECTION]) {
                    reportData[model.PROPS_REPORT_TYPE_PAGE_SELECTION] = JSON.stringify(
                        reportData[model.PROPS_REPORT_TYPE_PAGE_SELECTION]
                    );
                }
                reportData[model.PROPS_SELECTED_REC_GUID_LIST] = Array.from(
                    this.props.selectedRecGuidSet
                );
                reportData[model.PROPS_SELECTED_EVENT_GUID_LIST] = Array.from(
                    this.props.selectedEventGuidSet
                );
                reportData[model.PROPS_FIELD_ITEM_LIST] = this._appendReportStatus(
                    this.sortFieldsByCustomerFarmField(this.props.selectedFields)
                );
                reportData[model.PROPS_REPORT_TYPE_GUID_LIST] = [this.selectedReportTypes[index]];
                reportData[model.PROPS_ALL_CUSTOMERS_FIELDS_YN] = this.state.useAllFields;
                createReportData.push({
                    model: reportData,
                });
            });

            const createReportRequestId = this.props.needs([
                this.props.createReports(createReportData),
            ]);
            this.createReportRequestIds.push(createReportRequestId);
        };

        _originalCreateReport = (): void => {
            const errorMessages: IErrorMessage[] = [];
            this.props.clearReportHubData();
            if (this.props.selectedFields.length === 0 && !this.state.useAllFields) {
                if (
                    this.state.selectedReportTypeNames.length === 1 &&
                    this.state.selectedReportTypeNames.includes(
                        ReportAPI.ReportNames.SOIL_TESTING_REQUIRED_REPORT
                    )
                ) {
                    this.setState({ showUseAllFieldsDialog: true });
                    return;
                } else {
                    errorMessages.push({
                        message: messages.selectFieldError,
                        messageOptions: {},
                    });
                }
            }
            if (this.selectedReportTypes.length === 0) {
                errorMessages.push({
                    message: messages.selectReportTypeError,
                    messageOptions: {},
                });
            }
            if (this.reportData[model.PROPS_START_DATE] && this.reportData[model.PROPS_END_DATE]) {
                const startDate = new Date(this.reportData[model.PROPS_START_DATE]);
                const endDate = new Date(this.reportData[model.PROPS_END_DATE]);
                if (startDate > endDate) {
                    errorMessages.push({
                        message: messages.dateOrderError,
                        messageOptions: {},
                    });
                }
            }
            if (
                this.props.recGeneralGuidToRecMap?.size > 0 &&
                this.props.selectedRecGuidSet?.size > 0 &&
                this.state.selectedReportTypeNames.includes(keywords.loadsheet)
            ) {
                const customerFarmFieldInfo: string[] = [];
                const isRecProcessing = Array.from(this.props.selectedRecGuidSet).some(
                    (recGeneralGuid) => {
                        const recSummary = this.props.recGeneralGuidToRecMap.get(recGeneralGuid);
                        const isProcessing = loadsheetPreventionStatusCodes.includes(
                            recSummary.importedStatus
                        );
                        if (isProcessing) {
                            const recField = this.props.selectedFields.find(
                                (f) => f.fieldGuid === recSummary.fieldGuid
                            );
                            customerFarmFieldInfo.push(
                                [recField.customerName, recField.farmName, recField.name]
                                    .filter((x) => x?.trim().length > 0)
                                    .join(" / ")
                            );
                        }
                        return isProcessing;
                    }
                );
                if (isRecProcessing) {
                    errorMessages.push({
                        message: errorCodeMessages[2865],
                        messageOptions: {
                            codeValue: `\n ${customerFarmFieldInfo.join("\n")}`,
                        },
                    });
                }
            }
            if (!this.reportData[model.PROPS_SELECTED_BASEMAP]) {
                this.reportData[model.PROPS_SELECTED_BASEMAP] = constants.DEFAULT_SELECTED_BASEMAP;
            }
            if (!this.reportData[model.PROPS_START_POSITION]) {
                this.reportData[model.PROPS_START_POSITION] = 1;
            }
            this.reportData[model.PROPS_USE_CUSTOM_IMAGERY] =
                this.reportData[model.PROPS_USE_CUSTOM_IMAGERY] || false;
            this.setState({
                errorMessages,
                createReportHubProgress: false,
            });

            if (errorMessages.length === 0) {
                this.reportData[model.PROPS_SELECTED_REC_GUID_LIST] = Array.from(
                    this.props.selectedRecGuidSet
                );
                this.reportData[model.PROPS_SELECTED_EVENT_GUID_LIST] = Array.from(
                    this.props.selectedEventGuidSet
                );
                this.reportData[model.PROPS_FIELD_ITEM_LIST] = this._appendReportStatus(
                    this.sortFieldsByCustomerFarmField(this.props.selectedFields)
                );
                this.reportData[model.PROPS_NEW_REPORT_TYPE_NAME_LIST] =
                    this.state.selectedReportTypeNames.filter((r) => NEW_REPORTS.includes(r));
                this.reportData[model.PROPS_REPORT_TYPE_GUID_LIST] = this.selectedReportTypes;
                this.reportData[model.PROPS_ALL_CUSTOMERS_FIELDS_YN] = this.state.useAllFields;
                this.reportData[model.PROPS_CUSTOMER_GUIDS] = Array.from(
                    this.props.selectedCustomerGuids
                );
                this.reportData[model.PROPS_NON_FIELD_FEATURE_GUIDS] = Array.from(
                    this.props.selectedNonFieldFeatureGuids
                );
                this.createReportRequestId = this.props.needs([
                    this.props.createReport({
                        model: this.reportData,
                    }),
                ]);
            }
        };

        onDialogBoxClose = (): void => {
            this.setState({
                errorMessages: [],
            });
        };
        onUseAllFieldsDialogClose = (): void => {
            this.setState({ useAllFields: false, showUseAllFieldsDialog: false });
        };
        onUseAllFieldsDialogConfirm = (): void => {
            this.setState({ useAllFields: true, showUseAllFieldsDialog: false }, this.createReport);
        };

        onInputChange = (key: string, value: any, reportType: string): void => {
            if (useRedux) {
                this.props.setReportDataValue({ key, value, reportType });
            } else {
                this.reportData[key] = value;
            }
        };

        onAllReportCreated = (): void => {
            this.setState({ createReportHubProgress: false });
        };

        componentDidMount(): void {
            this._initializeReportData();
        }

        componentWillUnmount(): void {
            this.props.resetReportData();
        }

        UNSAFE_componentWillReceiveProps(nextProps: P): void {
            this._proccessCreateReportRequest(nextProps);
        }

        render(): JSX.Element {
            const { createReportHubProgress, selectedReportTypeNames } = this.state;
            const {
                crops,
                defaultFileNameFormat,
                defaultMultiFieldRec,
                fileNameFormats,
                seasons,
                reportPreferences,
                reportTypes,
                reportTypePages,
                selectedFieldCount,
                totalingUnit,
            } = this.props;
            const props = {
                createReport: this.createReport,
                createReportHubProgress,
                crops,
                cropPurpose: this.props.cropPurpose,
                defaultFileNameFormat,
                defaultMultiFieldRec,
                fileNameFormats,
                selectedFieldCount,
                getYieldByAnalysisCategory: this.props.getYieldByAnalysisCategory,
                getYieldByCompareByOption: this.props.getYieldByCompareByOption,
                mergeReportData: this.props.mergeReportData,
                initialRequestOptions: {
                    [model.PROPS_FIELD_GUID_LIST]: getFieldGuidArray(this.props.selectedFields),
                },
                needs: this.props.needs,
                onAllReportCreated: this.onAllReportCreated,
                onInputChange: this.onInputChange,
                reportData: this.props.reportData,
                reportPreferences,
                reportTypes,
                reportTypePages,
                requestIds: this.props.requestIds,
                seasons,
                selectReportType: this.selectReportType,
                selectedEventGuidSet: this.props.selectedEventGuidSet,
                selectedFields: this.props.selectedFields,
                selectedRecGuidSet: this.props.selectedRecGuidSet,
                selectedReportTypeNames,
                selectedReportTypes: this.selectedReportTypes,
                totalingUnit,
                updateYieldByAnalysisCategory: this.props.updateYieldByAnalysisCategory,
                updateYieldByCompareByOptions: this.props.updateYieldByCompareByOptions,
            };

            const { errorMessages } = this.state;
            const { formatMessage, formatNumber } = this.props.intl;

            return (
                <div className="with-report-container">
                    <ChildComponent {...(props as unknown as P)} />
                    <DialogBox
                        footerType={DialogBoxFooterType.NONE}
                        isOpen={errorMessages.length > 0}
                        onClose={() => this.onDialogBoxClose()}
                        title={formatMessage(messages.required)}
                    >
                        <div>
                            {errorMessages.map((error, index) => (
                                <p className="error-message" key={index}>
                                    {formatMessage(error.message, error.messageOptions)}
                                </p>
                            ))}
                        </div>
                    </DialogBox>
                    <DialogBox
                        isOpen={this.state.showUseAllFieldsDialog}
                        isModal={true}
                        title={formatMessage(messages.confirmTitle)}
                        draggable={true}
                        hideCloseX={true}
                        onAction={() => this.onUseAllFieldsDialogConfirm()}
                        onClose={() => this.onUseAllFieldsDialogClose()}
                        footerType={DialogBoxFooterType.YES_NO}
                    >
                        {formatMessage(messages.useAllFields, {
                            numFields: formatNumber(this.props.quickSummary.fields),
                        })}
                    </DialogBox>
                </div>
            );
        }
    }

    return withMasked(WithReportErrors(reportContainer(injectIntl(ReportWrapper))));
};
