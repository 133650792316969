import React, { Component } from "react";
import { injectIntl, intlShape, InjectedIntlProps } from "react-intl";
import {
    Button,
    Section,
    RadioButtonGroup,
    RadioButton,
    Checkbox,
    SelectInputMulti,
    DialogBoxFooterType,
    DialogBox,
} from "~/core";
import { onTextChange, preventBubbleUp } from "~/admin/utils";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { messages } from "../i18n-messages";
import { getDefaultRecords, model } from "./data";
import { keywords } from "~/admin/containers/keywords";
import SeasonFilter from "./season-filter";
import { PreferenceAPI, UserAPI } from "@ai360/core";
import "./preference.css";
import { logFirebaseEvent } from "~/utils/firebase";
import { MultiValue } from "react-select";
import { ISelectOption } from "../../../core/components/select-input/model";
import AttributeColorOverride from "./attribute-color-override";
import { ColorOverrideAPI } from "@ai360/core";
import { IAttributeColorOverride } from "@ai360/core/dist/4x/es/api/color-override";

const LIGHT = "light";
const DARK = "dark";

interface IPreferenceProps {
    intl: intlShape;
    closeSlidingPanel: () => void;
    colorOverrides: ColorOverrideAPI.IColorOverrideResult[];
    needs: () => void;
    fetchPreference: () => void;
    savePreference: (userPreference: PreferenceAPI.IUserPreference) => void;
    fetchColorOverrides: () => void;
    fetchColorOverrideTypes: () => void;
    saveColorOverride: (
        mergeColorOverrideRequests: ColorOverrideAPI.IMergeColorOverrideRequest
    ) => void;
    deleteColorOverride: (
        deleteColorOverrideRequests: ColorOverrideAPI.IDeleteColorOverrideRequest
    ) => void;
    userPreference: PreferenceAPI.IUserPreference;
    currentTheme: string;
    switchTheme: (colorScheme: string) => void;
    setBreadcrumbs: () => void;
    userRole: UserAPI.IUserRole;
}

interface IPreferenceState {
    colorOverrides: ColorOverrideAPI.IAttributeColorOverride[];
    colorOverrideTypes: ColorOverrideAPI.IAttributeColorOverrideType[];
    userPreference: Partial<PreferenceAPI.IUserPreference>;
    preferenceSaveRequestId: string;
    colorOverrideSaveRequestId: string;
    colorOverrideDeleteRequestId: string;
    modals: IModalArray[];
    validationError: string;
}

interface IModalArray {
    id: string;
    isOpen: boolean;
}

const PREFERENCE_VALIDATION_MODAL = "preferenceValidationModal";

export class Preference extends Component<IPreferenceProps & InjectedIntlProps, IPreferenceState> {
    static labelList = [messages.webInApp, messages.mobileInApp, messages.email];
    static sampleLabelList = [messages.webInApp, messages.mobileInApp];
    static notificationCategory = ["report", "sampling"];
    static autoImportDataOptions: MultiValue<ISelectOption<string>> = [
        {
            label: "Application",
            value: "Application",
            selected: false,
        },
        {
            label: "Harvest",
            value: "Harvest",
            selected: false,
        },
        {
            label: "Planting",
            value: "Planting",
            selected: false,
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            colorOverrides: [],
            colorOverrideTypes: [],
            userPreference: getDefaultRecords(),
            preferenceSaveRequestId: null,
            colorOverrideSaveRequestId: null,
            colorOverrideDeleteRequestId: null,
            modals: [{ id: PREFERENCE_VALIDATION_MODAL, isOpen: false }],
            validationError: "",
        };
    }

    componentDidMount(): void {
        this.props.setBreadcrumbs();
        this.props.needs([this.props.fetchPreference()]);
        this.props.needs([this.props.fetchColorOverrides()]);
        this.props.needs([this.props.fetchColorOverrideTypes()]);
    }

    UNSAFE_componentWillReceiveProps(nextProps): void {
        if (nextProps.userPreference !== this.props.userPreference) {
            const revisedPreference = { ...nextProps.userPreference };
            this.setState({
                userPreference: revisedPreference,
            });
            this.#switchTheme(revisedPreference[model.PROPS_COLOR_SCHEME]);
        }

        if (nextProps.colorOverrides !== this.props.colorOverrides) {
            this.setState({
                colorOverrides: nextProps.colorOverrides.map(this.#toAttributeColorOverride),
            });
        }
        if (nextProps.colorOverrideTypes != this.props.colorOverrideTypes) {
            this.setState({
                colorOverrideTypes: nextProps.colorOverrideTypes,
            });
        }

        this.#processSavePreferenceRequest(nextProps);
    }

    #closeModal(id: string) {
        const otherModals = this.state.modals.filter((modal) => {
            return modal.id !== id;
        });
        let modalToUpdate = this.state.modals.filter((modal) => {
            return modal.id === id;
        });
        modalToUpdate = [{ ...modalToUpdate[0], isOpen: false }];

        const updatedModals = [...otherModals].concat(modalToUpdate);

        const newState: Partial<IPreferenceState> = {};

        newState.modals = updatedModals;

        this.setState(newState as IPreferenceState);
    }

    #openModal(id: string) {
        const otherModals = this.state.modals.filter((modal) => {
            return modal.id !== id;
        });
        let modalToUpdate = this.state.modals.filter((modal) => {
            return modal.id === id;
        });
        modalToUpdate = [{ ...modalToUpdate[0], isOpen: true }];

        const updatedModals = [...otherModals].concat(modalToUpdate);

        this.setState({
            modals: updatedModals,
        });
    }

    #switchTheme = (colorScheme: string | boolean): void => {
        const { switchTheme, currentTheme } = this.props;
        const theme = currentTheme.split("-")[0];
        if (!colorScheme || colorScheme === "0") {
            switchTheme(theme);
        } else {
            switchTheme(`${theme}-${LIGHT}`);
        }
    };

    #getThemeClassName = (mode: string): string => {
        const theme = this.props.currentTheme.split("-")[0];
        if (mode === LIGHT) {
            return `${theme}-${LIGHT}`;
        }
        return theme;
    };

    #getReportFormKey = (label: string): string => {
        if (label === messages.webInApp.defaultMessage) {
            return model.PROPS_RECEIVE_WEB_NOTIFICATIONS_FOR_REPORTS;
        } else if (label === messages.mobileInApp.defaultMessage) {
            return model.PROPS_RECEIVE_IN_APP_NOTIFICATIONS_FOR_REPORTS;
        } else {
            return model.PROPS_RECEIVE_EMAIL_NOTIFICATIONS_FOR_REPORTS;
        }
    };

    #getSamplingFormKey = (label: string): string => {
        if (label === messages.webInApp.defaultMessage) {
            return model.PROPS_RECEIVE_WEB_NOTIFICATIONS_FOR_SAMPLING_RESULTS;
        } else {
            return model.PROPS_RECEIVE_IN_APP_NOTIFICATIONS_FOR_SAMPLING_RESULTS;
        }
    };

    #processSavePreferenceRequest = (nextProps): void => {
        const {
            preferenceSaveRequestId,
            colorOverrideSaveRequestId,
            colorOverrideDeleteRequestId,
        } = this.state;
        if (
            nextProps.requestIds[preferenceSaveRequestId] === keywords.SUCCESS ||
            nextProps.requestIds[colorOverrideSaveRequestId] === keywords.SUCCESS ||
            nextProps.requestIds[colorOverrideDeleteRequestId] === keywords.SUCCESS
        ) {
            this.props.closeSlidingPanel();
            this.setState({
                preferenceSaveRequestId: null,
                colorOverrideSaveRequestId: null,
            });
        } else if (
            nextProps.requestIds[preferenceSaveRequestId] === keywords.FAILURE ||
            nextProps.requestIds[colorOverrideSaveRequestId] === keywords.FAILURE ||
            nextProps.requestIds[colorOverrideDeleteRequestId] === keywords.FAILURE
        ) {
            this.setState({
                preferenceSaveRequestId: null,
                colorOverrideSaveRequestId: null,
            });
        }
    };

    #onTextChange = (formKey: string, value: string | boolean, callback = null): void => {
        const { userPreference } = this.state;
        switch (formKey) {
            case model.PROPS_REAL_TIME_FIELD_UPDATES:
                logFirebaseEvent("preference_real_time");
                break;
            case model.PROPS_ORG_LEVEL_QUICK_CHANGER:
                logFirebaseEvent("preference_quick_changer");
                break;
            case model.PROPS_AUTO_IMPORT_CONFIRMATION:
                logFirebaseEvent("preference_auto_import_confirmation");
                break;
            case model.PROPS_SEASON_FILTER_LIST:
                logFirebaseEvent("preference_season_filter");
                break;
            case model.PROPS_COLOR_SCHEME:
                logFirebaseEvent("preference_color_theme");
                break;
        }
        if (formKey === model.PROPS_AUTO_IMPORT_CONFIRMATION && value === false) {
            this.#onMultiSelectChange(model.PROPS_AUTO_IMPORT_DATA, []);
        }
        this.setState({
            userPreference: onTextChange(userPreference, { formKey: [formKey], value }, callback),
        });
    };

    #onMultiSelectChange = (fromKey: string, value: string[], callback = null): void => {
        const { userPreference } = this.state;
        userPreference[fromKey] = value.sort().join(",");
        this.setState({ userPreference }, callback);
    };

    #toAttributeColorOverride = (
        override: ColorOverrideAPI.IColorOverrideResult
    ): ColorOverrideAPI.IAttributeColorOverride => {
        return {
            colorOverrideGuid: override.id,
            loginGuid: override.loginId,
            colorOverrideTypeGuid: override.colorOverrideTypeId,
            colorOverrideTypeName: null,
            foreignKeyGuid: override.foreignKeyId,
            foreignKeyValue: override.foreignKeyValue,
            varietyHybridData: override.varietyHybridData,
            colorValue: override.colorValue,
        };
    };

    #getChangeableAttributes = (colorOverride: IAttributeColorOverride) => {
        return {
            colorOverrideTypeGuid: colorOverride.colorOverrideTypeGuid,
            foreignKeyGuid: colorOverride.foreignKeyGuid,
            colorValue: colorOverride.colorValue,
        };
    };

    #getMergeAndDeleteColorOverrides = (): [
        ColorOverrideAPI.IMergeColorOverrideRequest[],
        ColorOverrideAPI.IDeleteColorOverrideRequest[]
    ] => {
        const { colorOverrides } = this.state;
        const initialColorOverrides = this.props.colorOverrides.map(this.#toAttributeColorOverride);
        const mergeColorOverrides: ColorOverrideAPI.IMergeColorOverrideRequest[] = [];
        const deleteColorOverrides: ColorOverrideAPI.IDeleteColorOverrideRequest[] = [];

        initialColorOverrides.forEach((colorOverride: IAttributeColorOverride) => {
            if (
                !colorOverrides.some((c) => c.colorOverrideGuid == colorOverride.colorOverrideGuid)
            ) {
                deleteColorOverrides.push({
                    colorOverrideId: colorOverride.colorOverrideGuid,
                });
            }
        });

        colorOverrides.forEach((colorOverride: IAttributeColorOverride) => {
            const matchedColorOverride = initialColorOverrides.find(
                (c) => c.colorOverrideGuid == colorOverride.colorOverrideGuid
            );
            if (
                matchedColorOverride == null ||
                JSON.stringify(this.#getChangeableAttributes(colorOverride)) !==
                    JSON.stringify(this.#getChangeableAttributes(matchedColorOverride))
            ) {
                mergeColorOverrides.push({
                    colorOverrideId: colorOverride.colorOverrideGuid,
                    colorOverrideTypeId: colorOverride.colorOverrideTypeGuid,
                    foreignKeyId: colorOverride.foreignKeyGuid,
                    colorValue: colorOverride.colorValue,
                });
            }
        });

        return [mergeColorOverrides, deleteColorOverrides];
    };

    #onSavePreference = (): void => {
        const { userRole } = this.props;
        const {
            preferenceSaveRequestId,
            colorOverrideSaveRequestId,
            colorOverrideDeleteRequestId,
            userPreference,
        } = this.state;

        if (userRole.autoImportData) {
            const saveValidationError = this.#saveValidationError();

            if (saveValidationError) {
                this.#openModal(PREFERENCE_VALIDATION_MODAL);
                return;
            }
        }

        if (preferenceSaveRequestId === null) {
            this.setState({
                preferenceSaveRequestId: this.props.needs([
                    this.props.savePreference(userPreference),
                ]),
            });
        }

        const mergeAndDeleteData = this.#getMergeAndDeleteColorOverrides();
        if (colorOverrideSaveRequestId === null && mergeAndDeleteData[0].length > 0) {
            this.setState({
                colorOverrideSaveRequestId: this.props.needs([
                    this.props.saveColorOverride(mergeAndDeleteData[0]),
                ]),
            });
        }

        if (colorOverrideDeleteRequestId === null && mergeAndDeleteData[1].length > 0) {
            this.setState({
                colorOverrideDeleteRequestId: this.props.needs([
                    this.props.deleteColorOverride(mergeAndDeleteData[1]),
                ]),
            });
        }
    };

    #saveValidationError = (): boolean => {
        const { userPreference } = this.state;
        const newState: Partial<IPreferenceState> = {};
        newState.validationError = "";

        if (
            userPreference[model.PROPS_AUTO_IMPORT_CONFIRMATION] &&
            userPreference[model.PROPS_AUTO_IMPORT_DATA].length === 0
        ) {
            newState.validationError = "Must select at least 1 auto import data type.";
        }

        this.setState(newState as IPreferenceState);
        return newState.validationError.length > 0;
    };

    #onCancel = (): void => {
        const { userPreference, closeSlidingPanel } = this.props;
        this.#switchTheme(userPreference.colorScheme);
        closeSlidingPanel();
    };

    #renderAttributeColorOverrideSettings = (): JSX.Element => {
        const { formatMessage } = this.props.intl;
        const { colorOverrides, colorOverrideTypes } = this.state;
        const records = colorOverrides || [];
        return (
            <Section
                headerText={formatMessage(messages.attributeColorOverrideSingular)}
                className={`attribute-color-override-section ${
                    records.length === 0 ? "no-table" : ""
                }`}
            >
                <AttributeColorOverride
                    colorOverrideTypes={colorOverrideTypes}
                    itemListAlias={formatMessage(messages.attributeColorOverridesText)}
                    formatMessage={formatMessage}
                    record={records}
                    onTextChange={(_, records) =>
                        this.#onTextChange(model.PROPS_ATTRIBUTE_COLOR_OVERRIDE_LIST, records)
                    }
                />
            </Section>
        );
    };

    #renderNotificationSettingsRow = (
        label: string,
        index: number,
        category: string
    ): JSX.Element => {
        const { userPreference } = this.state;
        let formKey: string;

        switch (category) {
            case "report":
                formKey = this.#getReportFormKey(label);
                break;
            case "sampling":
                formKey = this.#getSamplingFormKey(label);
                break;
        }

        return (
            <div key={index} className="receive-notification-row">
                <Checkbox
                    label={label}
                    tabIndex={0}
                    value={userPreference[formKey]}
                    onChange={(e, value) => this.#onTextChange(formKey, value)}
                />
            </div>
        );
    };

    #renderNotificationSettings = (): JSX.Element => {
        const { formatMessage } = this.props.intl;
        return (
            <Section headerText={formatMessage(messages.notificationSettings)}>
                <div className="notification-settings">
                    <div className="notification-section">
                        <div className="options-header">Reports</div>
                        {Preference.labelList.map((label, index) =>
                            this.#renderNotificationSettingsRow(
                                formatMessage(label),
                                index,
                                "report"
                            )
                        )}
                    </div>
                    <div className="notification-section">
                        <div className="options-header">Sample Results</div>
                        {Preference.sampleLabelList.map((label, index) =>
                            this.#renderNotificationSettingsRow(
                                formatMessage(label),
                                index,
                                "sampling"
                            )
                        )}
                    </div>
                </div>
            </Section>
        );
    };

    #renderOnsiteSettings = (): JSX.Element => {
        const { userRole } = this.props;
        const { formatMessage } = this.props.intl;
        const { userPreference } = this.state;

        const renderAutoImportData = userRole.autoImportData;
        return (
            <Section headerText={formatMessage(messages.onsitePreferences)}>
                <div>
                    {!renderAutoImportData || (
                        <div className={"onsite-autoimport-container"}>
                            <Checkbox
                                className={"onsite-autoimport"}
                                label={formatMessage(messages.autoImportDataLabel)}
                                value={
                                    userPreference[model.PROPS_AUTO_IMPORT_CONFIRMATION] || false
                                }
                                onChange={(e, value) =>
                                    this.#onTextChange(model.PROPS_AUTO_IMPORT_CONFIRMATION, value)
                                }
                            />
                            {userPreference[model.PROPS_AUTO_IMPORT_DATA] == null ? null : (
                                <SelectInputMulti
                                    isDisabled={
                                        !userPreference[model.PROPS_AUTO_IMPORT_CONFIRMATION]
                                    }
                                    className={"onsite-notifications"}
                                    required={true}
                                    clearable={true}
                                    onChange={(value) =>
                                        this.#onMultiSelectChange(
                                            model.PROPS_AUTO_IMPORT_DATA,
                                            value
                                        )
                                    }
                                    options={Preference.autoImportDataOptions}
                                    placeholder={formatMessage(messages.autoImportDataType)}
                                    sort={true}
                                    value={userPreference[model.PROPS_AUTO_IMPORT_DATA]?.split(",")}
                                />
                            )}
                        </div>
                    )}
                </div>
            </Section>
        );
    };

    #renderSeasonFilters = (): JSX.Element => {
        const { formatMessage } = this.props.intl;
        const { userPreference } = this.state;
        const records = userPreference[model.PROPS_SEASON_FILTER_LIST] || [];
        return (
            <Section
                headerText={formatMessage(messages.seasonFiltersText)}
                className={`season-filter-section ${records.length === 0 ? "no-table" : ""}`}
            >
                <SeasonFilter
                    itemListAlias={formatMessage(messages.seasonFiltersText)}
                    formatMessage={formatMessage}
                    record={records}
                    onTextChange={(_, records) =>
                        this.#onTextChange(model.PROPS_SEASON_FILTER_LIST, records)
                    }
                />
            </Section>
        );
    };

    #renderGeneralSettings = (): JSX.Element => {
        const { userRole } = this.props;
        const { userPreference } = this.state;
        const { formatMessage } = this.props.intl;

        const renderRealTimeUpdate = userRole.realTimeUpdates;
        const renderOrgLevelQuickChanger = userRole.orgLevelQuickChanger;
        const renderMyAgData = userRole.enableMyAgData;
        return (
            <Section headerText={formatMessage(messages.generalSettingsText)}>
                <div>
                    {!renderRealTimeUpdate ? null : (
                        <Checkbox
                            className={"onsite-notifications"}
                            label={formatMessage(messages.realTimeUpdatesLabel)}
                            value={userPreference[model.PROPS_REAL_TIME_FIELD_UPDATES] || false}
                            onChange={(e, value) =>
                                this.#onTextChange(model.PROPS_REAL_TIME_FIELD_UPDATES, value)
                            }
                        />
                    )}
                    {!renderOrgLevelQuickChanger ? null : (
                        <Checkbox
                            className={"onsite-notifications"}
                            label={formatMessage(messages.orgLevelQuickChanger)}
                            value={userPreference[model.PROPS_ORG_LEVEL_QUICK_CHANGER] || false}
                            onChange={(e, value) =>
                                this.#onTextChange(model.PROPS_ORG_LEVEL_QUICK_CHANGER, value)
                            }
                        />
                    )}

                    {!renderMyAgData ? null : (
                        <Checkbox
                            className={"onsite-notifications"}
                            label={formatMessage(messages.enableMyAgData)}
                            value={userPreference[model.PROPS_ORG_ENABLE_MYAGDATA] || false}
                            onChange={(e, value) =>
                                this.#onTextChange(model.PROPS_ORG_ENABLE_MYAGDATA, value)
                            }
                        />
                    )}
                </div>
            </Section>
        );
    };

    #renderPreference = (): JSX.Element => {
        const { formatMessage } = this.props.intl;
        const { modals, userPreference } = this.state;
        return (
            <div className="add-edit-panel">
                {this.props.userRole.autoImportData ||
                this.props.userRole.realTimeUpdates ||
                this.props.userRole.orgLevelQuickChanger
                    ? this.#renderGeneralSettings()
                    : null}
                {this.#renderSeasonFilters()}
                {this.#renderNotificationSettings()}
                {this.#renderOnsiteSettings()}
                <Section
                    headerText={formatMessage(messages.appColorTheme)}
                    className="theme-selector"
                >
                    <RadioButtonGroup
                        className="theme-control-cont"
                        selectedValue={userPreference[model.PROPS_COLOR_SCHEME] || "0"}
                        afterOnChange={(value) => {
                            this.#onTextChange(model.PROPS_COLOR_SCHEME, value);
                            this.#switchTheme(value);
                        }}
                    >
                        <RadioButton
                            tabIndex={0}
                            value={"0"}
                            labelClassName={this.#getThemeClassName(DARK)}
                        />
                        <RadioButton
                            tabIndex={0}
                            value={"1"}
                            labelClassName={this.#getThemeClassName(LIGHT)}
                        />
                    </RadioButtonGroup>

                    <DialogBox
                        className="preference-validation-modal"
                        footerType={DialogBoxFooterType.NONE}
                        isOpen={
                            modals.filter((modal) => {
                                return modal.id === PREFERENCE_VALIDATION_MODAL;
                            })[0].isOpen
                        }
                        onClose={() => {
                            this.#closeModal(PREFERENCE_VALIDATION_MODAL);
                        }}
                        title={"Preference Validation Error"}
                    >
                        <p>{this.state.validationError}</p>
                    </DialogBox>
                </Section>
                {this.props.userRole.attributeColorOverride
                    ? this.#renderAttributeColorOverrideSettings()
                    : null}
            </div>
        );
    };

    render(): JSX.Element {
        const props = {
            children: [
                <Button key="0" type="save" forceSubmit onClick={this.#onSavePreference} />,
                <Button key="1" type="cancel" onClick={this.#onCancel} />,
            ],
            component: this.#renderPreference,
            navigateTo: { parentNameCode: 100, childNameCode: 1 },
        };

        return (
            <div className="user-preference-main-container">
                <form onSubmit={(event) => preventBubbleUp(event)}>
                    <SlidingPanel {...props} />
                </form>
            </div>
        );
    }
}
export default injectIntl(Preference);
