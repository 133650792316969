import { combineReducers } from "redux";

import { accordionReducer } from "~/accordion";
import * as immutableUtils from "~/utils/immutable";

import * as actions from "./actions";
import * as selectors from "./selectors";

import * as nonFieldDataSelectors from "./non-field-data/selectors";
import Immutable from "immutable";

const layerModuleInitialState = {
    accordionInit: {
        scrollTop: 0,
    },
    cadastralLegendInfos: [],
    classificationMethods: [],
    colorRamps: [],
    colorSchemeOptions: [],
    currentTabIndex: 0,
    expandedFieldGuidSet: new Set(),
    fieldGuidToFieldMap: Immutable.OrderedMap(),
    filter: {
        croppingSeasonGuid: "",
        cropGuid: "",
        layerType: "",
        onlySelected: false,
    },
    filterInfo: {
        croppingSeasons: new Map(),
        crops: new Map(),
        filters: {
            all: [],
            croppingSeasons: [],
            crops: [],
            layerTypes: [],
        },
        layerTypes: new Map(),
    },
    filterOptions: {
        cropOptions: [],
        croppingSeasonOptions: [],
        layerTypeOptions: [],
    },
    layerInfos: new Map(),
    layerPanelLoading: false,
    loadingFieldGuidSet: new Set(),
    loadingSampleSitesSet: new Set(),
    loadingSurfacesSet: new Set(),
    numberOfClasses: [],
    showFilters: false,
    searchValue: "",
    splitScreenCompareTool: {
        displayEventSurfaceStats: false,
        eventSurfaceStatsLoading: false,
        fieldGuid: null,
        surfaceInfo: null,
        tableRecords: [],
    },
    visibleSampleSites: new Map(),
    visibleSurfaces: new Map(),
};

const reducer = (state = layerModuleInitialState, action) => {
    switch (action.type) {
        case actions.CLEAR_LAYER_FILTER: {
            const layerFilter = {
                cropGuid: null,
                layerType: null,
                croppingSeasonGuid: null,
            };
            const filter = {
                ...state.filter,
                ...layerFilter,
            };
            return Object.freeze({
                ...state,
                filter,
            });
        }
        case actions.CLEAR_TABLE_RECORDS:
            return Object.freeze({
                ...state,
                splitScreenCompareTool: {
                    ...state.splitScreenCompareTool,
                    tableRecords: [],
                },
            });
        case actions.SET_DISPLAY_EVENT_SURFACE_STATS:
            return Object.freeze({
                ...state,
                splitScreenCompareTool: {
                    ...state.splitScreenCompareTool,
                    displayEventSurfaceStats: action.displayEventSurfaceStats,
                },
            });
        case actions.SET_ACTIVATE_ZONE_SPLIT:
            return Object.freeze({
                ...state,
                splitScreenCompareTool: {
                    ...state.splitScreenCompareTool,
                    activateZoneSplit: action.payload.activateZoneSplit,
                },
            });
        case actions.SET_CLEAR_DGG_STATS:
            return Object.freeze({
                ...state,
                splitScreenCompareTool: {
                    ...state.splitScreenCompareTool,
                    clearDggStats: action.payload.clearDggStats,
                },
            });
        case actions.SET_CURRENT_VIEW_MAP_COUNT:
            return Object.freeze({
                ...state,
                splitScreenCompareTool: {
                    ...state.splitScreenCompareTool,
                    currentViewMapCount: action.payload.currentViewMapCount,
                },
            });
        case actions.SET_EVENT_SURFACE_STATS_LOADING:
            return Object.freeze({
                ...state,
                splitScreenCompareTool: {
                    ...state.splitScreenCompareTool,
                    eventSurfaceStatsLoading: action.isLoading,
                },
            });
        case actions.FETCH_COLOR_SCHEMES_SUCCESS:
            return Object.freeze({
                ...state,
                colorSchemeOptions: action.payload,
            });
        case actions.FETCH_LEGEND_INFO_SUCCESS:
            return Object.freeze({
                ...state,
                cadastralLegendInfos: action.payload.cadastralLegendInfos,
            });
        case actions.FETCH_SYMBOLOGY_OPTIONS_SUCCESS:
            return Object.freeze({
                ...state,
                classificationMethods: action.payload.classifications,
                colorRamps: action.payload.colorRamps,
                numberOfClasses: action.payload.numberOfClasses,
            });
        case actions.FETCH_TABLE_RECORDS_SUCCESS:
            return Object.freeze({
                ...state,
                splitScreenCompareTool: {
                    ...state.splitScreenCompareTool,
                    tableRecords: action.payload,
                },
            });
        case actions.REMOVE_LAYER_INFOS:
            return Object.freeze({
                ...state,
                layerInfos: immutableUtils.noMutateMapDeleteKeys(
                    state.layerInfos,
                    action.fieldGuids
                ),
            });
        case actions.REMOVE_ALL_VISIBLE_SAMPLE_SITES:
            return Object.freeze({
                ...state,
                visibleSampleSites: new Map(),
            });
        case actions.REMOVE_ALL_VISIBLE_SURFACES:
            return Object.freeze({
                ...state,
                visibleSurfaces: new Map(),
            });
        case actions.REMOVE_VISIBLE_SAMPLE_SITES:
            return Object.freeze({
                ...state,
                visibleSampleSites: immutableUtils.noMutateMapDeleteKeys(
                    state.visibleSampleSites,
                    action.fieldGuids
                ),
            });
        case actions.REMOVE_VISIBLE_SURFACES:
            return Object.freeze({
                ...state,
                visibleSurfaces: immutableUtils.noMutateMapDeleteKeys(
                    state.visibleSurfaces,
                    action.fieldGuids
                ),
            });
        case actions.SET_CURRENT_TAB_INDEX:
            return Object.freeze({
                ...state,
                currentTabIndex: action.index,
            });
        case actions.SET_FIELD_GUID_TO_FIELD_MAP:
            return Object.freeze({
                ...state,
                fieldGuidToFieldMap: action.map,
            });
        case actions.SET_LAYER_FILTER:
            return Object.freeze({
                ...state,
                filter: {
                    ...state.filter,
                    ...action.filter,
                },
            });
        case actions.SET_LAYER_FILTER_INFO:
            return Object.freeze({
                ...state,
                filterInfo: {
                    ...state.filterInfo,
                    ...action.filterInfo,
                },
            });
        case actions.SET_LAYER_FILTER_OPTIONS:
            return Object.freeze({
                ...state,
                filterOptions: {
                    ...state.filterOptions,
                    ...action.filterOptions,
                },
            });
        case actions.SET_LAYER_INFO:
            return Object.freeze({
                ...state,
                layerInfos: immutableUtils.noMutateMapSet(
                    state.layerInfos,
                    action.fieldGuid,
                    action.layerInfo
                ),
            });
        case actions.SET_LAYER_STATUS: {
            let layerInfoMap = state.layerInfos;
            for (const [fieldGuid, layerInfos] of layerInfoMap) {
                for (const layerInfo of layerInfos) {
                    const { analysisLayerGuid } = layerInfo;
                    if (
                        analysisLayerGuid != null &&
                        analysisLayerGuid === action.analysisLayerGuid
                    ) {
                        layerInfo.status = action.status;
                        layerInfoMap = immutableUtils.noMutateMapSet(
                            layerInfoMap,
                            fieldGuid,
                            layerInfos
                        );
                    }
                }
            }

            return Object.freeze({
                ...state,
                layerInfos: layerInfoMap,
            });
        }
        case actions.SET_ANALYSIS_LAYER_STATUS: {
            let layerInfoMap = state.layerInfos;
            for (const [fieldGuid, layerInfos] of layerInfoMap) {
                for (const layerInfo of layerInfos) {
                    const { analysisLayerGuid } = layerInfo;
                    if (
                        analysisLayerGuid != null &&
                        analysisLayerGuid === action.analysisLayerGuid
                    ) {
                        layerInfo.analysisLayerStatus = action.analysisLayerStatus;
                        layerInfoMap = immutableUtils.noMutateMapSet(
                            layerInfoMap,
                            fieldGuid,
                            layerInfos
                        );
                    }
                }
            }

            return Object.freeze({
                ...state,
                layerInfos: layerInfoMap,
            });
        }
        case actions.SET_PANEL_LOADING:
            return Object.freeze({
                ...state,
                layerPanelLoading: action.panelLoading,
            });
        case actions.SET_SCROLL_TOP:
            return Object.freeze({
                ...state,
                accordionInit: {
                    ...state.accordionInit,
                    scrollTop: action.scrollTop,
                },
            });
        case actions.SET_SHOW_FILTERS:
            return Object.freeze({
                ...state,
                showFilters: action.showFilters,
            });
        case actions.SET_SPLIT_SCREEN_COMPARE_TOOL:
            return Object.freeze({
                ...state,
                splitScreenCompareTool: {
                    ...state.splitScreenCompareTool,
                    ...action.splitScreenCompareTool,
                },
            });
        case actions.SET_VISIBLE_SAMPLE_SITES:
            return Object.freeze({
                ...state,
                visibleSampleSites: immutableUtils.noMutateMapSet(
                    state.visibleSampleSites,
                    action.fieldGuid,
                    action.layer
                ),
            });
        case actions.SET_VISIBLE_SURFACE:
            return Object.freeze({
                ...state,
                visibleSurfaces: immutableUtils.noMutateMapSet(
                    state.visibleSurfaces,
                    action.fieldGuid,
                    action.surfaceInfo
                ),
            });
        case actions.SET_VISIBLE_SURFACES:
            return Object.freeze({
                ...state,
                visibleSurfaces: immutableUtils.noMutateMapSetAll(
                    state.visibleSurfaces,
                    action.newVisibleSurfaces
                ),
            });
        case actions.UPDATE_EXPANDED_FIELD_GUID_SET:
            return Object.freeze({
                ...state,
                expandedFieldGuidSet: immutableUtils.noMutateSetChange(
                    action.isExpanded,
                    state.expandedFieldGuidSet,
                    action.fieldGuids
                ),
            });
        case actions.UPDATE_LOADING_FIELD_GUID_SET:
            return Object.freeze({
                ...state,
                loadingFieldGuidSet: immutableUtils.noMutateSetChange(
                    action.isLoading,
                    state.loadingFieldGuidSet,
                    action.fieldGuid
                ),
            });
        case actions.UPDATE_LOADING_SAMPLE_SITES_SET:
            return Object.freeze({
                ...state,
                loadingSampleSitesSet: immutableUtils.noMutateSetChange(
                    action.isLoading,
                    state.loadingSampleSitesSet,
                    action.agEventGeneralGuid
                ),
            });
        case actions.UPDATE_LOADING_SURFACES_SET:
            return Object.freeze({
                ...state,
                loadingSurfacesSet: immutableUtils.noMutateSetChange(
                    action.isLoading,
                    state.loadingSurfacesSet,
                    action.surfaceGuid
                ),
            });
        case actions.UPDATE_SEARCH: {
            const { searchValue } = action.payload;
            return Object.freeze({
                ...state,
                searchValue: searchValue,
            });
        }
        default:
            return state;
    }
};

export const layerListReducer = combineReducers({
    [selectors.ACCORDION_KEY]: accordionReducer,
    [selectors.MAIN_REDUCER_KEY]: reducer,
    [nonFieldDataSelectors.ACCORDION_KEY]: accordionReducer,
});
