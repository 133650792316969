import {
    AppHelpers,
    BasemapUtils,
    ConversionUtils,
    MapConfig,
    ReportAPI,
    ReportsConfig,
    ReportsModels,
} from "@ai360/core";
import { model } from "~/reports/data";
import * as constants from "~/reports/data/constants";
import { IReportData } from "~/reports/hocs/with-report";

export function getFieldGuidArray(selectedFields) {
    const updatedSelectedFields = selectedFields.map(({ fieldGuid }) => fieldGuid);
    if (updatedSelectedFields.length > 0) {
        return updatedSelectedFields;
    }
    return null;
}

export function getBasemapOptions() {
    const customReportBaseMaps = Object.keys(MapConfig.customReportBasemaps).map((cb) => ({
        label: MapConfig.customReportBasemaps[cb].title,
        value: cb,
    }));
    return Object.keys(ConversionUtils.BasemapConversion)
        .filter(
            (b) => MapConfig.reportBasemaps.indexOf(b) > -1 && !MapConfig.customReportBasemaps[b]
        )
        .map((b) => ({
            label: BasemapUtils.getBasemapById(ConversionUtils.BasemapConversion[b], true).title,
            value: b,
        }))
        .concat(customReportBaseMaps)
        .sort((a, b) => {
            if (a.label < b.label) {
                return -1;
            }
            if (a.label > b.label) {
                return 1;
            }
            return 0;
        });
}

export function getDefaultReportImageConfig(mapkey) {
    const boundaryRC = ReportsConfig.getReportImageConfig(mapkey);
    return JSON.parse(JSON.stringify(boundaryRC));
}

export function getFieldsPerPageOptions() {
    return [
        {
            label: "Grower",
            value: "grower",
        },
        {
            label: "Farm",
            value: "farm",
        },
        {
            label: "Field",
            value: "field",
        },
    ];
}

export function getLabelSizeOptions() {
    return [
        {
            label: "1",
            value: "1",
        },
        {
            label: "2",
            value: "2",
        },
        {
            label: "3",
            value: "3",
        },
        {
            label: "4",
            value: "4",
        },
        {
            label: "5",
            value: "5",
        },
        {
            label: "6",
            value: "6",
        },
        {
            label: "7",
            value: "7",
        },
        {
            label: "8",
            value: "8",
        },
    ];
}

export function getPageSizeOptions() {
    return Object.keys(ReportsModels.PageDimensions).map((p) => ({
        label: ReportsModels.PageDimensions[p].label,
        value: p,
    }));
}

export function getZoomLevelOptions() {
    return Object.keys(ReportsModels.ZoomLevelDetails).map((z) => ({
        label: ReportsModels.ZoomLevelDetails[z].label,
        value: z,
    }));
}

export function getReportDataFromPreferences(reportPreferences) {
    return {
        [model.PROPS_AGE_OF_SAMPLE]: AppHelpers.isNothing(
            reportPreferences[model.PROPS_AGE_OF_SAMPLE]
        )
            ? constants.DEFAULT_AGE_OF_SAMPLE
            : reportPreferences[model.PROPS_AGE_OF_SAMPLE],
        [model.PROPS_AGRPL_DISPLAY_OPTION]:
            reportPreferences[model.PROPS_AGRPL_DISPLAY_OPTION] ||
            getFieldsPerPageOptions()[0].value,
        [model.PROPS_AGVANCE_COLOR]:
            reportPreferences[model.PROPS_AGVANCE_COLOR] || constants.DEFAULT_CROP_COLOR,
        [model.PROPS_BOUNDARY_MAP_QR]: reportPreferences[model.PROPS_BOUNDARY_MAP_QR],
        [model.PROPS_BREAK_EVEN_CROP_DATA]: reportPreferences.breakEvenCropList || [],
        [model.PROPS_CROP_MAP_ORIENTATION]:
            reportPreferences[model.PROPS_CROP_MAP_ORIENTATION] ||
            constants.DEFAULT_CROP_MAP_ORIENTATION,
        [model.PROPS_CROP_ZONE]:
            reportPreferences[model.PROPS_CROP_ZONE] || constants.DEFAULT_CROP_ZONE,
        [model.PROPS_DISPLAY_FIELD_ACRES]: true,
        [model.PROPS_DISPLAY_PARAMETERS]: reportPreferences[model.PROPS_DISPLAY_PARAMETERS],
        [model.PROPS_DISPLAY_SAMPLE_SITES]: reportPreferences[model.PROPS_DISPLAY_SAMPLE_SITES],
        [model.PROPS_DISPLAY_TRAITS]: false,
        [model.PROPS_DISPLAY_TYPE]:
            reportPreferences[model.PROPS_DISPLAY_TYPE] || constants.DEFAULT_DISPLAY_TYPE,
        [model.PROPS_FIELD_LABEL_CONFIG]:
            typeof reportPreferences[model.PROPS_FIELD_LABEL_CONFIG] == "string"
                ? reportPreferences[model.PROPS_FIELD_LABEL_CONFIG]
                : JSON.stringify(reportPreferences[model.PROPS_FIELD_LABEL_CONFIG]) || null,
        [model.PROPS_FIELDS_PER_PAGE]: AppHelpers.isNothing(
            reportPreferences[model.PROPS_FIELDS_PER_PAGE]
        )
            ? getFieldsPerPageOptions()[0].value
            : getFieldsPerPageOptions()[reportPreferences[model.PROPS_FIELDS_PER_PAGE]].value,
        [model.PROPS_GENERAL_NOTES]: reportPreferences[model.PROPS_GENERAL_NOTES],
        [model.PROPS_GROWER_SUMMARY_DISPLAY_OPTION]: AppHelpers.isNothing(
            reportPreferences[model.PROPS_GROWER_SUMMARY_DISPLAY_OPTION]
        )
            ? getFieldsPerPageOptions()[0].value
            : getFieldsPerPageOptions()[
                  reportPreferences[model.PROPS_GROWER_SUMMARY_DISPLAY_OPTION]
              ].value,
        [model.PROPS_HGSUMMARY_DISPLAY_OPTION]:
            reportPreferences[model.PROPS_HGSUMMARY_DISPLAY_OPTION] ||
            getFieldsPerPageOptions()[0].value,
        [model.PROPS_INCLUDE_PRODUCT_RECAP]: reportPreferences[model.PROPS_INCLUDE_PRODUCT_RECAP],
        [model.PROPS_LAB_ACCOUNT]: reportPreferences[model.PROPS_LAB_ACCOUNT],
        [model.PROPS_LABEL_SIZE]:
            reportPreferences[model.PROPS_LABEL_SIZE] || getLabelSizeOptions()[1].value,
        [model.PROPS_LABEL_TYPE]:
            reportPreferences[model.PROPS_LABEL_TYPE] || constants.DEFAULT_CROP_MAP_LABEL_TYPE,
        [model.PROPS_LEGEND_TYPE]:
            reportPreferences[model.PROPS_LEGEND_TYPE] || constants.DEFAULT_CROP_MAP_LEGEND_TYPE,
        [model.PROPS_LOW_RATE_LEGEND]: reportPreferences[model.PROPS_LOW_RATE_LEGEND],
        [model.PROPS_NUMBER_OF_SEASONS]:
            reportPreferences[model.PROPS_NUMBER_OF_SEASONS] || constants.DEFAULT_NUMBER_OF_SEASONS,
        [model.PROPS_MULTIPLE_MAPS_PER_PAGE]: reportPreferences[model.PROPS_MULTIPLE_MAPS_PER_PAGE],
        [model.PROPS_NUTRIENT_TARGET_COLORS]: reportPreferences[model.PROPS_NUTRIENT_TARGET_COLORS],
        [model.PROPS_ORIENTATION]:
            reportPreferences[model.PROPS_ORIENTATION] || constants.DEFAULT_ORIENTATION,
        [model.PROPS_ORIGINAL_FORMAT]: reportPreferences[model.PROPS_ORIGINAL_FORMAT],
        [model.PROPS_PAGE_SIZE]: AppHelpers.isNothing(reportPreferences[model.PROPS_PAGE_SIZE])
            ? getPageSizeOptions()[0].value
            : reportPreferences[model.PROPS_PAGE_SIZE], // letter
        [model.PROPS_PLANTING_ATTRIBUTE]:
            reportPreferences[model.PROPS_PLANTING_ATTRIBUTE] ||
            constants.DEFAULT_PLANTING_ATTRIBUTE,
        [model.PROPS_PLANTING_REC_COST_DETAIL]:
            reportPreferences[model.PROPS_PLANTING_REC_COST_DETAIL],
        [model.PROPS_PLSS_INFO]: reportPreferences[model.PROPS_PLSS_INFO],
        [model.PROPS_PRINT_FARM_TOTALS]: reportPreferences[model.PROPS_PRINT_FARM_TOTALS],
        [model.PROPS_PRINT_FIELD_TOTALS]: reportPreferences[model.PROPS_PRINT_FIELD_TOTALS],
        [model.PROPS_PRINT_GROWER_TOTALS]: reportPreferences[model.PROPS_PRINT_GROWER_TOTALS],
        [model.PROPS_REPORT_BOOK_GROUP_BY]: AppHelpers.isNothing(
            reportPreferences[model.PROPS_REPORT_BOOK_GROUP_BY]
        )
            ? getFieldsPerPageOptions()[0].value
            : getFieldsPerPageOptions()[reportPreferences[model.PROPS_REPORT_BOOK_GROUP_BY]].value,
        [model.PROPS_INDIVIDUAL_RB]: reportPreferences[model.PROPS_INDIVIDUAL_RB],
        [model.PROPS_REPORT_IMAGE_CONFIG]: AppHelpers.isNothing(
            reportPreferences[model.PROPS_REPORT_IMAGE_CONFIG]
        )
            ? getDefaultReportImageConfig(ReportAPI.ReportNames.REPORT_BOUNDARY_MAP)
            : JSON.parse(reportPreferences[model.PROPS_REPORT_IMAGE_CONFIG]),
        [model.PROPS_REPORT_TYPE_PAGE_SELECTION]:
            reportPreferences[model.PROPS_REPORT_TYPE_PAGE_SELECTION],
        [model.PROPS_SAMPLE_BAG_LABEL_TYPE]:
            reportPreferences[model.PROPS_SAMPLE_BAG_LABEL_TYPE] ||
            constants.DEFAULT_SOIL_BAG_LABEL_ATTRIBUTE,
        [model.PROPS_SELECTED_BASEMAP]: reportPreferences[model.PROPS_SELECTED_BASEMAP],
        [model.PROPS_SELECTED_TEST_PACKAGES]: reportPreferences.labSubmissionTestPackageGuid,
        [model.PROPS_SELECTED_TESTING_LAB]: reportPreferences.labSubmissionTestingLabVendorGuid,
        [model.PROPS_SHOW_FARM_TOTALS]: reportPreferences[model.PROPS_SHOW_FARM_TOTALS] || true,
        [model.PROPS_SHOW_GROWER_TOTALS]: reportPreferences[model.PROPS_SHOW_GROWER_TOTALS] || true,
        [model.PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL]:
            reportPreferences[model.PROPS_SHOW_GROWER_SUMMARY_COST_DETAIL],
        [model.PROPS_SHOW_GUARANTEED_ANALYSIS]:
            reportPreferences[model.PROPS_SHOW_GUARANTEED_ANALYSIS],
        [model.PROPS_SHOW_LOADSHEET_PRODUCT_DETAIL]:
            reportPreferences[model.PROPS_SHOW_LOADSHEET_PRODUCT_DETAIL],
        [model.PROPS_SHOW_LOADSHEET_MULTI_FIELD_REC]:
            reportPreferences[model.PROPS_SHOW_LOADSHEET_MULTI_FIELD_REC] || false,
        [model.PROPS_SHOW_NON_FIELD_FEATURES]:
            reportPreferences[model.PROPS_SHOW_NON_FIELD_FEATURES],
        [model.PROPS_SHOW_PHOTOS]: reportPreferences[model.PROPS_SHOW_PHOTOS],
        [model.PROPS_SHOW_PRODUCT_REC_COST_DETAIL]:
            reportPreferences[model.PROPS_SHOW_PRODUCT_REC_COST_DETAIL],
        [model.PROPS_SHOW_PRODUCT_REC_SUMMARY_COST_DETAIL]:
            reportPreferences[model.PROPS_SHOW_PRODUCT_REC_SUMMARY_COST_DETAIL],
        [model.PROPS_SHOW_REC_SUMMARY]: reportPreferences[model.PROPS_SHOW_REC_SUMMARY],
        [model.PROPS_SHOW_SCOUTING_DETAILS]: reportPreferences[model.PROPS_SHOW_SCOUTING_DETAILS],
        [model.PROPS_SHOW_YIELD_POLYGON]: reportPreferences[model.PROPS_SHOW_YIELD_POLYGON],
        [model.PROPS_USE_IMPORT_DATE]: reportPreferences[model.PROPS_USE_IMPORT_DATE],
        [model.PROPS_SOIL_TYPE_ATTRIBUTE]: reportPreferences[model.PROPS_SOIL_TYPE_ATTRIBUTE],
        [model.PROPS_TESTING_LAB_SAMPLE_TYPE_GUID]:
            reportPreferences[model.reportPreferenceKeys.labSubmissionSampleTypeGuid],
        [model.PROPS_USE_CROP_PLANNING_SUMMARY]:
            reportPreferences[model.PROPS_USE_CROP_PLANNING_SUMMARY],
        [model.PROPS_USE_CUSTOM_IMAGERY]: reportPreferences[model.PROPS_USE_CUSTOM_IMAGERY],
        [model.PROPS_USE_OVERVIEW_MAP]: reportPreferences[model.PROPS_USE_OVERVIEW_MAP],
        [model.PROPS_USE_PRODUCT_RECS]: reportPreferences[model.PROPS_USE_PRODUCT_RECS],
        [model.PROPS_VARIETY_HYBRID_TYPE]: constants.DEFAULT_VARIETY_HYBRID_TYPE,
        [model.PROPS_YIELD_ATTRIBUTE]:
            reportPreferences[model.PROPS_YIELD_ATTRIBUTE] || constants.DEFAULT_YIELD_ATTRIBUTE,
        [model.PROPS_SKY_APPLICATION_ATTRIBUTE]:
            reportPreferences[model.PROPS_SKY_APPLICATION_ATTRIBUTE] ||
            constants.DEFAULT_SKY_APP_ATTRIBUTE,
        [model.PROPS_APPLICATION_ATTRIBUTE]:
            reportPreferences[model.PROPS_APPLICATION_ATTRIBUTE] ||
            constants.DEFAULT_APPLICATION_ATTRIBUTE,
        [model.PROPS_ZOOM_LEVEL]: AppHelpers.isNothing(reportPreferences[model.PROPS_ZOOM_LEVEL])
            ? getZoomLevelOptions()[0].value
            : reportPreferences[model.PROPS_ZOOM_LEVEL],
        [model.PROPS_PLANTING_SUMMARY]: true,
    } as Partial<IReportData>;
}
