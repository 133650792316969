import React, { Component } from "react";
import { InjectedIntlProps } from "react-intl";
import _ from "lodash";
import {
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    NumericInput,
    ZeroToInfiniteGrid,
    SelectInput,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import { messages } from "../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";
import { adminData } from "~/admin/data";
import { IComponentState, INutrientListProps } from "../interfaces";

const PROPS_NUTRIENT_GUID = "nutrientGuid";
const PROPS_NUTRIENT_TYPE = "nutrientName";
const PROPS_NUTRIENT_PERCENTAGE = "nutrientPercent";
const PROPS_NUTRIENT_IS_PRIMARY = "isPrimary";

class NutrientList extends Component<INutrientListProps & InjectedIntlProps, IComponentState> {
    constructor(props) {
        super(props);
        this.state = {
            editOptions: [],
            editingItem: false,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { record, options } = nextProps;
        if (record && record.length > 0) {
            if (options) {
                this.initializeNutrientData(nextProps);
            }
        }
        if (options !== this.state.editOptions) {
            this.initializeNutrients(options);
        }
    }

    initializeNutrientData = ({ record = [], options }) => {
        return record.map((recordItem) => {
            const selectedNutrientName = _.find(
                options,
                (nutrient) => nutrient[PROPS_NUTRIENT_GUID] === recordItem[PROPS_NUTRIENT_GUID]
            );
            recordItem[PROPS_NUTRIENT_TYPE] =
                selectedNutrientName && selectedNutrientName[PROPS_NUTRIENT_TYPE];
            return recordItem;
        });
    };

    initializeNutrients = (options) => {
        this.setState({
            editOptions: prepareSelectableOptions(options, {
                guid: PROPS_NUTRIENT_GUID,
                label: PROPS_NUTRIENT_TYPE,
                id: "",
            }),
        });
    };

    onEdit = (record, index) => {
        this.setState({ editingItem: true });
        const keysToUpdate = [
            { name: [PROPS_NUTRIENT_TYPE], key: PROPS_NUTRIENT_TYPE },
            { name: [PROPS_NUTRIENT_GUID], key: PROPS_NUTRIENT_GUID },
            PROPS_NUTRIENT_PERCENTAGE,
        ];
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onDelete = (record, index) => {
        this.props.onDelete({ record, index });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        const formData = { ...editData };
        return (
            <DialogBox
                forceOverflow
                hideCloseX
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.nutrient
                )}
                actionDisabled={!editData[PROPS_NUTRIENT_PERCENTAGE]}
                onClose={() => toggleModal("isEditing", false)}
                onAction={() => onAddOrEditItem()}
                isOpen={isEditing}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
            >
                <SelectInput
                    tabIndex={0}
                    initialFilterStr=""
                    optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                    options={this.state.editOptions}
                    placeholderText={formatMessage(messages.nutrient)}
                    value={mapToPicklistValue({
                        options: this.state.editOptions,
                        selectedGuid: formData[PROPS_NUTRIENT_GUID],
                    })}
                    onChange={({ guid, name }) => {
                        onChange({
                            [PROPS_NUTRIENT_GUID]: guid,
                            [PROPS_NUTRIENT_TYPE]: name,
                        });
                    }}
                    required
                />
                <NumericInput
                    tabIndex={0}
                    scale={4}
                    precision={7}
                    containerClassNames={["form-input-id"]}
                    placeholderText={formatMessage(messages.nutrientPercent)}
                    labelText={formatMessage(messages.nutrientPercent)}
                    value={formData[PROPS_NUTRIENT_PERCENTAGE] || ""}
                    onChange={(value) => onChange({ [PROPS_NUTRIENT_PERCENTAGE]: value })}
                    required
                />
            </DialogBox>
        );
    };

    printIsPrimary = (value, record) => {
        return (
            <Checkbox
                className="agvance-checkbox nutrient-primary-checkbox"
                value={record.isPrimary}
                onChange={(event, val) => {
                    event.stopPropagation();
                    if (val) {
                        const newNutrientList = this.props.record.map((n) => {
                            return {
                                ...n,
                                [PROPS_NUTRIENT_IS_PRIMARY]:
                                    n[PROPS_NUTRIENT_GUID] === record[PROPS_NUTRIENT_GUID] && val,
                            };
                        });
                        this.props.onTextChange(null, newNutrientList);
                    }
                }}
            />
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_NUTRIENT_TYPE]: {
                                title: formatMessage(messages.nutrient),
                            },
                            [PROPS_NUTRIENT_PERCENTAGE]: {
                                title: formatMessage(messages.percentage),
                            },
                            [PROPS_NUTRIENT_IS_PRIMARY]: {
                                title: formatMessage(messages.primary),
                                printer: this.printIsPrimary,
                            },
                        }}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.nutrient)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(NutrientList);
