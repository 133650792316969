import { combineReducers } from "redux";

import * as actions from "./actions";
import * as fieldListSelectors from "./components/field-list/selectors";
import { fieldListReducer } from "./components/field-list/reducer";
import * as selectors from "./selectors";
import { logFirebaseEvent } from "~/utils/firebase";

export const initialFieldModel = {
    activeYn: true,
    agvanceFieldId: "",
    agvanceFieldGuid: "",
    agvanceFieldOrgLevelGuid: "",
    agvanceCustomerGuid: "",
    apn: "",
    brandOrganizationGuid: "",
    certifiedOrganic: false,
    cluAcres: "",
    cluBoundaryUsed: false,
    cluId: "",
    cropClassNameGuid: "",
    cropGuid: "",
    cropPlantingDate: null,
    cropPurposeGuid: "",
    customerFarmList: [],
    customerName: "",
    directions: "",
    drainageTile: false,
    farmName: "",
    fieldBoundary: {
        acres: 0,
        calculatedArea: "0",
        county: "",
        fieldBoundaryGuid: "",
        fieldGuid: "",
        importFileGuid: "",
        range: "",
        section: "",
        shapes: [],
        soilSeries: null,
        soilTexture: null,
        stateAbbr: "",
        township: "",
        watershedName: "",
        watershedNum: "",
    },
    fieldCropYieldList: [],
    fieldLeaseList: [],
    fieldName: "",
    fieldNotes: "",
    fieldGuid: "",
    fsaAcres: "",
    fsaFarmNum: "",
    fsaFieldNum: "",
    fsaTractNum: "",
    highRiskLandYn: false,
    insuranceNum: "",
    insurancePolicyTypeGuid: "",
    irrigated: "",
    irrigationAccessYn: false,
    irrigationTypeGuid: "",
    irrigationTypeList: [],
    landOwnershipTypeGuid: "",
    leaseExpireDate: null,
    legalDescription: "",
    varietyHybridGuid: "",
    combineFieldsError: null,
};

export const newInitialState = () => ({
    activeTab: actions.FieldListTabs.ACTIVE,
    agvanceFieldData: null,
    agvanceFieldList: [],
    agvanceFieldClassificationList: [],
    agvanceOrgLevelList: [],
    customerFieldListWithOrgLevel: {},
    dropdowns: {},
    fetchingFieldInfo: false,
    fieldBoundariesToClip: {},
    fieldBoundaryOverlapAction: null,
    fieldBoundaryTransferFieldModel: null,
    fieldBoundaryTransferGeometries: [],
    fieldModel: initialFieldModel,
    fieldDeleteAgvanceOverride: [],
    fullFieldRecords: null,
    isCombineDialogOpen: false,
    isFieldBoundaryOverlapOpen: false,
    isFieldBoundaryTransferOpen: false,
    isLoading: true,
    isMoveDialogLoading: false,
    fieldsToMove: null,
    orgLevelGuid: null,
    resurfaceEvents: null,
});

const fieldInfoReducer = (state, action) => {
    if (state == null) {
        return Object.freeze(newInitialState());
    }

    switch (action.type) {
        case actions.FETCHED_DROPDOWN_DATA:
            return Object.freeze({
                ...state,
                dropdowns: {
                    ...state.dropdowns,
                    ...action.payload,
                },
            });
        case actions.CHANGE_ACTIVE_TAB:
        case actions.FETCH_AGVANCE_FIELD_LIST_SUCCEEDED:
        case actions.FETCH_AGVANCE_ORG_LEVEL_LIST_SUCCEEDED:
        case actions.FETCH_AGVANCE_FIELD_CLASSIFICATION_LIST_SUCCEEDED:
        case actions.SET_IS_LOADING:
        case actions.SET_FIELDS_TO_MOVE:
        case actions.UPDATE_FIELD_INFO:
            action.type === actions.SET_FIELDS_TO_MOVE && logFirebaseEvent("move_fields");
            return Object.freeze({
                ...state,
                ...action.payload,
            });
        case actions.FETCH_FIELD_INFO_INIT: {
            return Object.freeze({
                ...state,
                fetchingFieldInfo: true,
            });
        }
        case actions.FETCH_FIELD_INFO_SUCCEEDED: {
            const { customerGuid } = action.payload;
            let { fieldModel } = action.payload;
            if (Object.keys(fieldModel).length === 0) {
                fieldModel = {
                    ...initialFieldModel,
                    customerGuid,
                };
            }
            fieldModel.fieldBoundary.fieldGuid = fieldModel.fieldGuid;
            return Object.freeze({
                ...state,
                fieldModel,
                fetchingFieldInfo: false,
            });
        }
        case actions.FETCH_FIELD_INFO_ERROR: {
            return Object.freeze({
                fetchingFieldInfo: false,
            });
        }
        case actions.CLEAR_AGVANCE_FIELD_LIST:
            return Object.freeze({
                ...state,
                agvanceFieldList: [],
            });
        case actions.CLEAR_AGVANCE_FIELD_CLASSIFICATION_LIST:
            return Object.freeze({
                ...state,
                agvanceFieldClassificationList: [],
            });
        case actions.CLEAR_FIELD_INFO:
            return Object.freeze({
                ...state,
                fieldModel: initialFieldModel,
                fieldBoundaryTransferFieldModel: null,
                fieldBoundaryTransferGeometries: [],
                resurfaceEvents: null,
            });
        case actions.CLEAR_FIELDS_TO_MOVE:
            return Object.freeze({
                ...state,
                fieldsToMove: null,
                agvanceFieldOrgLevelGuid: "",
                agvanceCustomerGuid: "",
                agvanceOrgLevelList: [],
            });
        case actions.SET_AGVANCE_FIELD_DATA:
            return Object.freeze({
                ...state,
                agvanceFieldData: action.payload.agvanceFieldData,
            });
        case actions.SET_MOVE_FIELD_LOADING:
            return Object.freeze({
                ...state,
                isMoveDialogLoading: action.payload.isLoading,
            });
        case actions.FETCH_ORG_LEVEL_LIST_SUCCEEDED: {
            const { orgLevelList } = action.payload;
            return Object.freeze({
                ...state,
                orgLevelList,
            });
        }
        case actions.CLEAR_CUSTOMER_FIELD_LIST_WITH_ORG_LEVEL: {
            return Object.freeze({
                ...state,
                customerFieldListWithOrgLevel: {},
            });
        }
        case actions.SET_ORG_LEVEL_GUID: {
            const { orgLevelGuid } = action.payload;
            return Object.freeze({
                ...state,
                orgLevelGuid,
            });
        }
        case actions.FETCH_RESURFACE_EVENTS_SUCCEEDED: {
            let resurfaceEvents = action.payload.keepExistingEvents
                ? state.resurfaceEvents || []
                : [];
            if (action.payload.resurfaceEvents.length > 0) {
                resurfaceEvents = resurfaceEvents.filter(
                    (r) => r.fieldGuid !== action.payload.resurfaceEvents[0].fieldGuid
                );
            }
            return Object.freeze({
                ...state,
                resurfaceEvents: [...resurfaceEvents, ...action.payload.resurfaceEvents],
            });
        }
        case actions.CLEAR_RESURFACE_EVENTS: {
            return Object.freeze({
                ...state,
                resurfaceEvents: null,
            });
        }
        case actions.REMOVE_RESURFACE_EVENTS: {
            const resurfaceEvents = (state.resurfaceEvents || []).filter(
                (event) => event.fieldGuid !== action.payload.fieldGuid
            );
            return Object.freeze({
                ...state,
                resurfaceEvents,
            });
        }
        case actions.SET_COMBINE_FIELDS_MODAL_OPEN: {
            logFirebaseEvent("combine_fields");
            return Object.freeze({
                ...state,
                isCombineDialogOpen: action.payload.isCombineFieldsModalOpen,
            });
        }
        case actions.SET_FIELD_BOUNDARY_TRANSFER_MODAL_OPEN: {
            return Object.freeze({
                ...state,
                isFieldBoundaryTransferOpen: action.payload.isFieldBoundaryTransferModalOpen,
            });
        }
        case actions.FETCH_FULL_FIELD_RECORDS_SUCCESS: {
            return Object.freeze({
                ...state,
                fullFieldRecords: action.payload,
            });
        }
        case actions.CLEAR_FULL_FIELD_RECORDS: {
            return Object.freeze({
                ...state,
                fullFieldRecords: null,
            });
        }
        case actions.FIELD_BOUNDARY_OVERLAP_DETECTED: {
            return Object.freeze({
                ...state,
                fieldBoundaryOverlapAction: null,
                isFieldBoundaryOverlapOpen: true,
            });
        }
        case actions.SET_FIELD_BOUNDARY_OVERLAP_ACTION: {
            return Object.freeze({
                ...state,
                fieldBoundaryOverlapAction: action.payload.action,
                isFieldBoundaryOverlapOpen: false,
            });
        }
        case actions.SET_FIELD_BOUNDARIES_TO_CLIP: {
            return Object.freeze({
                ...state,
                fieldBoundariesToClip: action.payload.fieldBoundaries,
            });
        }
        case actions.SET_FIELD_BOUNDARY_TRANSFER_GEOMETRIES: {
            return Object.freeze({
                ...state,
                fieldBoundaryTransferGeometries: action.payload.geometries,
            });
        }
        case actions.SET_FIELD_BOUNDARY_TRANSFER_FIELD_MODEL: {
            return Object.freeze({
                ...state,
                fieldBoundaryTransferFieldModel: action.payload,
            });
        }
        case actions.SET_FIELD_BOUNDARY_IMPORT_FILE_GUID: {
            const { fieldBoundary } = state.fieldModel;
            const { importFileGuid } = action.payload;
            const updatedFieldBoundary = {
                ...fieldBoundary,
                importFileGuid,
            };
            return Object.freeze({
                ...state,
                fieldModel: {
                    ...state.fieldModel,
                    fieldBoundary: updatedFieldBoundary,
                },
            });
        }
        case actions.SET_COMBINE_FIELDS_ERROR: {
            return Object.freeze({
                ...state,
                combineFieldsError: action.payload.error,
            });
        }
        case actions.SET_FIELD_DELETE_AGVANCE_OVERRIDE_MODAL_OPEN: {
            return Object.freeze({
                ...state,
                fieldDeleteAgvanceOverride: action.payload,
            });
        }
        default:
            return state;
    }
};

export const fieldModuleReducer = combineReducers({
    [fieldListSelectors.FIELD_LIST_KEY]: fieldListReducer,
    [selectors.FIELD_INFO_STATE_KEY]: fieldInfoReducer,
});
