import React, { Component } from "react";
import { InjectedIntlProps } from "react-intl";
import {
    TextInput,
    TextArea,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    ZeroToInfiniteGrid,
} from "~/core";
import { messages } from "../../i18n-messages";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";
import { withEditableGrid } from "~/hocs";
import { IDocumentListProps, IDocumentListState } from "../interfaces";
// constants
export const PROPS_DESCRIPTION = "description";
export const PROPS_FILENAME = "fileName";
export const PROPS_TYPE = "type";

interface DocumentListItem {
    value: any;
    onChange: (valueObj: any) => void;
    formatMessage: (message: string) => string;
    index?: number;
}

const DocumentListItem = ({ value, onChange, formatMessage }: DocumentListItem) => {
    return (
        <div className="document-list-item">
            <div className="item-container">
                <TextInput
                    tabIndex={0}
                    maxLength={50}
                    placeholderText={formatMessage(messages.documentType)}
                    required
                    autoFocus
                    value={value[PROPS_TYPE]}
                    onChange={(value) => onChange({ [PROPS_TYPE]: value })}
                />
                <TextInput
                    required
                    tabIndex={0}
                    maxLength={50}
                    placeholderText={formatMessage(messages.documentFileName)}
                    value={value[PROPS_FILENAME]}
                    onChange={(value) => onChange({ [PROPS_FILENAME]: value })}
                />
                <TextArea
                    maxLength={500}
                    placeholderText={formatMessage(messages.description)}
                    value={value[PROPS_DESCRIPTION]}
                    onChange={(value) => onChange({ [PROPS_DESCRIPTION]: value })}
                />
            </div>
        </div>
    );
};

class DocumentList extends Component<IDocumentListProps & InjectedIntlProps, IDocumentListState> {
    serviceObj: {
        [PROPS_FILENAME]: { title: string };
        [PROPS_TYPE]: { title: string };
        [PROPS_DESCRIPTION]: { title: string };
    };
    constructor(props) {
        super(props);
        this.state = {
            isPreview: false,
            previewImageData: null,
            editingItem: false,
        };
        const { formatMessage } = this.props;
        this.serviceObj = {
            [PROPS_FILENAME]: { title: formatMessage(messages.fileName) },
            [PROPS_TYPE]: { title: formatMessage(messages.type) },
            [PROPS_DESCRIPTION]: { title: formatMessage(messages.description) },
        };
    }
    onEdit = (record, index) => {
        const keysToUpdate = [PROPS_FILENAME, PROPS_TYPE, PROPS_DESCRIPTION];
        this.setState({ editingItem: true });
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        const formData = { ...editData };
        return (
            <DialogBox
                hideCloseX
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.document
                )}
                actionDisabled={!editData[PROPS_TYPE] || !editData[PROPS_FILENAME]}
                onClose={() => toggleModal("isEditing", false)}
                onAction={() => onAddOrEditItem()}
                isOpen={isEditing}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
            >
                <div className="document-dialog-body">
                    <DocumentListItem
                        formatMessage={formatMessage}
                        value={formData}
                        onChange={onChange}
                    />
                </div>
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage } = this.props;
        return (
            <div className={"form-section-child-stretch mini-grid"}>
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={this.serviceObj}
                        onEdit={this.onEdit}
                    />
                )}
                {this.renderAddEditModal()}
                <div className="add-link-container">
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.document)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(DocumentList);
