export const keywords = {
    accupoint: "Accupoint",
    accupointDefinedLegendsEBU: "AccupointDefinedLegendEBU",
    accupointDefinedLegendsMultiField: "AccupointDefinedLegendMultiField",
    accupointDefinedLegendsMultiFieldProductRec: "AccupointDefinedLegendMultiFieldProductRec",
    accupointSummary: "AccupointSummary",
    agOptic: "GlAgOptics",
    application: "application",
    applicationEvent: "Application",
    applicationEquation: "Application (Equation)",
    applicationManual: "Application (Manual)",
    applicationMethod: "applicationMethod",
    applicationTiming: "applicationTiming",
    ask: "Ask",
    agvanceSkyNutrient: "AgvanceSkyNutrient",
    agvanceSkyScoutingDetail: "AgvanceSkyScouting",
    agvanceSkyScouting: "AgvanceSkyScoutingSummary",
    agvanceSkySoils: "AgvanceSkySoils",
    agvanceSkyYield: "AgvanceSkyYield",
    summary: "SUMMARY",
    completed: "Completed",
    coreMax: "GlCoreMax",
    coreMaxSummary: "CoreMaxSummary",
    coreMaxDefinedLegendsMultiField: "CoreMaxDefinedLegendMultiField",
    coreMaxDefinedLegendsMultiFieldProductRec: "CoreMaxDefinedLegendMultiFieldProductRec",
    coreMaxResultsOnly: "CoreMaxResultsOnly",
    farmMap: "FarmMap",
    fertilizerRecSummary: "FertilizerRecSummary",
    harvestEvent: "Harvest",
    harvestSummary: "HarvestSummary",
    harvestSummaryMultiSeason: "HarvestSummaryMultiSeason",
    hyGroundFsr: "HyGroundFsr",
    hyGround: "HyGround",
    hyGroundDefinedLegends: "HyGroundDefinedLegend",
    hyGroundDefinedLegendsMultiField: "HyGroundDefinedLegendMultiField",
    hyGroundDefinedLegendsMultiFieldProductRec: "HyGroundDefinedLegendMultiFieldProductRec",
    hyGroundDefinedLegendsEBU: "HyGroundDefinedLegendEBU",
    hyGroundNorthwest: "HyGroundNorthwest",
    hyGroundResultsOnly: "HyGroundResultsOnly",
    hyGroundSummary: "HyGroundSummary",
    hyGroundWestern: "HyGroundWestern",
    inProgress: "In Progress",
    inspex: "Inspex",
    labSubmissionForm: "LabSubmissionForm",
    loadsheet: "Loadsheet",
    nutrientMap: "NutrientMap",
    outputMerge: "outputMerge",
    uploading: "Uploading",
    // categories keys
    aliasVariety: "aliasVariety",
    fertilityTopography: "fertilityTopography",
    monitorVariety: "monitorVariety",
    planting: "planting",
    plantingEvent: "Planting",
    plantingEquation: "Planting (Equation)",
    plantingManual: "Planting (Manual)",
    product: "product",
    productRate: "productRate",
    productRecSummary: "ProductRecSummary",
    productAppliedRate: "productAppliedRate",
    productTargetRate: "productTargetRate",
    sampleBagLabels: "SampleBagLabels",
    samplingSoil: "Sampling - Soil",
    samplingTissue: "Sampling - Tissue",
    scoutingEvent: "Scouting",
    seedingRate: "seedingRate",
    seedingTargetRate: "seedingTargetRate",
    soilSampleResults: "SoilSampleResults",
    soilTestingRequired: "SoilTestingRequired",
    soilType: "soilType",
    soilTypeMap: "SoilTypeMap",
    soilTypeName: "soilTypeName",
    tillage: "tillage",
    targetRate: "targetRate",
    tillageEvent: "Tillage",
    tillageDepth: "tillageDepth",
    tillageMethod: "tillageMethod",
    trueRec: "GlTrueRec",
    truYieldImpact: "TruYieldImpact",
    variety: "variety",
    varietyHybrid: "varietyHybrid",
    yieldBy: "YieldBy",
    yieldByBrand: "yieldByBrand",
    yieldByCec: "yieldByCec",
    yieldByKppm: "yieldByKppm",
    yieldByOm: "yieldByOm",
    yieldByPercentSlope: "yieldByPercentSlope",
    yieldByPlantingDate: "yieldByPlantingDate",
    yieldByPm3Ppm: "yieldByPm3Ppm",
    yieldByRelativeMaturityList: "yieldByRelativeMaturityList",
    yieldBySoilType: "yieldBySoilType",
    yieldBySoilTypeByVariety: "yieldBySoilTypeByVariety",
    yieldBySoilTypeByVarietyBySeedingRate: "yieldBySoilTypeByVarietyBySeedingRate",
    yieldByVariety: "yieldByVariety",
    yieldByVarietyBySeedingRate: "yieldByVarietyBySeedingRate",
    yieldByVarietyBySoilType: "yieldByVarietyBySoilType",
    yieldByVarietyBySoilTypeBySeedingRate: "yieldByVarietyBySoilTypeBySeedingRate",
    yieldBypH: "yieldBypH",
    yieldByElevation: "yieldbyElevation",
    yieldMap: "YieldMap",
    yieldOneFieldAnalysis: "GlYieldOne",
    yieldOneImpact: "GlYieldOneImpact",
    yieldOneSummary: "GlYieldOneSummary",
    weatherHistory: "WeatherHistory",
};
