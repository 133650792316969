import { ADMIN_STATE_KEY } from "~/admin";
import { CUSTOMER_DATA_KEY, ICustomerState } from "./reducer";

const _getModuleState = (state) => state[ADMIN_STATE_KEY][CUSTOMER_DATA_KEY] as ICustomerState;

export const getAgvanceCustomers = (state) => _getModuleState(state).agvanceCustomers;
export const getCustomerNameExists = (state) => _getModuleState(state).customerNameExists;
export const getCommunityDataDefault = (state) => _getModuleState(state).communityDataDefault;
export const getCustomerEquipmentList = (state) => _getModuleState(state).equipmentList;
export const getCustomerRecord = (state) => _getModuleState(state).customerRecord;
export const getNewPrimaryCustomer = (state) => _getModuleState(state).newPrimaryCustomer;
export const getSalespersonList = (state) => _getModuleState(state).salespersonList;
export const getOracleCustomer = (state) => _getModuleState(state).oracleCustomer;
export const getAgvanceCustomerListOutOfBound = (state) =>
    _getModuleState(state).isAgvanceCustomerListOutOfBound;
export const getAdditionalAgvanceCustomerList = (state) =>
    _getModuleState(state).additionalAgvanceCustomerList;
export const getCustomerSearchIsLoading = (state) => _getModuleState(state).customerSearchIsLoading;
export const getAdditionalAgvanceCustomerSearchFlag = (state) =>
    _getModuleState(state).isAdditionalAgvanceCustomerSearch;
