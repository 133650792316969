import React, { useEffect, useState } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import {
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    LoaderTypes,
    SelectInput,
    TextInput,
} from "~/core";
import { getTheUserGuid } from "~/login";
import { FileImportAPI } from "@ai360/core";

import { messages } from "../../../i18n-messages";

import "./export-controller-file-modal.css";
import { IGenericOption, IOption } from "~/core/components/select-input/model";
import {
    REC_TYPE_NAME_EQUATION_APPLICATION,
    REC_TYPE_NAME_MANUAL_APPLICATION,
    RecSummary,
} from "~/recs-events/recs/model";
import { IFileNameFormat } from "~/reports/data/interfaces";
import { logFirebaseEvent } from "~/utils/firebase";
import { ExportControllerCustomFilename } from "~/action-panel/components/rec-module/components/rec-list/components/dialog-boxes/export-controller-custom-filename";
import natsort from "natsort";

export interface IProps {
    intl: intlShape;
    isOpen: boolean;
    onAction: (exportRecRequest: FileImportAPI.ExportRecRequest) => void;
    onClose: () => void;
    recGeneralGuidList: string[];
    recSummaries: RecSummary[];
    userGuid: string;
}

interface IControllerFileType {
    guid: string;
    sendThroughOnsite: boolean;
}

const ExportControllerFileModal_ = (props: IProps): JSX.Element => {
    const { intl, isOpen, onAction, onClose, recGeneralGuidList, recSummaries, userGuid } = props;
    const [controllerTypeOptions, setControllerTypeOptions] = useState<IOption[]>([]);
    const [createLoadsheetYn, setCreateLoadsheetYn] = useState<boolean>(null);
    const [isLoading, setIsLoading] = useState<boolean>(null);
    const [mergeControllerFilesYn, setMergeControllerFilesYn] = useState<boolean>(null);
    const [multiProductYn, setMultiProductYn] = useState<boolean>(null);
    const [productFormatOptions, setProductFormatOptions] = useState<IFileNameFormat[]>([]);
    const [selectedControllerFileType, setSelectedControllerFileType] =
        useState<IControllerFileType>(null);
    const [selectedControllerType, setSelectedControllerType] = useState<any>(null);
    const [selectedExportFormat, setSelectedExportFormat] = useState<any>(null);
    const [validationWarnings, setValidationWarnings] = useState<any[]>([]);
    const [controllerFileTypeOptions, setControllerFileTypeOptions] = useState<
        IGenericOption<IControllerFileType>[]
    >([]);
    const [editFilenames, setEditFilenames] = useState<boolean>(null);
    const [filenamesValid, setFilenamesValid] = useState<boolean>(null);
    const [lastFilenameResult, setLastFilenameResult] =
        useState<Partial<FileImportAPI.ExportRecRequest>>(null);
    const [sortedSummaries, setSortedSummaries] = useState<RecSummary[]>([]);
    const { formatMessage } = intl;
    const [falconId, setFalconId] = useState<string>(null);
    const [errorType, setErrorType] = useState<string>(null);

    function onDialogAction() {
        const isGeneric =
            controllerTypeOptions.find((x) => x.value === selectedControllerType)?.label ===
                "Generic" ?? false;

        if (mergeControllerFilesYn || selectedControllerFileType.sendThroughOnsite) {
            const validationWarnings = validateControllerFileMerge();
            if (validationWarnings.length > 0) {
                setValidationWarnings(validationWarnings);
                return;
            }
        }
        setIsLoading(true);

        if (editFilenames) {
            logFirebaseEvent("export_controller_edit_file_names");
        }

        const exportRecRequest = new FileImportAPI.ExportRecRequest(recGeneralGuidList);
        exportRecRequest.setProps({
            controllerFileTypeGuid: selectedControllerFileType.guid,
            controllerTypeGuid: selectedControllerType,
            fileNameFormatGuid: selectedExportFormat,
            loadsheetYn: createLoadsheetYn,
            mergeControllerFilesYn,
            multiProductYn,
            falconId: falconId,
            ...(isGeneric ? lastFilenameResult : {}),
        });
        onAction(exportRecRequest);
    }

    function fetchOptions(userGuid) {
        const promises = [];
        promises.push(
            FileImportAPI.getControllerTypeList(userGuid).then((result) => {
                const controllerTypeOptions = result.map((r) => ({
                    label: r.name,
                    value: r.guid,
                }));
                return controllerTypeOptions;
            })
        );

        promises.push(FileImportAPI.getUserExportPreferences(userGuid));

        promises.push(
            FileImportAPI.getFileNameFormatList(userGuid).then((result) =>
                result.fileNameFormats.map((x) => ({
                    ...x,
                    name: x.name.replace("Customer", "Grower"),
                }))
            )
        );

        Promise.all(promises).then(([controllerTypeOptions, prefs, productFormatOptions]) => {
            const {
                controllerExportFormatGuid,
                controllerTypeGuid,
                controllerFileTypeGuid,
                mergeControllerFilesYn,
                multiProductYn,
                falconId,
                createLoadsheetYn,
            } = prefs;
            setControllerTypeOptions(controllerTypeOptions);
            setCreateLoadsheetYn(createLoadsheetYn);
            setMergeControllerFilesYn(recGeneralGuidList.length > 1 && mergeControllerFilesYn);
            setMultiProductYn(multiProductYn);
            setProductFormatOptions(productFormatOptions);
            setSelectedControllerType(controllerTypeGuid);
            setSelectedExportFormat(controllerExportFormatGuid);
            setEditFilenames(editFilenames);
            validateFalconId(falconId ? falconId : "123456");
            setIsLoading(false);

            onChangeControllerType(controllerTypeGuid, multiProductYn, controllerFileTypeGuid);
        });
    }

    function onChangeControllerType(
        newSelectedControllerType: string,
        multiProductYn = false,
        fileTypeGuid = false
    ) {
        if (
            newSelectedControllerType === selectedControllerType &&
            selectedControllerFileType &&
            selectedControllerFileType.sendThroughOnsite
        ) {
            // if it's just a multiProductYn change and a filetype sent through onsite is already selected, don't reload the file type list
            setIsLoading(false);
            return;
        }

        const isGeneric =
            controllerTypeOptions.find((x) => x.value === selectedControllerType)?.label ===
                "Generic" ?? false;

        setSelectedControllerType(newSelectedControllerType);
        setEditFilenames(isGeneric && editFilenames);
        setIsLoading(true);

        FileImportAPI.getControllerFileTypeList(
            multiProductYn ? recGeneralGuidList : [],
            newSelectedControllerType
        ).then((result) => {
            const controllerFileTypeOptionsList = result.map((r) => ({
                label: r.name,
                value: {
                    guid: r.guid,
                    sendThroughOnsite: r.sendThroughOnsite,
                },
            }));

            let selectedControllerFileType = null;
            if (controllerFileTypeOptionsList.length === 1) {
                selectedControllerFileType = controllerFileTypeOptionsList[0].value;
            } else if (fileTypeGuid) {
                selectedControllerFileType = controllerFileTypeOptionsList.filter(
                    (o) => o.value.guid === fileTypeGuid
                )[0]?.value;
            }

            setControllerFileTypeOptions(controllerFileTypeOptionsList);
            setIsLoading(false);
            setSelectedControllerFileType(selectedControllerFileType);
        });
    }

    function onChangeControllerFormat(selectedControllerFileType) {
        if (selectedControllerFileType.sendThroughOnsite) {
            setMultiProductYn(false);
        }

        setMultiProductYn(multiProductYn);
        setSelectedControllerFileType(selectedControllerFileType);
    }

    function validateControllerFileMerge() {
        const fields = new Set([...recSummaries.map((r) => r.fieldGuid)]);
        const recTypes = recSummaries.map((r) => r.recType);
        const warnings: string[] = [];
        if (fields.size !== recSummaries.length) {
            warnings.push(messages.exportValidationWarningOneRecPerField);
        }

        if (
            recTypes.some((r) => r.includes("Planting")) &&
            recTypes.some((r) => r.includes("Application"))
        ) {
            warnings.push(messages.exportValidationWarningOneRecType);
        }

        if (
            recTypes.some((r) => r === "Planting (Manual)") &&
            recTypes.some((r) => r === "Planting (Equation)")
        ) {
            warnings.push(messages.exportValidationWarningPlantingRecType);
        }

        return warnings;
    }

    function getValidationMessages() {
        const { formatMessage } = intl;
        return validationWarnings.map((warning) => {
            return (
                <div className="validation-message" key={warning.id}>
                    {formatMessage(warning)}
                </div>
            );
        });
    }

    useEffect(() => {
        setIsLoading(true);
        setEditFilenames(false);
        setFilenamesValid(true);
        fetchOptions(userGuid);

        if (recSummaries) {
            const naturalSorter = natsort({ insensitive: true });
            setSortedSummaries(
                recSummaries?.sort((l, r) => {
                    if (l == null || r == null) {
                        return 0;
                    }
                    // this works because it'll keep evaluating until it hits a non-zero value
                    return (
                        naturalSorter(l.customerName, r.customerName) ||
                        naturalSorter(l.farmName, r.farmName) ||
                        naturalSorter(l.fieldName, r.fieldName) ||
                        naturalSorter(l.recDate, r.recDate)
                    );
                }) ?? []
            );
        }
    }, [userGuid, isOpen]);

    function isValid(falconId) {
        const regex = /^[0-9]+$/;
        return regex.test(falconId);
    }

    function validateFalconId(falconId) {
        setFalconId(falconId);
        if (falconId.length != 6) {
            setErrorType("falconIdLength");
        } else if (!isValid(falconId)) {
            setErrorType("falconIdSpecial");
        } else {
            setErrorType(null);
        }
    }

    const isGenericType =
        controllerTypeOptions.find((x) => x.value === selectedControllerType)?.label ===
            "Generic" ?? false;

    const exportControllerFilesTitle = formatMessage(messages.exportControllerFile, {
        count: recGeneralGuidList.length,
    });
    const title =
        recGeneralGuidList.length > 1
            ? `${exportControllerFilesTitle} (${recGeneralGuidList.length})`
            : exportControllerFilesTitle;

    const sendThroughOnsite =
        (selectedControllerFileType && selectedControllerFileType.sendThroughOnsite) || false;

    const fileNameFormats = productFormatOptions.map((o) => {
        return { label: o.name, value: o.guid };
    });

    const isFalconType =
        controllerTypeOptions.find((x) => x.value === selectedControllerType)?.label === "AGCO" ??
        false;

    const isFalconFileFormat =
        controllerFileTypeOptions.find((x) => x.value === selectedControllerFileType)?.label ===
            "Falcon (*.tiff)" ?? false;

    const isFalconId = isFalconType && isFalconFileFormat;

    const exportFormat = fileNameFormats.find((o) => o.value === selectedExportFormat)
        ? selectedExportFormat
        : null;

    const exportFormatLabel = fileNameFormats.find((o) => o.value === selectedExportFormat)?.label;

    const canEditFilenames =
        isGenericType &&
        recSummaries.some(
            (x) =>
                x.recType === REC_TYPE_NAME_EQUATION_APPLICATION ||
                x.recType === REC_TYPE_NAME_MANUAL_APPLICATION
        );

    const canExport =
        selectedControllerType != null &&
        selectedControllerFileType != null &&
        exportFormat != null &&
        (errorType == null || !isFalconFileFormat) &&
        filenamesValid;

    return (
        <DialogBox
            draggable
            action={formatMessage(messages.exportToFile)}
            actionDisabled={!canExport}
            className="export-controller-file-modal"
            footerType={DialogBoxFooterType.ACTION_CANCEL}
            forceOverflow
            isOpen={isOpen}
            onAction={() => onDialogAction()}
            onClose={() => onClose()}
            title={title}
            unrestricted
        >
            <div className="row">
                <SelectInput
                    clearable={false}
                    onChange={(value) => onChangeControllerType(value)}
                    options={controllerTypeOptions}
                    required
                    placeholderText="Controller Company"
                    value={selectedControllerType}
                />
                <SelectInput
                    clearable={false}
                    onChange={(value) => onChangeControllerFormat(value)}
                    options={controllerFileTypeOptions}
                    required
                    placeholderText="Controller Format"
                    value={selectedControllerFileType}
                />
            </div>

            <div className="row">
                <div className="checkboxes">
                    <Checkbox
                        label="Loadsheet"
                        onChange={(e, createLoadsheetYn) => setCreateLoadsheetYn(createLoadsheetYn)}
                        value={createLoadsheetYn}
                    />
                    <Checkbox
                        label="Multi-Product File"
                        onChange={(e, multiProductYn) => {
                            setMultiProductYn(multiProductYn);
                            onChangeControllerType(selectedControllerType, multiProductYn);
                        }}
                        value={multiProductYn}
                    />
                    {!sendThroughOnsite && recGeneralGuidList.length > 1 && (
                        <Checkbox
                            label="Merge Files"
                            onChange={(e, mergeControllerFilesYn) =>
                                setMergeControllerFilesYn(mergeControllerFilesYn)
                            }
                            value={mergeControllerFilesYn}
                        />
                    )}
                    {canEditFilenames && (
                        <Checkbox
                            label="Edit File Names"
                            value={editFilenames}
                            onChange={(_, editFilenames) => setEditFilenames(editFilenames)}
                        />
                    )}
                </div>
                <SelectInput
                    clearable={false}
                    onChange={(selectedExportFormat) =>
                        setSelectedExportFormat(selectedExportFormat)
                    }
                    options={fileNameFormats}
                    required={true}
                    placeholderText="File Name Format"
                    value={exportFormat}
                />
            </div>
            <div className="bottom-row">
                {isFalconId && (
                    <TextInput
                        clearable={false}
                        onChange={(falconId) => validateFalconId(falconId)}
                        disabled={false}
                        hasError={errorType == "falconIdLength" || errorType == "falconIdSpecial"}
                        required={true}
                        errorText={formatMessage(
                            errorType == "falconIdLength"
                                ? messages.exportValidationWarningFalconIdLength
                                : messages.exportValidationWarningFalconIdSpecialCharacters
                        )}
                        placeholderText="Dealer ID"
                        value={falconId}
                    />
                )}
            </div>
            {canEditFilenames && (
                <ExportControllerCustomFilename
                    showInputs={editFilenames}
                    updateState={(lastFilenameResult, filenamesValid) => {
                        setLastFilenameResult(lastFilenameResult);
                        setFilenamesValid(filenamesValid);
                    }}
                    exportFormat={exportFormatLabel}
                    recSummaries={sortedSummaries}
                    isGenericType={isGenericType}
                    mergeFiles={mergeControllerFilesYn}
                    multiProduct={multiProductYn}
                />
            )}
            {!isLoading ? null : <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />}
            <DialogBox
                title={formatMessage(messages.exportValidationWarningTitle, {
                    count: validationWarnings.length,
                })}
                isOpen={validationWarnings.length > 0}
                onClose={() => setValidationWarnings([])}
            >
                {<div>{getValidationMessages()}</div>}
            </DialogBox>
        </DialogBox>
    );
};

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});
const ExportControllerFileModal = connect(mapStateToProps)(injectIntl(ExportControllerFileModal_));
export default ExportControllerFileModal;
