import { connect } from "react-redux";
import AddEditPanelContainer from "../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { pickLists, service, dropdowns, actions } from "../data";
import { getVendorEquipmentList } from "../data/selectors";

const mapStateToProps = (state) => ({
    equipmentList: getVendorEquipmentList(state),
});

const mapDispatchToProps = (dispatch) => ({
    // Action to be dispatched using needs hoc
    createEquipment: (payload) => actions.createEquipment(payload),
    fetchVendorEquipmentList: (payload) => actions.fetchVendorEquipmentList(payload),
    removeVendorRecord: () => dispatch(actions.removeVendorRecord()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditPanelContainer(AddEditPanel, { service, pickLists, dropdowns }));
