import React, { Component } from "react";
import { messages } from "../../i18n-messages";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { mapToPicklistValue, prepareSelectableOptions } from "~/admin/utils";
import * as model from "../data/model";
import {
    REQUEST_VARIETY_HYBRID,
    REQUEST_PAYLOAD_BRAND_ORGANIZATION,
    REQUEST_PAYLOAD_CROP_GUID,
} from "../data";
import { adminData, GUID, ID, NAME, EDIT } from "~/admin/data";
// Components
import { Section, SelectInput, SubSection, TextInput } from "~/core";
import ChemicalFertilizerInfo from "./chemical-fertilizer-container";
import { CropAPI } from "@ai360/core";
//Types
import { ISeedInformationProps, ISeedInformationState } from "../interfaces";

class SeedInformation extends Component<
    ISeedInformationProps & InjectedIntlProps,
    ISeedInformationState
> {
    constructor(props) {
        super(props);
        this.state = {
            [model.PROPS_CROP_NAME]: prepareSelectableOptions(props[model.PROPS_CROP_NAME], {
                guid: GUID,
                label: NAME,
                id: ID,
                appendIdToLabel: true,
            }),
            [model.PROPS_BRAND_ORGANIZATION_NAME]: prepareSelectableOptions(
                props[model.PROPS_BRAND_ORGANIZATION_NAME],
                { guid: GUID, label: NAME, id: ID }
            ),
            [model.VARIETY_TYPE]: prepareSelectableOptions(props[model.VARIETY_TYPE], {
                guid: GUID,
                label: NAME,
                id: ID,
            }),
            [model.PROPS_CROP_GUID]: props.product[model.PROPS_CROP_GUID] || "",
            [model.PROPS_BRAND_ORGANIZATION_GUID]:
                props.product[model.PROPS_BRAND_ORGANIZATION_GUID] || "",
            [model.PROPS_VARIETY_HYBRID_GUID]: props.product[model.PROPS_VARIETY_HYBRID_GUID] || "",
            productParentType: "",
        };
        if (props.mode === EDIT && props.product) {
            this.requestBrandOrganization(props.product[model.PROPS_CROP_GUID]);
            this.requestVarietyHybrid(
                props.product[model.PROPS_CROP_GUID],
                props.product[model.PROPS_BRAND_ORGANIZATION_GUID]
            );
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.initializeCropNames(nextProps);
        this.initializeBrand(nextProps);
        this.initializeVarietyHybrid(nextProps);
        this.setupVarietyHybrid(nextProps);
    }

    initializeCropNames = (nextProps) => {
        if (nextProps.product[model.PROPS_CROP_GUID] != null && nextProps[model.PROPS_CROP_NAME]) {
            this.setState({
                [model.PROPS_CROP_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_CROP_NAME],
                    { guid: GUID, label: NAME, id: ID, appendIdToLabel: true }
                ),
            });
        }
    };

    initializeBrand = (nextProps) => {
        if (
            nextProps.product[model.PROPS_BRAND_ORGANIZATION_GUID] != null &&
            nextProps[model.PROPS_BRAND_ORGANIZATION_NAME]
        ) {
            this.setState({
                [model.PROPS_BRAND_ORGANIZATION_NAME]: prepareSelectableOptions(
                    nextProps[model.PROPS_BRAND_ORGANIZATION_NAME],
                    { guid: GUID, label: NAME, id: ID }
                ),
            });
        }
    };

    initializeVarietyHybrid = (nextProps) => {
        if (
            nextProps.product[model.PROPS_VARIETY_HYBRID_GUID] != null &&
            nextProps[model.VARIETY_TYPE]
        ) {
            this.setState({
                [model.VARIETY_TYPE]: prepareSelectableOptions(nextProps[model.VARIETY_TYPE], {
                    guid: GUID,
                    label: NAME,
                    id: ID,
                }),
            });
        }
    };

    setupVarietyHybrid = (nextProps) => {
        if (
            nextProps.product &&
            nextProps.product[model.PROPS_CROP_GUID] !==
                this.props.product[model.PROPS_CROP_GUID] &&
            nextProps.product[model.PROPS_BRAND_ORGANIZATION_GUID] !==
                this.props.product[model.PROPS_BRAND_ORGANIZATION_GUID]
        ) {
            this.setState({
                [model.PROPS_CROP_GUID]: nextProps.product[model.PROPS_CROP_GUID],
                [model.PROPS_BRAND_ORGANIZATION_GUID]:
                    nextProps.product[model.PROPS_BRAND_ORGANIZATION_GUID],
            });
        }
    };

    onCropNameChange = (data = null) => {
        if (data) {
            this.requestBrandOrganization(data[GUID]);
            this.setState({ [model.PROPS_CROP_GUID]: data[GUID] });
            this.setState({ [model.PROPS_BRAND_ORGANIZATION_GUID]: "" });
            this.props.onPicklistChange(
                {
                    type: model.PROPS_CROP_NAME,
                    guid: model.PROPS_CROP_GUID,
                },
                data
            );
            this.props.onPicklistChange(
                {
                    type: model.PROPS_BRAND_ORGANIZATION_NAME,
                    guid: model.PROPS_BRAND_ORGANIZATION_GUID,
                },
                { guid: "", name: "" }
            );
            this.props.onPicklistChange(
                {
                    type: model.PROPS_VARIETY_HYBRID_NAME,
                    guid: model.PROPS_VARIETY_HYBRID_GUID,
                },
                { guid: "", name: "" }
            );
        } else {
            this.setState({ [model.PROPS_CROP_GUID]: "" });
            this.setState({ [model.PROPS_BRAND_ORGANIZATION_GUID]: "" });
            this.props.onPicklistChange(
                {
                    type: model.PROPS_CROP_NAME,
                    guid: model.PROPS_CROP_GUID,
                },
                { guid: "", name: "" }
            );
            this.props.onPicklistChange(
                {
                    type: model.PROPS_BRAND_ORGANIZATION_NAME,
                    guid: model.PROPS_BRAND_ORGANIZATION_GUID,
                },
                { guid: "", name: "" }
            );
            this.props.onPicklistChange(
                {
                    type: model.PROPS_VARIETY_HYBRID_NAME,
                    guid: model.PROPS_VARIETY_HYBRID_GUID,
                },
                { guid: "", name: "" }
            );
        }
    };

    onBrandChange = (data = null) => {
        if (data) {
            this.requestVarietyHybrid(this.state[model.PROPS_CROP_GUID], data[GUID]);
            this.setState({ [model.PROPS_BRAND_ORGANIZATION_GUID]: data[GUID] });
            this.props.onPicklistChange(
                {
                    type: model.PROPS_BRAND_ORGANIZATION_NAME,
                    guid: model.PROPS_BRAND_ORGANIZATION_GUID,
                },
                data
            );
            this.props.onPicklistChange(
                {
                    type: model.PROPS_VARIETY_HYBRID_NAME,
                    guid: model.PROPS_VARIETY_HYBRID_GUID,
                },
                { guid: "", name: "" }
            );
        } else {
            this.setState({ [model.PROPS_BRAND_ORGANIZATION_GUID]: "" });
            this.props.onPicklistChange(
                {
                    type: model.PROPS_BRAND_ORGANIZATION_NAME,
                    guid: model.PROPS_BRAND_ORGANIZATION_GUID,
                },
                { guid: "", name: "" }
            );
        }
    };

    requestVarietyHybrid = (cropGuid, brandOrgGuid) => {
        const { needs, fetchDropdownData } = this.props;
        if (cropGuid && brandOrgGuid) {
            needs([
                fetchDropdownData({
                    [model.VARIETY_TYPE]: {
                        url: REQUEST_VARIETY_HYBRID,
                        model: {
                            [REQUEST_PAYLOAD_CROP_GUID]: cropGuid,
                            [REQUEST_PAYLOAD_BRAND_ORGANIZATION]: brandOrgGuid,
                        },
                    },
                }),
            ]);
        }
    };

    requestBrandOrganization = (cropGuid) => {
        const { needs, fetchDropdownData } = this.props;
        if (cropGuid) {
            needs([
                fetchDropdownData({
                    [model.PROPS_BRAND_ORGANIZATION_NAME]: {
                        url: CropAPI.REQUEST_BRAND_ORG,
                        model: cropGuid,
                    },
                }),
            ]);
        }
    };

    render() {
        const { formatMessage } = this.props.intl;
        const chemicalProps = {
            product: this.props.product,
            onPicklistChange: this.props.onPicklistChange,
            onTextChange: this.props.onTextChange,
            productParentType: "",
        };
        return (
            <Section headerText={formatMessage(messages.seedInformation)}>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[model.PROPS_CROP_NAME]}
                        placeholderText={formatMessage(messages.cropIdName)}
                        value={mapToPicklistValue({
                            options: this.state[model.PROPS_CROP_NAME],
                            selectedGuid: this.props.product[model.PROPS_CROP_GUID],
                        })}
                        onChange={(value) => {
                            this.onCropNameChange(value);
                        }}
                    />
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[model.PROPS_BRAND_ORGANIZATION_NAME]}
                        disabled={
                            !this.state[model.PROPS_CROP_GUID] ||
                            !this.state[model.PROPS_BRAND_ORGANIZATION_NAME].length
                        }
                        placeholderText={formatMessage(messages.brandOrganization)}
                        value={mapToPicklistValue({
                            options: this.state[model.PROPS_BRAND_ORGANIZATION_NAME],
                            selectedGuid: this.props.product[model.PROPS_BRAND_ORGANIZATION_GUID],
                        })}
                        onChange={(value) => {
                            this.onBrandChange(value);
                        }}
                    />
                </SubSection>
                <SubSection>
                    <SelectInput
                        tabIndex={0}
                        optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                        options={this.state[model.VARIETY_TYPE]}
                        disabled={
                            !this.state[model.PROPS_BRAND_ORGANIZATION_GUID] ||
                            !this.state[model.VARIETY_TYPE].length
                        }
                        placeholderText={formatMessage(messages.varietyHybridName)}
                        value={mapToPicklistValue({
                            options: this.state[model.VARIETY_TYPE],
                            selectedGuid: this.props.product[model.PROPS_VARIETY_HYBRID_GUID],
                        })}
                        onChange={(value) => {
                            this.props.onPicklistChange(
                                {
                                    type: model.PROPS_VARIETY_HYBRID_NAME,
                                    guid: model.PROPS_VARIETY_HYBRID_GUID,
                                },
                                value
                            );
                        }}
                    />
                    <TextInput
                        tabIndex={0}
                        maxLength={50}
                        placeholderText={formatMessage(messages.treatment)}
                        value={this.props.product[model.PROPS_TREATMENT]}
                        onChange={(value) => this.props.onTextChange(model.PROPS_TREATMENT, value)}
                    />
                </SubSection>
                <SubSection>
                    <ChemicalFertilizerInfo {...chemicalProps} />
                </SubSection>
            </Section>
        );
    }
}

export default injectIntl(SeedInformation);
