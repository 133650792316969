import { service, pickLists, dropdowns } from "./service";
import { actions as defaultActions } from "./controller";
import * as model from "./model";
import * as selectors from "./selectors";
import * as customActions from "./actions";
import vendorSagas from "./sagas";

const customActionFunctions = Object.keys(customActions).reduce((map, key) => {
    if (typeof customActions[key] === "function") {
        map[key] = customActions[key];
    }
    return map;
}, {});

const actions = {
    ...defaultActions,
    ...customActionFunctions,
} as any;

export { service, actions, vendorSagas, pickLists, dropdowns, model, selectors };
