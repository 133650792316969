import React, { useState, ReactElement, MouseEvent as ReactMouseEvent } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";
import classnames from "classnames";

import { NoLink, DialogBoxFooterType, DialogBox } from "~/core";
import { messages } from "~/i18n-messages";

import "./zero-to-infinite.css";

export interface IZeroToInfiniteProps {
    addButton?: ReactElement;
    // items are different for each item that is added or changed.
    addItem?: (event: ReactMouseEvent, items: any, index: number) => void;
    addText?: string;
    children?: ReactElement;
    containerClassNames?: Record<string, unknown>[];
    deleteText?: string;
    deleteWarn?: (index: number) => boolean;
    // the item object here is going to be a different object depending on the child.
    getChildProps?: (item: Record<string, unknown>, index: string) => Record<string, unknown>;
    hideAddAndDelete?: boolean;
    initialValue?: any;
    isDisabled?: boolean;
    // items are different for each item that is added or changed.
    items?: any;
    limit?: number;
    // items are different for each item that is added or changed.
    onDelete?: (event: ReactMouseEvent, items: any, index: number) => void;
    required?: boolean;
    intl: intlShape;
}

function ZeroToInfinite(props: IZeroToInfiniteProps) {
    const [isDeleting, setIsDeleting] = useState(false);
    const [index, setIndex] = useState(null);

    const addChild = (event: ReactMouseEvent) => {
        const items = [...props.items];
        items.push(props.initialValue);
        if (props.addItem) {
            props.addItem(event, items, items.length - 1);
        }
    };

    const onDeleteItem = (event: ReactMouseEvent, index: number) => {
        if (props.deleteWarn) {
            props.deleteWarn(index);
            setIsDeleting(true);
            return;
        } else {
            setIsDeleting(true);
        }
        setIsDeleting(true);
        setIndex(index);
    };

    const onDelete = (event: ReactMouseEvent) => {
        const items = [...props.items];
        items.splice(index, 1);
        if (props.onDelete) {
            props.onDelete(event, items, index);
        }
        setIsDeleting(false);
    };

    const renderChildItems = () => {
        const { deleteText, children, getChildProps, items, addText, hideAddAndDelete, required } =
            props;
        const childItems = items.map((item, index) => {
            return (
                <div className="child-row" key={`${addText}-${index}`}>
                    {hideAddAndDelete || (items.length === 1 && required) ? null : (
                        <div className="delete-link-container">
                            <NoLink
                                label={deleteText}
                                className="delete-link"
                                onClick={(event) => onDeleteItem(event, index)}
                            />
                        </div>
                    )}
                    {React.cloneElement(children, {
                        ...getChildProps(item, index),
                        index,
                    })}
                </div>
            );
        });
        return childItems;
    };

    const renderDeleteModal = () => {
        const { formatMessage } = props.intl;
        return (
            <DialogBox
                footerType={DialogBoxFooterType.YES_NO}
                isOpen={isDeleting}
                onAction={(item) => onDelete(item as ReactMouseEvent)}
                onClose={() => setIsDeleting(false)}
                title={formatMessage(messages.confirmTitle)}
            >
                {formatMessage(messages.deleteConfirmationMessage)}
            </DialogBox>
        );
    };

    return (
        <div className={classnames("zero-to-infinite-cont", ...props.containerClassNames)}>
            {renderChildItems()}
            {props.hideAddAndDelete
                ? null
                : props.addButton || (
                      <div className="add-link-container">
                          <NoLink
                              label={props.addText}
                              className="add-link"
                              onClick={addChild}
                              disabled={props.isDisabled}
                          ></NoLink>
                      </div>
                  )}
            {renderDeleteModal()}
        </div>
    );
}

ZeroToInfinite.propTypes = {
    addButton: PropTypes.elementType,
    addItem: PropTypes.func,
    addText: PropTypes.string,
    children: PropTypes.array,
    containerClassNames: PropTypes.array,
    deleteText: PropTypes.string,
    deleteWarn: PropTypes.func,
    getChildProps: PropTypes.func,
    hideAddAndDelete: PropTypes.bool,
    initialValue: PropTypes.any,
    isDisabled: PropTypes.bool,
    items: PropTypes.any,
    limit: PropTypes.number,
    onDelete: PropTypes.func,
    required: PropTypes.bool,
    intl: intlShape,
};

ZeroToInfinite.defaultProps = {
    items: [],
    addText: "+",
    children: [],
    deleteText: "-",
    containerClassNames: [],
    initialValue: null,
    hideAddAndDelete: false,
    getChildProps: (): IZeroToInfiniteProps => null,
};

export default injectIntl(ZeroToInfinite);
