import { adminData } from "~/admin/data";
// Model
export const NUTRIENT_TYPE = "nutrientType";
export const PRODUCT_TYPE = "productType";
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_AGGATEWAY_COMPANY_ID = "agGatewayCompanyId";
export const PROPS_AGGATEWAY_PRODUCT_ID = "agGatewayProductId";
export const PROPS_AGRIAN_ID = "agrianId";
export const PROPS_AGX_ID = "agxId";
export const PROPS_ANGLE_OF_REPOSE = "angleOfRepose";
export const PROPS_BRAND_ORGANIZATION_GUID = "brandOrganizationGuid";
export const PROPS_BRAND_ORGANIZATION_NAME = "brandOrganizationName";
export const PROPS_BUFFER_DISTANCE_INFORMATION = "bufferDistanceInformation";
export const PROPS_CHEMICAL_FAMILY_GUID = "chemicalFamilyGuid";
export const PROPS_CHEMICAL_FAMILY_NAME = "chemicalFamilyName";
export const PROPS_CROP_GUID = "cropGuid";
export const PROPS_CROP_ID = "cropId";
export const PROPS_CROP_NAME = "cropName";
export const PROPS_DENSITY = "density";
export const PROPS_DENSITY_UNIT_ABBREVIATION = "densityUnitAbbreviation";
export const PROPS_DENSITY_UNIT_GUID = "densityUnitGuid";
export const PROPS_DENSITY_UNIT_NAME = "densityUnitName";
export const PROPS_DOCUMENT_LIST = "documentList";
export const PROPS_FEDERALLY_RESTRICTED = "federallyRestricted";
export const PROPS_FORMULATION_TYPE_GUID = "formulationTypeGuid";
export const PROPS_FORMULATION_TYPE_NAME = "formulationTypeName";
export const PROPS_FREEZING_TEMPERATURE = "freezingTemperature";
export const PROPS_GRAZING_RESTRICTION = "grazingRestriction";
export const PROPS_INGREDIENT_LIST = "ingredientList";
export const PROPS_MANUFACTURER = "manufacturer";
export const PROPS_MANUFACTURER_GUID = "manufacturerGuid";
export const PROPS_MANUFACTURER_NAME = "manufacturerName";
export const PROPS_MEDICAL_PHONENUMBER_OBJ = "medicalPhoneNumberObj";
export const PROPS_MODE_OF_ACTION = "modeOfAction";
export const PROPS_MODIFIED_DATE = adminData.PROPS_MODIFIED_DATE;
export const PROPS_NUTRIENT_LIST = "nutrientList";
export const PROPS_OXIDE = "oxide";
export const PROPS_PEST_INJURY_SYMPTOMS = "pestInjurySymptoms";
export const PROPS_PH = "ph";
export const PROPS_PHYSICAL_STATE_GUID = "physicalStateGuid";
export const PROPS_PHYSICAL_STATE_NAME = "physicalStateName";
export const PROPS_PPE_INFORMATION = "ppeInformation";
export const PROPS_PPE_REENTRY_INFORMATION = "ppeReentryInformation";
export const PROPS_PRODUCT_ACTION_METHOD_GUID = "productActionMethodGuid";
export const PROPS_PRODUCT_ACTION_METHOD_NAME = "productActionMethodName";
export const PROPS_PRODUCT_ALIAS = "aliases";
export const PROPS_PRODUCT_DESCRIPTION = "productDescription";
export const PROPS_PRODUCT_GUID = "productGuid";
export const PROPS_PRODUCT_ID = "productId";
export const PROPS_PRODUCT_LOGO = "productLogo";
export const PROPS_PRODUCT_NAME = "productName";
export const PROPS_PRODUCT_PACKAGE_LIST = "productPackageList";
export const PROPS_PRODUCT_PARENT_TYPE = "productParentType";
export const PROPS_PRODUCT_PARENT_TYPE_GUID = "productParentTypeGuid";
export const PROPS_PRODUCT_PARENT_TYPE_NAME = "productParentTypeName";
export const PROPS_PRODUCT_PLANT_BACK_RESTRICTION_LIST = "productPlantBackRestrictionList";
export const PROPS_PRODUCT_TYPES = "productTypes";
export const PROPS_PRODUCT_TYPE_LIST = "productTypeList";
export const PROPS_REGISTRATION_CODE = "registrationCode";
export const PROPS_REI_HOURS_HIGH = "reiHoursHigh";
export const PROPS_REI_HOURS_LOW = "reiHoursLow";
export const PROPS_RESPONSE_PHONENUMBER_OBJ = "responsePhoneNumberObj";
export const PROPS_SALTING_OUT_TEMPERATURE = "saltingOutTemperature";
export const PROPS_SHELF_LIFE = "shelfLife";
export const PROPS_SIGNAL_WORD = "signalWord";
export const PROPS_SOLUBILITY_IN_WATER = "solubilityInWater";
export const PROPS_SPECIAL_INSTRUCTIONS = "specialInstructions";
export const PROPS_SPECIFIC_GRAVITY = "specificGravity";
export const PROPS_SYSTEM_LOGO_DARK = "systemLogoDark";
export const PROPS_TREATMENT = "treatment";
export const PROPS_VARIETY_HYBRID_GUID = "varietyHybridGuid";
export const PROPS_VARIETY_HYBRID_ID = "varietyHybridId";
export const PROPS_VARIETY_HYBRID_NAME = "varietyHybridName";
export const VARIETY_TYPE = "varietyType";
export const PROPS_UFTRS_CODE = "uftrsCode";
export const PROPS_STANDARD_RATE = "standardRate";
export const PROPS_STANDARD_RATE_UNIT_GUID = "standardRateUnitGuid";
