import { messaging } from "@ai360/core";
import { AnySubscription } from "./subscription";
import { v4 as uuid } from "uuid";
import { action } from "typesafe-actions";

export const SUBSCRIBE = "messaging/SUBSCRIBE";
export const START = "messaging/START";
export const NEW_RECEIVER = "messaging/NEW_RECEIVER";

export const subscribe = (
    batchMilliseconds: number,
    ...subscriptions: [AnySubscription, ...AnySubscription[]]
) => action(SUBSCRIBE, { batchMilliseconds, subscriptions });

export const start = (
    userGuid: uuid,
    orgLevelGuids: Array<uuid>,
    endpoint: string,
    region: string,
    credentials: messaging.Credentials
) => action(START, { userGuid, orgLevelGuids, endpoint, region, credentials });

export const newReceiver = (receiver: unknown) => action(NEW_RECEIVER, { receiver });
