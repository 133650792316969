import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";

import { actions as accordionActions, model as accordionModel } from "~/accordion";
import { FieldAPI, LayerAPI, LayerUtilsAPI } from "@ai360/core";

import { Checkbox, Loader, LoaderTypes, RadioButton } from "~/core";
import { WarningIcon } from "~/core/icons";

import { createLegendAccordionItems } from "./legend-item";
import { LayerStats } from "./dialog-boxes/layer-stats";
import { ManualSurfaceProperties } from "./dialog-boxes/manual-surface-properties";
import { SurfaceProperties } from "./dialog-boxes/surface-properties";
import { SurfaceLayerContextMenu } from "../../context-menus/surface-layer-context-menu";
import { CloudIcon } from "../../analysis-info/icons/cloud";
import * as actions from "../actions";

import * as selectors from "../selectors";
import { messages } from "../../i18n-messages";
import { getLayerTypeLetter, getSampleSitesMarkerStyle } from "../../../utils";

import "./surface-item.css";
import { getIsLoading } from "~/map/components/map-control/selectors";
import { logFirebaseEvent } from "~/utils/firebase";

const PropDialogType = {
    Unknown: 0,
    Manual: 1,
    Surface: 2,
    Analysis: 3,
    ManagementArea: 4,
};

export const SURFACE_LAYER_HEIGHT = 30;

export const createSurfaceAccordionItems = (surfaces, type) => {
    const attributeGuidField = LayerUtilsAPI.getAttributeGuidFieldName(type);

    return surfaces.map((surface) => {
        const { classBreaks, surfaceGuid } = surface;
        const attributeGuid = surface[attributeGuidField];
        const payload = { surfaceGuid, attributeGuid };
        const surfaceItem = new accordionModel.AccordionItem(SURFACE_LAYER_HEIGHT, false, payload);
        return accordionModel.AccordionItem.updateAccordionItem(surfaceItem, {
            children: classBreaks?.length > 0 ? createLegendAccordionItems(classBreaks) : [],
        });
    });
};

interface ISurfaceItemProps {
    accordionId: number;
    attributeGuid: string;
    customerGuid: string;
    collapseItem: (accordionId: number, itemDimIdx: number[]) => void;
    expandItem: (accordionId: number, itemDimIdx: number[]) => void;
    field: FieldAPI.IField;
    intl: intlShape;
    isExpanded: boolean;
    isLoading: boolean;
    isSampleSites: boolean;
    itemDimIdx: number[];
    layer: any;
    mapIsLoading: boolean;
    onChangeManualLayerProps: (props) => void;
    onChangeSurfaceLayerProps: (props) => void;
    onPrintLayer: (printLayer) => void;
    onRemoveVisibleSampleSites: (fieldGuids: string[]) => void;
    onSetVisibleSampleSite: (fieldGuid: string, layerInfo: Partial<LayerAPI.ILayerInfo>) => void;
    surface: LayerAPI.ISubLayer;
    surfaceGuid: string;
    type: number;
    visibleSampleSitesMap: Map<string, Partial<LayerAPI.ILayerInfo>>;
}

interface ISurfaceItemState {
    layerStatsOpen: boolean;
    manualPropsOpen: boolean;
    surfacePropsOpen: boolean;
}

class SurfaceItem_ extends Component<ISurfaceItemProps, ISurfaceItemState> {
    constructor(props) {
        super(props);
        this.state = {
            layerStatsOpen: false,
            manualPropsOpen: false,
            surfacePropsOpen: false,
        };
    }

    _getPropsType() {
        switch (this.props.type) {
            case LayerUtilsAPI.LayerType.EVENT_IMPORTED:
            case LayerUtilsAPI.LayerType.EVENT_FROM_EQUATION_REC:
                return PropDialogType.Surface;
            case LayerUtilsAPI.LayerType.EVENT_MANUAL:
            case LayerUtilsAPI.LayerType.SOIL:
                return PropDialogType.Manual;
            case LayerUtilsAPI.LayerType.REC:
                if (this.props.layer.isManual) {
                    return PropDialogType.Manual;
                } else {
                    return PropDialogType.Surface;
                }
            case LayerUtilsAPI.LayerType.ANALYSIS:
                return PropDialogType.Analysis;
            case LayerUtilsAPI.LayerType.MANAGEMENT_AREA:
                return PropDialogType.ManagementArea;
            default:
                break;
        }
        return PropDialogType.Unknown;
    }

    _onClick(evt) {
        if (this.props.mapIsLoading) {
            return;
        }
        const {
            accordionId,
            collapseItem,
            expandItem,
            isExpanded,
            isLoading,
            isSampleSites,
            itemDimIdx,
            layer,
            onRemoveVisibleSampleSites,
            onSetVisibleSampleSite,
            visibleSampleSitesMap,
        } = this.props;
        if (isLoading || (evt && evt.isDefaultPrevented())) {
            return;
        }
        if (isSampleSites) {
            const vss = visibleSampleSitesMap.get(layer.fieldGuid);
            if (vss && vss.agEventGeneralGuid === layer.agEventGeneralGuid) {
                onRemoveVisibleSampleSites([layer.fieldGuid]);
            } else {
                onSetVisibleSampleSite(layer.fieldGuid, LayerUtilsAPI.getSlimLayerInfo(layer));
            }
        } else {
            if (isExpanded) {
                collapseItem(accordionId, itemDimIdx);
            } else {
                expandItem(accordionId, itemDimIdx);
            }
        }
    }

    _onCloseManualProps() {
        this.setState({ manualPropsOpen: false });
    }

    _onCloseSurfaceProps() {
        this.setState({ surfacePropsOpen: false });
    }

    _onEditProps() {
        logFirebaseEvent("edit_layer_properties");
        switch (this._getPropsType()) {
            case PropDialogType.Manual:
                this.setState({ manualPropsOpen: true });
                return;
            case PropDialogType.ManagementArea:
            case PropDialogType.Surface:
                this.setState({ surfacePropsOpen: true });
                return;
            default:
                break;
        }
        alert("Not Implemented yet");
    }

    _onPrintLayer(rendererGuid, renderPoints, imageryLayerGuid) {
        const { field, layer, onPrintLayer, visibleSampleSitesMap, isSampleSites } = this.props;
        const displayName = `${layer.displayName} - ${this.getDisplayName()}`;
        const showSampleSites = !!visibleSampleSitesMap.get(layer.fieldGuid);
        onPrintLayer({
            agEventGeneralGuid: layer.agEventGeneralGuid,
            displayName,
            fieldGuid: field.fieldGuid,
            imageryLayerGuid,
            isSampleSites: isSampleSites,
            rendererGuid,
            renderPoints,
            showSampleSites: isSampleSites ? true : showSampleSites,
        });
    }

    _onSaveManualProps({ attributeGuid, colorSchemeGuid }) {
        this._onCloseManualProps();
        const { field, itemDimIdx, layer, onChangeManualLayerProps, surface } = this.props;
        onChangeManualLayerProps({
            itemDimIdx,
            fieldGuid: field.fieldGuid,
            layer,
            surface,
            attributeGuid,
            colorSchemeGuid,
        });
    }

    _onSaveSurfaceProps({
        attributeGuid,
        classificationMethodGuid,
        colorRampGuid,
        colorSchemeGuid,
        numberOfClasses,
        surfaceTypeGuid,
    }) {
        this._onCloseSurfaceProps();
        const { field, itemDimIdx, layer, onChangeSurfaceLayerProps, surface } = this.props;
        onChangeSurfaceLayerProps({
            itemDimIdx,
            fieldGuid: field.fieldGuid,
            layer,
            surface,
            attributeGuid,
            classificationMethodGuid,
            colorRampGuid,
            colorSchemeGuid,
            numberOfClasses,
            surfaceTypeGuid,
        });
    }

    getDisplayName() {
        const { isSampleSites, layer, surface } = this.props;
        const { formatMessage } = this.props.intl;
        let displayName = isSampleSites
            ? formatMessage(messages.sampleSites)
            : surface?.displayName;
        if (!layer.isManual && !layer.isSampling && surface?.surfaceTypeDisplayName) {
            displayName = `${displayName} - ${surface.surfaceTypeDisplayName}`;
        }
        return displayName;
    }

    render() {
        const {
            customerGuid,
            field,
            isExpanded,
            isLoading,
            isSampleSites,
            layer,
            mapIsLoading,
            surface,
            type,
            visibleSampleSitesMap,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const { layerStatsOpen, manualPropsOpen, surfacePropsOpen } = this.state;
        const displayName = this.getDisplayName();
        const sampleSitesChecked =
            visibleSampleSitesMap.has(layer.fieldGuid) &&
            visibleSampleSitesMap.get(layer.fieldGuid).agEventGeneralGuid ===
                layer.agEventGeneralGuid;
        const expandedIndicator = isSampleSites ? (
            <Checkbox className="surface-checkbox" value={sampleSitesChecked} />
        ) : (
            <RadioButton
                disabled={mapIsLoading}
                className="surface-radio"
                checked={isExpanded}
                ternary={true}
            />
        );
        const letterIcon = getLayerTypeLetter(type, formatMessage);
        const isCloudy = Boolean(surface?.cloudPercentage && Number(surface.cloudPercentage) > 0);
        let propsDialog = null;

        switch (this._getPropsType()) {
            case PropDialogType.Manual:
                propsDialog = (
                    <ManualSurfaceProperties
                        key="manual"
                        isOpen={manualPropsOpen}
                        layer={layer}
                        letterIcon={letterIcon}
                        onSave={(evt) => this._onSaveManualProps(evt)}
                        onClose={() => this._onCloseManualProps()}
                        surface={surface}
                        type={type}
                    />
                );
                break;
            case PropDialogType.ManagementArea:
            case PropDialogType.Surface:
                propsDialog = (
                    <SurfaceProperties
                        key="surface"
                        isOpen={surfacePropsOpen}
                        layer={layer}
                        letterIcon={letterIcon}
                        onSave={(evt) => this._onSaveSurfaceProps(evt)}
                        onClose={() => this._onCloseSurfaceProps()}
                        surface={surface}
                        type={type}
                        customerGuid={customerGuid}
                    />
                );
                break;
            default:
                break;
        }

        const items = [
            <div
                key="item"
                className={classnames("surface-accordion-item", {
                    expanded: isExpanded,
                })}
                onClick={(evt) => this._onClick(evt)}
            >
                {expandedIndicator}
                {!isSampleSites ? null : (
                    <div className="sample-sites-icon" style={getSampleSitesMarkerStyle()} />
                )}
                <span className="surface-item-label"> {displayName} </span>
                {!isCloudy ? null : (
                    <CloudIcon
                        className="cloud-icon"
                        title={`${Number(surface.cloudPercentage).toFixed(0)}% cloud coverage`}
                    />
                )}
                {!surface.isCorrupt ? null : (
                    <WarningIcon
                        className="warning-icon"
                        title={formatMessage(messages.surfaceIsCorrupt)}
                    />
                )}
                {
                    <SurfaceLayerContextMenu
                        field={field}
                        onEditProps={() => this._onEditProps()}
                        onLayerStats={() => this.setState({ layerStatsOpen: true })}
                        onPrintLayer={(rendererGuid, renderPoints, imageryLayerGuid) =>
                            this._onPrintLayer(rendererGuid, renderPoints, imageryLayerGuid)
                        }
                        surfaceInfo={surface}
                        onSampleSites={isSampleSites}
                        type={type}
                    />
                }
                {!isLoading ? null : (
                    <Loader
                        className="layer-loader"
                        type={LoaderTypes.LINE_SCALE_PULSE_OUT_RAPID}
                    />
                )}
            </div>,
        ];
        if (!isSampleSites) {
            items.push(propsDialog);
        }
        items.push(
            <LayerStats
                key="stats"
                isOpen={layerStatsOpen}
                layer={layer}
                letterIcon={letterIcon}
                onClose={() => this.setState({ layerStatsOpen: false })}
                surface={surface}
                type={type}
            />
        );

        return items;
    }
}

const mapDispatchToProps = (dispatch) => ({
    collapseItem: (accordionId, dimIdx) =>
        dispatch(accordionActions.collapseAccordionItem(accordionId, dimIdx)),
    expandItem: (accordionId, dimIdx) =>
        dispatch(accordionActions.expandAccordionItem(accordionId, dimIdx)),
    onChangeManualLayerProps: (payload) => dispatch(actions.changeManualLayerProps(payload)),
    onChangeSurfaceLayerProps: (payload) => dispatch(actions.changeSurfaceLayerProps(payload)),
    onPrintLayer: (payload) => dispatch(actions.createPrintLayer(payload)),
    onRemoveVisibleSampleSites: (fieldGuids) =>
        dispatch(actions.removeVisibleSampleSites(fieldGuids)),
    onSetVisibleSampleSite: (fieldGuid, layer) =>
        dispatch(actions.setVisibleSampleSites(fieldGuid, layer)),
});

const mapStateToProps = (state, ownProps) => {
    const surface = LayerUtilsAPI.getSurfaceInfo(
        ownProps.layer,
        ownProps.surfaceGuid,
        ownProps.attributeGuid
    );
    const mapIsLoading = getIsLoading(state);

    return {
        customerGuid: ownProps.field.customerGuid,
        mapIsLoading,
        isSampleSites: ownProps.surfaceGuid === LayerUtilsAPI.SAMPLE_SITES_GUID,
        surface,
        visibleSampleSitesMap: selectors.getVisibleSampleSitesMap(state),
    };
};

export const SurfaceItem = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SurfaceItem_));
