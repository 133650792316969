import React, { Component } from "react";
import { messages } from "../../i18n-messages";
import { injectIntl, InjectedIntlProps } from "react-intl";
import {
    model,
    service,
    getProductParentType,
    isChemicalOrFertilizerType,
    isChemicalType,
    isFertilizerType,
    REQUEST_PRODUCT_TYPE,
    REQUEST_PAYLOAD_PRODUCT_PARENT_TYPE,
    REQUEST_PAYLOAD_PRODUCT_TYPE,
} from "../data";

import {
    getAgBytesErrorClassNames,
    formatPlainTextLabel,
    handlePicklistChange,
    mapToPicklistValue,
    onNumberChange,
    onTextChange,
    onTextChangeObj,
    prepareSelectableOptions,
} from "~/admin/utils";

// constants
import * as picklistService from "~/core/picklist/picklist-names";
import { adminData, ID, PICKLIST_GUID, VALUE } from "~/admin/data";
// Components
import {
    Checkbox,
    NumericInput,
    RadioButton,
    RadioButtonGroup,
    Section,
    SelectInput,
    SubSection,
    TextArea,
    TextInput,
} from "~/core";
import {
    ChemicalFertilizerInfo,
    SeedInformation,
    ProductList,
    Properties,
    DocumentList,
    NutrientList,
} from "../components";
import { PhotoSimple } from "~/admin/agBytes/components/photo-simple";
import "./../components/components.css";
import "./add-edit.css";
import AliasList from "~/admin/agBytes/components/alias-list";
import { IAddEditPanelState, IAddEditProps, Product } from "../interfaces";
const SVG = "svg";
const PRODUCT_TYPE = {
    chemical: "1",
    fertilizer: "2",
    seed: "3",
    service: "4",
};
const NUTRIENT_TYPE = {
    elemental: "elemental",
    oxide: "oxide",
};
export class AddEditPanel extends Component<IAddEditProps & InjectedIntlProps, IAddEditPanelState> {
    product: Product;
    constructor(props) {
        super(props);
        this.product = {} as Product;
        this.state = {
            [picklistService.PICKLIST_PRODUCT_PARENT_TYPE]: [],
            [model.PRODUCT_TYPE]: [],
            [model.NUTRIENT_TYPE]: [],
            [picklistService.PICKLIST_MANUFACTURER]: [],
            nextId: null,
            productParentTypeState: "",
            recordIsUpdated: false,
        };
    }

    componentDidMount() {
        this.setupPicklistRecord();
        this.props.setBreadcrumbs([""]);
        const { needs } = this.props;
        needs([
            this.props.getNextId(),
            this.props.fetchPicklistData(),
            this.props.fetchUnitData(),
            this.props.fetchDropdownData(),
        ]);

        if (this.props.recordGuid) {
            needs([this.props.fetchRecord(this.props.recordGuid)]);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.fetchData) {
            this.props.liftRecordData(this.product);
        }
        if (nextProps.addEditPanel.mode === "ADD") {
            this.setupNextId(nextProps);
        } else {
            if (nextProps.record && nextProps.record !== this.props.record) {
                this.product = nextProps.record;
                this.requestProductType(this.product[model.PROPS_PRODUCT_PARENT_TYPE_GUID]);
                this.setState({
                    recordIsUpdated: true,
                });
            }
        }

        this.initializeDropdowns(nextProps);
    }

    getLogoErrorClassName = (errorCodes = []) => {
        const { apiErrors } = this.props;
        return getAgBytesErrorClassNames(errorCodes, apiErrors).length
            ? "photo-simple-error"
            : null;
    };

    setupNextId = (nextProps) => {
        if (nextProps.nextId && nextProps.nextId !== this.state.nextId) {
            this.product[model.PROPS_PRODUCT_ID] = "" + nextProps.nextId;
            this.setState({
                nextId: nextProps.nextId,
            });
        }
    };

    setupPicklistRecord = () => {
        const { addEditPanel } = this.props;
        this.product = {} as Product;
        if (addEditPanel.mode === "ADD") {
            this.product = service.getDefaultRecord();
        }
    };

    initializeDropdowns = (nextProps) => {
        this.initializeProductParentType(nextProps);
        this.initializeManufacturer(nextProps);
        this.initializeProductType(nextProps);
        this.initializeNutrients(nextProps);
    };

    initializeProductParentType = (nextProps) => {
        if (
            this.product[model.PROPS_PRODUCT_PARENT_TYPE_GUID] != null &&
            nextProps[picklistService.PICKLIST_PRODUCT_PARENT_TYPE]
        ) {
            const options = prepareSelectableOptions(
                nextProps[picklistService.PICKLIST_PRODUCT_PARENT_TYPE],
                { guid: PICKLIST_GUID, label: VALUE, id: ID }
            );
            let productParentTypeState = "";
            if (this.product[model.PROPS_PRODUCT_PARENT_TYPE_GUID]) {
                const { id } = mapToPicklistValue({
                    options,
                    selectedGuid: this.product[model.PROPS_PRODUCT_PARENT_TYPE_GUID],
                });
                productParentTypeState = id;
            }
            this.setState({
                [picklistService.PICKLIST_PRODUCT_PARENT_TYPE]: options,
                productParentTypeState,
            });
        }
    };

    initializeProductType = (nextProps) => {
        if (
            this.product[model.PROPS_PRODUCT_PARENT_TYPE_GUID] != null &&
            nextProps[model.PRODUCT_TYPE]
        ) {
            this.setState({
                [model.PRODUCT_TYPE]: nextProps[model.PRODUCT_TYPE],
            });
        }
    };

    initializeNutrients = (nextProps) => {
        if (this.product[model.PROPS_NUTRIENT_LIST] != null && nextProps[model.NUTRIENT_TYPE]) {
            this.setState({
                [model.NUTRIENT_TYPE]: nextProps[model.NUTRIENT_TYPE],
            });
        }
    };

    initializeManufacturer = (nextProps) => {
        if (
            this.product[model.PROPS_MANUFACTURER_GUID] != null &&
            nextProps[picklistService.PICKLIST_MANUFACTURER]
        ) {
            this.setState({
                [picklistService.PICKLIST_MANUFACTURER]: prepareSelectableOptions(
                    nextProps[picklistService.PICKLIST_MANUFACTURER],
                    { guid: PICKLIST_GUID, label: VALUE, id: ID }
                ),
            });
        }
    };

    onPicklistChange = ({ type, guid }, value, callback?: () => void): void => {
        this.product = handlePicklistChange(this.product, { type, guid, value }, callback);
    };

    onTextChange = (formKey: string, value: any, callback?: () => void): void => {
        if (formKey === model.PROPS_PRODUCT_ALIAS) {
            const valueArr = [];
            value.forEach((val) => {
                for (const prop in val) {
                    valueArr.push(val[prop]);
                }
            });
            this.product = onTextChange(
                this.product,
                { formKey: [formKey], value: valueArr },
                callback
            );
        } else {
            this.product = onTextChange(this.product, { formKey: [formKey], value }, callback);
        }
        this.forceUpdate();
    };

    onTextChangeObj = (formKey, propertyName, value, callback?: () => void) => {
        this.product = onTextChangeObj(
            this.product,
            { formKey: [formKey], propertyName: [propertyName], value },
            callback
        );
    };
    onNumberChange = (formKey: string, value: string | number, callback?: () => void): void => {
        this.product = onNumberChange(this.product, { formKey: [formKey], value }, callback);
    };

    setHeaderText = () => {
        this.props.setBreadcrumbs([this.product[model.PROPS_PRODUCT_NAME]]);
    };

    onParentProductTypeChange = ({ guid, id }) => {
        this.resetValuesPerProductType();
        guid && this.requestProductType(guid);
        this.setState({
            productParentTypeState: id,
        });
        if (id === PRODUCT_TYPE.fertilizer) {
            //if parent type = fertilizer set nutrient to oxide
            this.onTextChange(model.PROPS_OXIDE, true);
        }
    };

    resetValuesPerProductType = () => {
        this.product[model.PROPS_PRODUCT_TYPE_LIST] = [];
        this.product[model.PROPS_NUTRIENT_LIST] = [];
        this.product[model.PROPS_CHEMICAL_FAMILY_GUID] = "";
        this.product[model.PROPS_CHEMICAL_FAMILY_NAME] = "";
        this.product[model.PROPS_MODE_OF_ACTION] = "";
        this.product[model.PROPS_PEST_INJURY_SYMPTOMS] = "";
        this.product[model.PROPS_PPE_INFORMATION] = "";
        this.product[model.PROPS_PPE_REENTRY_INFORMATION] = "";
        this.product[model.PROPS_REI_HOURS_LOW] = "";
        this.product[model.PROPS_REI_HOURS_HIGH] = "";
    };

    requestProductType = (value) => {
        const { needs, fetchDropdownData } = this.props;
        needs([
            fetchDropdownData({
                [model.PRODUCT_TYPE]: {
                    url: REQUEST_PRODUCT_TYPE,
                    model: {
                        parentPicklistName: REQUEST_PAYLOAD_PRODUCT_PARENT_TYPE,
                        picklistName: REQUEST_PAYLOAD_PRODUCT_TYPE,
                        picklistParentGuid: value,
                    },
                },
            }),
        ]);
    };

    getNutrientType(value = false) {
        if (value) {
            return NUTRIENT_TYPE.oxide;
        } else {
            return NUTRIENT_TYPE.elemental;
        }
    }

    onRadioButtonChange = (value) => {
        if (value && value === NUTRIENT_TYPE.oxide) {
            this.onTextChange(model.PROPS_OXIDE, true);
        } else {
            this.onTextChange(model.PROPS_OXIDE, false);
        }
    };

    isProductServiceOrNull(productParentTypeState) {
        return getProductParentType(productParentTypeState);
    }

    renderProductInfo = () => {
        const { formatMessage } = this.props.intl;
        const { product } = this;
        const { nextId, productParentTypeState } = this.state;
        return (
            <div className="section-container">
                <Section>
                    <SubSection>
                        <NumericInput
                            tabIndex={0}
                            scale={0}
                            precision={9}
                            suppressFormatting
                            containerClassNames={getAgBytesErrorClassNames(
                                [15, 22],
                                this.props.apiErrors,
                                ["form-input-id"]
                            )}
                            placeholderText={formatMessage(messages.productId)}
                            value={nextId || product[model.PROPS_PRODUCT_ID]}
                            onChange={(value) =>
                                this.onNumberChange(
                                    model.PROPS_PRODUCT_ID,
                                    value == null || value === "" ? null : value
                                )
                            }
                            required
                        />
                        {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                            <Checkbox
                                className="active-checkbox"
                                onChange={(e, value) =>
                                    this.onTextChange(model.PROPS_ACTIVE_YN, value)
                                }
                                value={product[model.PROPS_ACTIVE_YN]}
                                label={formatMessage(messages.active)}
                            />
                        )}
                    </SubSection>
                    <div className="form-sub-section section-column product-id-section">
                        <span>
                            {formatPlainTextLabel(
                                formatMessage,
                                messages.agrianID,
                                product[model.PROPS_AGRIAN_ID]
                            )}
                        </span>
                        <span>
                            {formatPlainTextLabel(
                                formatMessage,
                                messages.agXID,
                                product[model.PROPS_AGX_ID]
                            )}
                        </span>
                        <span>
                            {formatPlainTextLabel(
                                formatMessage,
                                messages.agGatewayID,
                                product[model.PROPS_AGGATEWAY_PRODUCT_ID]
                            )}
                        </span>
                        <span>
                            {formatPlainTextLabel(
                                formatMessage,
                                messages.agGatewayCompanyID,
                                product[model.PROPS_AGGATEWAY_COMPANY_ID]
                            )}
                        </span>
                    </div>
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            autoFocus
                            openOnFocus={false}
                            required
                            containerClassNames={getAgBytesErrorClassNames(
                                414,
                                this.props.apiErrors
                            )}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_PRODUCT_PARENT_TYPE]}
                            placeholderText={formatMessage(messages.productParentTypeName)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_PRODUCT_PARENT_TYPE],
                                selectedGuid: product[model.PROPS_PRODUCT_PARENT_TYPE_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_PRODUCT_PARENT_TYPE_NAME,
                                        guid: model.PROPS_PRODUCT_PARENT_TYPE_GUID,
                                    },
                                    value,
                                    () => this.onParentProductTypeChange(value)
                                );
                            }}
                        />
                        <TextInput
                            tabIndex={0}
                            maxLength={50}
                            required
                            containerClassNames={getAgBytesErrorClassNames(
                                [359, 450],
                                this.props.apiErrors
                            )}
                            placeholderText={formatMessage(messages.productName)}
                            value={product[model.PROPS_PRODUCT_NAME]}
                            onChange={(value) =>
                                this.onTextChange(
                                    model.PROPS_PRODUCT_NAME,
                                    value,
                                    this.setHeaderText
                                )
                            }
                        />
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <Section className="manufacturer-section">
                    <SubSection>
                        <SelectInput
                            tabIndex={0}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            options={this.state[picklistService.PICKLIST_MANUFACTURER]}
                            placeholderText={formatMessage(messages.manufacturer)}
                            value={mapToPicklistValue({
                                options: this.state[picklistService.PICKLIST_MANUFACTURER],
                                selectedGuid: product[model.PROPS_MANUFACTURER_GUID],
                            })}
                            onChange={(value) => {
                                this.onPicklistChange(
                                    {
                                        type: model.PROPS_MANUFACTURER_NAME,
                                        guid: model.PROPS_MANUFACTURER_GUID,
                                    },
                                    value
                                );
                            }}
                        />
                    </SubSection>
                    <SubSection>
                        <TextArea
                            maxLength={2000}
                            placeholderText={formatMessage(messages.description)}
                            value={product[model.PROPS_PRODUCT_DESCRIPTION]}
                            onChange={(value) =>
                                this.onTextChange(model.PROPS_PRODUCT_DESCRIPTION, value)
                            }
                        />
                    </SubSection>
                </Section>
                <span className="no-bar section-fence"></span>
                <div className="section-container section-column">
                    <Section
                        className="grid-section product-type-section"
                        required
                        headerText={formatMessage(messages.productType, {
                            count: 1,
                        })}
                    >
                        <SubSection>
                            <ProductList
                                apiErrors={this.props.apiErrors}
                                formatMessage={formatMessage}
                                addProductLinkText={messages.productType}
                                addProductTitle={messages.productType}
                                disabled={!productParentTypeState}
                                options={this.state[model.PRODUCT_TYPE]}
                                record={product[model.PROPS_PRODUCT_TYPE_LIST]}
                                itemListAlias={model.PROPS_PRODUCT_TYPE_LIST}
                                onTextChange={(e, value) =>
                                    this.onTextChange(model.PROPS_PRODUCT_TYPE_LIST, value)
                                }
                            />
                        </SubSection>
                    </Section>
                    <Section
                        className="grid-section product-type-section"
                        headerText={formatMessage(messages.productLogo)}
                    >
                        <PhotoSimple
                            className={this.getLogoErrorClassName([2869])}
                            formatMessage={formatMessage}
                            record={this.product[model.PROPS_PRODUCT_LOGO]}
                            itemListAlias={model.PROPS_PRODUCT_LOGO}
                            onChange={(value) => this.onTextChange(model.PROPS_PRODUCT_LOGO, value)}
                            title={formatMessage(messages.recommendedImageSize)}
                            id={model.PROPS_SYSTEM_LOGO_DARK}
                            fileType={SVG}
                        />
                    </Section>
                </div>
            </div>
        );
    };

    renderProductInfo1 = () => {
        const { product } = this;
        const { productParentTypeState } = this.state;
        const seedProsps = {
            product: product,
            onPicklistChange: this.onPicklistChange,
            onTextChange: this.onTextChange,
            productParentType: productParentTypeState,
            needs: this.props.needs,
            mode: this.props.addEditPanel.mode,
        };
        const chemicalProps = {
            product: product,
            onPicklistChange: this.onPicklistChange,
            onTextChange: this.onTextChange,
            onNumberChange: this.onNumberChange,
            productParentType: getProductParentType(productParentTypeState),
        };

        return (
            <div className="section-container">
                {productParentTypeState === PRODUCT_TYPE.seed ? (
                    <SeedInformation {...seedProsps} />
                ) : (
                    <ChemicalFertilizerInfo {...chemicalProps} />
                )}
            </div>
        );
    };

    renderPhoneNumbers = () => {
        const { product } = this;
        const { formatMessage } = this.props.intl;
        const phoneNumberProp = "phoneNumber";
        return (
            <Section headerText={formatMessage(messages.phoneNumbers)}>
                <SubSection>
                    <TextInput
                        tabIndex={0}
                        maxLength={20}
                        placeholderText={formatMessage(messages.responsePhoneNumber)}
                        value={product[model.PROPS_RESPONSE_PHONENUMBER_OBJ].phoneNumber || ""}
                        onChange={(value) =>
                            this.onTextChangeObj(
                                model.PROPS_RESPONSE_PHONENUMBER_OBJ,
                                phoneNumberProp,
                                value
                            )
                        }
                    />
                </SubSection>
                <SubSection>
                    <TextInput
                        tabIndex={0}
                        maxLength={20}
                        placeholderText={formatMessage(messages.medicalPhoneNumber)}
                        value={product[model.PROPS_MEDICAL_PHONENUMBER_OBJ].phoneNumber || ""}
                        onChange={(value) =>
                            this.onTextChangeObj(
                                model.PROPS_MEDICAL_PHONENUMBER_OBJ,
                                phoneNumberProp,
                                value
                            )
                        }
                    />
                </SubSection>
            </Section>
        );
    };

    renderRegulatoryInfo = () => {
        const { product } = this;
        const { formatMessage } = this.props.intl;
        const { productParentTypeState } = this.state;
        return (
            <Section headerText={formatMessage(messages.regulatoryInfo)}>
                <SubSection>
                    <TextInput
                        tabIndex={0}
                        maxLength={50}
                        placeholderText={formatMessage(messages.registrationCode)}
                        value={product[model.PROPS_REGISTRATION_CODE] || ""}
                        onChange={(value) =>
                            this.onTextChange(model.PROPS_REGISTRATION_CODE, value)
                        }
                    />
                    {isChemicalType(productParentTypeState) ? (
                        <Checkbox
                            onChange={(e, value) =>
                                this.onTextChange(model.PROPS_FEDERALLY_RESTRICTED, value)
                            }
                            value={product[model.PROPS_FEDERALLY_RESTRICTED]}
                            label={formatMessage(messages.federallyRestricted)}
                        />
                    ) : null}
                </SubSection>
                <SubSection>
                    <TextArea
                        placeholderText={formatMessage(messages.bufferDistanceInformation)}
                        value={product[model.PROPS_BUFFER_DISTANCE_INFORMATION]}
                        onChange={(value) =>
                            this.onTextChange(model.PROPS_BUFFER_DISTANCE_INFORMATION, value)
                        }
                    />
                </SubSection>
                <SubSection>
                    <Checkbox
                        onChange={(e, value) =>
                            this.onTextChange(model.PROPS_GRAZING_RESTRICTION, value)
                        }
                        value={product[model.PROPS_GRAZING_RESTRICTION]}
                        label={formatMessage(messages.grazingRestriction)}
                    />
                </SubSection>
            </Section>
        );
    };

    renderPPEInfo = () => {
        const { product } = this;
        const { formatMessage } = this.props.intl;
        return (
            <Section>
                <SubSection>
                    <TextArea
                        placeholderText={formatMessage(messages.ppeInformation)}
                        value={product[model.PROPS_PPE_INFORMATION]}
                        onChange={(value) => this.onTextChange(model.PROPS_PPE_INFORMATION, value)}
                    />
                </SubSection>
                <SubSection>
                    <TextArea
                        placeholderText={formatMessage(messages.reppeInformation)}
                        value={product[model.PROPS_PPE_REENTRY_INFORMATION]}
                        onChange={(value) =>
                            this.onTextChange(model.PROPS_PPE_REENTRY_INFORMATION, value)
                        }
                    />
                </SubSection>
                <SubSection>
                    <NumericInput
                        tabIndex={0}
                        scale={4}
                        precision={17}
                        placeholderText={formatMessage(messages.reEntryIntervalHoursLow)}
                        value={product[model.PROPS_REI_HOURS_LOW]}
                        onChange={(value) => this.onNumberChange(model.PROPS_REI_HOURS_LOW, value)}
                    />
                    <NumericInput
                        tabIndex={0}
                        scale={4}
                        precision={17}
                        placeholderText={formatMessage(messages.reEntryIntervalHoursHigh)}
                        value={product[model.PROPS_REI_HOURS_HIGH]}
                        onChange={(value) => this.onNumberChange(model.PROPS_REI_HOURS_HIGH, value)}
                    />
                </SubSection>
            </Section>
        );
    };

    renderDocumentAndNutrient = () => {
        const { product } = this;
        const { formatMessage } = this.props.intl;
        const { productParentTypeState } = this.state;

        return (
            <div className="section-container section-column">
                {!productParentTypeState ? null : (
                    <Section className="grid-section" headerText={formatMessage(messages.document)}>
                        <DocumentList
                            formatMessage={formatMessage}
                            record={product[model.PROPS_DOCUMENT_LIST]}
                            itemListAlias={model.PROPS_DOCUMENT_LIST}
                            onTextChange={this.onTextChange}
                        />
                    </Section>
                )}
                {isFertilizerType(this.state.productParentTypeState) && (
                    <Section headerText={formatMessage(messages.nutrientName)}>
                        <SubSection>
                            <RadioButtonGroup
                                className={"nutrient-radio-group"}
                                selectedValue={this.getNutrientType(product[model.PROPS_OXIDE])}
                                afterOnChange={this.onRadioButtonChange}
                            >
                                <RadioButton
                                    tabIndex={0}
                                    value={NUTRIENT_TYPE.elemental}
                                    label={formatMessage(messages.elemental)}
                                />
                                <RadioButton
                                    tabIndex={0}
                                    value={NUTRIENT_TYPE.oxide}
                                    label={formatMessage(messages.oxide)}
                                />
                            </RadioButtonGroup>
                        </SubSection>
                        <NutrientList
                            formatMessage={formatMessage}
                            options={this.state[model.NUTRIENT_TYPE]}
                            record={product[model.PROPS_NUTRIENT_LIST]}
                            itemListAlias={model.PROPS_NUTRIENT_LIST}
                            onTextChange={(f, value) =>
                                this.onTextChange(model.PROPS_NUTRIENT_LIST, value)
                            }
                        />
                    </Section>
                )}
                <Section
                    className="grid-section"
                    headerText={formatMessage(messages.productNameAlias)}
                >
                    <SubSection>
                        <AliasList
                            formatMessage={formatMessage}
                            aliasTextLabel="productNameAlias"
                            record={(this.product[model.PROPS_PRODUCT_ALIAS] || []).map(
                                (aliasName) => ({
                                    aliasName,
                                })
                            )}
                            itemListAlias={model.PROPS_PRODUCT_ALIAS}
                            onTextChange={this.onTextChange}
                        />
                    </SubSection>
                </Section>
            </div>
        );
    };

    render() {
        const props = {
            product: this.product,
            onPicklistChange: this.onPicklistChange,
            onTextChange: this.onTextChange,
            productParentType: this.state.productParentTypeState,
        };
        return (
            <div className="add-edit-panel">
                {this.renderProductInfo()}
                {this.isProductServiceOrNull(this.state.productParentTypeState) ? (
                    <div className="section-container">
                        {this.renderProductInfo1()}
                        <span className="bar section-fence"></span>
                        <div className="section-container section-column">
                            <Properties {...props} />
                            {this.renderPhoneNumbers()}
                            {this.renderRegulatoryInfo()}
                            {isChemicalOrFertilizerType(this.state.productParentTypeState) &&
                                this.renderPPEInfo()}
                        </div>
                        <span className="bar section-fence"></span>
                        {this.renderDocumentAndNutrient()}
                    </div>
                ) : (
                    <div>{this.renderDocumentAndNutrient()}</div>
                )}
            </div>
        );
    }
}

export default injectIntl(AddEditPanel);
