import React from "react";
import "./field-information-title-bar.css";

interface IProps {
    className?: string;
    fieldName: string;
    customerName: string;
    farmName: string;
    acres: number;
}

export const FieldInformationTitleBar = (props: IProps): JSX.Element => {
    /// ----------------------------------------- ///

    /// Event Handlers ///

    /// ------------- ///

    const { fieldName, customerName, farmName, acres } = props;
    const farmNameVisible = farmName !== "" && farmName != null;

    return (
        <div className="field-information-title-bar title-bar">
            <div className="first-line">{fieldName}</div>
            <div className="second-line">{customerName}</div>
            <div className="third-line">
                {farmNameVisible && <span>{farmName}, </span>}
                <span>{acres.toFixed(2)} acres</span>
            </div>
        </div>
    );
};
FieldInformationTitleBar.defaultProps = {
    fieldName: "",
    customerName: "",
    farmName: "",
    acres: 0,
};
