import React, { Component } from "react";
import PropTypes from "prop-types";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../i18n-messages";
import { messages as mainMessages, createAddLinkLabelText } from "~/i18n-messages";

// Components
import { ZeroToInfiniteUnsafe, Section } from "~/core";
import UrlItem from "./url-item";

//Style
import "./urls.css";

export class Urls extends Component {
    static propTypes = {
        addEditPanel: PropTypes.object,
        index: PropTypes.number,
        intl: intlShape.isRequired,
        onChildComponentChange: PropTypes.func,
        required: PropTypes.bool,
        urlList: PropTypes.array,
        urlListAlias: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            urls: [],
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.addEditPanel.mode === "EDIT" && nextProps.urlList !== this.state.email) {
            this.setState({
                urls: nextProps.urlList,
            });
        }
    }

    addItem = (e, items) => {
        this.setState({ urls: items });
    };

    onDelete = (e, items) => {
        this.urls = items;
    };

    onChange = ({ type }, value, index) => {
        const urls = [...this.state.urls];
        if (type) {
            urls[index].url = value;
        }
        this.setState({ urls }, () =>
            this.props.onChildComponentChange(this.props.urlListAlias, this.state.urls)
        );
    };

    render() {
        const { formatMessage } = this.props.intl;
        const { urls } = this.state;
        const initialValue = {
            url: "",
        };
        const { onChange } = this;
        const props = {
            onChange,
            urls,
        };
        return (
            <Section
                className="z-to-n-header url-header"
                headerText={formatMessage(messages.url, { count: 2 })}
            >
                <ZeroToInfiniteUnsafe
                    items={urls}
                    initialValue={initialValue}
                    getChildProps={() => null}
                    onDelete={this.onDelete}
                    addItem={this.addItem}
                    addText={createAddLinkLabelText(formatMessage, messages.url, { count: 1 })}
                    deleteText={formatMessage(mainMessages.delete)}
                    formatMessage={formatMessage}
                    required={this.props.required}
                >
                    <UrlItem {...props} />
                </ZeroToInfiniteUnsafe>
            </Section>
        );
    }
}

export default injectIntl(Urls);
