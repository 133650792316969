import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { injectIntl, intlShape } from "react-intl";

import { TextInput, SelectInput, NumericInput, Bucket, BucketHeader, NoLink } from "~/core";
import {
    ACTIVE_YN,
    picklistNames,
    actions as picklistActions,
    selectors as picklistSelectors,
} from "~/core/picklist";

import {
    ProfitLossCost,
    ProfitLossCostType,
    ProfitLossDetailedCostInfo,
    ProfitLossActualCost,
    IAnalysisProfitLossSummary,
} from "~/recs-events/analysis/model";

import * as actions from "./actions";
import * as selectors from "./selectors";
import { getSetValuesForErrorCodeList } from "~/action-panel/components/common/validation-utils";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { CropAPI, LayerAPI, PicklistAPI } from "@ai360/core";
import { messages } from "../i18n-messages";

import { AnalysisIcon } from "./icons/analysis";

import "~/action-panel/components/common/rec-event-info/rec-event-info.css";
import "./analysis-info.css";
import "./analysis-profit-loss-form.css";
import { ProfitLossDetailedConfigModel } from "./profitLossDetailed-config-modal";
import { IErrorCodeList } from "./interfaces";

interface Props {
    analysisSummary: IAnalysisProfitLossSummary;
    intl: intlShape;
    normalizedEventYieldOptions: LayerAPI.INormalizedYieldSeason[];
    pickListNumClasses: PicklistAPI.IPicklistItem[];
    analysisDetailsErrorCodeList: number[];
    onUpdateCurrentAnalysisSummary: (newProps: Partial<IAnalysisProfitLossSummary>) => void;
    fetchPicklistData: (picklists: any) => void;
    fetchDropdownData: (dropdowns: any) => void;
    croppingSeasonOptions: PicklistAPI.IPicklistItem[];
    dropdownData: any;
    onfetchActualDetailCost: (model: ProfitLossActualCost) => void;
    onCloseProfitLossDetails: () => void;
}

export const formLabelMessage = messages.profitLossSidebar;
export const formLabelIcon = AnalysisIcon;

const errorCodeToMessageIdSetMap = new Map([
    [2800, messages.analysisMethodNoYieldLayers],
    [2903, messages.profitLossNoYieldLayers],
    [2805, messages.analysisMethodPlaceholderText],
    [2806, messages.analysisDuplicateName],
]);

export const errorCodesApply = (errorCodeList: IErrorCodeList): number => {
    return errorCodeList.some((errorCode) => errorCodeToMessageIdSetMap.has(errorCode));
};

export class AnalysisProfitLossForm_ extends PureComponent<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errorMessagePlaceholderSet: this.getErrorMessagePlaceholderSet(props),
        };
    }

    componentDidMount(): void {
        this.props.fetchDropdownData({
            cropData: {
                url: CropAPI.REQUEST_CROP,
                model: "Name",
            },
        });
        this.props.fetchPicklistData({
            [picklistNames.PICKLIST_CROPPING_SEASON]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_CROPPING_SEASON
            ),
        });
    }

    UNSAFE_componentWillReceiveProps(newProps: Props): void {
        if (newProps.analysisDetailsErrorCodeList !== this.props.analysisDetailsErrorCodeList) {
            const errorMessagePlaceholderSet = this.getErrorMessagePlaceholderSet(newProps);
            this.setState({ errorMessagePlaceholderSet });
        }
    }

    private getErrorMessagePlaceholderSet(props) {
        const { analysisDetailsErrorCodeList } = props;
        return getSetValuesForErrorCodeList(
            analysisDetailsErrorCodeList,
            errorCodeToMessageIdSetMap
        );
    }

    private changeCostValue(type: ProfitLossCostType, to: number) {
        const currentType = this.currentCost().type;
        if (currentType === type) {
            this.updateAnalysisSummary({
                cost: { type: currentType, value: to },
            });
        }
    }

    private costValue(forType: ProfitLossCostType) {
        const currentCost = this.currentCost();
        if (currentCost.type === forType) {
            return currentCost.value;
        }

        const sellingPrice = this.props.analysisSummary.sellingPrice;
        if (sellingPrice == null || currentCost.value == null) {
            return null;
        }

        if (forType === ProfitLossCostType.CostPerAcre) {
            return Number(currentCost.value) * sellingPrice;
        } else if (forType === ProfitLossCostType.BreakEvenYield) {
            return Number(currentCost.value) / sellingPrice;
        } else {
            return Number(currentCost.value);
        }
    }

    private changeCostType(to: ProfitLossCostType) {
        this.updateAnalysisSummary({ cost: { type: to, value: null } });
        this.updateAnalysisSummary({ detailedTypeCostInfo: this.initialize() });
    }

    private currentCost(): ProfitLossCost {
        return this.props.analysisSummary.cost;
    }

    private updateAnalysisSummary(newProps) {
        this.props.onUpdateCurrentAnalysisSummary(newProps);
    }

    private renderErrors(errorMessagePlaceholderSet, formatMessage) {
        return errorMessagePlaceholderSet == null || errorMessagePlaceholderSet.size === 0
            ? null
            : [...errorMessagePlaceholderSet].map((message, index) => (
                  <div className="analysis-error-message" key={index}>
                      {formatMessage(message)}
                  </div>
              ));
    }

    private initialize() {
        const init: ProfitLossDetailedCostInfo = {
            seed: 0,
            fertilizer: 0,
            chemical: 0,
            irrigation: 0,
            harvest: 0,
            equipment: 0,
            labor: 0,
            cropInsurance: 0,
            interestOperatingCapital: 0,
            miscellaneous: 0,
            land: 0,
            nonCropIncome: 0,
            total: 0,
            useSeed: false,
            useFertilizer: false,
            useChemical: false,
        };
        return init;
    }

    private onAddEditDetailedCost() {
        const { analysisSummary } = this.props;
        this.setState({ isDetailedSetupActive: true });
        this.props.onfetchActualDetailCost({
            croppingSeasonGuid: analysisSummary.croppingSeasonGuid,
            fieldGuids: analysisSummary.layers.map((x) => x.fieldGuid),
        });
    }

    _getDetailCostModal(): JSX.Element {
        const { analysisSummary } = this.props;
        const onClose = (newdetailedCostType) => {
            if (newdetailedCostType != null) {
                this.changeCostValue(ProfitLossCostType.Detailed, newdetailedCostType.total);
                this.updateAnalysisSummary({
                    detailedTypeCostInfo: newdetailedCostType,
                });
            }
            this.props.onCloseProfitLossDetails();
            this.setState({ isDetailedSetupActive: false });
        };
        return (
            <ProfitLossDetailedConfigModel
                detailedCost={
                    analysisSummary.detailedTypeCostInfo
                        ? analysisSummary.detailedTypeCostInfo
                        : this.initialize()
                }
                onClose={onClose}
                fieldGuid={analysisSummary.layers[0].fieldGuid}
            />
        );
    }

    _displaySelectedCostType(): JSX.Element {
        const { analysisSummary } = this.props;
        const { formatMessage } = this.props.intl;
        if (analysisSummary.cost.type === ProfitLossCostType.CostPerAcre) {
            return (
                <NumericInput
                    required={true}
                    containerClassNames={["analysis-layer-profit-loss-input"]}
                    onChange={(v) => this.changeCostValue(ProfitLossCostType.CostPerAcre, v)}
                    scale={2}
                    precision={9}
                    placeholderText={formatMessage(messages.profitLossCostPerAcrePlaceholder)}
                    value={this.costValue(ProfitLossCostType.CostPerAcre)}
                />
            );
        } else if (analysisSummary.cost.type === ProfitLossCostType.BreakEvenYield) {
            return (
                <NumericInput
                    required={true}
                    containerClassNames={["analysis-layer-profit-loss-input"]}
                    onChange={(v) => this.changeCostValue(ProfitLossCostType.BreakEvenYield, v)}
                    scale={2}
                    precision={9}
                    placeholderText={formatMessage(messages.profitLossBreakEvenYieldPlaceholder)}
                    value={this.costValue(ProfitLossCostType.BreakEvenYield)}
                />
            );
        } else {
            return (
                <NumericInput
                    disabled={true}
                    required={true}
                    containerClassNames={["analysis-layer-profit-loss-input"]}
                    onChange={(v) => this.changeCostValue(ProfitLossCostType.Detailed, v)}
                    scale={2}
                    precision={9}
                    placeholderText={formatMessage(messages.profitLossDetailedPlaceholder)}
                    value={this.costValue(ProfitLossCostType.Detailed)}
                />
            );
        }
    }

    render(): JSX.Element {
        const { analysisSummary, croppingSeasonOptions, dropdownData } = this.props;
        const { formatMessage } = this.props.intl;
        const { errorMessagePlaceholderSet } = this.state;

        const profitLossCostType = [
            { value: 0, label: "Cost/Acre" },
            { value: 1, label: "Break Even Yield" },
            { value: 2, label: "Detailed" },
        ];

        const cropOptions = dropdownData.cropData.map(({ guid, name, activeYn }) => ({
            value: guid,
            label: name,
            activeYn,
        }));

        const eventSelectionUsed = analysisSummary.eventSelectionUsed;

        return (
            <div className="rec-event-info-form">
                {this.renderErrors(errorMessagePlaceholderSet, formatMessage)}
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader className="analysis-info-bucket-header">
                        {formatMessage(messages.profitLossGeneralInformation)}
                    </BucketHeader>
                    <div className="analysis-layer-profit-loss-grid">
                        <TextInput
                            required={true}
                            autoFocus={!analysisSummary.name}
                            containerClassNames={["analysis-layer-profit-loss-input"]}
                            onChange={(v) => this.updateAnalysisSummary({ name: v })}
                            placeholderText={formatMessage(messages.profitLossNamePlaceholder)}
                            value={analysisSummary.name}
                            maxLength={50}
                        />
                        <SelectInput
                            disabled={eventSelectionUsed}
                            clearable={false}
                            required={true}
                            containerClassNames={["analysis-layer-profit-loss-input"]}
                            onChange={(v) =>
                                this.updateAnalysisSummary({
                                    croppingSeasonGuid: v,
                                })
                            }
                            options={croppingSeasonOptions}
                            placeholderText={formatMessage(
                                messages.profitLossCroppingSeasonPlaceholder
                            )}
                            value={analysisSummary.croppingSeasonGuid}
                        />
                    </div>
                </Bucket>
                <Bucket showSymbol={false} isCollapsible={false} isExpanded>
                    <BucketHeader className="analysis-info-bucket-header">
                        {formatMessage(messages.profitLossCropDetail)}
                    </BucketHeader>
                    <div className="analysis-layer-profit-loss-grid">
                        <SelectInput
                            disabled={eventSelectionUsed}
                            clearable={false}
                            required={true}
                            containerClassNames={["analysis-layer-profit-loss-input"]}
                            onChange={(v) => this.updateAnalysisSummary({ cropGuid: v })}
                            options={cropOptions}
                            optionIsHiddenKey={ACTIVE_YN}
                            placeholderText={formatMessage(messages.profitLossCropPlaceholder)}
                            value={analysisSummary.cropGuid}
                        />
                        <NumericInput
                            required={true}
                            containerClassNames={["analysis-layer-profit-loss-input"]}
                            onChange={(v) => this.updateAnalysisSummary({ sellingPrice: v })}
                            scale={2}
                            precision={9}
                            placeholderText={formatMessage(
                                messages.profitLossSellingPricePlaceholder
                            )}
                            value={analysisSummary.sellingPrice}
                        />
                        <SelectInput
                            disabled={false}
                            clearable={false}
                            required={true}
                            containerClassNames={["analysis-layer-profit-loss-input"]}
                            onChange={(v) => this.changeCostType(v)}
                            options={profitLossCostType}
                            placeholderText={formatMessage(messages.profitLossCostTypePlaceholder)}
                            value={analysisSummary.cost.type}
                        />
                        {this._displaySelectedCostType()}
                    </div>
                </Bucket>
                {analysisSummary.cost.type === ProfitLossCostType.Detailed ? (
                    <div className="detailed-link">
                        <NoLink
                            label={
                                analysisSummary.cost.type === ProfitLossCostType.Detailed &&
                                analysisSummary.cost.value > 0
                                    ? formatMessage(messages.profitLossEditDetailedSetup)
                                    : formatMessage(messages.profitLossNewDetailedSetup)
                            }
                            onClick={() => this.onAddEditDetailedCost()}
                        />
                        {!this.state.isDetailedSetupActive ? null : this._getDetailCostModal()}
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onUpdateCurrentAnalysisSummary: (newProps) =>
        dispatch(actions.updateProfitLossAnalysisSummary(newProps)),
    fetchPicklistData: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    fetchDropdownData: (dropdowns) =>
        dispatch(
            (fetchDropdownData as any)({
                ...dropdowns,
                action: actions.fetchedDropdownData,
            })
        ),
    onfetchActualDetailCost: (model) =>
        dispatch(actions.fetchActualDetailCost(model as ProfitLossActualCost)),
    onCloseProfitLossDetails: (): void => dispatch(actions.closeProfitLossDetails()),
});

const mapStateToProps = (state) => {
    const { getPickListCode, PICKLIST_CROPPING_SEASON } = picklistNames;
    const croppingSeasonOptions = picklistSelectors.getPicklistOptionsFromCode(
        state,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );
    const dropdownData = selectors.getDropdownData(state);

    return {
        croppingSeasonOptions,
        dropdownData,
    };
};

export const AnalysisProfitLossForm = connect(
    mapStateToProps,
    mapDispatchToProps,
    null
)(injectIntl(AnalysisProfitLossForm_));
