import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";

import { RecEventItem } from "~/action-panel/components/common/accordion/rec-event-accordion-item";
import { RecEventInfo } from "~/action-panel/components/common/rec-event-info/rec-event-info";
import { config as intlConfig } from "~/intl-provider/config";
import { Pane, Loader } from "~/core";
import { messages as globalMessages } from "~/i18n-messages";
import { messages as analysisMessages } from "../i18n-messages";
import { models as analysisModels } from "~/recs-events";
import * as analysisSelectors from "~/recs-events/analysis/selectors";
import {
    areAllRequiredFieldsSet,
    ANALYSIS_INFO_NAME_MANAGEMENT_AREA,
    AnyAnalysisSummary,
} from "~/recs-events/analysis/model";
import { FieldAPI } from "@ai360/core";

import * as actions from "./actions";
import * as analysisNormalizedYieldForm from "./analysis-normalized-yield-form";
import * as analysisProfitLossForm from "./analysis-profit-loss-form";
import * as analysisEcDataForm from "./analysis-ec-data-form";
import * as analysisSeedForm from "./analysis-seed-form";
import * as analysisUnknownForm from "./analysis-unknown-form";
import * as analysisManagementArea from "./analysis-management-area-form";
import * as analysisImagerySetupForm from "./analysis-imagery-setup-form";
import * as selectors from "./selectors";
import { IMessages } from "~/core/components/tables/interfaces";

const getAnalysisTypeNameToFormMap = () =>
    new Map([
        [
            analysisModels.ANALYSIS_INFO_NAME_UNKNOWN,
            [
                analysisUnknownForm.default,
                analysisUnknownForm.errorCodesApply,
                analysisUnknownForm.formLabelMessage,
                analysisUnknownForm.formLabelIcon,
            ],
        ],
        [
            analysisModels.ANALYSIS_INFO_NAME_FOUR_MATION,
            [
                analysisSeedForm.default,
                analysisSeedForm.errorCodesApply,
                analysisSeedForm.formLabelMessage,
                analysisSeedForm.formLabelIcon,
            ],
        ],
        [
            analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP,
            [
                analysisImagerySetupForm.default,
                analysisImagerySetupForm.errorCodesApply,
                analysisImagerySetupForm.formLabelMessage,
                analysisImagerySetupForm.formLabelIcon,
            ],
        ],
        [
            analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA,
            [
                analysisManagementArea.AnalysisManagementAreaForm,
                analysisManagementArea.errorCodesApply,
                analysisManagementArea.formLabelMessage,
                analysisManagementArea.formLabelIcon,
            ],
        ],
        [
            analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD,
            [
                analysisNormalizedYieldForm.default,
                analysisNormalizedYieldForm.errorCodesApply,
                analysisNormalizedYieldForm.formLabelMessage,
                analysisNormalizedYieldForm.formLabelIcon,
            ],
        ],
        [
            analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD_BENCHMARK,
            [
                analysisNormalizedYieldForm.default,
                analysisNormalizedYieldForm.errorCodesApply,
                analysisNormalizedYieldForm.formLabelMessage,
                analysisNormalizedYieldForm.formLabelIcon,
            ],
        ],
        [
            analysisModels.ANALYSIS_INFO_NAME_PROFIT_LOSS,
            [
                analysisProfitLossForm.AnalysisProfitLossForm,
                analysisProfitLossForm.errorCodesApply,
                analysisProfitLossForm.formLabelMessage,
                analysisProfitLossForm.formLabelIcon,
            ],
        ],
        [
            analysisModels.ANALYSIS_INFO_NAME_EC_DATA,
            [
                analysisEcDataForm.AnalysisEcDataForm,
                analysisEcDataForm.errorCodesApply,
                analysisEcDataForm.formLabelMessage,
                analysisEcDataForm.formLabelIcon,
            ],
        ],
        [
            analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG,
            [
                analysisSeedForm.default,
                analysisSeedForm.errorCodesApply,
                analysisSeedForm.formLabelMessage,
                analysisSeedForm.formLabelIcon,
            ],
        ],
        [
            analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG_DH,
            [
                analysisSeedForm.default,
                analysisSeedForm.errorCodesApply,
                analysisSeedForm.formLabelMessage,
                analysisSeedForm.formLabelIcon,
            ],
        ],
    ]);

const pickListNumClasses = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
];

const joined = (elements, delimiter) => elements.filter((v) => v && v !== "").join(delimiter);

interface IFormLabelProps {
    hasError: boolean;
    intl: intlShape;
    labelIconEl: JSX.Element;
    labelMessage: IMessages;
}

export class FormLabel_ extends PureComponent<IFormLabelProps> {
    render() {
        const { hasError, labelIconEl, labelMessage } = this.props;
        const { formatMessage } = this.props.intl;

        return (
            <div
                className={classnames("rec-event-info-tab-label", {
                    "has-error": hasError,
                })}
            >
                {labelIconEl}
                <div className="tab-nav-text">{formatMessage(labelMessage)}</div>
            </div>
        );
    }
}

export const FormLabel = injectIntl(FormLabel_);

interface IAnalysisInfoProps {
    analysisDetailsErrorCodeList: number[];
    analysisSummary: AnyAnalysisSummary;
    customer: any;
    enableSave: boolean;
    fields: Partial<FieldAPI.IField>[];
    intl: intlShape;
    isLoading: boolean;
    onClose: () => void;
    onSaveChanges: () => void;
}

export class AnalysisInfo_ extends PureComponent<IAnalysisInfoProps> {
    private analysisTypeNameToFormMap: Map<string, any[]> = null;

    private getAnalysisTypeNameToFormMap() {
        if (this.analysisTypeNameToFormMap == null) {
            this.analysisTypeNameToFormMap = getAnalysisTypeNameToFormMap();
        }
        return this.analysisTypeNameToFormMap;
    }

    private getAddEventMenuItems() {
        return [];
    }

    private getEventStripItem() {
        const { analysisSummary, isLoading, fields } = this.props;
        const { formatMessage, formatNumber } = this.props.intl;
        if (isLoading) {
            return (
                <div className="item-container">
                    <Loader />;
                </div>
            );
        }
        const { type, name } = analysisSummary;

        const basicSummaryMessage = () => {
            const elements = [
                fields[0].customerName,
                fields[0].farmName,
                fields[0].fieldName,
                `${formatNumber(fields[0].acres, intlConfig.numberFormatOptions)} ${formatMessage(
                    globalMessages.acres
                )}`,
            ];

            return joined(elements, ", ");
        };

        const batchSummaryMessage = () => {
            const isImagerySetup =
                analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP;
            const summaryMessage = isImagerySetup
                ? analysisMessages.batchImagerySetupSummary
                : analysisMessages.analysisLayerBatchingSummary;
            return formatMessage(summaryMessage, {
                count: fields.length,
            });
        };

        const summaryMessage = isLoading
            ? ""
            : fields.length === 1
            ? basicSummaryMessage()
            : batchSummaryMessage();

        const itemProps = {
            displayName: joined([type, name], " - "),
            summary: summaryMessage,
            contextMenu: null,
            deselectItemsFromDimIdx: () => null,
            isExpanded: false,
            itemDimIdx: [],
            lastClickedDimIdx: [],
            setLastClickedDimIdx: () => null,
            selectItemsFromDimIdx: () => null,
            isSelected: true,
        };
        return (
            <div className="item-container">
                <RecEventItem {...itemProps} />
            </div>
        );
    }

    private getAnalysisPanes() {
        const panes = [];
        const { analysisSummary, analysisDetailsErrorCodeList, isLoading } = this.props;
        if (isLoading || !analysisSummary) {
            const [, errorCodesApply, labelMessage, LabelIcon] =
                this.getAnalysisTypeNameToFormMap().get(analysisModels.ANALYSIS_INFO_NAME_UNKNOWN);
            const label = (
                <FormLabel
                    canRemove={false}
                    hasError={errorCodesApply(analysisDetailsErrorCodeList)}
                    labelMessage={labelMessage}
                    labelIconEl={<LabelIcon />}
                />
            );
            panes.push(
                <Pane key={analysisModels.ANALYSIS_INFO_NAME_UNKNOWN} label={label}>
                    <Loader />
                </Pane>
            );
            return panes;
        }
        const { type } = analysisSummary;
        const [EventEditForm, errorCodesApply, labelMessage, LabelIcon] =
            this.getAnalysisTypeNameToFormMap().get(type);
        const label = (
            <FormLabel
                canRemove={false}
                hasError={errorCodesApply(analysisDetailsErrorCodeList)}
                labelMessage={labelMessage}
                labelIconEl={<LabelIcon />}
            />
        );
        panes.push(
            <Pane key={type} label={label}>
                <EventEditForm
                    analysisSummary={analysisSummary}
                    pickListNumClasses={pickListNumClasses}
                    analysisDetailsErrorCodeList={analysisDetailsErrorCodeList}
                />
            </Pane>
        );
        return panes;
    }

    render() {
        const { enableSave, isLoading, analysisSummary, onClose, onSaveChanges } = this.props;

        let isNew = false;
        if (!isLoading) {
            const analysisLayerGuid =
                analysisSummary?.layers?.length > 0 && analysisSummary?.layers[0].analysisLayerGuid;
            isNew = Boolean(
                !analysisLayerGuid || analysisLayerGuid === "" || analysisSummary.isNew
            );
        }

        const informationPanes = [...this.getAnalysisPanes()];

        return (
            <RecEventInfo
                addItemTitle={""}
                enableSave={enableSave}
                errorCodeList={[]} //{analysisDetailsErrorCodeList} We don't want to display these errors as dialogs
                eventRecItemStrip={this.getEventStripItem()}
                informationPanes={informationPanes}
                isBatchTemplate={false}
                isAnalysis={true}
                isManagementArea={
                    analysisSummary && analysisSummary.type === ANALYSIS_INFO_NAME_MANAGEMENT_AREA
                }
                isLoading={isLoading}
                isNew={isNew}
                addMenuItems={this.getAddEventMenuItems()}
                canAddEvent={false}
                onSave={onSaveChanges}
                onCancel={onClose}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    onClose: () => dispatch(actions.closeAnalysisInfo()),
    onSaveChanges: () => dispatch(actions.saveAnalysisInfo()),
});

const mapStateToProps = (state) => {
    const analysisInfoState = selectors.getModuleState(state);
    const { analysisSummary, isLoading } = analysisInfoState;
    const analysisDetailsErrorCodeList = analysisSelectors.getAnalysisDetailsErrorCodeList(state);
    const fields = selectors.getFields(state);
    const enableSave = !isLoading && areAllRequiredFieldsSet(analysisSummary);

    return {
        analysisDetailsErrorCodeList,
        analysisSummary,
        enableSave,
        fields,
        isLoading,
    };
};

export const AnalysisInfo = connect(mapStateToProps, mapDispatchToProps)(injectIntl(AnalysisInfo_));
