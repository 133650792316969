export const PROPS_COLOR_SCHEME = "colorScheme";
export const PROPS_RECEIVE_EMAIL_NOTIFICATIONS_FOR_REPORTS = "receiveEmailNotificationsForReports";
export const PROPS_RECEIVE_WEB_NOTIFICATIONS_FOR_REPORTS = "receiveWebNotificationsForReports";
export const PROPS_RECEIVE_IN_APP_NOTIFICATIONS_FOR_REPORTS = "receiveInAppNotificationsForReports";
export const PROPS_RECEIVE_WEB_NOTIFICATIONS_FOR_SAMPLING_RESULTS =
    "receiveWebNotificationsForSamplingResults";
export const PROPS_RECEIVE_IN_APP_NOTIFICATIONS_FOR_SAMPLING_RESULTS =
    "receiveInAppNotificationsForSamplingResults";
export const PROPS_AUTO_IMPORT_CONFIRMATION = "autoImportConfirmation";
export const PROPS_AUTO_IMPORT_DATA = "autoImportData";
export const PROPS_REAL_TIME_FIELD_UPDATES = "realTimeFieldUpdates";
export const PROPS_ORG_LEVEL_QUICK_CHANGER = "orgLevelQuickChanger";
export const PROPS_ORG_ENABLE_MYAGDATA = "enableMyAgData";
export const PROPS_USER_PREFERENCE_GUID = "userPreferenceGuid";
export const PROPS_SEASON_FILTER_LIST = "seasonFilters";
export const PROPS_ATTRIBUTE_COLOR_OVERRIDE_LIST = "colorOverrides";
