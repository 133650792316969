import {
    all,
    call,
    put,
    select,
    take,
    takeLatest,
    takeEvery,
    CallEffect,
    SelectEffect,
    PutEffect,
    AllEffect,
} from "redux-saga/effects";

import { actions as accordionActions } from "~/accordion";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import { actions as analysisActions, models as analysisModels } from "~/recs-events";

import {
    COMPLETED,
    createLayerAccordionItems,
    ERROR,
    IMAGERY_UNAVAILABLE,
    SATELLITE_API_ERROR,
} from "../layer-list/layer-accordion/layer-item";
import * as eventListActions from "~/action-panel/components/event-module/components/event-list/actions";
import * as analysisSelectors from "~/recs-events/analysis/selectors";
import {
    ProfitLossCostType,
    ANALYSIS_INFO_NAME_MANAGEMENT_AREA,
    ANALYSIS_INFO_NAME_IMAGERY_SETUP,
    AnyAnalysisSummary,
    IAnalysisLayerArea,
    IAnalysisImagerySetupSummary,
} from "~/recs-events/analysis/model";
import { ClassificationMethods } from "~/models/classification-method";

import {
    AnalysisLayerAPI,
    LayerAPI,
    FieldAPI,
    Toolset,
    GeometryMath,
    GeometryUtils,
    IAnalysisLayer,
    SearchAPI,
} from "@ai360/core";
import { getTheUserGuid, getTheUserCompanyGuid, getTheUserLockCustomer } from "~/login";

import { mapActions, mapToolsActions } from "~/map";

import * as actions from "./actions";
import * as selectors from "./selectors";

import * as layerModuleActions from "../../actions";
import * as layerListActions from "../layer-list/actions";
import * as actionPanelActions from "~/action-panel/actions";

import { actions as notificationActions, MSGTYPE } from "~/notifications";

import * as layerActions from "../layer-list/actions";
import * as layerSelectors from "../layer-list/selectors";
import { messages } from "../i18n-messages";
import { EcAttributes } from "~/models/ec-attribute";
import { eventsSelectors } from "~/recs-events";
import { eventListSelectors } from "../../../event-module";

import {
    picklistNames,
    actions as picklistActions,
    selectors as picklistSelectors,
} from "~/core/picklist";

import _ from "lodash";
import { v4 as uuid } from "uuid";
import { apiFilterOptionsToLayerFilterInfo, filterLayerOptions } from "../../utils";
import { logFirebaseEvent } from "~/utils/firebase";

interface IEndPoints {
    run: (
        Model: AnyAnalysisSummary | AnalysisLayerAPI.IAnalysisImagerySetupRequest,
        UserGuid: string
    ) => Promise<any>;
    update: (
        Model: AnyAnalysisSummary | AnalysisLayerAPI.IAnalysisImagerySetupRequest,
        UserGuid: string
    ) => Promise<any>;
    valid: (Model: AnyAnalysisSummary, UserGuid: string) => Promise<any>;
}

const _clearSelectedEvents = function* () {
    const selectedFieldGuidSet: Immutable.Set<string> = yield select(
        cdSelectors.getSelectedFieldGuids
    );
    const fieldGuidList = Array.from(selectedFieldGuidSet.values());
    yield put(eventListActions.deselectEventsForFieldGuids(fieldGuidList));
};

const _getFilterOptions = function* (userGuid: string) {
    const selectedFieldGuidSet: Immutable.Set<string> = yield select(
        cdSelectors.getSelectedFieldGuids
    );
    const lockCustomersNotEnrolled = yield select(getTheUserLockCustomer);
    //get current layer filter options
    return yield call(
        LayerAPI.getLayerFilterOptions,
        Array.from(selectedFieldGuidSet.values()),
        userGuid,
        lockCustomersNotEnrolled ? true : null
    );
};

const _getFormattedFields = (fields: SearchAPI.IFieldResult[]): Partial<FieldAPI.IField>[] => {
    const formattedFields = fields.map((field) => {
        return {
            acres: field.acres,
            activeYn: field.activeYn,
            customerEnrolled: field.customerEnrolled,
            customerGuid: field.customerId,
            customerName: field.customerName,
            farmName: field.farmName,
            fieldGuid: field.id,
            fieldBoundaryGuid: field.boundaryId,
            fieldName: field.name,
        };
    });
    return formattedFields;
};

const _getFieldBoundaryGuid = (
    fieldGuid: string,
    boundaries: FieldAPI.IFieldBoundary[]
): string => {
    const boundary = boundaries.find((b) => b.fieldGuid === fieldGuid);
    return boundary.fieldBoundaryGuid;
};

const _isBatchIndividualRequest = (analysisSummary: AnyAnalysisSummary) => {
    const fieldGuids = analysisSummary.layers.map((l) => l.fieldGuid);
    return (
        analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP &&
        fieldGuids.length > 1
    );
};

const getApplicableLayerInfo = function* (fieldGuid: string) {
    const layerInfos: Map<string, LayerAPI.ILayer[]> = yield select(layerSelectors.getLayerInfos);
    if (layerInfos && layerInfos.size > 0) {
        return layerInfos.get(fieldGuid);
    }
    return [];
};

const getVisibleSurface = function* (fieldGuid: string) {
    const surfacesMap: Map<string, LayerAPI.ISubLayer> = yield select(
        layerSelectors.getVisibleSurfacesMap
    );
    return surfacesMap.get(fieldGuid);
};

const defaultSummary = function* (
    type: string,
    layers: IAnalysisLayer[],
    analysisLayerAreaList: IAnalysisLayerArea[],
    fieldGuids: string[],
    typeGuid: string,
    analysisLayerGuid: string,
    cropGuid?: string,
    croppingSeasonGuid?: string
) {
    const create = (numClasses: string, specificProps): AnyAnalysisSummary => ({
        type,
        typeGuid,
        layers,
        analysisLayerAreaList,
        name: "",
        numClasses,
        batchGuid: null,
        isNew: true,
        ...specificProps,
    });

    switch (type) {
        case analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD:
            return create("5", {
                agEventGeneralGuidList: yield _selectedHarvestEventGeneralGuids(type),
                cropList: [],
                isMultipartZoneAutoSplitAllowed: () => true,
            });
        case analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD_BENCHMARK:
            return create("5", {
                agEventGeneralGuidList: yield _selectedHarvestEventGeneralGuids(type),
                cropList: yield call(
                    LayerAPI.getNormalizedYieldBenchmarkCrops,
                    yield _selectedHarvestEventGeneralGuids(type),
                    yield select(getTheUserGuid)
                ),
                isMultipartZoneAutoSplitAllowed: () => true,
            });
        case analysisModels.ANALYSIS_INFO_NAME_PROFIT_LOSS: {
            const selectedEventGeneralGuids: string[] = yield _selectedHarvestEventGeneralGuids(
                type
            );
            const eventSelectionUsed: boolean = cropGuid != null && croppingSeasonGuid != null;
            return create("5", {
                cropGuid: eventSelectionUsed ? cropGuid : "",
                sellingPrice: null,
                cost: {
                    type: ProfitLossCostType.CostPerAcre,
                    value: null,
                },
                croppingSeasonGuid: eventSelectionUsed
                    ? croppingSeasonGuid
                    : yield defaultCroppingSeasonGuid(),
                agEventGeneralGuidList: selectedEventGeneralGuids,
                eventSelectionUsed,
            });
        }
        case analysisModels.ANALYSIS_INFO_NAME_EC_DATA: {
            const userGuid = yield select(getTheUserGuid);
            const attributes = yield call(LayerAPI.retrieveEcDataAttributes, fieldGuids, userGuid);
            yield put(actions.setEcDataAttributes(attributes));
            const attribute = _defaultAttribute(attributes);
            if (attribute == null) {
                return null;
            }

            const colorRampGuid = yield _defaultEcDataColorRampGuid(
                attribute.agEventGeneralGuid,
                attribute.importAttributeGuid
            );
            return create("5", {
                classificationMethod: ClassificationMethods.naturalBreaks.name,
                attribute: attribute.attribute,
                colorRampGuid,
            });
        }
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG:
            return create("5", {
                normalizedYieldGuid: "",
                useEcData: false,
                isSeedStrongDH: false,
                is4Mation: false,
            });
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG_DH:
            return create("6", {
                normalizedYieldGuid: "",
                useEcData: false,
                isSeedStrongDH: true,
                is4Mation: false,
            });
        case analysisModels.ANALYSIS_INFO_NAME_FOUR_MATION:
            return create("6", {
                normalizedYieldGuid: "",
                useEcData: false,
                isSeedStrongDH: false,
                is4Mation: true,
            });
        case analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP:
            return create("5", {
                analysisLayerGuid: analysisLayerGuid,
                analysisLayerType: type,
                analysisLayerTypeGuid: typeGuid,
                agEventGeneralGuidList: [],
                batchGuid: uuid(),
                calculateAbsoluteNdvi: false,
                croppingSeasonGuid: yield defaultCroppingSeasonGuid(),
                hasChanged: false,
                imageryTypeGuidList: [],
            });
        case analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA:
            analysisLayerAreaList.forEach((boundary) => {
                boundary.applyAnalysisToArea = false;
            });
            return create("3", {
                normalizedYieldGuid: "",
                useEcData: false,
                isSeedStrongDH: false,
                is4Mation: false,
                managementAreaData: {},
                managementAreaTemplateGuid: null,
                croppingSeasonGuid: yield defaultCroppingSeasonGuid(),
                isMultipartZoneAutoSplitAllowed: () => true,
            });
        default:
            console.error("Unknown analysis layer type.");
            return null;
    }
};

const setAnalysisSummary = function* (type: string, summary, userGuid: string) {
    const fieldGuids = summary.layers.map((x) => x.fieldGuid);

    yield put(actions.setAnalysisSummary({ ...summary }));

    switch (type) {
        case analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD: {
            const analysisLayerGuid = summary.layers[0].analysisLayerGuid;
            const isNew = !analysisLayerGuid || analysisLayerGuid === "";
            const options = yield call(
                LayerAPI.getNormalizedYieldOptions,
                isNew ? fieldGuids[0] : summary.selectedField,
                userGuid
            );
            yield put(actions.setNormalizedYieldOptions(options));
            break;
        }
        case analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD_BENCHMARK: {
            const analysisLayerGuid = summary.layers[0].analysisLayerGuid;
            const isNew = !analysisLayerGuid || analysisLayerGuid === "";
            const options = yield call(
                LayerAPI.getNormalizedYieldOptions,
                isNew ? fieldGuids[0] : summary.selectedField,
                userGuid
            );
            yield put(actions.setNormalizedYieldOptions(options));
            break;
        }
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG:
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG_DH:
        case analysisModels.ANALYSIS_INFO_NAME_FOUR_MATION: {
            const options = yield call(LayerAPI.getSeedStrongOptions, fieldGuids[0], userGuid);
            yield put(actions.setSeedStrongOptions(options));
            break;
        }
        case analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA: {
            const templates = yield call(LayerAPI.getManagementAreaTypes, userGuid);
            yield put(actions.setManagementAreaTypes(templates));
            break;
        }
        default:
            return;
    }
};
const fetchFields = async (
    fieldGuids: string[],
    userGuid: string
): Promise<Partial<FieldAPI.IField>[]> => {
    const fields = await SearchAPI.getFields({ userGuid, fieldGuid: fieldGuids, active: true });
    return _getFormattedFields(fields);
};

const isNewLayer = (summary: AnyAnalysisSummary): boolean => {
    const analysisLayerGuid = summary.layers[0].analysisLayerGuid;
    const newRequiresAnalysisLayerGuid = isNewAnalysisLayerGuidRequired(summary.type);
    return newRequiresAnalysisLayerGuid
        ? summary.isNew
        : !analysisLayerGuid || analysisLayerGuid === "";
};

const isNewAnalysisLayerGuidRequired = (type: string) => {
    //New Api calls to create analysis layers may require the ui to create a new analysisLayerGuid for new layers
    return type === analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP;
};

const execute = function* (summary: AnyAnalysisSummary, endPoints: IEndPoints) {
    const userGuid = yield select(getTheUserGuid);
    const { run, update, valid } = endPoints;
    if (valid) {
        yield call(valid, summary, userGuid);
    }

    const isNew = isNewLayer(summary);
    const endpoint = isNew ? run : update;
    yield put(actions.closeAnalysisInfo());
    return yield call(endpoint, summary, userGuid);
};

const runAnalysisLayer = function* (analysisSummary: AnyAnalysisSummary) {
    try {
        const result = yield* runLayer(analysisSummary);
        const isImagerySetup =
            analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP;
        if (
            isImagerySetup &&
            (result?.satelliteImageryDownloadStarted ||
                result?.satelliteImageryScheduled ||
                _.isEmpty(result))
        ) {
            yield* handleImagerySetupSuccess(
                result,
                analysisSummary as IAnalysisImagerySetupSummary
            );
        }
    } catch (err) {
        const fieldGuids = analysisSummary.layers.map((x) => x.fieldGuid);
        for (const fieldGuid of fieldGuids) {
            yield put(actions.removeProcessingAnalysisInfo(fieldGuid));
        }

        if (analysisSummary.isNew && analysisSummary.layers.some((l) => l.analysisLayerGuid)) {
            //New Api error handling
            yield* handleAnalysisSaveErrors(err, analysisSummary);
            return;
        }

        // If error occurred on an edit of exiting layer, then reset Analysis Layer status
        if (!analysisSummary.isNew && analysisSummary.layers?.length > 0) {
            const analysisLayerGuid = analysisSummary.layers[0].analysisLayerGuid;
            yield put(
                layerListActions.setAnalysisLayerStatus(
                    analysisLayerGuid,
                    messages.analysisLayerCompleted.defaultMessage
                )
            );
        }

        const analysisErrorCodes = analysisModels.ANALYSIS_ERROR_CODES_LIST;
        if (
            err.errorCodeList &&
            err.errorCodeList.some((errorCode) => analysisErrorCodes.indexOf(errorCode) > -1)
        ) {
            yield put(analysisActions.setAnalysisErrorCodesList(err.errorCodeList));
        } else {
            yield put(notificationActions.apiCallError(err));
        }
    }
};

const runLayer = function* (analysisSummary: AnyAnalysisSummary) {
    switch (analysisSummary.type) {
        case analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD:
        case analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD_BENCHMARK:
            yield _clearSelectedEvents();
            return yield execute(analysisSummary, {
                run: LayerAPI.runNormalizedYield,
                update: LayerAPI.updateNormalizedYield,
                valid: LayerAPI.validNormalizedYield,
            });
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG:
        case analysisModels.ANALYSIS_INFO_NAME_SEED_STRONG_DH:
        case analysisModels.ANALYSIS_INFO_NAME_FOUR_MATION:
            return yield execute(analysisSummary, {
                run: LayerAPI.runSeedStrong,
                update: LayerAPI.updateSeedStrong,
                valid: LayerAPI.validSeedStrong,
            });
        case analysisModels.ANALYSIS_INFO_NAME_PROFIT_LOSS:
            return yield execute(analysisSummary, {
                run: LayerAPI.runProfitLoss,
                update: LayerAPI.updateProfitLoss,
                valid: LayerAPI.validProfitLoss,
            });
        case analysisModels.ANALYSIS_INFO_NAME_EC_DATA:
            return yield execute(analysisSummary, {
                run: LayerAPI.runEcData,
                update: LayerAPI.updateEcData,
                valid: LayerAPI.validEcData,
            });
        case analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA:
            return yield execute(analysisSummary, {
                run: LayerAPI.runManagementArea,
                update: LayerAPI.updateManagementArea,
                valid: LayerAPI.validManagementArea,
            });
        case analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP:
            return yield execute(analysisSummary, {
                run: AnalysisLayerAPI.runImagerySetup,
                update: AnalysisLayerAPI.runImagerySetup,
                valid: null,
            });
        default:
            console.error("Unknown analysis layer type.");
            return null;
    }
};

const handleImagerySetupSuccess = function* (
    result,
    analysisSummary: IAnalysisImagerySetupSummary
) {
    const isScheduled =
        result.satelliteImageryDownloadStarted === false && result.satelliteImageryScheduled;
    if (!_.isEmpty(result) && !isScheduled) {
        return;
    }
    yield put(layerListActions.setLayerStatus(analysisSummary.analysisLayerGuid, COMPLETED));
    const firstAnalysisLayer = analysisSummary.layers ? analysisSummary.layers[0] : null;
    if (!firstAnalysisLayer) {
        return;
    }
    yield put(layerActions.fetchLayerInfo(firstAnalysisLayer.fieldGuid));
};

const handleImagerySetupError = function* (err, targetLayer: IAnalysisLayer) {
    const imageryAlreadyExists =
        err.apiResultObj?.errors &&
        err.apiResultObj?.errors[0].message === "The imagery setup for this field already exists.";

    if (imageryAlreadyExists) {
        yield* removeFromLayerInfos(targetLayer.fieldGuid, targetLayer.analysisLayerGuid);
        yield* createAnalysisErrorToasterMessages(err);
        return;
    }
    const thirdPartyAPIError = err.apiResultObj?.code === "BadGateway";
    const imageryUnavailable =
        !thirdPartyAPIError &&
        err.apiResultObj?.satelliteImageryDownloadStarted === false &&
        err.apiResultObj?.satelliteImageryScheduled === false &&
        err.apiResultObj?.layersAreasAddedToDatabase;
    const statusMsg = imageryUnavailable
        ? IMAGERY_UNAVAILABLE
        : thirdPartyAPIError
        ? SATELLITE_API_ERROR
        : ERROR;

    if (imageryUnavailable || SATELLITE_API_ERROR) {
        yield put(
            notificationActions.pushToasterMessage(statusMsg, MSGTYPE.ERROR, {
                seconds: 10,
            })
        );
    } else {
        yield* createAnalysisErrorToasterMessages(err);
    }
    yield put(layerListActions.setLayerStatus(targetLayer.analysisLayerGuid, statusMsg));
};

const handleAnalysisSaveErrors = function* (err, analysisSummary: AnyAnalysisSummary) {
    const targetLayer = analysisSummary.layers.find((l) => l.analysisLayerGuid !== null);
    if (analysisSummary.type === ANALYSIS_INFO_NAME_IMAGERY_SETUP) {
        yield* handleImagerySetupError(err, targetLayer);
        return;
    }
    yield put(layerListActions.setLayerStatus(targetLayer.analysisLayerGuid, ERROR));
    yield* createAnalysisErrorToasterMessages(err);
};

const createAnalysisErrorToasterMessages = function* (err) {
    if (!err.apiResultObj?.errors) {
        yield put(
            notificationActions.pushToasterMessage(err.apiResultObj?.message, MSGTYPE.ERROR, {
                seconds: 10,
            })
        );
    } else {
        for (const error of err.apiResultObj?.errors) {
            yield put(
                notificationActions.pushToasterMessage(error.message, MSGTYPE.ERROR, {
                    seconds: 10,
                })
            );
        }
    }
};

const areasFromBoundaries = (boundaries, analysisLayerType: string): IAnalysisLayerArea[] => {
    const isManagementArea = analysisLayerType === ANALYSIS_INFO_NAME_MANAGEMENT_AREA;
    const isImagerySetup = analysisLayerType === ANALYSIS_INFO_NAME_IMAGERY_SETUP;
    const groups = _(boundaries)
        .groupBy((x) => x.fieldGuid)
        .toPairs();
    const refinedBoundaries =
        groups.size() === 1
            ? isManagementArea
                ? [
                      [
                          {
                              ...groups.value()[0][1][0],
                              shape: GeometryUtils.getCombinedBoundaries(
                                  groups.value()[0][1].map((b) => b.shape)
                              ),
                          },
                          1,
                      ],
                  ]
                : groups.value()[0][1].map((boundary, i) => [boundary, i + 1])
            : groups
                  .map((group) =>
                      _(group[1]).reduce(
                          (accumulator, boundary) => ({
                              ...boundary,
                              shape:
                                  accumulator == null
                                      ? boundary.shape
                                      : GeometryMath.mergePolygons(
                                            boundary.shape,
                                            accumulator.shape
                                        ),
                          }),
                          null
                      )
                  )
                  .value()
                  .map((boundary) => [boundary, 1]);
    return refinedBoundaries.map(([boundary, id]) => ({
        analysisLayerAreaId: id,
        analysisLayerAreaGuid: isImagerySetup ? uuid() : "",
        analysisLayerGuid: "",
        applyAnalysisToArea: true,
        calculatedArea: boundary.calculatedArea,
        fieldGuid: "",
        fieldBoundaryGuid: boundary.fieldBoundaryGuid,
        zonePolygons: GeometryMath.getSimplifiedPolygonParts(boundary.shape).map((poly) => ({
            polygonGuid: null,
            shape: poly,
        })),
    }));
};

const setupCurrentAreaIds = function* (fieldGuids: string[]) {
    for (const fieldGuid of fieldGuids) {
        yield put(analysisActions.setCurrentAreaId(fieldGuid, 1));
    }
};

const expandFieldLayersForFieldGuid = function* (fieldGuid: string) {
    const accordionId = yield select(layerSelectors.getAccordionId);
    const fieldIdx = yield* getFieldIdx(fieldGuid);
    yield put(accordionActions.expandAccordionItem(accordionId, [fieldIdx]));
};

const expandLayerForFieldGuid = function* (fieldGuid: string, layerIdx: number) {
    const accordionId = yield select(layerSelectors.getAccordionId);
    const fieldIdx = yield* getFieldIdx(fieldGuid);
    yield put(accordionActions.expandAccordionItem(accordionId, [fieldIdx, layerIdx]));
};

const expandLayerAndSufaceForFieldGuid = function* (fieldGuid: string, layerIdx: number) {
    const accordionId = yield select(layerSelectors.getAccordionId);
    const fieldIdx = yield* getFieldIdx(fieldGuid);
    yield put(accordionActions.expandAccordionItem(accordionId, [fieldIdx, layerIdx]));
    yield put(accordionActions.expandAccordionItem(accordionId, [fieldIdx, layerIdx, 0]));
};

const _getUpdatedDisplayName = function* (analysisSummary, existingDisplayName) {
    const isImagerySetup = analysisSummary.analysisLayerType === ANALYSIS_INFO_NAME_IMAGERY_SETUP;
    if (isImagerySetup) {
        const picklistOptionsCroppingSeason = yield select(
            picklistSelectors.getPicklistOptionsFromCode,
            picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
        );
        const croppingSeasonOption = picklistOptionsCroppingSeason.find(
            (option) => option.value === analysisSummary.croppingSeasonGuid
        );

        return "Sentinel 2 - " + croppingSeasonOption.label + " - " + analysisSummary.name;
    }
    return existingDisplayName;
};

const _updateLayerInfos = function* (
    layerInfos: Map<string, LayerAPI.ILayer[]>,
    analysisSummary: AnyAnalysisSummary
) {
    const isNew = isNewLayer(analysisSummary);
    for (const analysisLayer of analysisSummary.layers) {
        const { fieldGuid, analysisLayerGuid } = analysisLayer;
        const hasLayerInfo = layerInfos && layerInfos.has(fieldGuid);
        if (isNew) {
            const processingAnalysisInfo = {
                analysisLayerGuid: isNewAnalysisLayerGuidRequired(analysisSummary.type)
                    ? analysisLayer.analysisLayerGuid
                    : selectors.TEMPGUID,
                analysisLayerStatus: messages.analysisLayerProcessing.defaultMessage,
                displayName: yield* _getUpdatedDisplayName(analysisSummary, analysisSummary.name),
                displayNamePrefix: analysisSummary.type,
                fieldGuid: fieldGuid,
                isManual: false,
                isSampling: false,
                status: messages.analysisLayerProcessing.defaultMessage,
                subLayers: [],
                surfaceType: "Analysis Layer",
                type: analysisSummary.type,
                layerType: analysisSummary.type,
            };
            yield put(actions.setProcessingAnalysisInfo(fieldGuid, processingAnalysisInfo));
            if (!hasLayerInfo) {
                yield call(expandFieldLayersForFieldGuid, fieldGuid);
            } else {
                yield call(_updateLayerSurfaces, fieldGuid);
            }
            continue;
        }

        if (hasLayerInfo) {
            const layers = [...layerInfos.get(fieldGuid)];

            const layerIdx = layers.findIndex((li) => li.analysisLayerGuid === analysisLayerGuid);
            const processingAnalysisInfo = {
                ...layers[layerIdx],
                analysisLayerStatus: messages.analysisLayerProcessing.defaultMessage,
                displayName: yield* _getUpdatedDisplayName(
                    analysisSummary,
                    layers[layerIdx].displayName
                ),
                status: messages.analysisLayerProcessing.defaultMessage,
            };
            layers.splice(layerIdx, 1, processingAnalysisInfo);
            yield put(actions.setProcessingAnalysisInfo(fieldGuid, processingAnalysisInfo));
            const accordionId = yield select(layerSelectors.getAccordionId);
            const fieldIdx = yield* getFieldIdx(fieldGuid);
            if (fieldIdx > -1) {
                yield put(
                    accordionActions.updateAccordionItem(accordionId, [fieldIdx], {
                        children: createLayerAccordionItems(layers),
                    })
                );
            }
            yield put(layerListActions.setLayerInfo(fieldGuid, layers));
            yield call(expandFieldLayersForFieldGuid, fieldGuid);
        }
    }
};

const _updateLayerSurfaces = function* (fieldGuid: string) {
    const layerInfos = yield select(layerSelectors.getLayerInfos);
    if (layerInfos) {
        const index = Array.from(layerInfos.keys()).findIndex((key) => key === fieldGuid);
        if (index > -1) {
            yield put(layerActions.updateLayerSurfaces(fieldGuid));
        }
    }
};

const _selectedHarvestEventGeneralGuids = function* (analysisLayerType: string) {
    const _harvestEventGuids = [];
    const isBatchNormalizedYield = yield select(selectors.getBatchNormalizedYield);
    const isProfitLoss = analysisLayerType === analysisModels.ANALYSIS_INFO_NAME_PROFIT_LOSS;
    if (isBatchNormalizedYield || isProfitLoss) {
        const selectedEventSummary = yield select(
            eventsSelectors.getEventGeneralGuidToEventSummaryMap
        );
        const selectedEventGuidSet = yield select(eventListSelectors.getSelectedEventGuidSet);
        for (const eventGeneralGuid of selectedEventGuidSet) {
            const event = selectedEventSummary.get(eventGeneralGuid) || {};
            if (event.agEventTypeName === "Harvest" && event.isImportedYn) {
                _harvestEventGuids.push(eventGeneralGuid);
            }
        }
        return _harvestEventGuids;
    } else {
        return [];
    }
};

const _defaultAttribute = (availableAttributes) =>
    availableAttributes.find((attribute) =>
        EcAttributes.all()
            .map((x) => x.attributeId)
            .includes(attribute.attribute)
    );

const _defaultEcDataColorRampGuid = function* (
    agEventGeneralGuid: string,
    importAttributeGuid: string
) {
    const userGuid = yield select(getTheUserGuid);
    const symbology = yield call(
        LayerAPI.getDefaultSymbology,
        userGuid,
        agEventGeneralGuid,
        importAttributeGuid
    );
    return symbology.colorRampGuid;
};

const onDeleteAnalysisLayer = function* (action) {
    const { analysisLayerGuid, fieldGuid, layerType } = action.payload;
    const userGuid = yield select(getTheUserGuid);

    if (layerType === ANALYSIS_INFO_NAME_IMAGERY_SETUP) {
        yield call(AnalysisLayerAPI.deleteImagerySetupLayer, analysisLayerGuid, userGuid);
    } else {
        yield call(LayerAPI.deleteAnalysisLayer, analysisLayerGuid, userGuid);
    }

    //get current layer filter options
    const filterOptions = yield _getFilterOptions(userGuid);

    //update layer filter info
    const layerFilterInfo = apiFilterOptionsToLayerFilterInfo(filterOptions);
    yield put(layerListActions.setLayerFilterInfo(layerFilterInfo));

    //update dropdown options
    const { croppingSeasons, crops, filters, layerTypes } = layerFilterInfo;
    const layerFilter = yield select(layerSelectors.getLayerFilter);
    const layerFilterOptions = filterLayerOptions(
        layerFilter,
        croppingSeasons,
        crops,
        filters,
        layerTypes
    );
    yield put(layerListActions.setLayerFilterOptions(layerFilterOptions));
    yield* removeFromLayerInfos(fieldGuid, analysisLayerGuid);
};

const onDeleteAnalysisSurface = function* (action) {
    const { surfaceGuid, analysisLayerGuid, fieldGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    yield call(AnalysisLayerAPI.deleteSurface, surfaceGuid, userGuid);
    const layerInfos: Map<string, LayerAPI.ILayer[]> = yield select(layerSelectors.getLayerInfos);
    if (layerInfos) {
        const layers = [...layerInfos.get(fieldGuid)];
        if (analysisLayerGuid && analysisLayerGuid !== "") {
            const revisedAnalysisLayer = layers.find(
                (l) => l.analysisLayerGuid === analysisLayerGuid
            );
            const layerIdx = layers.findIndex((l) => l.analysisLayerGuid === analysisLayerGuid);
            const surfaceIndexToDelete = revisedAnalysisLayer.subLayers.findIndex(
                (si) => si.surfaceGuid === surfaceGuid
            );
            revisedAnalysisLayer.subLayers.splice(surfaceIndexToDelete, 1);
            layers.map((l) =>
                l.analysisLayerGuid === analysisLayerGuid ? revisedAnalysisLayer : l
            );
            yield* updateFieldAccordionLayers(fieldGuid, layers);
            yield* removeVisibleSurface(surfaceGuid, fieldGuid);
            yield put(layerListActions.setLayerInfo(fieldGuid, layers));
            yield put(mapActions.setForceRefreshFlag(true));
            yield call(expandLayerForFieldGuid, fieldGuid, layerIdx);
        }
    }
};

const removeFromLayerInfos = function* (fieldGuid: string, analysisLayerGuid: string) {
    const applicableLayerInfo = yield* getApplicableLayerInfo(fieldGuid);
    if (applicableLayerInfo.length > 0) {
        const layers = [...applicableLayerInfo];
        if (analysisLayerGuid && analysisLayerGuid !== "") {
            const layerIdx = layers.findIndex((li) => li.analysisLayerGuid === analysisLayerGuid);
            yield* removeVisibleLayerSurfaces(layers[layerIdx].subLayers, fieldGuid);
            layers.splice(layerIdx, 1);
        }
        yield* updateFieldAccordionLayers(fieldGuid, layers);
        yield put(layerListActions.setLayerInfo(fieldGuid, layers));
    }

    yield put(mapActions.setForceRefreshFlag(true));
};

const getFieldIdx = function* (fieldGuid: string) {
    const items = yield select(layerSelectors.getAccordionItems);
    return items.findIndex((item) => item.payload.fieldGuid === fieldGuid);
};

const updateFieldAccordionLayers = function* (fieldGuid: string, layers: LayerAPI.ILayer[]) {
    const fieldIdx = yield* getFieldIdx(fieldGuid);
    if (fieldIdx > -1) {
        const accordionId = yield select(layerSelectors.getAccordionId);
        yield put(
            accordionActions.updateAccordionItem(accordionId, [fieldIdx], {
                children: createLayerAccordionItems(layers),
            })
        );
    }
};

const removeVisibleSurface = function* (surfaceGuid, fieldGuid) {
    const visibleSurface = yield* getVisibleSurface(fieldGuid);
    if (visibleSurface && visibleSurface.surfaceGuid === surfaceGuid) {
        yield put(layerListActions.removeVisibleSurfaces([fieldGuid]));
    }
};

const removeVisibleLayerSurfaces = function* (subLayers: LayerAPI.ISubLayer[], fieldGuid: string) {
    const visibleSurface = yield* getVisibleSurface(fieldGuid);
    const matchingSurface = visibleSurface
        ? subLayers.find((s) => s.surfaceGuid === visibleSurface.surfaceGuid)
        : null;
    if (matchingSurface) {
        yield put(layerListActions.removeVisibleSurfaces([fieldGuid]));
    }
};

const onDeleteAnalysisLayerBatch = function* (action) {
    const { batchGuid } = action.payload;
    const analysisInBatch: LayerAPI.IAnalysisInBatch[] = action.payload.analysisInBatch;
    const layerInfos = yield select(layerSelectors.getLayerInfos);
    if (!layerInfos) {
        return;
    }

    const atLeastOneLayerStillInProcess = analysisInBatch
        .filter(
            (x) =>
                x.analysisLayerGuid &&
                x.analysisLayerGuid !== "" &&
                layerInfos.get(x.fieldGuid) != null
        )
        .some(
            (y) =>
                layerInfos.get(y.fieldGuid).analysisLayerStatus ===
                messages.analysisLayerProcessing.defaultMessage
        );

    if (atLeastOneLayerStillInProcess) {
        yield put(
            notificationActions.pushToasterMessage(
                messages.deleteAllAnalysisLayersErrorStillProcessing.defaultMessage,
                MSGTYPE.ERROR,
                {
                    seconds: 10,
                }
            )
        );
        return;
    }

    const userGuid = yield select(getTheUserGuid);
    yield call(LayerAPI.deleteAnalysisLayerBatch, batchGuid, userGuid);

    //remove from layerInfos
    const accordionId = yield select(layerSelectors.getAccordionId);
    const accordionItems = yield select(layerSelectors.getAccordionItems);

    const analysis = analysisInBatch
        .filter(
            (x) =>
                x.analysisLayerGuid &&
                x.analysisLayerGuid !== "" &&
                layerInfos.get(x.fieldGuid) != null
        )
        .map((x) => ({
            ...x,
            newLayers: layerInfos
                .get(x.fieldGuid)
                .filter((layer) => layer.analysisLayerGuid !== x.analysisLayerGuid),
            accordionIndex: accordionItems.findIndex(
                (item) => item.payload.fieldGuid === x.fieldGuid
            ),
        }));

    yield put(layerListActions.removeVisibleSurfaces(analysisInBatch.map((x) => x.fieldGuid)));

    for (const { accordionIndex, newLayers } of analysis) {
        if (accordionIndex > -1) {
            yield put(
                accordionActions.updateAccordionItem(accordionId, [accordionIndex], {
                    children: createLayerAccordionItems(newLayers),
                })
            );
        }
    }

    for (const { fieldGuid, newLayers } of analysis) {
        yield put(layerListActions.setLayerInfo(fieldGuid, newLayers));
    }

    yield put(mapActions.setForceRefreshFlag(true));
};

const fetchAnalysisInfo = function* (action) {
    const { analysisLayerGuid } = action.payload;
    if (!analysisLayerGuid) {
        yield put(actions.fetchAnalysisInfoSucceeded(null));
        return;
    }
    const userGuid = yield select(getTheUserGuid);
    try {
        const analysisSummary: AnyAnalysisSummary = yield call(
            LayerAPI.getAnalysisLayer,
            analysisLayerGuid,
            userGuid
        );
        if (
            analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA ||
            analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD
        ) {
            analysisSummary.isMultipartZoneAutoSplitAllowed = () => true;
        }
        analysisSummary.isNew = false;
        yield put(actions.setAnalysisSummary(analysisSummary));

        if (analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_EC_DATA) {
            const fieldGuids = analysisSummary.layers.map((x) => x.fieldGuid);
            const attributes = yield call(LayerAPI.retrieveEcDataAttributes, fieldGuids, userGuid);
            yield put(actions.setEcDataAttributes(attributes));
        } else if (analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_MANAGEMENT_AREA) {
            yield put(
                actions.fetchManagementAreaAttributes(
                    analysisSummary.managementAreaTemplateGuid,
                    analysisLayerGuid
                )
            );
        } else if (analysisSummary.type === analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP) {
            analysisSummary.agEventGeneralGuidList = [];
            analysisSummary.analysisLayerType = ANALYSIS_INFO_NAME_IMAGERY_SETUP;
            analysisSummary.userGuid = userGuid;
        }

        yield put(actions.fetchAnalysisInfoSucceeded(analysisSummary));
    } catch (err) {
        const analysisErrorCodes = analysisModels.ANALYSIS_ERROR_CODES_LIST;
        if (
            err.errorCodeList &&
            err.errorCodeList.some((errorCode) => analysisErrorCodes.indexOf(errorCode) > -1)
        ) {
            yield put(analysisActions.setAnalysisErrorCodesList(err.errorCodeList));
        } else {
            yield put(notificationActions.apiCallError(err, action));
        }
    }
};

const fetchAnalysisDetails = function* (action) {
    const { analysisLayerTypes } = action.payload;
    const summary = yield select(selectors.getAnalysisLayerInfo);
    const { layers, typeGuid } = summary;
    const layerType = analysisLayerTypes.find(
        (item) =>
            Object.hasOwn(item, analysisModels.ANALYSIS_INFO_TYPE_GUID) &&
            item[analysisModels.ANALYSIS_INFO_TYPE_GUID] === typeGuid
    ).name;
    const userGuid = yield select(getTheUserGuid);
    const fieldGuids = layers.map((x) => x.fieldGuid);
    const fields: Partial<FieldAPI.IField>[] = yield fetchFields(fieldGuids, userGuid);
    try {
        yield setAnalysisSummary(layerType, summary, userGuid);
    } catch (err) {
        const analysisErrorCodes = analysisModels.ANALYSIS_ERROR_CODES_LIST;
        if (
            err.errorCodeList &&
            err.errorCodeList.some((errorCode) => analysisErrorCodes.indexOf(errorCode) > -1)
        ) {
            yield put(analysisActions.setAnalysisErrorCodesList(err.errorCodeList));
        } else {
            yield put(notificationActions.apiCallError(err, action));
        }
    } finally {
        yield put(analysisActions.setAnalysisSummaryMap(summary, fieldGuids));

        yield put(actions.setFieldGuids(fieldGuids));
        yield put(actions.setFields(fields));

        if (fieldGuids.length > 1) {
            yield setupCurrentAreaIds(fieldGuids);
        } else {
            const field = fields[0];
            yield put(
                mapToolsActions.setActiveToolset(Toolset.ANALYSIS, {
                    recEventDetails: {
                        ...summary,
                        fieldBoundaryGuid: field.fieldBoundaryGuid,
                    },
                    field,
                })
            );
        }
        yield put(mapActions.setZoomToFieldList(fieldGuids));
        yield put(actions.setAnalysisDetailsLoading(false));
    }
};

const fetchAnalysisInfoSucceeded = function* () {
    try {
        let analysisLayerTypes = yield select(analysisSelectors.getAnalysisLayerTypes);
        if (analysisLayerTypes.length === 0) {
            const companyGuid = yield select(getTheUserCompanyGuid);
            const userGuid = yield select(getTheUserGuid);
            analysisLayerTypes = yield call(LayerAPI.getAnalysisLayerTypes, companyGuid, userGuid);
            yield put(analysisActions.setAnalysisLayerTypes(analysisLayerTypes));
        }
        yield put(actions.fetchAnalysisDetails(analysisLayerTypes));
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
        yield put(actions.closeAnalysisInfo());
    }
};

const fetchManagementAreaAttributes = function* (action) {
    const { importTemplateGuid, analysisLayerGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        const importAttributes = yield call(
            LayerAPI.getManagementAreaAttributes,
            { importTemplateGuid, analysisLayerGuid },
            userGuid
        );
        if (Array.isArray(importAttributes)) {
            yield put(actions.setManagementAreaAttributes(importAttributes));
            if (!analysisLayerGuid) {
                yield put(actions.resetManagementAreaAttributeData());
            }
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
    }
};

const getLayerTypes = function* () {
    let analysisLayerTypes = yield select(analysisSelectors.getAnalysisLayerTypes);
    if (!analysisLayerTypes || analysisLayerTypes.length === 0) {
        const companyGuid = yield select(getTheUserCompanyGuid);
        const userGuid = yield select(getTheUserGuid);
        analysisLayerTypes = yield call(LayerAPI.getAnalysisLayerTypes, companyGuid, userGuid);
        yield put(analysisActions.setAnalysisLayerTypes(analysisLayerTypes));
    }
    return analysisLayerTypes;
};

const onCreateAnalysisLayer = function* (action) {
    const { fieldGuids, analysisLayerType, cropGuid, croppingSeasonGuid } = action.payload;
    switch (analysisLayerType) {
        case analysisModels.ANALYSIS_INFO_NAME_EC_DATA:
            logFirebaseEvent("new_batch_analysis_layer_multi_field_ec_data");
            break;
        case analysisModels.ANALYSIS_INFO_NAME_NORMALIZED_YIELD:
            logFirebaseEvent("new_batch_analysis_layer_normalized_yield");
            break;
        case analysisModels.ANALYSIS_INFO_NAME_IMAGERY_SETUP:
            logFirebaseEvent("new_batch_analysis_layer_imagery_setup");
            break;
        case analysisModels.ANALYSIS_INFO_NAME_PROFIT_LOSS:
            logFirebaseEvent("new_batch_analysis_layer_profit_loss");
            break;
    }

    const analysisLayerTypes = yield call(getLayerTypes);
    const layerTypeGuid = analysisLayerTypes.find(
        (item) =>
            Object.hasOwn(item, analysisModels.ANALYSIS_INFO_TYPE_NAME) &&
            item[analysisModels.ANALYSIS_INFO_TYPE_NAME] === analysisLayerType
    );

    const boundaries = yield call(
        FieldAPI.fetchFieldBoundariesByFieldGuid,
        fieldGuids,
        null,
        null,
        true
    );
    console.assert(boundaries.length > 0);

    //clear previous error codes
    yield put(analysisActions.setAnalysisErrorCodesList([]));
    const isImagerySetup = analysisLayerType === ANALYSIS_INFO_NAME_IMAGERY_SETUP;
    const analysisLayerGuid = uuid();
    const layers = fieldGuids.map((fieldGuid) => ({
        analysisLayerGuid: isImagerySetup ? analysisLayerGuid : "",
        fieldGuid,
        fieldBoundaryGuid: _getFieldBoundaryGuid(fieldGuid, boundaries),
    }));

    const analysisSummary: AnyAnalysisSummary = yield defaultSummary(
        analysisLayerType,
        layers,
        areasFromBoundaries(boundaries, analysisLayerType),
        fieldGuids,
        layerTypeGuid[analysisModels.ANALYSIS_INFO_TYPE_GUID],
        analysisLayerGuid,
        cropGuid,
        croppingSeasonGuid
    );
    if (analysisSummary == null) {
        return;
    }

    yield put(actions.setAnalysisSummary(analysisSummary));
    yield put(cdActions.addSelectedFields(fieldGuids));
    yield put(analysisActions.setAnalysisSummaryMap(analysisSummary, fieldGuids));
    yield put(actions.showAnalysisInfo(undefined));
};

const onCloseAnalysisInfo = function* () {
    yield put(mapToolsActions.setActiveToolset(Toolset.DEFAULT));
    yield put(actionPanelActions.setIsEnabled(true));
    yield put(actions.setNormalizedYieldOptions([]));
    yield put(actions.setSeedStrongOptions([]));
    yield put(actions.setEcDataAttributes([]));
    yield put(actions.setManagementAreaTypes([]));
    yield put(actions.setManagementAreaAttributes([]));
    yield put(analysisActions.clearAnalysisDetails());
    yield put(analysisActions.setAnalysisErrorCodesList([]));
    yield put(notificationActions.clearToasterMessages());
    yield put(actions.setBatchNormalizedYield(false));
    yield put(layerModuleActions.setActivePage(layerModuleActions.LayerModulePages.LAYER_LIST));
};

const onSaveAnalysisInfo = function* () {
    logFirebaseEvent("analysis_layer_save");
    yield put(actions.setAnalysisDetailsLoading(true));
    //clear previous error codes
    yield put(analysisActions.setAnalysisErrorCodesList([]));
    try {
        yield put(actions.updateAnalysisLayer());
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
    } finally {
        yield put(actions.setAnalysisDetailsLoading(false));
    }
};

const onShowAnalysisInfo = function* (action) {
    yield put(actions.setAnalysisDetailsLoading(true));
    const { analysisLayerGuid } = action.payload;

    yield put(
        layerModuleActions.setActivePage(layerModuleActions.LayerModulePages.ANALYSIS_INFORMATION)
    );
    yield put(actions.fetchAnalysisInfo(analysisLayerGuid));
    yield put(actionPanelActions.setIsEnabled(false));
};

const updateAnalysisLayer = function* () {
    const analysisSummary = yield select(selectors.getAnalysisLayerInfo);
    const isBatchIndividualRequest = _isBatchIndividualRequest(analysisSummary);
    if (isBatchIndividualRequest) {
        yield* updateMultipleAnalysisLayers(analysisSummary);
        return;
    }
    yield call(_updateLayerInfos, yield select(layerSelectors.getLayerInfos), analysisSummary);
    yield* runAnalysisLayer(analysisSummary);
};

const updateMultipleAnalysisLayers = function* (analysisSummary: AnyAnalysisSummary) {
    for (const layer of analysisSummary.layers) {
        const analysisLayerGuid = uuid();
        layer.analysisLayerGuid = analysisLayerGuid;
        const analysisLayerAreaList = analysisSummary.analysisLayerAreaList.filter(
            (a) => a.fieldBoundaryGuid === layer.fieldBoundaryGuid
        );
        const revisedSummary = {
            ...analysisSummary,
            analysisLayerGuid,
            analysisLayerAreaList,
            layers: [layer],
        };
        yield call(_updateLayerInfos, yield select(layerSelectors.getLayerInfos), revisedSummary);
        yield* runAnalysisLayer(revisedSummary);
    }
};

export const defaultCroppingSeasonGuid = function* () {
    yield put(
        picklistActions.fetchPicklistData({
            [picklistNames.PICKLIST_CROPPING_SEASON]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_CROPPING_SEASON
            ),
        })
    );

    yield take(picklistActions.fetchedPicklistData);

    const { getPickListCode, PICKLIST_CROPPING_SEASON } = picklistNames;
    const croppingSeasonOptions = yield select(
        picklistSelectors.getPicklistOptionsFromCode,
        getPickListCode(PICKLIST_CROPPING_SEASON)
    );

    const fullYear = String(new Date().getFullYear());
    const defaultCroppingSeason = croppingSeasonOptions.find((option) => option.label === fullYear);
    return defaultCroppingSeason && defaultCroppingSeason.value;
};

export const onAnalysisCompletedAndLoaded = function* (
    action: ReturnType<typeof layerActions.updateLoadingFieldGuidSet>
): Generator<
    PutEffect<any> | CallEffect | SelectEffect | Generator<CallEffect | SelectEffect, any, unknown>,
    void,
    any
> {
    const { fieldGuid, isLoading } = action;
    const processedAnalysisInfos = yield select(selectors.getProcessingAnalysisInfo);
    const processedAnalysisInfo = processedAnalysisInfos.get(fieldGuid);
    if (!processedAnalysisInfo) {
        return;
    }
    if (
        processedAnalysisInfo.analysisLayerStatus ===
            messages.analysisLayerCompleted.defaultMessage &&
        !isLoading
    ) {
        const userGuid = yield select(getTheUserGuid);
        //get current layer filter options
        const filterOptions = yield _getFilterOptions(userGuid);

        //update layer filter info
        const layerFilterInfo = apiFilterOptionsToLayerFilterInfo(filterOptions);
        yield put(layerListActions.setLayerFilterInfo(layerFilterInfo));

        //update dropdown options
        const { croppingSeasons, crops, filters, layerTypes } = layerFilterInfo;
        const layerFilter = yield select(layerSelectors.getLayerFilter);
        const layerFilterOptions = filterLayerOptions(
            layerFilter,
            croppingSeasons,
            crops,
            filters,
            layerTypes
        );
        yield put(layerListActions.setLayerFilterOptions(layerFilterOptions));

        const layerInfos = yield select(layerSelectors.getLayerInfos);
        const layerInfo = layerInfos.get(fieldGuid);
        const layerIdx = layerInfo.findIndex(
            (layer) =>
                (layer.displayName === processedAnalysisInfo.displayName &&
                    layer.surfaceType === processedAnalysisInfo.surfaceType) ||
                layer.analysisLayerGuid === processedAnalysisInfo.analysisLayerGuid
        );
        yield put(actions.removeProcessingAnalysisInfo(fieldGuid));
        yield call(expandLayerAndSufaceForFieldGuid, fieldGuid, layerIdx);
    }
};

export const updateAnalysisZones = function* (
    action: ReturnType<typeof actions.updateZones>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { zones, fieldBoundaryGuid, areaIdToNewAreaIdPolygonMap } = action.payload;
    if (zones == null || zones.length === 0) {
        return;
    }

    const analysisSummary = yield select(selectors.getAnalysisLayerInfo);
    const isImagerySetup = analysisSummary.type === ANALYSIS_INFO_NAME_IMAGERY_SETUP;
    if (analysisSummary.layers.length === 1) {
        analysisSummary.analysisLayerAreaList = zones.map((zone) => ({
            analysisLayerAreaId: zone.attributes.AREA_ID,
            analysisLayerAreaGuid: isImagerySetup
                ? zone.attributes.AnalysisLayerAreaGuid || uuid()
                : "",
            analysisLayerGuid:
                zone.attributes.AnalysisLayerGuid || analysisSummary.analysisLayerGuid,
            applyAnalysisToArea: zone.isIncluded != undefined ? zone.isIncluded : true,
            calculatedArea: zone.attributes.CALCULATED_AREA,
            fieldBoundaryGuid,
            zonePolygons: GeometryMath.getSimplifiedPolygonParts(zone.geometry).map((poly) => ({
                shape: poly,
            })),
        }));
        if (
            analysisSummary.type === ANALYSIS_INFO_NAME_MANAGEMENT_AREA &&
            areaIdToNewAreaIdPolygonMap != null &&
            Object.keys(analysisSummary.managementAreaData).length > 0
        ) {
            const { managementAreaData } = analysisSummary;
            analysisSummary.managementAreaData = {};
            for (const [
                replaceAreaId,
                newAreaIdToPolygonMap,
            ] of areaIdToNewAreaIdPolygonMap.entries()) {
                for (const newAreaId of newAreaIdToPolygonMap.keys()) {
                    analysisSummary.managementAreaData[newAreaId] = managementAreaData[
                        replaceAreaId
                    ].map((attribute) => ({
                        ...attribute,
                    }));
                }
            }
        }
        yield put(actions.setAnalysisSummary(analysisSummary));
        yield put(actions.updateManagementAreaApplyToArea());
        yield put(
            analysisActions.setAnalysisSummaryMap(
                analysisSummary,
                analysisSummary.layers.map((x) => x.fieldGuid)
            )
        );
    }
};

export const updateManagementAreaAttributeData = function* (
    action: ReturnType<typeof actions.updateManagementAreaAttributeData>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { areaId, name, textValue, yesNoValue, decimalIntValue } = action.payload;
    yield put(
        actions.setManagementAreaAttributeData(areaId, name, textValue, yesNoValue, decimalIntValue)
    );
    yield put(actions.updateManagementAreaApplyToArea());
    const analysisSummary = yield select(selectors.getAnalysisLayerInfo);
    yield put(
        analysisActions.setAnalysisSummaryMap(
            analysisSummary,
            analysisSummary.layers.map((x) => x.fieldGuid)
        )
    );
};

const onFetchActualDetailedCost = function* (action) {
    const { model } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        yield put(actions.setIsLoading(true));
        const applicationCost = yield call(LayerAPI.getActualDetailedCost, model, userGuid);
        yield put(actions.fetchActualDetailCostCompleted(applicationCost));
        yield put(actions.setIsLoading(false));
    } catch (err) {
        yield put(notificationActions.apiCallError(err));
        yield put(actions.setIsLoading(false));
    }
};

export const analysisInfoSaga = function* (): Generator<AllEffect, void, any> {
    yield all([
        takeLatest(actions.ADD_ANALYSIS_LAYER, onCreateAnalysisLayer),
        takeLatest(actions.CLOSE_ANALYSIS_INFO, onCloseAnalysisInfo),
        takeLatest(actions.DELETE_ANALYSIS_LAYER, onDeleteAnalysisLayer),
        takeLatest(actions.DELETE_ANALYSIS_LAYER_BATCH, onDeleteAnalysisLayerBatch),
        takeLatest(actions.DELETE_ANALYSIS_SURFACE, onDeleteAnalysisSurface),
        takeLatest(actions.FETCH_ACTUAL_DETAIL_COST, onFetchActualDetailedCost),
        takeLatest(actions.FETCH_ANALYSIS_DETAILS, fetchAnalysisDetails),
        takeLatest(actions.FETCH_ANALYSIS_INFO, fetchAnalysisInfo),
        takeLatest(actions.FETCH_ANALYSIS_INFO_SUCCEEDED, fetchAnalysisInfoSucceeded),
        takeLatest(actions.FETCH_MANAGEMENT_AREA_ATTRIBUTES, fetchManagementAreaAttributes),
        takeLatest(actions.SAVE_ANALYSIS_INFO, onSaveAnalysisInfo),
        takeLatest(actions.SHOW_ANALYSIS_INFO, onShowAnalysisInfo),
        takeLatest(actions.UPDATE_ANALYSIS_ZONES, updateAnalysisZones),
        takeEvery(actions.UPDATE_ANALYSIS_LAYER, updateAnalysisLayer),
        takeEvery(actions.UPDATE_MANAGEMENT_AREA_ATTRIBUTE_DATA, updateManagementAreaAttributeData),
        takeLatest(layerActions.UPDATE_LOADING_FIELD_GUID_SET, onAnalysisCompletedAndLoaded),
    ]);
};
