import React, { Component } from "react";
import { connect } from "react-redux";
import { injectIntl, intlShape } from "react-intl";
import classnames from "classnames";
import moment from "moment";

import { actions as accordionActions, model as accordionModel } from "~/accordion";
import { LayerAPI, LayerUtilsAPI, UserAPI } from "@ai360/core";
import { Loader, LoaderTypes } from "~/core";
import { getUser } from "~/login";

import { createSurfaceAccordionItems } from "./surface-item";
import * as selectors from "../selectors";
import { LayerTypeShape, getLayerTypeLetter } from "../../../utils";
import { messages } from "../../../components/i18n-messages";

import {
    ANALYSIS_INFO_NAME_IMAGERY_SETUP,
    ANALYSIS_INFO_NAME_MANAGEMENT_AREA,
    ANALYSIS_INFO_NAME_PROFIT_LOSS,
} from "~/recs-events/analysis/model";
import "./layer-item.css";
import LayerItemContextMenu from "../../context-menus/layer-item-context-menu";

export const LAYER_HEIGHT = 25;
export const COMPLETED = "Completed";
export const ERROR = "Error";
export const IMAGERY_UNAVAILABLE = "Imagery Unavailable";
export const SATELLITE_API_ERROR = "Satellite API Error";

export const createLayerAccordionItems = (layers: LayerAPI.ILayer[]) => {
    return layers.map((layer, index) => {
        const type = LayerUtilsAPI.getLayerType(layer);
        const payload = {
            agEventGeneralGuid: layer.agEventGeneralGuid,
            cropGuid: layer.cropGuid,
            croppingSeasonGuid: layer.croppingSeasonGuid,
            index,
            layerType: layer.layerType,
            type,
        };
        const layerItem = new accordionModel.AccordionItem(LAYER_HEIGHT, false, payload);
        const attributeGuidField = LayerUtilsAPI.getAttributeGuidFieldName(type);

        switch (type) {
            case LayerUtilsAPI.LayerType.EVENT_FROM_EQUATION_REC:
                return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                    children: createSurfaceAccordionItems(
                        layer.subLayers.filter((s) => {
                            return s.surfaceTypeGuid === layer.selectedSurfaceTypeGuid;
                        }),
                        type
                    ),
                });
            case LayerUtilsAPI.LayerType.EVENT_IMPORTED:
                if (layer.isSampling) {
                    if (layer.subLayers) {
                        return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                            children: createSurfaceAccordionItems(
                                [
                                    {
                                        classBreaks: [{}],
                                        surfaceGuid: LayerUtilsAPI.SAMPLE_SITES_GUID,
                                    },
                                    ...layer.subLayers.filter((s) => {
                                        return s.surfaceTypeGuid === layer.selectedSurfaceTypeGuid;
                                    }),
                                ],
                                type
                            ),
                        });
                    } else {
                        return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                            children: createSurfaceAccordionItems(
                                [
                                    {
                                        classBreaks: [{}],
                                        surfaceGuid: LayerUtilsAPI.SAMPLE_SITES_GUID,
                                    },
                                ],
                                type
                            ),
                        });
                    }
                } else {
                    return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                        children: createSurfaceAccordionItems(
                            layer.subLayers.filter((s) => {
                                return s.surfaceTypeGuid === s.selectedSurfaceTypeGuid;
                            }),
                            type
                        ),
                    });
                }
            case LayerUtilsAPI.LayerType.EVENT_MANUAL:
                return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                    children: createSurfaceAccordionItems(
                        layer.subLayers.filter((s) => {
                            return s.surfaceTypeGuid === layer.selectedSurfaceTypeGuid;
                        }),
                        type
                    ),
                });
            case LayerUtilsAPI.LayerType.MANAGEMENT_AREA:
                return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                    children: createSurfaceAccordionItems(
                        layer.subLayers.filter((s) => {
                            return (
                                s.surfaceGuid === layer.selectedSurfaceGuid &&
                                s.surfaceTypeGuid === s.selectedSurfaceTypeGuid
                            );
                        }),
                        type
                    ),
                });
            case LayerUtilsAPI.LayerType.ANALYSIS:
            case LayerUtilsAPI.LayerType.REC:
                return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                    children: createSurfaceAccordionItems(
                        layer.subLayers.filter((s) => {
                            return s.surfaceTypeGuid === s.selectedSurfaceTypeGuid;
                        }),
                        type
                    ),
                });
            case LayerUtilsAPI.LayerType.SOIL:
                if (layer.subLayers) {
                    return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                        children: createSurfaceAccordionItems(
                            layer.subLayers.filter((s) => {
                                return s[attributeGuidField] === layer.selectedAttributeGuid;
                            }),
                            type
                        ),
                    });
                }
                return layerItem;
            case LayerUtilsAPI.LayerType.IMAGERY:
                return accordionModel.AccordionItem.updateAccordionItem(layerItem, {
                    children: createSurfaceAccordionItems(layer.subLayers, type),
                });
            default:
                return layerItem;
        }
    });
};

interface ILayerItemProps {
    accordionId: number;
    collapseItem: (accordionId: number, itemDimIdx: number[]) => void;
    displayNamePrefix: string;
    expandItem: (accordionId: number, itemDimIdx: number[]) => void;
    fieldGuid: string;
    hasVisibleSurface: boolean;
    index: number;
    intl: intlShape;
    isExpanded: boolean;
    itemDimIdx: number[];
    layer: LayerAPI.ILayer;
    status: string;
    type: typeof LayerTypeShape;
    userInfo: UserAPI.IUser;
}

class LayerItem_ extends Component<ILayerItemProps> {
    _onClick(event) {
        if (event.isDefaultPrevented()) {
            return;
        }

        const { isExpanded, expandItem, collapseItem, accordionId, itemDimIdx } = this.props;
        if (isExpanded) {
            collapseItem(accordionId, itemDimIdx);
        } else {
            expandItem(accordionId, itemDimIdx);
        }
    }

    useCroppingSeasonCombo(layer) {
        return (
            (layer.layerType === ANALYSIS_INFO_NAME_PROFIT_LOSS ||
                layer.layerType === ANALYSIS_INFO_NAME_MANAGEMENT_AREA) &&
            layer.subLayers?.length > 0 &&
            layer.subLayers[0].croppingSeason !== ""
        );
    }

    getDisplayName() {
        const { displayNamePrefix, layer } = this.props;
        let { displayName } = layer;
        if (displayNamePrefix) {
            if (this.useCroppingSeasonCombo(layer)) {
                displayName = `${layer.layerType} - ${layer.subLayers[0].croppingSeason} - ${displayName}`;
            } else if (layer.layerType === ANALYSIS_INFO_NAME_MANAGEMENT_AREA) {
                displayName = `${layer.layerType} - ${displayName}`;
            } else {
                displayName = `${displayNamePrefix} - ${displayName}`;
            }
        }
        return displayName;
    }

    getTooltipText() {
        const { layer, userInfo } = this.props;
        const formatedDate = moment(layer.layerDate).format("M/D/YY - h:mm a");

        const user =
            layer.modifiedBy != null && layer.modifiedBy?.trim() !== ""
                ? `${layer.modifiedBy} - `
                : userInfo != null && userInfo.personProperties != null
                ? `${userInfo.personProperties.firstName} ${userInfo.personProperties.lastName} - `
                : "";
        const date = formatedDate ? formatedDate + " " : "";

        const toolTipText = `${user}${date}`;
        return toolTipText;
    }

    getToolTipTextImagerySetup() {
        const { status } = this.props;
        const { formatMessage } = this.props.intl;
        if (status === IMAGERY_UNAVAILABLE) {
            return formatMessage(messages.imagerySetupError);
        } else if (status === ERROR) {
            return formatMessage(messages.imagerySetupUnavailable);
        }
        return this.getTooltipText();
    }

    render() {
        const { fieldGuid, hasVisibleSurface, layer, status, type, userInfo } = this.props;
        const { formatMessage } = this.props.intl;

        if (!layer) {
            return null;
        }

        const displayName = this.getDisplayName();
        const statusClass = status === COMPLETED ? "layer-status-completed" : "layer-status";
        const isImagerySetupLayer = layer.layerType === ANALYSIS_INFO_NAME_IMAGERY_SETUP;
        const toolTip = isImagerySetupLayer
            ? this.getToolTipTextImagerySetup()
            : this.getTooltipText();
        const isImagerySetuploading =
            isImagerySetupLayer &&
            status !== COMPLETED &&
            status !== ERROR &&
            status !== SATELLITE_API_ERROR &&
            status !== IMAGERY_UNAVAILABLE;
        const loader = !isImagerySetuploading ? null : (
            <div className="layer-item-loader">
                <Loader className="layer-loader" type={LoaderTypes.BALL_CLIP_ROTATE} />
            </div>
        );

        return (
            <div className="layer-accordion-item" onClick={(event) => this._onClick(event)}>
                <div
                    className={classnames("letter-icon", {
                        "has-visible": hasVisibleSurface,
                    })}
                >
                    {getLayerTypeLetter(type, formatMessage)}
                </div>
                <div className="layer-label" title={displayName}>
                    {displayName}
                </div>
                {!status ? null : (
                    <div title={toolTip} className={statusClass}>
                        {loader}
                        {status}
                    </div>
                )}
                {!isImagerySetupLayer ||
                isImagerySetuploading ||
                !userInfo.role.imagerySetup ? null : (
                    <LayerItemContextMenu layer={layer} type={Number(type)} fieldGuid={fieldGuid} />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    expandItem: (accordionId, dimIdx) =>
        dispatch(accordionActions.expandAccordionItem(accordionId, dimIdx)),
    collapseItem: (accordionId, dimIdx) =>
        dispatch(accordionActions.collapseAccordionItem(accordionId, dimIdx)),
});

const mapStateToProps = (state, ownProps) => {
    const layerInfos = selectors.getLayerInfos(state).get(ownProps.fieldGuid);
    const layer = layerInfos ? layerInfos[ownProps.index] : null;
    const userInfo = getUser(state);
    //For some reason, calling remove layer infos doesn't stop this from loading - may need to track this down
    if (!layerInfos || !layer) {
        return {
            displayNamePrefix: "",
            layer: null,
            status: "",
        };
    }
    let { displayNamePrefix, status } = layer;
    if (!status) {
        status = null;
    }
    if (ownProps.type === LayerUtilsAPI.LayerType.MANAGEMENT_AREA) {
        displayNamePrefix = layer.layerType;
        status = layer.analysisLayerStatus;
    } else if (layer.layerType === ANALYSIS_INFO_NAME_IMAGERY_SETUP) {
        displayNamePrefix = "Imagery";
        status = status || layer.analysisLayerStatus;
    } else if (ownProps.type === LayerUtilsAPI.LayerType.ANALYSIS && layer.subLayers.length > 0) {
        displayNamePrefix =
            layer.layerType === ANALYSIS_INFO_NAME_PROFIT_LOSS
                ? (displayNamePrefix = layer.layerType)
                : LayerUtilsAPI.getSurfaceInfo(layer).displayName;
        status = layer.analysisLayerStatus;
    }
    return {
        displayNamePrefix,
        layer,
        status,
        userInfo,
    };
};

export const LayerItem = connect(mapStateToProps, mapDispatchToProps)(injectIntl(LayerItem_));
