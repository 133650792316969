import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import { AutoSizer, List } from "react-virtualized";

import { actions } from "~/action-panel/components/field-module/";
import { messages as apMessages } from "~/action-panel/i18n-messages";
import { AgvanceUtils } from "~/admin/setup/customer/agvance-utils";
import { adminData } from "~/admin/data";
import { messages as setupMessages } from "~/admin/setup/i18n-messages";
import {
    AutoSearch,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    LoaderTypes,
    SelectInput,
} from "~/core";
import { messages as globalMessages } from "~/i18n-messages";
import { getTheUserGuid } from "~/login";
import { getTheUserPersonalityId, getUser } from "~/login/selectors";
import { actions as notificationActions } from "~/notifications";
import { IFieldsToMove } from "~/action-panel/components/field-module/models";
import { SearchAPI } from "@ai360/core";

import { messages } from "../../i18n-messages";
import * as selectors from "../../../../selectors";
import { messages as contextMessages } from "../../../../../context-menus/i18n-messages";

import "./move-fields-modal.css";
import { fetchOccupiedFarmNames } from "~/utils/api/field";

interface IProps {
    agvanceOrgList: any[];
    apiCallError: () => void;
    clearFieldsToMove: () => void;
    fetchAgvanceOrgLevelList: (customerGuid: string) => void;
    intl: any;
    isOpen: boolean;
    isLoading: boolean;
    fieldsToMove: IFieldsToMove;
    moveCustomerFields: (payload: any) => void;
    orgLevelList: any[];
    personalityId: number;
    userGuid: string;
    userRoles: any;
}

const MoveFieldsModal_ = (props: IProps): JSX.Element => {
    const { formatMessage } = props.intl;

    const [agvanceCustomerGuid, setAgvanceCustomerGuid] = useState<string>(null);
    const [agvanceFieldOrgLevelGuid, setAgvanceFieldOrgLevelGuid] = useState<string>(null);
    const [customerGuid, setCustomerGuid] = useState<string>(null);
    const [customerName, setCustomerName] = useState<string>("");
    const [farmName, setFarmName] = useState<string>("");
    const [farmNameOptions, setFarmNameOptions] = useState<any[]>([]);
    const [includeInactive, setIncludeInactive] = useState<boolean>(
        props.fieldsToMove.fields.some((f) => !f.activeYn)
    );

    const isAgvanceCustomer = AgvanceUtils.isAgvanceConnected(props.personalityId);

    useEffect(() => {
        if (isAgvanceCustomer) {
            props.fetchAgvanceOrgLevelList(customerGuid);
        }
    }, [customerGuid, farmNameOptions, agvanceCustomerGuid, agvanceFieldOrgLevelGuid]);

    useEffect(() => {
        const fetchFarmNameOptions = async (customerGuid: string) => {
            return await getFarmNameOptions(customerGuid);
        };

        if (!hasAcrossCustomerRole()) {
            fetchFarmNameOptions(props.fieldsToMove.customerGuid).then((farmNameOptions) => {
                setFarmNameOptions(farmNameOptions);
                setCustomerGuid(props.fieldsToMove.customerGuid);
            });
        } else {
            setFarmNameOptions([]);
        }
    }, [props.fieldsToMove.customerGuid]);

    useEffect(() => {
        const { fieldsToMove } = props;
        const { customerGuid } = fieldsToMove;
        const { farmName } = fieldsToMove.fields.find((x) => x.id === fieldsToMove.fieldGuids[0]);
        setCustomerGuid(customerGuid);
        setFarmName(fieldsToMove.fieldGuids.length === 1 ? farmName : "");
        setCustomerName(fieldsToMove.customer.name);
        setIncludeInactive(fieldsToMove.fields.some((f) => !f.activeYn));
    }, [props.isOpen, props.fieldsToMove]);

    useEffect(() => {
        if (isAgvanceCustomer && customerGuid) {
            const primaryAgvanceCustomer = props.agvanceOrgList.find((item) => {
                return item.isPrimary;
            });
            setAgvanceCustomerGuid(
                primaryAgvanceCustomer ? primaryAgvanceCustomer.agvanceCustomerGuid : ""
            );
            setAgvanceFieldOrgLevelGuid(
                primaryAgvanceCustomer ? primaryAgvanceCustomer.orgLevelGuid : ""
            );
        }
    }, [isAgvanceCustomer, customerGuid, props.agvanceOrgList]);

    const getFarmNameOptions = async (customerGuid: string) => {
        const { userGuid } = props;
        const farmNames = await fetchOccupiedFarmNames(
            customerGuid,
            !includeInactive ? true : null,
            userGuid
        );

        return farmNames.map((f) => ({
            label: f,
            value: f,
        }));
    };

    const hasAcrossCustomerRole = () => {
        return props.userRoles.acrossCustomers;
    };

    const onAction = () => {
        const { userGuid, fieldsToMove } = props;
        const agvanceData = isAgvanceCustomer
            ? { agvanceCustomerGuid, agvanceFieldOrgLevelGuid }
            : {};

        props.moveCustomerFields({
            userGuid,
            customerGuid,
            farmName,
            fieldGuidList: fieldsToMove.fieldGuids,
            ...agvanceData,
        });
    };

    const onAgvanceCustomerChange = ({ agvanceCustomerGuid, orgLevelGuid }) => {
        setAgvanceCustomerGuid(agvanceCustomerGuid);
        setAgvanceFieldOrgLevelGuid(orgLevelGuid);
    };

    const onClose = () => {
        setFarmName("");
        props.clearFieldsToMove();
    };

    const rowRenderer = ({ index, key, style }) => {
        const { fieldsToMove } = props;
        const field = fieldsToMove.fields.find((x) => x.id === fieldsToMove.fieldGuids[index]);
        const display = [field.farmName, field.name].filter((s) => s).join(", ");
        return (
            <div className="mf-field-list-item" key={key} style={style} title={display}>
                {display}
            </div>
        );
    };

    const showAdditionalAgvanceCustomer = () => {
        return customerGuid && isAgvanceCustomer && props.agvanceOrgList.length > 1;
    };

    const updateCustomerFarmList = async (customer: any) => {
        if (customer) {
            const { customerGuid } = customer;

            const farmNameOptions = await getFarmNameOptions(customerGuid);

            setCustomerGuid(customerGuid);
            setFarmNameOptions(farmNameOptions);
            setAgvanceCustomerGuid("");
            setAgvanceFieldOrgLevelGuid("");
        } else {
            setCustomerGuid("");
            setFarmNameOptions([]);
            setAgvanceCustomerGuid("");
            setAgvanceFieldOrgLevelGuid("");
        }
    };

    const getOptions = (userGuid: string, searchValue: string) => {
        const search = searchValue === "_" ? null : searchValue;
        const orgLevelGuids = props.orgLevelList?.map(({ orgLevelGuid }) => orgLevelGuid);
        const active = includeInactive ? null : true;

        return SearchAPI.getCustomers({
            userGuid,
            search,
            orgLevelGuids,
            active,
            lastUsedCustomer: SearchAPI.LastUsedCustomer.Match,
        });
    };

    if (!props.isOpen) {
        return null;
    }

    const searchForCustomer = formatMessage(setupMessages.searchFor, {
        searchedFor: formatMessage(setupMessages.customer),
    });
    return (
        <DialogBox
            draggable
            action={formatMessage(messages.move)}
            actionDisabled={hasAcrossCustomerRole() ? !customerGuid : !farmName}
            className="move-fields-modal"
            footerType={DialogBoxFooterType.ACTION_CANCEL}
            forceOverflow
            isOpen={props.isOpen}
            onAction={() => onAction()}
            onClose={() => onClose()}
            title={formatMessage(contextMessages.moveFieldsText, {
                count: props.fieldsToMove.fieldGuids.length,
            })}
            unrestricted
        >
            <div className="mf-row">
                <div className="mf-col">
                    <div className="mf-header">
                        {formatMessage(messages.selectedField, {
                            count: props.fieldsToMove.fieldGuids.length,
                        })}
                    </div>
                    <div className="mf-content">
                        <AutoSizer>
                            {({ width, height }) => (
                                <List
                                    height={height}
                                    rowCount={props.fieldsToMove.fieldGuids.length}
                                    rowHeight={20}
                                    rowRenderer={(e) => rowRenderer(e)}
                                    width={width}
                                />
                            )}
                        </AutoSizer>
                    </div>
                </div>
                <div className="mf-col">
                    <div className="mf-header">{formatMessage(messages.moveTo)}</div>
                    {!hasAcrossCustomerRole() ? null : (
                        <AutoSearch
                            getAutoSearchList={getOptions.bind(this)}
                            keyProp={SearchAPI.Props.CUSTOMER_GUID}
                            nameProp={SearchAPI.Props.CUSTOMER_NAME}
                            itemList={[]}
                            onSelection={(item) => updateCustomerFarmList(item)}
                            placeholderText={searchForCustomer}
                            secondaryPropList={[
                                SearchAPI.Props.CUSTOMER_CITY,
                                SearchAPI.Props.STATE_ABBR,
                            ]}
                            userGuid={props.userGuid}
                        />
                    )}
                    {!showAdditionalAgvanceCustomer() ? null : (
                        <SelectInput
                            clearable={false}
                            optionIsHiddenKey={adminData.PROPS_ACTIVE_YN}
                            clearFilterInputOnBlur={false}
                            placeholderText={formatMessage(globalMessages.agvanceCustomer)}
                            value={{
                                agvanceCustomerGuid,
                                orgLevelGuid: agvanceFieldOrgLevelGuid,
                            }}
                            onChange={(v) => onAgvanceCustomerChange(v)}
                            options={props.agvanceOrgList.map(
                                ({
                                    agvanceGrowerName,
                                    orgLevelGuid,
                                    customerName,
                                    agvanceCustomerGuid,
                                    orgLevelName,
                                    isPrimary,
                                }) => ({
                                    label: `${
                                        isPrimary ? customerName : agvanceGrowerName
                                    } > ${orgLevelName}`,
                                    value: {
                                        agvanceCustomerGuid,
                                        orgLevelGuid,
                                    },
                                })
                            )}
                            required
                        />
                    )}
                    <SelectInput
                        disabled={hasAcrossCustomerRole() ? !customerGuid : false}
                        initialFilterStr={farmName}
                        onInputChange={(farmName) => setFarmName(farmName)}
                        onChange={(farmName) => setFarmName(farmName)}
                        options={farmNameOptions}
                        placeholderText={formatMessage(apMessages.farmName)}
                        value={farmName}
                        clearFilterInputOnBlur={false}
                        allowEmptyOptions
                    />
                </div>
            </div>
            {!props.isLoading &&
            !(isAgvanceCustomer && customerGuid && !agvanceCustomerGuid) ? null : (
                <Loader type={LoaderTypes.LINE_SCALE_PULSE_OUT} />
            )}
        </DialogBox>
    );
};

const mapStateToProps = (state) => ({
    agvanceOrgList: selectors.getAgvanceOrgLevelList(state),
    personalityId: getTheUserPersonalityId(state),
    userGuid: getTheUserGuid(state),
    userRoles: getUser(state).role,
    isLoading: selectors.getMoveDialogLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
    apiCallError: (err) => dispatch(notificationActions.apiCallError(err)),
    clearFieldsToMove: () => dispatch(actions.clearFieldsToMove()),
    fetchAgvanceOrgLevelList: (payload) => dispatch(actions.fetchAgvanceOrgLevelList(payload)),
    moveCustomerFields: (payload) => dispatch(actions.moveCustomerFields(payload)),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});
export const MoveFieldsModal = connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(injectIntl(MoveFieldsModal_));
