import { AgvanceAPI, EventAPI, FieldAPI, FieldData } from "@ai360/core";
import { IFieldFilterOptions } from "./components/field-list/models";
import { model as accModels } from "~/accordion";
import * as models from "./models";
import { FieldListTabs } from "./actions";

export interface IActionData {
    type: string;
    payload?: IPayloadData;
}

export interface IPayloadData {
    action?: FieldBoundaryOverlapAction;
    customerGuid?: string;
    fieldGuid?: string;
    fieldGuids?: string[];
    analysisLayerType?: string;
    agvanceFieldList?: AgvanceAPI.IFieldResult[];
    agvanceFieldClassificationList?: string[];
    agvanceOrgLevelList?: string[];
    activeCustomerGuid?: string;
    activeFieldGuid?: string;
    fieldBoundaries?: { [fieldGuid: string]: FieldAPI.IFieldBoundary[] };
    fieldModel?: unknown;
    orgLevelList?: string[];
    userGuid?: string;
    farmName?: string;
    fieldGuidList?: string[];
    agvanceCustomerGuid?: string;
    agvanceFieldOrgLevelGuid?: string;
    isLoading?: boolean;
    callback?: () => any;
    response?: unknown;
    importFileGuid?: string;
    moveFieldCustomerGuid?: string;
    moveFieldGuidList?: string[];
    orgLevelGuid?: string;
    fieldList?: unknown[];
    combineFieldRequest?: boolean;
    error?: Error;
    customerGuids?: Immutable.Set<string> | string[];
    filterOptions?: Partial<IFieldFilterOptions>;
    isClean?: boolean;
    isCombineFieldsModalOpen?: boolean;
    isFieldBoundaryTransferModalOpen?: boolean;
    activeTab?: string;
    lastClickedDimIdx?: accModels.MultiDimIdx;
    salespersonGuids?: Immutable.Map<string, Set<string>>;
    showFilters?: boolean;
    searchValue?: string;
    initScrollTop?: number;
    scrollToFlatIdx?: number;
    agvanceFieldData?: FieldData;
    fieldsToMove?: FieldData;
    newProps?: Record<string, any>;
    showBatchEdit?: boolean;
    ignoreAgvance?: boolean;
    geometries?: __esri.Geometry[];
    resurfaceEvents?: EventAPI.IResurfaceEvent;
    keepExistingEvents?: boolean;
    cropGuid?: string;
    croppingSeasonGuid?: string;
}

export interface IFieldsToMove {
    customerGuid: string;
    fieldGuids: string[];
}

export enum FieldBoundaryOverlapAction {
    CLIP_TO_OTHER_FIELD = "clipToOtherField",
    UPDATE_OTHER_FIELD = "updateOtherField",
    CANCEL = "cancel",
}

export interface IFieldModuleState {
    FIELD_INFO_STATE_KEY: {
        activeTab: FieldListTabs;
        agvanceFieldClassificationList: any;
        agvanceFieldData: any;
        agvanceFieldList: AgvanceAPI.IFieldResult[];
        agvanceOrgLevelList: any;
        combineFieldsError: any;
        customerFieldListWithOrgLevel: any;
        dropdowns: any;
        errorCodes: any;
        fieldBoundariesToClip: { [fieldGuid: string]: FieldAPI.IFieldBoundary[] };
        fieldBoundaryOverlapAction: FieldBoundaryOverlapAction;
        fieldBoundaryTransferFieldModel: models.IFieldModel;
        fieldBoundaryTransferGeometries: __esri.Polygon[];
        fieldDeleteAgvanceOverride: any;
        fetchingFieldInfo: any;
        fieldModel: models.IFieldModel;
        fieldsToMove: IFieldsToMove;
        fullFieldRecords: FieldAPI.IFieldResult[];
        isCombineDialogOpen: boolean;
        isFieldBoundaryOverlapOpen: boolean;
        isFieldBoundaryTransferOpen: boolean;
        isLoading: boolean;
        isMoveDialogLoading: boolean;
        lastSearchValue: string;
        orgLevelGuid: string;
        resurfaceEvents: EventAPI.IResurfaceEvent;
    };
}
