import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";

import { messages as mainMessages, createAddLinkLabelText } from "~/i18n-messages";

import {
    Bucket,
    BucketHeader,
    Checkbox,
    DialogBox,
    DialogBoxFooterType,
    Loader,
    NoLink,
    NumericInput,
    SelectInput,
    ZeroToInfiniteUnsafe,
} from "~/core";
import * as actions from "./actions";
import * as selectors from "./selectors";
import * as models from "~/recs-events/events/model";

import { categories, getCategoryGroupName } from "./scouting-detail";

import {
    ACTIVE_YN,
    picklistNames,
    actions as picklistActions,
    selectors as picklistSelectors,
} from "~/core/picklist";

import { actions as unitActions, selectors as unitSelectors, unitNames } from "~/core/units";

import { CropAPI, PicklistAPI } from "@ai360/core";
import { fetchDropdownData } from "~/core/dropdowns/actions";
import { fetchedDropdownData } from "../../actions";
import * as eventInfoSelectors from "../../selectors";

import { EventScoutingTemplateObsItem } from "./event-scouting-template-obs-item";
import { AgBytesItem, IObservationData } from "./models";

import { messages } from "./i18n-messages";

import "./event-scouting-template-modal.css";

const { getUnitCode, UNIT_LENGTH, UNIT_CROP_POPULATION } = unitNames;

class ObservationDataRequest {
    observationGuidList: Array<string>;
}

interface IScoutingTemplateModalProps {
    agEventModel: any;
    brandOrgData: AgBytesItem[];
    countData: PicklistAPI.IPicklistItem[];
    cropData: AgBytesItem[];
    cropPurpose: PicklistAPI.IPicklistItem[];
    densityData: PicklistAPI.IPicklistItem[];
    fetchDropdownData: (dropDowns: any) => void;
    fetchGrowthStageData: (cropGuid: string) => void;
    fetchObservationData?: () => void;
    fetchGrowthStageObservationData: (newProps: ObservationDataRequest) => void;
    fetchPicklistData: (picklists: any) => void;
    fetchScoutingTemplate: (cropGuid: string, cropPurposeGuid: string) => void;
    fetchUnitData?: (newProps: any) => void;
    growthStageData: PicklistAPI.IPicklistItem[];
    growthStageObservationData: Map<string, Array<any>>;
    intl: intlShape.isRequired;
    isLoading: boolean;
    isOpen: boolean;
    numericRating: PicklistAPI.IPicklistItem[];
    observationData: IObservationData[];
    onClose: () => void;
    plantLocationData: PicklistAPI.IPicklistItem[];
    resetScoutingTemplate: () => void;
    saveScoutingTemplate: () => void;
    scoutingTemplate: models.ScoutingTemplate;
    unitCropPopulation: PicklistAPI.IPicklistItem[];
    unitLength: PicklistAPI.IPicklistItem[];
    updateScoutingTemplate: (newProps: Partial<models.ScoutingTemplate>) => void;
    varietyHybridData: AgBytesItem[];
}

interface IScoutingTemplateModalState {
    filteredObservationData: IObservationData[];
    isResetting: boolean;
    originalScoutingTemplate: models.ScoutingTemplate;
    observationInfo: models.ScoutingTemplateObservation[];
    showBrandOrganization: boolean;
    showVarietyHybrid: boolean;
    showCropGrowthStage: boolean;
    showCropCondition: boolean;
    showCropInjuryRating: boolean;
    showDaysAfterCropDamage: boolean;
    showCropHeight: boolean;
    showCropPopulation: boolean;
    showYieldPercentLoss: boolean;
    showStandPercentLoss: boolean;
    showLeafPercentLoss: boolean;
}

class EventScoutingTemplateModal_ extends Component<
    IScoutingTemplateModalProps,
    IScoutingTemplateModalState
> {
    public UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            nextProps.scoutingTemplate &&
            ((nextProps.scoutingTemplate.cropGuid &&
                nextProps.scoutingTemplate.cropGuid !== this.props.scoutingTemplate.cropGuid) ||
                (nextProps.scoutingTemplate.cropPurposeGuid &&
                    nextProps.scoutingTemplate.cropPurposeGuid !==
                        this.props.scoutingTemplate.cropPurposeGuid))
        ) {
            //new template has been loaded, so reset values and data
            this.setUpTemplateData(nextProps.scoutingTemplate);
        }
        if (
            nextProps.scoutingTemplate.cropInfo &&
            nextProps.scoutingTemplate.cropInfo.brandOrganizationGuid &&
            nextProps.scoutingTemplate.cropInfo.brandOrganizationGuid !==
                this.props.scoutingTemplate.cropInfo.brandOrganizationGuid
        ) {
            this.refreshBrandVarietyData(
                nextProps.scoutingTemplate.cropGuid,
                nextProps.scoutingTemplate.cropInfo.brandOrganizationGuid
            );
        }
    }

    constructor(props) {
        super(props);
        const cropInfo = props.scoutingTemplate.cropInfo;
        this.state = {
            filteredObservationData: [],
            isResetting: false,
            originalScoutingTemplate: props.scoutingTemplate,
            observationInfo: props.scoutingTemplate.observationInfo,
            showBrandOrganization: cropInfo.showBrandOrganization,
            showVarietyHybrid: cropInfo.showVarietyHybrid,
            showCropGrowthStage: cropInfo.showCropGrowthStage,
            showCropCondition: cropInfo.showCropCondition,
            showCropInjuryRating: cropInfo.showCropInjuryRating,
            showDaysAfterCropDamage: cropInfo.showDaysAfterCropDamage,
            showCropHeight: cropInfo.showCropHeight,
            showCropPopulation: cropInfo.showCropPopulation,
            showYieldPercentLoss: cropInfo.showYieldPercentLoss,
            showStandPercentLoss: cropInfo.showStandPercentLoss,
            showLeafPercentLoss: cropInfo.showLeafPercentLoss,
        };
    }

    public addItem(e, items: models.ScoutingTemplateObservation[]) {
        const filteredObservationData = this.props.observationData.filter((observation) =>
            items.every((ob) => ob.observationGuid !== observation.guid)
        );
        this.setState({ observationInfo: items, filteredObservationData }, () => {
            this.props.updateScoutingTemplate({ observationInfo: items });
        });
    }

    public onDelete(e, items: models.ScoutingTemplateObservation[]) {
        this.setState({ observationInfo: items }, () => {
            this.props.updateScoutingTemplate({ observationInfo: items });
        });
    }

    public componentDidMount() {
        this.setUpTemplateData(this.props.scoutingTemplate);
    }

    private canSave() {
        const { scoutingTemplate } = this.props;
        const { observationInfo } = this.state;
        if (!scoutingTemplate.cropGuid) {
            return false;
        }
        for (const observation of observationInfo) {
            if (!observation.observationGuid) {
                return false;
            }
        }
        for (const prop in scoutingTemplate) {
            if (Object.hasOwn(scoutingTemplate, prop)) {
                if (prop === "cropInfo") {
                    for (const cropProp in scoutingTemplate.cropInfo) {
                        if (scoutingTemplate.cropInfo[cropProp]) {
                            return true;
                        }
                    }
                } else if (prop === "observationInfo") {
                    for (let i = 0; i < scoutingTemplate.observationInfo.length; i++) {
                        for (const obsProp in scoutingTemplate.observationInfo[i]) {
                            if (scoutingTemplate.observationInfo[i][obsProp]) {
                                return true;
                            }
                        }
                    }
                } else {
                    if (prop === "allowPhotos" && !scoutingTemplate[prop]) {
                        return true;
                    } else if (
                        Boolean(scoutingTemplate[prop]) &&
                        ["cropGuid", "cropPurposeGuid"].indexOf(prop) < 0
                    ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private onCropChange(newProps: Partial<models.ScoutingTemplate>) {
        const { fetchGrowthStageData, fetchScoutingTemplate, scoutingTemplate } = this.props;

        if (newProps.cropGuid) {
            //in cases where the cropGuid is cleared do not fetch the growth stage or brand data
            fetchGrowthStageData(newProps.cropGuid);
        }
        if (Object.hasOwn(newProps, "cropGuid")) {
            newProps.cropPurposeGuid = null;
            fetchScoutingTemplate(newProps.cropGuid, null);
            this.props.fetchDropdownData({
                brandOrgData: {
                    url: CropAPI.REQUEST_BRAND_ORG,
                    model: newProps.cropGuid,
                },
            });
        } else if (Object.hasOwn(newProps, "cropPurposeGuid")) {
            fetchScoutingTemplate(scoutingTemplate.cropGuid, newProps.cropPurposeGuid);
        }
    }

    private onBrandOrganizationChange(newProps: Partial<models.ScoutingTemplateCrop>) {
        this.props.fetchDropdownData({
            varietyHybridData: {
                url: CropAPI.REQUEST_VARIETY_HYBRID,
                model: {
                    CropID: this.props.scoutingTemplate.cropGuid,
                    BrandOrganization: newProps.brandOrganizationGuid,
                },
            },
        });

        this.onUpdateScoutingTemplateCropInfo(newProps);
    }

    private onSave() {
        const {
            observationInfo,
            showBrandOrganization,
            showVarietyHybrid,
            showCropGrowthStage,
            showCropCondition,
            showCropInjuryRating,
            showDaysAfterCropDamage,
            showCropHeight,
            showCropPopulation,
            showYieldPercentLoss,
            showStandPercentLoss,
            showLeafPercentLoss,
        } = this.state;
        this.onUpdateScoutingTemplateCropInfo({
            ...this.props.scoutingTemplate.cropInfo,
            showBrandOrganization,
            showVarietyHybrid,
            showCropGrowthStage,
            showCropCondition,
            showCropInjuryRating,
            showDaysAfterCropDamage,
            showCropHeight,
            showCropPopulation,
            showYieldPercentLoss,
            showStandPercentLoss,
            showLeafPercentLoss,
        });
        this.props.updateScoutingTemplate({ observationInfo });

        this.props.saveScoutingTemplate();
        this.setState({ originalScoutingTemplate: this.props.scoutingTemplate }, () => {
            if (this.props.onClose) {
                this.props.onClose();
            }
        });
    }

    private onClose() {
        const { agEventModel, fetchScoutingTemplate, updateScoutingTemplate } = this.props;
        const { originalScoutingTemplate } = this.state;
        if (originalScoutingTemplate.cropGuid) {
            fetchScoutingTemplate(
                originalScoutingTemplate.cropGuid,
                originalScoutingTemplate.cropPurposeGuid
            );
            this.refreshBrandVarietyData(agEventModel.cropGuid, agEventModel.brandOrganizationGuid);
        } else {
            updateScoutingTemplate({ ...new models.ScoutingTemplate() });
        }

        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private onUpdateScoutingTemplateCropInfo(newProps: Partial<models.ScoutingTemplateCrop>) {
        const { scoutingTemplate, updateScoutingTemplate } = this.props;
        const cropInfo = {
            ...scoutingTemplate.cropInfo,
            ...newProps,
        };
        updateScoutingTemplate({ cropInfo });
    }

    public onChange = (type: string, value: any, index: number, cb: (v: any) => void) => {
        const observationInfo = [...this.state.observationInfo];
        if (type) {
            const newObservationInfo =
                type === "observationGuid"
                    ? models.ScoutingTemplateObservation.fromJsonObj({
                          [type]: value,
                          observationOrderId: index + 1,
                      })
                    : models.ScoutingTemplateObservation.fromJsonObj({
                          ...observationInfo[index],
                          [type]: value,
                          observationOrderId: index + 1,
                      });
            observationInfo.splice(index, 1, newObservationInfo);
        }
        const filteredObservationData = this.props.observationData.filter((observation) =>
            observationInfo.every((ob) => ob.observationGuid !== observation.guid)
        );
        this.setState({ observationInfo, filteredObservationData }, () => {
            if (typeof cb === "function") {
                cb(value);
            }
        });
    };

    private refreshBrandVarietyData(cropGuid: string, brandOrganizationGuid: string) {
        const dropDownRequest = {};
        if (cropGuid) {
            Object.assign(dropDownRequest, {
                brandOrgData: {
                    url: CropAPI.REQUEST_BRAND_ORG,
                    model: cropGuid,
                },
            });
        }
        if (brandOrganizationGuid) {
            Object.assign(dropDownRequest, {
                varietyHybridData: {
                    url: CropAPI.REQUEST_VARIETY_HYBRID,
                    model: {
                        CropID: cropGuid,
                        BrandOrganization: brandOrganizationGuid,
                    },
                },
            });
        }
        if (Object.hasOwn(dropDownRequest, "brandOrgData")) {
            this.props.fetchDropdownData(dropDownRequest);
        }
    }

    private renderResetModal = () => {
        const { isResetting } = this.state;
        const { formatMessage } = this.props.intl;
        return (
            <DialogBox
                footerType={DialogBoxFooterType.YES_NO}
                isOpen={isResetting}
                onAction={() => {
                    this.props.resetScoutingTemplate();
                    this.setState({ isResetting: false });
                }}
                onClose={() => this.setState({ isResetting: false })}
                title={formatMessage(messages.resetTemplateText)}
            >
                {formatMessage(messages.resetTemplateConfirmation)}
            </DialogBox>
        );
    };

    private setUpTemplateData(scoutingTemplate) {
        const {
            fetchGrowthStageData,
            fetchPicklistData,
            fetchUnitData,
            fetchObservationData,
            observationData,
        } = this.props;
        const cropInfo = scoutingTemplate.cropInfo;
        if (scoutingTemplate.cropGuid) {
            fetchGrowthStageData(scoutingTemplate.cropGuid);
            this.refreshBrandVarietyData(
                scoutingTemplate.cropGuid,
                scoutingTemplate.cropInfo.brandOrganizationGuid
            );
        }
        const observationGuidList = scoutingTemplate.observationInfo.map(
            (ob) => ob.observationGuid
        );
        if (observationGuidList.length > 0) {
            this.props.fetchGrowthStageObservationData({ observationGuidList });
        }
        const filteredObservationData = observationData.filter((observation) =>
            scoutingTemplate.observationInfo.every((ob) => ob.observationGuid !== observation.guid)
        );
        this.setState({
            filteredObservationData,
            observationInfo: [...scoutingTemplate.observationInfo],
            showBrandOrganization: cropInfo.showBrandOrganization,
            showVarietyHybrid: cropInfo.showVarietyHybrid,
            showCropGrowthStage: cropInfo.showCropGrowthStage,
            showCropCondition: cropInfo.showCropCondition,
            showCropInjuryRating: cropInfo.showCropInjuryRating,
            showDaysAfterCropDamage: cropInfo.showDaysAfterCropDamage,
            showCropHeight: cropInfo.showCropHeight,
            showCropPopulation: cropInfo.showCropPopulation,
            showYieldPercentLoss: cropInfo.showYieldPercentLoss,
            showStandPercentLoss: cropInfo.showStandPercentLoss,
            showLeafPercentLoss: cropInfo.showLeafPercentLoss,
        });
        fetchPicklistData({
            [picklistNames.PICKLIST_COUNT_UNIT]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_COUNT_UNIT
            ),
            [picklistNames.PICKLIST_NUMERIC_RATING]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_NUMERIC_RATING
            ),
            [picklistNames.PICKLIST_DENSITY_RATING]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_DENSITY_RATING
            ),
            [picklistNames.PICKLIST_LOCATIONON_PLANT]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_LOCATIONON_PLANT
            ),
            [picklistNames.PICKLIST_OBSERVATION_TYPE]: picklistNames.getPickListCode(
                picklistNames.PICKLIST_OBSERVATION_TYPE
            ),
        });
        fetchUnitData({
            [unitNames.UNIT_LENGTH]: unitNames.getUnitCode(unitNames.UNIT_LENGTH),
            [unitNames.UNIT_CROP_POPULATION]: unitNames.getUnitCode(unitNames.UNIT_CROP_POPULATION),
        });
        fetchObservationData();
    }

    public render() {
        const {
            brandOrgData,
            countData,
            cropData,
            cropPurpose,
            densityData,
            fetchGrowthStageObservationData,
            growthStageData,
            growthStageObservationData,
            isOpen,
            isLoading,
            numericRating,
            observationData,
            plantLocationData,
            scoutingTemplate,
            unitCropPopulation,
            unitLength,
            updateScoutingTemplate,
            varietyHybridData,
        } = this.props;
        const { formatMessage } = this.props.intl;
        const {
            filteredObservationData,
            observationInfo,
            showBrandOrganization,
            showVarietyHybrid,
            showCropGrowthStage,
            showCropCondition,
            showCropInjuryRating,
            showDaysAfterCropDamage,
            showCropHeight,
            showCropPopulation,
            showYieldPercentLoss,
            showStandPercentLoss,
            showLeafPercentLoss,
        } = this.state;
        const observationList = observationInfo.map((observationItem) => ({
            ...observationItem,
        })); //decouples ZeroToInfiniteUnsafe from state
        const isEmpty = !scoutingTemplate;

        const props = {
            categories,
            countData,
            densityData,
            fetchGrowthStageObservationData,
            formatMessage,
            filteredObservationData,
            observationData,
            onChange: this.onChange,
            getCategoryGroupName,
            growthStageObservationData,
            messages,
            plantLocationData,
            observationInfo: observationList,
            unitLength,
        };
        const extractValue = (ifNotEmpty) => (isEmpty ? null : ifNotEmpty());
        return (
            <DialogBox
                className="scouting-template-dialog"
                actionDisabled={!this.canSave()}
                draggable={true}
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                forceOverflow
                isOpen={isOpen}
                isModal={true}
                unrestricted
                onAction={() => this.onSave()}
                onClose={() => this.onClose()}
                title={`${formatMessage(messages.scoutingTemplateTitle)}`}
            >
                <div className="scouting-template-content">
                    <div className="scouting-template-top-bar">
                        <SelectInput
                            optionIsHiddenKey={ACTIVE_YN}
                            options={cropData.map(({ guid, name, activeYn }) => ({
                                value: guid,
                                label: name,
                                activeYn,
                            }))}
                            onChange={(v) => this.onCropChange({ cropGuid: v })}
                            placeholderText={formatMessage(messages.crop)}
                            value={extractValue(() => scoutingTemplate.cropGuid)}
                        />
                        <SelectInput
                            options={cropPurpose}
                            onChange={(v) => this.onCropChange({ cropPurposeGuid: v })}
                            placeholderText={formatMessage(messages.cropPurpose)}
                            value={extractValue(() => scoutingTemplate.cropPurposeGuid)}
                        />
                        <div className="allow-photo-container">
                            {!scoutingTemplate.cropGuid ? null : (
                                <Checkbox
                                    className="photos-checkbox"
                                    onChange={(e, v) =>
                                        updateScoutingTemplate({
                                            allowPhotos: v,
                                        })
                                    }
                                    value={extractValue(() => scoutingTemplate.allowPhotos)}
                                    label={formatMessage(messages.allowPhotos)}
                                />
                            )}
                        </div>
                        <div className="reset-container">
                            <NoLink
                                className="reset-nolink"
                                label={formatMessage(messages.resetTemplateText)}
                                onClick={() => this.setState({ isResetting: true })}
                            />
                        </div>
                    </div>

                    <Bucket isExpanded={true}>
                        <BucketHeader>{formatMessage(messages.cropInfo)}</BucketHeader>

                        {!scoutingTemplate.cropGuid ? null : (
                            <div className="main-bucket-content">
                                <div className="template-input-row">
                                    <div className="template-input-item">
                                        <Checkbox
                                            onChange={(e, v) => {
                                                this.setState({
                                                    showBrandOrganization: v,
                                                    showVarietyHybrid: false,
                                                });
                                                this.onBrandOrganizationChange({
                                                    brandOrganizationGuid: null,
                                                    varietyHybridGuid: null,
                                                });
                                            }}
                                            value={extractValue(() => showBrandOrganization)}
                                        />
                                        <SelectInput
                                            disabled={
                                                !scoutingTemplate.cropGuid || !showBrandOrganization
                                            }
                                            options={brandOrgData.map(
                                                ({ guid, name, activeYn }) => ({
                                                    value: guid,
                                                    label: name,
                                                    activeYn,
                                                })
                                            )}
                                            onChange={(v) =>
                                                this.onBrandOrganizationChange({
                                                    brandOrganizationGuid: v,
                                                    varietyHybridGuid: null,
                                                })
                                            }
                                            placeholderText={formatMessage(
                                                messages.brandOrganization
                                            )}
                                            value={extractValue(
                                                () =>
                                                    scoutingTemplate.cropInfo.brandOrganizationGuid
                                            )}
                                        />
                                    </div>
                                    <div className="template-input-item">
                                        <Checkbox
                                            onChange={(e, v) => {
                                                this.setState({
                                                    showVarietyHybrid: v,
                                                });
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    varietyHybridGuid: null,
                                                });
                                            }}
                                            value={extractValue(
                                                () => showBrandOrganization && showVarietyHybrid
                                            )}
                                        />
                                        <SelectInput
                                            disabled={
                                                !showVarietyHybrid ||
                                                !showBrandOrganization ||
                                                !scoutingTemplate.cropInfo.brandOrganizationGuid
                                            }
                                            options={varietyHybridData.map(
                                                ({ guid, name, activeYn }) => ({
                                                    value: guid,
                                                    label: name,
                                                    activeYn,
                                                })
                                            )}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    varietyHybridGuid: v,
                                                })
                                            }
                                            placeholderText={formatMessage(messages.varietyHybrid)}
                                            value={extractValue(
                                                () => scoutingTemplate.cropInfo.varietyHybridGuid
                                            )}
                                        />
                                    </div>
                                    <div className="template-input-item">
                                        <Checkbox
                                            onChange={(e, v) => {
                                                this.setState({
                                                    showCropGrowthStage: v,
                                                });
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropGrowthStageGuid: null,
                                                });
                                            }}
                                            value={extractValue(() => showCropGrowthStage)}
                                        />
                                        <SelectInput
                                            disabled={
                                                !showCropGrowthStage || !scoutingTemplate.cropGuid
                                            }
                                            options={[...growthStageData]}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropGrowthStageGuid: v,
                                                })
                                            }
                                            placeholderText={formatMessage(messages.growthStage)}
                                            value={extractValue(
                                                () => scoutingTemplate.cropInfo.cropGrowthStageGuid
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="template-input-row">
                                    <div className="template-input-item">
                                        <Checkbox
                                            onChange={(e, v) => {
                                                this.setState({
                                                    showCropCondition: v,
                                                });
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropConditionGuid: null,
                                                });
                                            }}
                                            value={extractValue(() => showCropCondition)}
                                        />
                                        <SelectInput
                                            disabled={!showCropCondition}
                                            options={numericRating}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropConditionGuid: v,
                                                })
                                            }
                                            placeholderText={formatMessage(messages.cropCondition)}
                                            value={extractValue(
                                                () => scoutingTemplate.cropInfo.cropConditionGuid
                                            )}
                                        />
                                    </div>
                                    <div className="template-input-item">
                                        <Checkbox
                                            onChange={(e, v) => {
                                                this.setState({
                                                    showCropInjuryRating: v,
                                                });
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropInjuryRatingGuid: null,
                                                });
                                            }}
                                            value={extractValue(() => showCropInjuryRating)}
                                        />
                                        <SelectInput
                                            disabled={!showCropInjuryRating}
                                            options={numericRating}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropInjuryRatingGuid: v,
                                                })
                                            }
                                            placeholderText={formatMessage(messages.cropInjury)}
                                            value={extractValue(
                                                () => scoutingTemplate.cropInfo.cropInjuryRatingGuid
                                            )}
                                        />
                                    </div>
                                    <div className="template-input-item">
                                        <Checkbox
                                            onChange={(e, v) => {
                                                this.setState({
                                                    showDaysAfterCropDamage: v,
                                                });
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    daysAfterCropDamage: null,
                                                });
                                            }}
                                            value={extractValue(() => showDaysAfterCropDamage)}
                                        />
                                        <NumericInput
                                            disabled={!showDaysAfterCropDamage}
                                            containerClassNames={["template-input-numeric"]}
                                            scale={8}
                                            precision={38}
                                            placeholderText={formatMessage(
                                                messages.daysAfterCropDamage
                                            )}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    daysAfterCropDamage: v,
                                                })
                                            }
                                            value={extractValue(
                                                () => scoutingTemplate.cropInfo.daysAfterCropDamage
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="template-input-row">
                                    <div className="template-input-item">
                                        <Checkbox
                                            onChange={(e, v) => {
                                                this.setState({
                                                    showCropHeight: v,
                                                });
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropHeight: null,
                                                    cropHeightUnitGuid: null,
                                                });
                                            }}
                                            value={extractValue(() => showCropHeight)}
                                        />
                                        <NumericInput
                                            disabled={!showCropHeight}
                                            containerClassNames={["template-input-numeric"]}
                                            scale={8}
                                            precision={38}
                                            placeholderText={formatMessage(messages.cropHeight)}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropHeight: v,
                                                })
                                            }
                                            value={extractValue(
                                                () => scoutingTemplate.cropInfo.cropHeight
                                            )}
                                        />
                                    </div>
                                    <div className="template-input-item no-toggle">
                                        <SelectInput
                                            disabled={!showCropHeight}
                                            options={unitLength}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropHeightUnitGuid: v,
                                                })
                                            }
                                            placeholderText={formatMessage(messages.cropHeightUnit)}
                                            value={extractValue(
                                                () => scoutingTemplate.cropInfo.cropHeightUnitGuid
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="template-input-row">
                                    <div className="template-input-item">
                                        <Checkbox
                                            onChange={(e, v) => {
                                                this.setState({
                                                    showCropPopulation: v,
                                                });
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropPopulation: null,
                                                    cropPopulationUnitGuid: null,
                                                });
                                            }}
                                            value={extractValue(() => showCropPopulation)}
                                        />
                                        <NumericInput
                                            disabled={!showCropPopulation}
                                            containerClassNames={["template-input-numeric"]}
                                            scale={0}
                                            precision={38}
                                            placeholderText={formatMessage(messages.cropPopulation)}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropPopulation: v,
                                                })
                                            }
                                            value={extractValue(
                                                () => scoutingTemplate.cropInfo.cropPopulation
                                            )}
                                        />
                                    </div>
                                    <div className="template-input-item no-toggle">
                                        <SelectInput
                                            disabled={!showCropPopulation}
                                            options={unitCropPopulation}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    cropPopulationUnitGuid: v,
                                                })
                                            }
                                            placeholderText={formatMessage(
                                                messages.cropPopulationUnit
                                            )}
                                            value={extractValue(
                                                () =>
                                                    scoutingTemplate.cropInfo.cropPopulationUnitGuid
                                            )}
                                        />
                                    </div>
                                </div>

                                <div className="template-input-row">
                                    <div className="template-input-item">
                                        <Checkbox
                                            onChange={(e, v) => {
                                                this.setState({
                                                    showYieldPercentLoss: v,
                                                });
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    yieldPercentLoss: null,
                                                });
                                            }}
                                            value={extractValue(() => showYieldPercentLoss)}
                                        />
                                        <NumericInput
                                            disabled={!showYieldPercentLoss}
                                            containerClassNames={["template-input-numeric"]}
                                            scale={8}
                                            precision={38}
                                            placeholderText={formatMessage(messages.yieldLoss)}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    yieldPercentLoss: v,
                                                })
                                            }
                                            value={extractValue(
                                                () => scoutingTemplate.cropInfo.yieldPercentLoss
                                            )}
                                        />
                                    </div>
                                    <div className="template-input-item">
                                        <Checkbox
                                            onChange={(e, v) => {
                                                this.setState({
                                                    showStandPercentLoss: v,
                                                });
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    standPercentLoss: null,
                                                });
                                            }}
                                            value={extractValue(() => showStandPercentLoss)}
                                        />
                                        <NumericInput
                                            disabled={!showStandPercentLoss}
                                            containerClassNames={["template-input-numeric"]}
                                            scale={8}
                                            precision={38}
                                            placeholderText={formatMessage(messages.standLoss)}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    standPercentLoss: v,
                                                })
                                            }
                                            value={extractValue(
                                                () => scoutingTemplate.cropInfo.standPercentLoss
                                            )}
                                        />
                                    </div>
                                    <div className="template-input-item">
                                        <Checkbox
                                            onChange={(e, v) => {
                                                this.setState({
                                                    showLeafPercentLoss: v,
                                                });
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    leafPercentLoss: null,
                                                });
                                            }}
                                            value={extractValue(() => showLeafPercentLoss)}
                                        />
                                        <NumericInput
                                            disabled={!showLeafPercentLoss}
                                            containerClassNames={["template-input-numeric"]}
                                            scale={8}
                                            precision={38}
                                            placeholderText={formatMessage(messages.leafLoss)}
                                            onChange={(v) =>
                                                this.onUpdateScoutingTemplateCropInfo({
                                                    leafPercentLoss: v,
                                                })
                                            }
                                            value={extractValue(
                                                () => scoutingTemplate.cropInfo.leafPercentLoss
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </Bucket>

                    <Bucket isExpanded={true} className="">
                        <BucketHeader className="">
                            {formatMessage(messages.observationInfo)}
                        </BucketHeader>

                        {!scoutingTemplate.cropGuid ? null : (
                            <div className="main-bucket-content">
                                <ZeroToInfiniteUnsafe
                                    items={observationList}
                                    initialValue={new models.ScoutingTemplateObservation()}
                                    getChildProps={() => null}
                                    onDelete={(e, items) => this.onDelete(e, items)}
                                    addItem={(e, items) => this.addItem(e, items)}
                                    addText={createAddLinkLabelText(
                                        formatMessage,
                                        messages.observation,
                                        { count: 1 }
                                    )}
                                    deleteText={formatMessage(mainMessages.delete)}
                                    formatMessage={formatMessage}
                                >
                                    <EventScoutingTemplateObsItem {...props} />
                                </ZeroToInfiniteUnsafe>
                            </div>
                        )}
                    </Bucket>
                </div>
                {!isLoading ? null : <Loader />}
                {this.renderResetModal()}
            </DialogBox>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    fetchDropdownData: (dropdowns) =>
        dispatch(fetchDropdownData({ ...dropdowns, action: fetchedDropdownData })),
    fetchGrowthStageData: (cropGuid: string) => dispatch(actions.fetchGrowthStageData(cropGuid)),
    fetchGrowthStageObservationData: (request: ObservationDataRequest) =>
        dispatch(actions.fetchGrowthStageObservationData(request)),
    fetchPicklistData: (pickLists) => dispatch(picklistActions.fetchPicklistData(pickLists)),
    fetchScoutingTemplate: (cropGuid: string, cropPurposeGuid: string) =>
        dispatch(actions.fetchScoutingTemplate(cropGuid, cropPurposeGuid)),
    fetchObservationData: () => dispatch(actions.fetchObservationData()),
    fetchUnitData: (payload) => dispatch(unitActions.fetchUnitData(payload)),
    resetScoutingTemplate: () => dispatch(actions.resetScoutingTemplate()),
    saveScoutingTemplate: () => dispatch(actions.saveScoutingTemplate()),
    updateScoutingTemplate: (newProps: Partial<models.ScoutingTemplate>) =>
        dispatch(actions.updateScoutingTemplate(newProps)),
});

const mapStateToProps = (state) => ({
    brandOrgData: eventInfoSelectors.getBrandOrgState(state),
    countData: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_COUNT_UNIT)
    ),
    cropData: eventInfoSelectors.getCropDropdownState(state),
    cropPurpose: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_CROP_PURPOSE)
    ),
    densityData: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_DENSITY_RATING)
    ),
    numericRating: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_NUMERIC_RATING)
    ),
    growthStageData: selectors.getGrowthStageData(state),
    growthStageObservationData: selectors.getGrowthStageObservationData(state),
    scoutingTemplate: selectors.getScoutingTemplate(state),
    isLoading: selectors.getScoutingTemplateLoading(state),
    observationData: selectors.getObservationData(state),
    plantLocationData: picklistSelectors.getPicklistOptionsFromCode(
        state,
        picklistNames.getPickListCode(picklistNames.PICKLIST_LOCATIONON_PLANT)
    ),
    unitCropPopulation: unitSelectors.getUnitPicklistOptionsFromCode(
        state,
        getUnitCode(UNIT_CROP_POPULATION)
    ),
    unitLength: unitSelectors.getUnitPicklistOptionsFromCode(state, getUnitCode(UNIT_LENGTH)),
    varietyHybridData: eventInfoSelectors.getVarietyHybridDropdownState(state),
});

export const EventScoutingTemplateModal = connect<
    Partial<IScoutingTemplateModalProps>,
    Partial<IScoutingTemplateModalProps>,
    Partial<IScoutingTemplateModalProps>
>(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(EventScoutingTemplateModal_));
