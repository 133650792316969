import { connect } from "react-redux";
import PropertiesView from "./properties";
import { getPicklist } from "../../../selectors";
import { getUnit } from "~/admin/selectors";
import {
    PICKLIST_FORMULATION_TYPE,
    PICKLIST_PHYSICAL_STATE,
    getPickListCode,
} from "~/core/picklist/picklist-names";
import { UNIT_DENSITY, getUnitCode } from "~/core/units/unit-names";
const mapStateToProps = (state) => ({
    [PICKLIST_FORMULATION_TYPE]: getPicklist(getPickListCode(PICKLIST_FORMULATION_TYPE), state),
    [PICKLIST_PHYSICAL_STATE]: getPicklist(getPickListCode(PICKLIST_PHYSICAL_STATE), state),
    [UNIT_DENSITY]: getUnit(getUnitCode(UNIT_DENSITY), state),
});

export default connect(mapStateToProps, null)(PropertiesView);
