import { connect } from "react-redux";

import { getTheUserCompanyGuid, getTheUserGuid } from "~/login/selectors";
import { getRequestIds } from "~/hocs/needs/selectors";

import { actions } from "../data";
import { actions as orgLevelActions, selectors as orgLevelSelectors } from "../../org-level/data";
import { actions as accountActions } from "../../account/data";
import { addOwner } from "../data/actions";
import AddEditPanelContainer from "./../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { pickLists, service, dropdowns } from "./../data";

const mapStateToProps = (state) => ({
    duplicateDatabaseIdOrgLevels: orgLevelSelectors.getDuplicateDatabaseIdOrgLevels(state),
    userGuid: getTheUserGuid(state),
    companyGuid: getTheUserCompanyGuid(state),
    requestIds: getRequestIds,
});

const mapDispatchToProps = (dispatch) => ({
    // Action to be dispatched using needs hoc
    clearUrlForRegistration: () => dispatch(accountActions.fetchUrlForRegistrationSuccess("")),
    createOwner: (payload) => actions.createOwner(payload),
    addOwner,
    fetchUrlForRegistration: actions.fetchUrlForRegistrationOwner,
    fetchAccount: accountActions.fetchAccount,
    getOrgLevelWithAgvanceDatabaseId: (payload) =>
        orgLevelActions.getOrgLevelWithAgvanceDatabaseId(payload),
    saveAccount: actions.saveOwnerTelematics,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditPanelContainer(AddEditPanel, { service, pickLists, dropdowns }));
