import { connect } from "react-redux";
import { intlShape } from "react-intl";

import CustomerContainer from "./../../containers/add-edit-panel";
import { withMasked, withApiResult } from "~/hocs";
import Customer from "./customer";
import { dropdowns, service } from "./data";
import * as actions from "./data/actions";
import * as selectors from "./data/selectors";
import {
    getTheUserCompanyGuid,
    getTheUserPersonalityId,
    getTheUserGuid,
    getTheUserEnrollmentRequired,
    currentUserHasRole,
} from "~/login/selectors";
import { getApiErrorCodeList } from "~/core/api/selectors";
import { actions as cdActions } from "~/customer-data";
import { Action } from "redux-act";
import { getUserPreference } from "~/admin/setup/preference/data/selectors";
import { PROPS_ROLE_ENABLE_MYAGDATA } from "~/admin/setup/user-role/data/model";
import { CustomerAPI } from "@ai360/core";

interface ICustomerSetupProps {
    additionalAgvanceCustomerList: any;
    agvanceCustomerAddressList: any[];
    agvanceCustomerList: any[];
    apiErrors: any[];
    closeSlidingPanel: () => void;
    clearAgvanceCustomerList: () => void;
    clearOracleCustomer: () => void;
    communityDataDefault: boolean;
    companyGuid: string;
    createCustomerOracleId: (payload: any) => Action<any>;
    createCustomer: (customer: CustomerAPI.ICustomer) => void;
    customerNameExists: boolean;
    createEquipment: (payload: any) => Action<any>;
    customerName: string;
    customerRecord: any;
    customerSearchIsLoading: boolean;
    enableMyAgData: boolean;
    enrollCustomer: (customerGuid: string) => void;
    enrollmentRequired: boolean;
    equipmentList: any[];
    fetchAgvanceCustomer: (payload: any) => Action<any>;
    fetchMyAgDataRegistration: (payload: string) => void;
    fetchAgvanceCustomerWithNameSearch: (payload: any) => void;
    fetchCustomerEquipmentList: (payload: any) => Action<any>;
    fetchCustomerExists: (payload: any) => Action<any>;
    fetchDropdownData: () => void;
    fetchEnrollmentForm: (payload: any) => void;
    fetchOwnerCommunityData: (payload: any) => Action<any>;
    getAgvanceCustomer: (payload: any) => Action<any>;
    id: any;
    intl: intlShape;
    isAdditionalAgvanceCustomerSearch: boolean;
    isAgvanceCustomerListOutOfBound: boolean;
    needs: (actions: any[]) => void;
    newPrimaryCustomer: any;
    onCloseSlidingPanel: () => void;
    onSaveFailed: () => void;
    onSaveSuccess: () => void;
    oracleCustomer: any;
    personalityId: number;
    removeCustomerRecord: () => void;
    salespersonList: any[];
    sendAuthentication: (payload: any) => Action<any>;
    setBreadcrumbs: () => void;
    setAgvanceCustomerListOutOfBound: (payload: any) => void;
    showCreateCustomerConfDialog: boolean;
    updateCustomer: (payload: CustomerAPI.ICustomer) => Action<any>;
    updateEnrollmentForm: (payload: any) => void;
    unenrollCustomer: (fieldGuids: string) => void;
    userGuid: string;
    userRole: any;
}

const mapStateToProps = (state: any): Partial<ICustomerSetupProps> => ({
    apiErrors: getApiErrorCodeList(state),
    agvanceCustomerList: selectors.getAgvanceCustomers(state),
    customerNameExists: selectors.getCustomerNameExists(state),
    communityDataDefault: selectors.getCommunityDataDefault(state),
    enrollmentRequired: getTheUserEnrollmentRequired(state),
    newPrimaryCustomer: selectors.getNewPrimaryCustomer(state),
    companyGuid: getTheUserCompanyGuid(state),
    customerRecord: selectors.getCustomerRecord(state),
    equipmentList: selectors.getCustomerEquipmentList(state),
    personalityId: getTheUserPersonalityId(state),
    userGuid: getTheUserGuid(state),
    salespersonList: selectors.getSalespersonList(state),
    oracleCustomer: selectors.getOracleCustomer(state),
    isAgvanceCustomerListOutOfBound: selectors.getAgvanceCustomerListOutOfBound(state),
    additionalAgvanceCustomerList: selectors.getAdditionalAgvanceCustomerList(state),
    customerSearchIsLoading: selectors.getCustomerSearchIsLoading(state),
    isAdditionalAgvanceCustomerSearch: selectors.getAdditionalAgvanceCustomerSearchFlag(state),
    enableMyAgData:
        (getUserPreference(state)?.enableMyAgData ?? false) &&
        currentUserHasRole(state, PROPS_ROLE_ENABLE_MYAGDATA),
});

const mapDispatchToProps = (dispatch: (action: any) => void): Partial<ICustomerSetupProps> => ({
    enrollCustomer: (customerGuid) => dispatch(cdActions.enrollCustomer(customerGuid)),
    unenrollCustomer: (fieldGuids) => dispatch(cdActions.unenrollCustomer(fieldGuids)),
    createCustomer: (payload) => actions.createCustomer(payload),
    createCustomerOracleId: (payload) => actions.createCustomerOracleId(payload),
    updateCustomer: (payload) => actions.updateCustomer(payload),
    updateEnrollmentForm: (payload) => dispatch(actions.updateEnrollmentForm(payload)),
    removeCustomerRecord: () => dispatch(actions.removeCustomerRecord()),
    createEquipment: (payload) => actions.createEquipment(payload),
    getAgvanceCustomer: (payload) => actions.getAgvanceCustomer(payload),
    fetchMyAgDataRegistration: (payload) => dispatch(actions.fetchMyAgDataRegistration(payload)),
    fetchCustomerEquipmentList: (payload) => actions.fetchCustomerEquipmentList(payload),
    fetchCustomerExists: (payload) => actions.fetchCustomerExists(payload),
    fetchOwnerCommunityData: (payload) => actions.fetchOwnerCommunityData(payload),
    fetchAgvanceCustomer: (payload) => actions.fetchAgvanceCustomer(payload),
    fetchEnrollmentForm: (payload) => dispatch(actions.fetchEnrollmentForm(payload)),
    clearAgvanceCustomerList: () => dispatch(actions.clearAgvanceCustomerList()),
    clearOracleCustomer: () => dispatch(actions.setOracleCustomer(null)),
    sendAuthentication: (payload) => actions.sendAuthentication(payload),
    fetchAgvanceCustomerWithNameSearch: (payload) =>
        dispatch(actions.fetchAgvanceCustomerWithNameSearch(payload)),
    setAgvanceCustomerListOutOfBound: (payload) =>
        dispatch(actions.setAgvanceCustomerListOutOfBound(payload)),
});

const advancedCustomerContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerContainer(Customer, { dropdowns, service }));

export default withMasked(withApiResult(advancedCustomerContainer));
