import React, { Component } from "react";
import { injectIntl, InjectedIntlProps } from "react-intl";
import { service, actions, model } from "./data";
import { preventBubbleUp } from "~/admin/utils";
import { withMasked, withApiResult, withCrud } from "~/hocs";
// Components
import AddEditPanel from "./add-edit/add-edit-container";
import ActiveInactiveLink from "../component/active-inactive-link";
import { DataTable, Button } from "~/core";
import HierarchyFilter from "../component/hierarchy-filter";
import { messages } from "../i18n-messages";
import { defaultRequestFilters, REQUEST_PAYLOAD_FILTER } from "./data/service";
import SlidingPanel from "~/sliding-panel/sliding-panel";
import { IAgBytesProps } from "~/admin/agBytes/interfaces";
import { IVendorProps, IVendorState } from "./interfaces";

export class Vendor_ extends Component<
    IAgBytesProps<typeof actions, typeof AddEditPanel> & IVendorProps & InjectedIntlProps,
    IVendorState
> {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isCreate: false,
            searchValue: "",
            personAddMode: false,
        };
    }
    onToggleModalClick = () => {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    };

    toggleActiveInactive = () => {
        this.setState(
            {
                showInactive: !this.state.showInactive,
            },
            () => {
                this.props.toggleActiveInactive();
            }
        );
    };

    deleteSelected = (options) => {
        let { selectedItems } = options;
        selectedItems = {
            vendorGuid: selectedItems[0],
        };
        options = {
            ...options,
            selectedItems,
            deleteMessage: "vendorDeleteMessage",
        };
        this.props.deleteSelected(options);
    };

    onFilterChange = (data) => {
        const { needs, actions } = this.props;
        const requestOptions = {
            ...defaultRequestFilters,
            [REQUEST_PAYLOAD_FILTER]: {
                ...defaultRequestFilters[REQUEST_PAYLOAD_FILTER],
                [model.PROPS_ORG_LEVEL_GUID]: data ? data.orgLevelGuid : null,
            },
        };
        needs([actions.fetch(requestOptions)]);
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.records !== this.props.records) {
            this.setState({
                records: nextProps.records,
            });
        }
    }

    render() {
        const { showAddEditPanel } = this.props.addEditPanel;
        let slidingPanelProps = { ...this.props };
        if (showAddEditPanel) {
            slidingPanelProps = {
                ...slidingPanelProps,
                ...this.props.addEditPanel,
            };
        }
        return (
            <div className="content-table-container">
                <div className="filter-header-container">
                    <div className="filter-container">
                        <HierarchyFilter
                            needs={this.props.needs}
                            onFilterChange={this.onFilterChange}
                        />
                    </div>
                    {!this.props.userRole[model.PROPS_ACTIVE_INACTIVE] ? null : (
                        <div className="active-inactive-container">
                            <ActiveInactiveLink toggleActiveInactive={this.toggleActiveInactive} />
                        </div>
                    )}
                </div>
                <DataTable
                    isEditable
                    isCheckbox={false}
                    service={service}
                    messages={messages}
                    {...this.props}
                    records={this.props.records}
                    deleteSelected={this.deleteSelected}
                />
                {!showAddEditPanel ? null : (
                    <form onSubmit={(event) => preventBubbleUp(event)}>
                        <SlidingPanel
                            {...slidingPanelProps}
                            close={this.props.closeSidePanel}
                            component={AddEditPanel}
                            navigateTo={{
                                parentNameCode: "101",
                                childNameCode: "122",
                            }}
                        >
                            <Button type="save" forceSubmit onClick={() => this.props.onSubmit()} />
                            <Button
                                type="cancel"
                                className="vendor-cancel"
                                onClick={() => {
                                    this.props.closeSidePanel();
                                    this.setState({ personAddMode: false });
                                }}
                            />
                        </SlidingPanel>
                    </form>
                )}
            </div>
        );
    }
}
export const Vendor = injectIntl(withMasked(withApiResult(withCrud(Vendor_, service, actions))));
