import { defineMessages } from "react-intl";

export const messages = defineMessages({
    analysisEventsPlaceholderText: {
        id: "layerModule.analysisInfo.eventsPlaceholderText",
        defaultMessage: "Yield Events",
    },
    analysisFormLabelText: {
        id: "layerModule.analysisInfo.formSeedStrongLabelText",
        defaultMessage: "General Information",
    },
    analysisFormSideBarText: {
        id: "layerModule.analysisInfo.formSeedStrongSideBarText",
        defaultMessage: "Analysis",
    },
    analysisNamePlaceholderText: {
        id: "layerModule.analysisInfo.nameSeedStrongPlaceholderText",
        defaultMessage: "Analysis Layer Name",
    },
    analysisNumClassesPlaceholderText: {
        id: "layerModule.analysisInfo.numClassesPlaceholderText",
        defaultMessage: "# of Classes",
    },
    analysisSymbologyInfoPlaceholderText: {
        id: "layerModule.analysisInfo.symbologyInfoPlaceholderText",
        defaultMessage: "Symbology Info",
    },
    analysisMethodPlaceholderText: {
        id: "layerModule.analysisInfo.analysisMethodPlaceholderText",
        defaultMessage: "Layer name is required!",
    },
    analysisMethodNoYieldLayers: {
        id: "layerModule.analysisInfo.analysisMethodNoYieldLayers",
        defaultMessage: "No Yield data exists for the selected area.",
    },
    analysisManagementAreaTypeNotSelected: {
        id: "layerModule.analysisInfo.analysisManagementAreaTypeNotSelected",
        defaultMessage: "Missing Management Area Type.",
    },
    analysisManagementAreaDetailsMissing: {
        id: "layerModule.analysisInfo.analysisManagementAreaDetailsMissing",
        defaultMessage: "At least one detail field must be filled out for a Management Area.",
    },
    analysisDuplicateName: {
        id: "layerModule.analysisInfo.analysisDuplicateName",
        defaultMessage: "Cannot have duplicate layer names.",
    },
    analysisLayerCalculatedArea: {
        id: "layerModule.analysisInfo.analysisLayerCalculatedArea",
        defaultMessage: "{calculatedArea} {calculatedArea, plural, one {Acre} other {Acres}}",
    },
    analysisLayerCompleted: {
        id: "layerModule.analysis.analysisLayerCompleted",
        defaultMessage: "Completed",
    },
    analysisLayerCreated: {
        id: "layerModule.analysis.analysisLayerCreated",
        defaultMessage: "Analysis Layer Finished.",
    },
    analysisLayerError: {
        id: "layerModule.analysis.analysisLayerError",
        defaultMessage: "One or more Analysis Layers failed.",
    },
    analysisLayerFailed: {
        id: "layerModule.analysis.analysisLayerFailed",
        defaultMessage: "Failed",
    },
    analysisLayerProcessing: {
        id: "layerModule.analysis.analysisLayerProcessing",
        defaultMessage: "Processing",
    },
    analysisLayerBatchingSummary: {
        id: "layerModule.analysis.analysisLayerBatchingSummary",
        defaultMessage: "Batching {count} Analysis Layers",
    },
    attribute: {
        id: "layerModule.modal.attribute",
        defaultMessage: "Attribute",
    },
    allFieldsLocked: {
        id: "layerList.allFieldsLocked",
        defaultMessage: "All selected Fields are not Enrolled",
    },
    batchAnalysisRequiresOneZonePerField: {
        id: "layerList.batchAnalysisRequiresOneZonePerField",
        defaultMessage: "Batch analysis layers require exactly one zone per field.",
    },
    batchImagerySetupSummary: {
        id: "layerModule.analysis.batchImagerySetupSummary",
        defaultMessage: "Batching {count} Imagery Setups",
    },
    benchmarkYield: {
        id: "layerModule.analysis.benchmarkYield",
        defaultMessage: "Benchmark Yield",
    },
    cadastralLayers: {
        id: "layerModule.tabs.cadastralLayers",
        defaultMessage: "Base Data",
    },
    cancel: { id: "layerModule.cancel", defaultMessage: "Cancel" },
    classification: { id: "layerModule.classification", defaultMessage: "Classification" },
    classificationMethods: {
        id: "layerModule.modal.classificationMethods",
        defaultMessage: "Classification Methods",
    },
    colorRamp: {
        id: "layerModule.modal.colorRamp",
        defaultMessage: "Color Ramp",
    },
    colorScheme: {
        id: "layerModule.modal.colorScheme",
        defaultMessage: "Color Scheme",
    },
    crops: {
        id: "layerModule.crops",
        defaultMessage: "Crops",
    },
    deleteMultipleBody: {
        id: "layerModule.deleteMultipleBody",
        defaultMessage:
            "The analysis layer {analysisLayerName} will be deleted from the following fields. Alternatively, deleting only the single analysis layer will delete it from the field {fieldName}.",
    },
    confirmDeleteMsg: {
        id: "layerModule.confirmDeleteMsg",
        defaultMessage: "Are you sure you want to delete?",
    },
    confirmDeleteTitle: {
        id: "layerModule.confirmDeleteTitle",
        defaultMessage: "Confirm",
    },
    createApplicationEqRec: {
        id: "layerModule.contextMenu.createApplicationEqRec",
        defaultMessage: "Application (Equation)",
    },
    createApplicationManRec: {
        id: "layerModule.contextMenu.createApplicationManRec",
        defaultMessage: "Application (Manual)",
    },
    createEventUsingLayer: {
        id: "layerModule.contextMenu.createEventUsingLayer",
        defaultMessage: "Create Event Using Layer",
    },
    createPlantingRec: {
        id: "layerModule.contextMenu.createPlantingRec",
        defaultMessage: "Planting (Manual)",
    },
    createRecUsingLayer: {
        id: "layerModule.contextMenu.createRecUsingLayer",
        defaultMessage: "Create Rec Using Layer",
    },
    customerName: {
        id: "layerModule.dialogs.customerName",
        defaultMessage: "Customer Name",
    },
    deleteAllButton: {
        id: "layerModule.deleteAllButton",
        defaultMessage: "Delete All",
    },
    deleteAllAnalysisLayersErrorStillProcessing: {
        id: "layerModule.deleteAllAnalysisLayersErrorStillProcessing",
        defaultMessage: "Delete All failed. One or more layers still processing.",
    },
    deleteAnalysisLayer: {
        id: "layerModule.contextMenu.deleteAnalysisLayerProperties",
        defaultMessage: "Delete Analysis Layer",
    },
    deleteImageryLayer: {
        id: "layerModule.contextMenu.deleteImageryLayerProperties",
        defaultMessage: "Delete Layer",
    },
    deleteImagerySetup: {
        id: "layerModule.contextMenu.deleteImagerySetup",
        defaultMessage: "Delete Imagery Setup",
    },
    deleteSingleButton: {
        id: "layerModule.deleteSingleSingle",
        defaultMessage: "Delete Single",
    },
    dggHelper: {
        id: "layerModule.dggHelper",
        defaultMessage: "Press & Hold Ctrl for Stats Tool",
    },
    displayType: {
        id: "layerModule.modal.displayType",
        defaultMessage: "Display Type",
    },
    editLayerProperties: {
        id: "layerModule.contextMenu.editLayerProperties",
        defaultMessage: "Edit Layer Properties",
    },
    editAnalysisLayer: {
        id: "layerModule.contextMenu.editAnalysisLayerProperties",
        defaultMessage: "Edit Analysis Layer",
    },
    editImageryLayer: {
        id: "layerModule.contextMenu.editImageryLayerProperties",
        defaultMessage: "Edit Layer",
    },
    editImagerySetup: {
        id: "layerModule.contextMenu.editImagerySetup",
        defaultMessage: "Edit Imagery Setup",
    },
    endMonthPlaceholder: {
        id: "layerModules.analysisInfo.endMonth",
        defaultMessage: "End Month",
    },
    failedToFetchLayerStats: {
        id: "layerModule.error.failedToFetchLayerStats",
        defaultMessage: "Failed to fetch Layer Stats.",
    },
    failedToResetToDefault: {
        id: "layerModule.error.failedToResetToDefault",
        defaultMessage: "Failed to recover default values.",
    },
    failedToUpdateSurface: {
        id: "layerModule.error.failedToUpdateSurface",
        defaultMessage:
            "Server was unable to update {layerName} ({surfaceName}) to use a {numberOfClasses}" +
            " class {classificationMethod} classification with a {colorRamp} color ramp.",
    },
    failedToUpdateSurfaceAlt: {
        id: "layerModule.error.failedToUpdateSurfaceAlt",
        defaultMessage:
            "Server was unable to update {layerName} ({surfaceName}) to use specified color scheme.",
    },
    farmName: {
        id: "layerModule.dialogs.farmName",
        defaultMessage: "Farm Name",
    },
    fieldName: {
        id: "layerModule.dialogs.fieldName",
        defaultMessage: "Field Name",
    },
    fieldArea: {
        id: "layerModule.dialogs.fieldArea",
        defaultMessage: "Area (acres)",
    },
    invisible: {
        id: "layerModule.layerNotVisible",
        defaultMessage: "Layer not visible at current map extent.",
    },
    imagerySetupNamePlaceholder: {
        id: "layerModule.analysisInfo.imagerySetupNamePlaceholder",
        defaultMessage: "Imagery Setup Name",
    },
    imageryOptions: {
        id: "layerModule.analysisInfo.imageryOptions",
        defaultMessage: "Imagery Options",
    },
    imagerySidebar: {
        id: "layerModule.analysisInfo.formImagerySidebar",
        defaultMessage: "Imagery",
    },
    imagerySetupError: {
        id: "layerModule.analysisInfo.imagerySetupError",
        defaultMessage: "Error creating imagery surfaces, please edit layer",
    },
    imagerySetupUnavailable: {
        id: "layerModule.analysisInfo.imageryUnavailable",
        defaultMessage: "Please adjust date range or type",
    },
    labelsEnabled: {
        id: "layerModule.labelsEnabled",
        defaultMessage: "Labels enabled.",
    },
    layerPreferences: {
        id: "layerModule.layerPreferences",
        defaultMessage: "Layer Preferences",
    },
    layerProperties: {
        id: "layerModule.layerProperties",
        defaultMessage: "Layer Properties",
    },
    layerStats: {
        id: "layerModule.contextMenu.layerStats",
        defaultMessage: "Layer Stats",
    },
    layerStatsDialogAverage: {
        id: "layerModule.layerStatsDialog.average",
        defaultMessage: "Average:",
    },
    layerStatsDialogAvg: {
        id: "layerModule.layerStatsDialog.avg",
        defaultMessage: "Avg:",
    },
    layerStatsDialogCoverageArea: {
        id: "layerModule.layerStatsDialog.coverageArea",
        defaultMessage: "Coverage Area:",
    },
    layerStatsDialogHarvestedArea: {
        id: "layerModule.layerStatsDialog.harvestedArea",
        defaultMessage: "Harvested Area:",
    },
    layerStatsDialogMax: {
        id: "layerModule.layerStatsDialog.max",
        defaultMessage: "Max:",
    },
    layerStatsDialogMin: {
        id: "layerModule.layerStatsDialog.min",
        defaultMessage: "Min:",
    },
    layerStatsDialogTotal: {
        id: "layerModule.layerStatsDialog.total",
        defaultMessage: "Total:",
    },
    layerTypeAnalysis: {
        id: "layerModule.layerTypeAnalysis",
        defaultMessage: "A",
    },
    layerTypeEvent: { id: "layerModule.layerTypeEvent", defaultMessage: "E" },
    layerTypeImagery: {
        id: "layerModule.layerTypeImagery",
        defaultMessage: "L",
    },
    layerTypeRec: { id: "layerModule.layerTypeRec", defaultMessage: "R" },
    layerTypeSoil: { id: "layerModule.layerTypeSoil", defaultMessage: "S" },
    managementAreaCrop: {
        id: "layerModule.managementAreaCrop",
        defaultMessage: "Crop",
    },
    managementAreaCrop2: {
        id: "layerModule.managementAreaCrop2",
        defaultMessage: "Crop 2",
    },
    managementAreaCropPurpose: {
        id: "layerModule.managementAreaCropPurpose",
        defaultMessage: "Crop Purpose",
    },
    managementAreaCropPurpose2: {
        id: "layerModule.managementAreaCropPurpose2",
        defaultMessage: "Crop Purpose 2",
    },
    managementAreaIrrigationType: {
        id: "layerModule.managementAreaIrrigationType",
        defaultMessage: "Irrigation Type",
    },
    maximumPercentCloudsAllowedPlaceholder: {
        id: "layerModule.analysisInfo.maximumPercentCloudsAllowedPlaceholder",
        defaultMessage: "Maximum % Clouds Allowed",
    },
    nonFieldDataTab: {
        id: "layerModule.nonFieldDataTab",
        defaultMessage: "Non-Field Data",
    },
    notesPlaceholderText: {
        id: "layerModule.notesPlaceholderText",
        defaultMessage: "Notes",
    },
    numberOfClasses: {
        id: "layerModule.modal.numberOfClasses",
        defaultMessage: "# of Classes",
    },
    printLayer: {
        id: "layerModule.layer.printLayer",
        defaultMessage: "Print Layer",
    },
    printLayerDispatched: {
        id: "layerModule.layer.printLayerDispatched",
        defaultMessage: "Print Layer Request Dispatched for {layer}",
    },
    operationalLayers: {
        id: "layerModule.tabs.operationalLayers",
        defaultMessage: "Field Data",
    },
    resetToDefault: {
        id: "layerModule.tabs.resetToDefault",
        defaultMessage: "Reset to default",
    },
    sampleNamePlaceholder: {
        id: "layerModule.sampleName",
        defaultMessage: "Event Name",
    },
    sampleSites: {
        id: "layerModule.sampleSites",
        defaultMessage: "Sample Sites",
    },
    showLabels: {
        id: "layerModule.dialogs.showLabels",
        defaultMessage: "Show Labels",
    },
    soilTypeAttribute: {
        id: "layerModule.modal.soilTypeAttribute",
        defaultMessage: "Soil Type Attribute",
    },
    splitScreenCompareTool: {
        id: "layerModule.modal.splitScreenCompareTool",
        defaultMessage: "Split Screen Comparison Tool",
    },
    splitScreenLayerLbl: {
        id: "layerModule.modal.splitScreenLayerLbl",
        defaultMessage: "Layer",
    },
    splitScreenPrintDispatched: {
        id: "layerModule.layer.splitScreenPrintDispatched",
        defaultMessage: "Split Screen Comparison Print Request Dispatched for {title}",
    },
    splitScreenSeasonLbl: {
        id: "layerModule.modal.splitScreenSeasonLbl",
        defaultMessage: "Season",
    },
    splitScreenSurfaceLbl: {
        id: "layerModule.modal.splitScreenSurfaceLbl",
        defaultMessage: "Surface",
    },
    standardizedLegend: {
        id: "layerModule.standardizedLegend",
        defaultMessage: "Standardized Legend",
    },
    surfaceIsCorrupt: {
        id: "layerModule.surfaceIsCorrupt",
        defaultMessage: "Layer is corrupt",
    },
    symbolsFilteredOut: {
        id: "layerModule.standard.symbolsFilteredOut",
        defaultMessage: "Filtered Out",
    },
    symbolsFocused: {
        id: "layerModule.standard.symbolsFocused",
        defaultMessage: "Focused",
    },
    symbolsNonFieldFeatures: {
        id: "layerModule.standard.symbolsNonFieldFeatures",
        defaultMessage: "Non-Field Features",
    },
    symbolsSelected: {
        id: "layerModule.standard.symbolsSelected",
        defaultMessage: "Selected",
    },
    ecDataSidebar: {
        id: "layerModule.analysisInfo.ecDataSidebar",
        defaultMessage: "Analysis",
    },
    ecDataGeneralInformation: {
        id: "layerModule.analysisInfo.ecDataGeneralInformation",
        defaultMessage: "General Information",
    },
    ecDataNamePlaceholder: {
        id: "layerModule.analysisInfo.ecDataNamePlaceholder",
        defaultMessage: "Analysis Layer Name",
    },
    ecDataUnknownClassificationMethod: {
        id: "layerModule.analysisInfo.ecDataUnknownClassificationMethod",
        defaultMessage: "Unknown classification method.",
    },
    ecDataMissingSurface: {
        id: "layerModule.analysisInfo.ecDataMissingSurface",
        defaultMessage: "Missing surface.",
    },
    ecDataSymbologyInfo: {
        id: "layerModule.analysisInfo.ecDataSymbologyInfo",
        defaultMessage: "Symbology Info",
    },
    ecDataAttributePlaceholder: {
        id: "layerModule.analysisInfo.ecDataAttributePlaceholder",
        defaultMessage: "Attribute",
    },
    ecDataNumberOfClassesPlaceholder: {
        id: "layerModule.analysisInfo.ecDataNumberOfClassesPlaceholder",
        defaultMessage: "# of Classes",
    },
    ecDataColorRampPlaceholder: {
        id: "layerModule.analysisInfo.ecDataColorRampPlaceholder",
        defaultMessage: "Color Ramp",
    },
    ecDataClassificationMethodsPlaceholder: {
        id: "layerModule.analysisInfo.ecDataClassificationMethodsPlaceholder",
        defaultMessage: "Classification Methods",
    },
    profitLossNoYieldLayers: {
        id: "layerModule.analysisInfo.analysisMethodProfitLossNoYieldLayers",
        defaultMessage: "No Yield data exists for the selected Crop and Season.",
    },
    profitLossSidebar: {
        id: "layerModule.analysisInfo.formProfitLossSidebar",
        defaultMessage: "Analysis",
    },
    profitLossGeneralInformation: {
        id: "layerModule.analysisInfo.formProfitLossGeneralInformation",
        defaultMessage: "General Information",
    },
    profitLossNamePlaceholder: {
        id: "layerModule.analysisInfo.formProfitLossNamePlaceholder",
        defaultMessage: "Analysis Layer Name",
    },
    profitLossCroppingSeasonPlaceholder: {
        id: "layerModule.analysisInfo.formProfitLossCroppingSeasonPlaceholder",
        defaultMessage: "Season",
    },
    profitLossCropDetail: {
        id: "layerModule.analysisInfo.formProfitLossCropDetail",
        defaultMessage: "Crop Detail",
    },
    profitLossCropPlaceholder: {
        id: "layerModule.analysisInfo.formProfitLossCropPlaceholder",
        defaultMessage: "Crop",
    },
    profitLossSellingPricePlaceholder: {
        id: "layerModule.analysisInfo.formProfitLossSellingPricePlaceholder",
        defaultMessage: "Selling Price ($)",
    },
    profitLossCostPerAcreOption: {
        id: "layerModule.analysisInfo.formProfitLossCostPerAcreOption",
        defaultMessage: "Cost/Acre",
    },
    profitLossBreakEvenYieldOption: {
        id: "layerModule.analysisInfo.formProfitLossBreakEvenYieldOption",
        defaultMessage: "Break Even Yield",
    },
    profitLossCostPerAcrePlaceholder: {
        id: "layerModule.analysisInfo.formProfitLossCostPerAcrePlaceholder",
        defaultMessage: "Cost/Acre ($)",
    },
    profitLossBreakEvenYieldPlaceholder: {
        id: "layerModule.analysisInfo.formProfitLossBreakEvenYieldPlaceholder",
        defaultMessage: "Break Even Yield",
    },
    profitLossDetailedPlaceholder: {
        id: "layerModule.analysisInfo.formProfitLossDetailedPlaceholder",
        defaultMessage: "Detailed Cost/Acre ($)",
    },
    profitLossCostTypePlaceholder: {
        id: "layerModule.analysisInfo.profitLossCostTypePlaceholder",
        defaultMessage: "Cost Type",
    },
    profitLossNewDetailedSetup: {
        id: "layerModule.analysisInfo.profitLossNewDetailedSetup",
        defaultMessage: "Setup Detailed Cost",
    },
    profitLossEditDetailedSetup: {
        id: "layerModule.analysisInfo.profitLossEditDetailedSetup",
        defaultMessage: "Edit Detailed Cost",
    },
    managementAreaSidebar: {
        id: "layerModules.analysisInfo.managementAreaSidebar",
        defaultMessage: "Analysis",
    },
    managementAreaGeneralInformation: {
        id: "layerModules.analysisInfo.managementAreaGeneralInformation",
        defaultMessage: "General Information",
    },
    managementAreaNamePlaceholder: {
        id: "layerModules.analysisInfo.managementAreaNamePlaceholder",
        defaultMessage: "Analysis Layer Name",
    },
    managementAreaSeasonPlaceholder: {
        id: "layerModules.analysisInfo.managementAreaSeasonPlaceholder",
        defaultMessage: "Season",
    },
    managementAreaManagementAreaTypePlaceholder: {
        id: "layerModules.analysisInfo.managementAreaManagementAreaTypePlaceholder",
        defaultMessage: "Management Area Type",
    },
    managementAreaDetail: {
        id: "layerModules.analysisInfo.managementAreaDetail",
        defaultMessage: "Management Area Detail",
    },
    managementAreaDetailZoneName: {
        id: "layerModules.analysisInfo.managementAreaDetailZoneName",
        defaultMessage: "Zone {areaId, number}:",
    },
    managementAreaDetailZoneNameEntireField: {
        id: "layerModules.analysisInfo.managementAreaDetailZoneNameEntireField",
        defaultMessage: "Entire Field:",
    },
    managementAreaDetailZoneSize: {
        id: "layerModules.analysisInfo.managementAreaDetailZoneSize",
        defaultMessage: "{calculatedArea, number} Acres",
    },
    managementAreaNoZoneSelected: {
        id: "layerModules.analysisInfo.managementAreaNoZoneSelected",
        defaultMessage: "There are no zones selected",
    },
    monthPlaceholder: {
        id: "layerModules.analysisInfo.month",
        defaultMessage: "Month",
    },
    harvestEvents: {
        id: "layerModules.analysisInfo.harvestEvents",
        defaultMessage: "Selected Harvest Events:",
    },
    sampleDatePlaceholderText: {
        id: "layerModules.sampleDatePlaceholderText",
        defaultMessage: "Sample Date",
    },
    season: {
        id: "layerModules.analysisInfo.season",
        defaultMessage: "Season:",
    },
    seasons: {
        id: "layerModules.analysisInfo.seasons",
        defaultMessage: "Seasons:",
    },
    startMonthPlaceholder: {
        id: "layerModules.analysisInfo.startMonth",
        defaultMessage: "Start Month",
    },
});
