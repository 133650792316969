import { connect } from "react-redux";
import AddEditPanelContainer from "../../../containers/add-edit-panel";
import AddEditPanel from "./add-edit-panel";
import { pickLists, unitTypes, service, dropdowns, model } from "../data";
import { getDropdowns } from "~/admin/selectors";

const mapStateToProps = (state) => ({
    ...getDropdowns([model.PRODUCT_TYPE], state),
});

export default connect(
    mapStateToProps,
    null
)(
    AddEditPanelContainer(AddEditPanel, {
        pickLists,
        service,
        dropdowns,
        unitTypes,
    })
);
