import { ICrop } from "@ai360/core/dist/4x/es/api/field/field";
import { IFileNameFormat, IReportType, IReportTypePage, IYieldByCategory } from "./interfaces";
import { action } from "typesafe-actions";

export const REPORTS_GET_CROP_DROPDOWN_LIST = "reports/GET_CROP_DROPDOWN_LIST";
export const REPORTS_GET_CROP_DROPDOWN_SUCCESS = "reports/GET_CROP_DROPDOWN_SUCCESS";
export const REPORTS_GET_CROP_DROPDOWN_FAILED = "reports/GET_CROP_DROPDOWN_FAILED";
export const REPORTS_GET_FILE_NAME_FORMAT_LIST = "reports/GET_FILE_NAME_FORMAT_LIST";
export const REPORTS_GET_FILE_NAME_FORMAT_SUCCESS = "reports/GET_FILE_NAME_FORMAT_SUCCESS";
export const REPORTS_GET_FILE_NAME_FORMAT_FAILED = "reports/GET_FILE_NAME_FORMAT_FAILED";
export const REPORTS_GET_REPORT_TYPE_LIST = "reports/REPORTS_GET_REPORT_TYPE_LIST";
export const REPORTS_GET_REPORT_TYPE_SUCCESS = "reports/REPORTS_GET_REPORT_TYPE_SUCCESS";
export const REPORTS_GET_REPORT_TYPE_FAILED = "reports/REPORTS_GET_REPORT_TYPE_FAILED";
export const REPORTS_GET_USER_FILE_FORMAT_PREF = "reports/REPORTS_GET_USER_FILE_FORMAT_PREF";
export const REPORTS_GET_USER_FILE_FORMAT_SUCCESS = "reports/REPORTS_GET_USER_FILE_FORMAT_SUCCESS";
export const REPORTS_GET_USER_FILE_FORMAT_FAILED = "reports/REPORTS_GET_USER_FILE_FORMAT_FAILED";
export const REPORTS_GET_USER_MULTI_FIELD_REC_PREF =
    "reports/REPORTS_GET_USER_MULTI_FIELD_REC_PREF";
export const REPORTS_GET_USER_MULTI_FIELD_REC_SUCCESS =
    "reports/REPORTS_GET_USER_MULTI_FIELD_REC_SUCCESS";
export const REPORTS_GET_USER_MULTI_FIELD_REC_FAILED =
    "reports/REPORTS_GET_USER_MULTI_FIELD_REC_FAILED";
export const REPORTS_GET_REPORT_TYPE_PAGE_LIST = "reports/REPORTS_GET_REPORT_TYPE_PAGE_LIST";
export const REPORTS_GET_REPORT_TYPE_PAGE_SUCCESS = "reports/REPORTS_GET_REPORT_TYPE_PAGE_SUCCESS";
export const REPORTS_GET_REPORT_TYPE_PAGE_FAILED = "reports/REPORTS_GET_REPORT_TYPE_PAGE_FAILED";
export const REPORTS_GET_REPORT_PREF_DATA = "reports/REPORTS_GET_REPORT_PREF_DATA";
export const REPORTS_GET_REPORT_PREF_SUCCESS = "reports/REPORTS_GET_REPORT_PREF_SUCCESS";
export const REPORTS_GET_REPORT_PREF_FAILED = "reports/REPORTS_GET_REPORT_PREF_FAILED";
export const REPORTS_CREATE_REPORTS = "reports/REPORTS_CREATE_REPORTS";
export const REPORTS_CREATE_REPORT_DATA = "reports/REPORTS_CREATE_REPORT_DATA";
export const REPORTS_CREATE_REPORT_HUB_SUCCESS = "reports/REPORTS_CREATE_REPORT_HUB_SUCCESS";
export const REPORTS_CREATE_REPORT_SUCCESS = "reports/REPORTS_CREATE_REPORT_SUCCESS";
export const REPORTS_CREATE_REPORT_FAILED = "reports/REPORTS_CREATE_REPORT_FAILED";
export const REPORTS_CREATE_SUCCESS = "reports/REPORTS_CREATE_SUCCESS";
export const REPORTS_CREATE_FAILED = "reports/REPORTS_CREATE_FAILED";
export const REPORTS_GET_YIELD_CATEGORY_SUCCESS = "reports/REPORTS_GET_YIELD_CATEGORY_SUCCESS";
export const REPORTS_GET_YIELD_CATEGORY_FAILED = "reports/REPORTS_GET_YIELD_CATEGORY_FAILED";
export const REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_DATA =
    "reports/REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_DATA";
export const REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS =
    "reports/REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS";
export const REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_DATA =
    "reports/REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_DATA";
export const REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS =
    "reports/REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS";
export const REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_FAILED =
    "reports/REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_FAILED";
export const REPORTS_DELETE_SELECTED = "reports/REPORTS_DELETE_SELECTED";
export const REPORTS_DELETE_SUCCESS = "reports/REPORTS_DELETE_SUCCESS";
export const REPORTS_DELETE_FAILED = "reports/REPORTS_DELETE_FAILED";
export const REPORTS_CLEAR_REPORT_HUB_DATA = "reports/REPORTS_CLEAR_REPORT_HUB_DATA";
export const REPORTS_SEND_EMAIL_DATA = "reports/REPORTS_SEND_EMAIL_DATA";
export const REPORTS_SEND_EMAIL_SUCCESS = "reports/REPORTS_SEND_EMAIL_SUCCESS";
export const REPORTS_SEND_EMAIL_FAILED = "reports/REPORTS_SEND_EMAIL_FAILED";
export const REPORTS_DOWNLOAD_DATA = "reports/REPORTS_DOWNLOAD_DATA";
export const REPORTS_DOWNLOAD_SUCCESS = "reports/REPORTS_DOWNLOAD_SUCCESS";
export const REPORTS_DOWNLOAD_FAILED = "reports/REPORTS_DOWNLOAD_FAILED";
export const REPORTS_PRINT_DATA = "reports/REPORTS_PRINT_DATA";
export const REPORTS_PRINT_SUCCESS = "reports/REPORTS_PRINT_SUCCESS";
export const REPORTS_PRINT_FAILED = "reports/REPORTS_PRINT_FAILED";
export const REPORTS_MERGE_DATA = "reports/REPORTS_MERGE_DATA";
export const REPORTS_MERGE_SUCCESS = "reports/REPORTS_MERGE_SUCCESS";
export const REPORTS_MERGE_FAILED = "reports/REPORTS_MERGE_FAILED";
export const REPORTS_SET_FIELDS = "reports/SET_FIELDS";
export const REPORTS_MERGE_REPORT_DATA = "reports/MERGE_REPORT_DATA";
export const REPORTS_RESET_REPORT_DATA = "reports/RESET_REPORT_DATA";
export const REPORTS_SET_REPORT_DATA_VALUE = "reports/SET_REPORT_DATA_VALUE";

// Crop dropdown actions
export const getCropDropdown = (payload: Record<string, any>) =>
    action(REPORTS_GET_CROP_DROPDOWN_LIST, payload);

export const getCropDropdownSuccess = (cropDropdown: ICrop[]) =>
    action(REPORTS_GET_CROP_DROPDOWN_SUCCESS, cropDropdown);

export const getCropDropdownFailed = (payload: Record<string, any>) =>
    action(REPORTS_GET_CROP_DROPDOWN_FAILED, payload);

//Loasheet File Name Format dropdown actions
export const getFileNameFormat = (payload: Record<string, any>) =>
    action(REPORTS_GET_FILE_NAME_FORMAT_LIST, payload);
export const getFileNameFormatSuccess = (fileNameFormatList: IFileNameFormat[]) =>
    action(REPORTS_GET_FILE_NAME_FORMAT_SUCCESS, fileNameFormatList);

export const getFileNameFormatFailed = (payload: Record<string, any>) =>
    action(REPORTS_GET_FILE_NAME_FORMAT_FAILED, payload);

// Report type list actions
export const getReportTypeList = (payload: Record<string, any>) =>
    action(REPORTS_GET_REPORT_TYPE_LIST, payload);
export const getReportTypeSuccess = (typeList: IReportType[]) =>
    action(REPORTS_GET_REPORT_TYPE_SUCCESS, typeList);
export const getReportTypeFailed = (payload: Record<string, any>) =>
    action(REPORTS_GET_REPORT_TYPE_FAILED, payload);

//Loasheet File Name Format actions
export const getUserFileNameFormatPreference = (payload: Record<string, any>) =>
    action(REPORTS_GET_USER_FILE_FORMAT_PREF, payload);
export const getUserFileNameFormatPreferenceSuccess = (defaultFileNameFormat: string) =>
    action(REPORTS_GET_USER_FILE_FORMAT_SUCCESS, defaultFileNameFormat);
export const getUserFileNameFormatPreferenceFailed = (payload: Record<string, any>) =>
    action(REPORTS_GET_USER_FILE_FORMAT_FAILED, payload);

// Report type page list actions
export const getReportTypePageList = (payload: Record<string, any>) =>
    action(REPORTS_GET_REPORT_TYPE_PAGE_LIST, payload);
export const getReportTypePageSuccess = (pageList: IReportTypePage[]) =>
    action(REPORTS_GET_REPORT_TYPE_PAGE_SUCCESS, pageList);
export const getReportTypePageFailed = (payload: Record<string, any>) =>
    action(REPORTS_GET_REPORT_TYPE_PAGE_FAILED, payload);

// Report prefs actions
export const getReportPrefData = () => action(REPORTS_GET_REPORT_PREF_DATA);
export const getReportPrefSuccess = (reportPreferences: Record<string, unknown>) =>
    action(REPORTS_GET_REPORT_PREF_SUCCESS, reportPreferences);
export const getReportPrefFailed = (payload: Record<string, any>) =>
    action(REPORTS_GET_REPORT_PREF_FAILED, payload);

// Delete Report
export const deleteSelectedReports = (payload: Record<string, any>) =>
    action(REPORTS_DELETE_SELECTED, payload);
export const deleteSelectedReportsSuccess = (DEL = true) => action(REPORTS_DELETE_SUCCESS, DEL);
export const deleteSelectedReportsFailed = (payload: Record<string, any>) =>
    action(REPORTS_DELETE_FAILED, payload);

// Create report actions
export const createReport = (payload: Record<string, any>) =>
    action(REPORTS_CREATE_REPORT_DATA, payload);

export const createReports = (payload: Record<string, any>) =>
    action(REPORTS_CREATE_REPORTS, payload);

export const createReportSuccess = (payload: []) => action(REPORTS_CREATE_REPORT_SUCCESS, payload);
export const createReportFailed = (payload: Record<string, any>) =>
    action(REPORTS_CREATE_REPORT_FAILED, payload);

export const createReportsSuccess = (payload: []) => action(REPORTS_CREATE_SUCCESS, payload);
export const createReportsFailed = (payload: []) => action(REPORTS_CREATE_FAILED, payload);

export const createReportHubSuccess = (reportStatus: string[]) =>
    action(REPORTS_CREATE_REPORT_HUB_SUCCESS, reportStatus);
export const clearReportHubData = () => action(REPORTS_CLEAR_REPORT_HUB_DATA);

// Send email actions
export const sendEmail = (payload: Record<string, any>) => action(REPORTS_SEND_EMAIL_DATA, payload);
export const sendEmailSuccess = () => action(REPORTS_SEND_EMAIL_SUCCESS);
export const sendEmailFailed = (payload: Record<string, any>) =>
    action(REPORTS_SEND_EMAIL_FAILED, payload);

// Download report actions
export const downloadReports = (payload: Record<string, any>) =>
    action(REPORTS_DOWNLOAD_DATA, payload);
export const downloadReportsSuccess = () => action(REPORTS_DOWNLOAD_SUCCESS);
export const downloadReportsFailed = (payload: Error) => action(REPORTS_DOWNLOAD_FAILED, payload);

// Download report actions
export const printReports = (payload: Record<string, any>) => action(REPORTS_PRINT_DATA, payload);
export const printReportsSuccess = () => action(REPORTS_PRINT_SUCCESS);
export const printReportsFailed = (payload: Record<string, any>) =>
    action(REPORTS_PRINT_FAILED, payload);

// Merge report actions
export const mergeReports = (payload: Record<string, any>) => action(REPORTS_MERGE_DATA, payload);
export const mergeReportsSuccess = () => action(REPORTS_MERGE_SUCCESS);
export const mergeReportsFailed = (payload: Error) => action(REPORTS_MERGE_FAILED, payload);

export const getYieldByAnalysisCategoryFailed = (payload: Record<string, any>) =>
    action(REPORTS_GET_YIELD_CATEGORY_FAILED, payload);
// Get yield by compare by options actions
export const getYieldByCompareByOption = (payload: Record<string, any>) =>
    action(REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_DATA, payload);
export const getYieldByCompareByOptionSuccess = (yieldByCompareByOptions: IYieldByCategory) =>
    action(REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS, yieldByCompareByOptions);
// Update yield by compare by options actions
export const updateYieldByCompareByOptions = (payload: Record<string, any>) =>
    action(REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_DATA, payload);
export const updateYieldByCompareByOptionsSuccess = (payload: Record<string, any>) =>
    action(REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_SUCCESS, payload);
export const updateYieldByCompareByOptionsFailed = (payload: Record<string, any>) =>
    action(REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_FAILED, payload);
export const setFields = (payload: Record<string, any>) => action(REPORTS_SET_FIELDS, payload);

// Updates report options
export const mergeReportData = (payload: {
    reportType?: string;
    reportData: Record<string, any>;
}) => action(REPORTS_MERGE_REPORT_DATA, payload);
export const resetReportData = () => action(REPORTS_RESET_REPORT_DATA);
export const setReportDataValue = (payload: { key: string; value: any; reportType: string }) =>
    action(REPORTS_SET_REPORT_DATA_VALUE, payload);
