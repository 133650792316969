import { createReducer } from "redux-act";
import {
    fetchVendorEquipmentListSuccess,
    removeVendorRecord,
    getTestingLabVendorListSuccess,
    getTestPackageListSuccess,
} from "./actions";

export const VENDOR_DATA_KEY = "VENDOR_DATA";
const initialState = {
    equipmentList: [],
    testingLabVendors: [],
    testPackages: [],
};

export const vendorReducer = createReducer(
    {
        [fetchVendorEquipmentListSuccess as any]: (state, equipmentList) => ({
            ...state,
            equipmentList,
        }),
        [removeVendorRecord as any]: (state) => ({
            ...state,
            equipmentList: [],
        }),
        [getTestingLabVendorListSuccess as any]: (state, testingLabVendors = []) => ({
            ...state,
            testingLabVendors,
        }),
        [getTestPackageListSuccess as any]: (state, testPackages = []) => ({
            ...state,
            testPackages,
        }),
    },
    initialState
);
