import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { setReportDataValue, mergeReportData } from "~/reports/data/actions";
import { getReportDataByReportType, getReportPref } from "~/reports/data/selectors";
import useReportPreferences from "~/reports/hooks/use-report-preferences";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { ReportOptionsComponent } from ".";
import { Loader } from "~/core";
import { model } from "~/reports/data";
import { NEW_REPORTS } from "../data/constants";

interface IReportOptionsWrapperProps {
    ReportOptions: ReportOptionsComponent<any>;
    hideDefaultOptions?: boolean;
}

function ReportOptionsWrapper({ ReportOptions, hideDefaultOptions }: IReportOptionsWrapperProps) {
    const [reportDataInitialized, setReportDataInitialized] = React.useState(false);
    const dispatch = useAppDispatch();
    const reportData = useAppSelector(getReportDataByReportType(ReportOptions.reportName));
    const legacyPreferences = useAppSelector(getReportPref);
    const { reportPreferences } = useReportPreferences(
        ReportOptions.reportName,
        ReportOptions.reportPreferences
    );

    if (
        ReportOptions.defaultReportOptions === undefined ||
        Object.keys(ReportOptions.defaultReportOptions).length === 0
    ) {
        throw new Error(
            "ReportOptions.defaultReportOptions must be defined and have at least one key"
        );
    }

    // Set default reportData for this report
    useEffect(() => {
        if (
            !reportDataInitialized &&
            reportPreferences &&
            Object.keys(reportData || {}).length === 0
        ) {
            const legacyReportPreference = {};
            if (
                Object.keys(reportPreferences).length == 0 &&
                !NEW_REPORTS.includes(ReportOptions.reportName)
            ) {
                Object.keys(ReportOptions.defaultReportOptions).forEach((key) => {
                    if (legacyPreferences[key] && key !== model.PROPS_REPORT_TYPE_PAGE_SELECTION) {
                        legacyReportPreference[key] = legacyPreferences[key];
                    }
                });
            }
            setReportDataInitialized(true);
            dispatch(
                mergeReportData({
                    reportType: ReportOptions.reportName,
                    reportData: {
                        ...ReportOptions.defaultReportOptions,
                        ...legacyReportPreference,
                        ...reportPreferences,
                    },
                })
            );
        }
    }, [reportData, reportPreferences, reportDataInitialized]);

    if (!reportPreferences || Object.keys(reportData || {}).length === 0) return <Loader />;

    const onInputChange = (key, value) => {
        dispatch(setReportDataValue({ reportType: ReportOptions.reportName, key, value }));
    };

    return (
        <div>
            <ReportOptions
                onInputChange={onInputChange}
                initialPreferences={reportPreferences}
                hideDefaultOptions={hideDefaultOptions}
            />
        </div>
    );
}

ReportOptionsWrapper.propTypes = {
    ReportOptions: PropTypes.elementType.isRequired,
    hideDefaultOptions: PropTypes.bool,
};

export default ReportOptionsWrapper;
