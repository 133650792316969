import { all, call, fork, put, select, takeEvery } from "redux-saga/effects";
import fileSaver from "file-saver";
import {
    DEFAULT_SELECTED_BASEMAP,
    DEFAULT_AGVANCE_CROP_COLOR,
    DEFAULT_LABEL_TYPE,
    DEFAULT_LEGEND_TYPE,
} from "./constants";
import { getTheUserGuid } from "~/login";
import { setApiResult, setApiResults } from "~/core/api/actions";
import { AppHelpers, ReportAPI, UserAPI, FileImportAPI, Request, APIError } from "@ai360/core";

import { actions as notificationActions, MSGTYPE } from "~/notifications";

import { reportTableSagas } from "../components";
import { messages } from "../i18n-messages";

import * as actions from "./actions";
import * as selectors from "./selectors";
import { reportPreferenceKeys, PROPS_REPORT_IMAGE_CONFIG } from "./model";
import { ReportsModels } from "@ai360/core";
import { actions as messagingActions } from "~/messaging";
import { ICrop } from "@ai360/core/dist/4x/es/api/field/field";
import { IFileNameFormat, IReportType, IReportTypePage, IYieldByCategory } from "./interfaces";
import * as cdActions from "~/customer-data/actions";
import * as cdSelectors from "~/customer-data/selectors";
import { getUserPreference } from "~/admin/setup/preference/data/selectors";

function* fetchCropData(action) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: action.payload };
    try {
        const response: ICrop[] = yield call(
            Request.post,
            ReportAPI.GET_CROP_DROPDOWN_URL,
            requestOptions
        );
        if (response) {
            yield put(actions.getCropDropdownSuccess(response));
        }
    } catch (error) {
        yield put(
            notificationActions.apiCallError(
                error,
                actions.getCropDropdown,
                AppHelpers.getFailedActionString(actions.REPORTS_GET_REPORT_TYPE_LIST)
            )
        );
        yield put(actions.getCropDropdownFailed(error));
    }
}

export function* watchFetchCrop() {
    yield takeEvery(actions.REPORTS_GET_CROP_DROPDOWN_LIST, fetchCropData);
}

function* fetchFileNameFormat() {
    const userGuid = yield select(getTheUserGuid);
    try {
        const response = yield call(FileImportAPI.getFileNameFormatList, userGuid);
        if (response) {
            const result: IFileNameFormat[] = response.fileNameFormats.filter(
                (fileNameformat) => !fileNameformat.isMultiProduct
            );
            yield put(actions.getFileNameFormatSuccess(result));
        }
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(
                notificationActions.apiCallError(
                    error,
                    actions.getFileNameFormat,
                    AppHelpers.getFailedActionString(actions.REPORTS_GET_FILE_NAME_FORMAT_LIST)
                )
            );
        }
        yield put(actions.getFileNameFormatFailed(error));
    }
}

export function* watchFetchFileNameFormat() {
    yield takeEvery(actions.REPORTS_GET_FILE_NAME_FORMAT_LIST, fetchFileNameFormat);
}

function* fetchReportTypes(action) {
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: action.payload.model || "" };
    try {
        const response: IReportType[] = yield call(
            Request.post,
            action.payload.url,
            requestOptions
        );
        if (response) {
            if (Array.isArray(response)) {
                yield put(actions.getReportTypeSuccess(response));
            } else {
                yield put(actions.getReportTypeSuccess([]));
            }
        }
    } catch (error) {
        yield put(
            notificationActions.apiCallError(
                error,
                actions.getCropDropdown,
                AppHelpers.getFailedActionString(actions.REPORTS_GET_REPORT_TYPE_LIST)
            )
        );
        yield put(actions.getReportTypeFailed(error));
    }
}

export function* watchFetchReportTypes() {
    yield takeEvery(actions.REPORTS_GET_REPORT_TYPE_LIST, fetchReportTypes);
}

function* fetchReportTypePages(action) {
    const requestOptions = { Model: action.payload.model || "" };
    try {
        const response: IReportTypePage[] = yield call(
            Request.post,
            action.payload.url,
            requestOptions
        );
        if (response) {
            if (Array.isArray(response)) {
                yield put(actions.getReportTypePageSuccess(response));
            } else {
                yield put(actions.getReportTypePageSuccess([]));
            }
        }
    } catch (error) {
        yield put(
            notificationActions.apiCallError(
                error,
                actions.getCropDropdown,
                AppHelpers.getFailedActionString(actions.REPORTS_GET_REPORT_TYPE_PAGE_LIST)
            )
        );
        yield put(actions.getReportTypePageFailed(error));
    }
}

export function* watchFetchReportTypePages() {
    yield takeEvery(actions.REPORTS_GET_REPORT_TYPE_PAGE_LIST, fetchReportTypePages);
}

function* fetchReportPrefData() {
    try {
        const UserGuid = yield select(getTheUserGuid);
        const response = yield call(
            UserAPI.getUserPreferencesByKeys,
            UserGuid,
            Object.values(reportPreferenceKeys)
        );
        if (response) {
            const reportPreferences = Object.keys(reportPreferenceKeys).reduce((a, k) => {
                const value = response.preferences[k];
                switch (reportPreferenceKeys[k]) {
                    case reportPreferenceKeys.boundaryMapReportImageConfig:
                        a[PROPS_REPORT_IMAGE_CONFIG] = value;
                        a[k] = value ? JSON.parse(value) : null;
                        break;
                    case reportPreferenceKeys.breakEvenCropList:
                    case reportPreferenceKeys.fieldLabelConfig:
                    case reportPreferenceKeys.reportTypePageSelection:
                        a[k] = value ? JSON.parse(value) : null;
                        break;
                    case reportPreferenceKeys.boundaryMapQrCode:
                    case reportPreferenceKeys.boundaryMapReportNonFieldFeatures:
                    case reportPreferenceKeys.cropZone:
                    case reportPreferenceKeys.displayParameters:
                    case reportPreferenceKeys.displaySampleSites:
                    case reportPreferenceKeys.generalNotes:
                    case reportPreferenceKeys.includeProductRecap:
                    case reportPreferenceKeys.lowRateLegend:
                    case reportPreferenceKeys.multipleMapsPerPage:
                    case reportPreferenceKeys.nutrientTargetColors:
                    case reportPreferenceKeys.originalFormat:
                    case reportPreferenceKeys.plantingRecCostDetail:
                    case reportPreferenceKeys.plssInfo:
                    case reportPreferenceKeys.printFarmTotals:
                    case reportPreferenceKeys.printFieldTotals:
                    case reportPreferenceKeys.printGrowerTotals:
                    case reportPreferenceKeys.showGuaranteedAnalysis:
                    case reportPreferenceKeys.showLoadsheetMultiFieldRec:
                    case reportPreferenceKeys.showLoadsheetProductDetail:
                    case reportPreferenceKeys.showFarmTotals:
                    case reportPreferenceKeys.showGrowerTotals:
                    case reportPreferenceKeys.showGrowerSummaryCostDetail:
                    case reportPreferenceKeys.showPhotos:
                    case reportPreferenceKeys.showProductRecCostDetail:
                    case reportPreferenceKeys.showProductRecSummaryCostDetail:
                    case reportPreferenceKeys.showRecSummary:
                    case reportPreferenceKeys.useImportDate:
                    case reportPreferenceKeys.showScoutingDetails:
                    case reportPreferenceKeys.showYieldPolygon:
                    case reportPreferenceKeys.useCustomImagery:
                    case reportPreferenceKeys.useCropPlanningSummary:
                    case reportPreferenceKeys.useProductRecs:
                    case reportPreferenceKeys.useOverviewMap:
                        a[k] = value ? value.toLowerCase() === "true" : false;
                        break;
                    case reportPreferenceKeys.selectedBasemap:
                        a[k] = value ? value : DEFAULT_SELECTED_BASEMAP;
                        break;
                    case reportPreferenceKeys.cropMapLabelType:
                        a[k] = value ? value : DEFAULT_LABEL_TYPE;
                        break;
                    case reportPreferenceKeys.cropMapLegendType:
                        a[k] = value ? value : DEFAULT_LEGEND_TYPE;
                        break;
                    case reportPreferenceKeys.agvanceCropColor:
                        a[k] = value ? value : DEFAULT_AGVANCE_CROP_COLOR;
                        break;
                    case reportPreferenceKeys.orientation:
                        a[k] = ReportsModels.PageOrientation[value.toUpperCase()];
                        break;
                    case reportPreferenceKeys.cropMapOrientation:
                        a[k] = ReportsModels.CropMapOrientation[value.toUpperCase()];
                        break;
                    case reportPreferenceKeys.growerSummaryDisplayOption:
                    case reportPreferenceKeys.hyGroundSummaryDisplayOption:
                    case reportPreferenceKeys.agrProfitLossDisplayOption:
                        a[k] = !value ? null : value.toLowerCase();
                        break;
                    case reportPreferenceKeys.applicationAttribute:
                        a[k] = !value ? null : value.toLowerCase();
                        break;
                    default:
                        a[k] = value;
                        break;
                }
                return a;
            }, {});

            yield put(actions.getReportPrefSuccess(reportPreferences));
        }
    } catch (error) {
        yield put(
            notificationActions.apiCallError(
                error,
                actions.getCropDropdown,
                AppHelpers.getFailedActionString(actions.REPORTS_GET_REPORT_PREF_DATA)
            )
        );
        yield put(actions.getReportPrefFailed(error));
    }
}

export function* watchFetchReportPrefData() {
    yield takeEvery(actions.REPORTS_GET_REPORT_PREF_DATA, fetchReportPrefData);
}

const chromelessMessageSubscriptions = function* () {
    const ChromelessReportResult = function* (message) {
        const userPreference = yield select(getUserPreference);
        const reportResult = message;
        if (reportResult.errMsg != null) {
            yield put(
                notificationActions.pushToasterMessage(
                    messages.chromelessReportFailed,
                    MSGTYPE.ERROR,
                    reportResult
                )
            );
            return;
        }
        let resp;
        try {
            resp = yield call(fetch, reportResult.resultUrl);
        } catch (err) {
            yield put(
                notificationActions.pushToasterMessage(
                    messages.chromelessReportFailed,
                    MSGTYPE.ERROR,
                    { ...reportResult, errMsg: err.toString() }
                )
            );
            return;
        }

        if (resp.status !== 200) {
            yield put(
                notificationActions.pushToasterMessage(
                    messages.chromelessReportFailed,
                    MSGTYPE.ERROR,
                    { ...reportResult, errMsg: resp.statusText }
                )
            );
            return;
        }

        resp.blob().then((blob) => fileSaver.saveAs(blob, reportResult.fileName));
        if (userPreference.receiveWebNotificationsForReports) {
            yield put(
                notificationActions.pushToasterMessage(
                    messages.chromelessReportComplete,
                    MSGTYPE.INFO,
                    reportResult
                )
            );
        }
    };

    yield put(
        messagingActions.subscribe(0, {
            eventName: "ChromelessReportResult",
            generator: ChromelessReportResult,
        })
    );
};

export const messageSubscriptions = function* () {
    const reportsCreated = function* (message) {
        const existingReportStatus = yield select(selectors.getReportStatus);
        const receivedReport = message;
        const reportsTobeUpdatedIdx = existingReportStatus.findIndex(
            (item) => item.reportGuid === receivedReport.reportGuid
        );
        if (reportsTobeUpdatedIdx > -1) {
            existingReportStatus.splice(reportsTobeUpdatedIdx, 1, receivedReport);
        } else {
            existingReportStatus.push(receivedReport);
        }
        yield put(actions.createReportHubSuccess([...existingReportStatus]));
    };
    yield put(
        messagingActions.subscribe(0, {
            eventName: "reportsCreated",
            generator: reportsCreated,
        })
    );
    yield put(
        messagingActions.subscribe(0, {
            eventName: "reportInProgress",
            generator: reportsCreated,
        })
    );
};

function* createReport(action) {
    const { model, reportType } = action.payload;
    if (model) {
        const UserGuid = yield select(getTheUserGuid);
        try {
            if (reportType) {
                // New Report interface will have this
                yield ReportAPI.createReport(UserGuid, model, reportType);
            } else {
                // CreateReport is not expecting field classifications to be anything but a list of ints
                model.fieldItemList.forEach(function (item) {
                    item.classifications = [];
                });
                yield call(Request.post, ReportAPI.CREATE_REPORT_URL, { UserGuid, Model: model });
            }
            yield put(actions.createReportSuccess([]));
        } catch (error) {
            if (error instanceof APIError) {
                if (Object.prototype.hasOwnProperty.call(error.apiResultObj, "errors")) {
                    yield put(setApiResult({ newApiResults: error.apiResultObj }));
                } else {
                    yield put(setApiResult(error));
                }
            } else {
                yield put(
                    notificationActions.apiCallError(
                        error,
                        actions.createReport,
                        AppHelpers.getFailedActionString(actions.REPORTS_GET_REPORT_TYPE_LIST)
                    )
                );
            }
            yield put(actions.createReportFailed(error));
        }
    } else {
        yield put(
            actions.createReportFailed({
                error: "No model provided",
            })
        );
    }
}

function* createReports(action) {
    const errorApiObjects = [];

    for (const { model, reportType } of action.payload) {
        if (model) {
            const UserGuid = yield select(getTheUserGuid);
            try {
                if (reportType) {
                    yield ReportAPI.createReport(UserGuid, model, reportType);
                } else {
                    // CreateReport is not expecting field classifications to be anything but a list of ints
                    model.fieldItemList.forEach(function (item) {
                        item.classifications = [];
                    });
                    yield call(Request.post, ReportAPI.CREATE_REPORT_URL, {
                        UserGuid,
                        Model: model,
                    });
                }
            } catch (error) {
                if (error instanceof APIError) {
                    errorApiObjects.push(error);
                } else {
                    yield put(
                        notificationActions.apiCallError(
                            error,
                            actions.createReport,
                            AppHelpers.getFailedActionString(actions.REPORTS_GET_REPORT_TYPE_LIST)
                        )
                    );
                }
            }
        } else {
            yield put(
                actions.createReportFailed({
                    error: "No model provided",
                })
            );
        }
    }
    if (errorApiObjects.length > 0) {
        yield put(setApiResults(errorApiObjects));
        yield put(actions.createReportsFailed(errorApiObjects[0].error));
    } else {
        yield put(actions.createReportsSuccess([]));
    }
}

export function* watchCreateReport() {
    yield takeEvery(actions.REPORTS_CREATE_REPORT_DATA, createReport);
}

export function* watchCreateReports() {
    yield takeEvery(actions.REPORTS_CREATE_REPORTS, createReports);
}

function* deleteReports(action) {
    const { payload } = action;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: payload };
    try {
        yield call(Request.post, ReportAPI.DELETE_REPORT_NAMES_LIST_URL, requestOptions);
        yield put(actions.deleteSelectedReportsSuccess());
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(
                notificationActions.apiCallError(
                    error,
                    actions.deleteSelectedReports,
                    AppHelpers.getFailedActionString(actions.REPORTS_DELETE_SELECTED)
                )
            );
        }
        yield put(actions.deleteSelectedReportsFailed(error));
    }
}

export function* watchDeleteSelectedReport() {
    yield takeEvery(actions.REPORTS_DELETE_SELECTED, deleteReports);
}

function* getUserFileFormatPreference() {
    const userGuid = yield select(getTheUserGuid);
    try {
        const response = yield call(FileImportAPI.getUserExportPreferences, userGuid);
        if (response) {
            yield put(
                actions.getUserFileNameFormatPreferenceSuccess(response.controllerExportFormatGuid)
            );
        }
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(
                notificationActions.apiCallError(
                    error,
                    actions.getUserFileNameFormatPreference,
                    AppHelpers.getFailedActionString(actions.REPORTS_GET_USER_FILE_FORMAT_PREF)
                )
            );
        }
        yield put(actions.getUserFileNameFormatPreferenceFailed(error));
    }
}

export function* watchGetUserFileFormatPreference() {
    yield takeEvery(actions.REPORTS_GET_USER_FILE_FORMAT_PREF, getUserFileFormatPreference);
}

function* getYieldByCompareByOption(action) {
    const model = action.payload || null;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: model };
    try {
        const response: IYieldByCategory = yield call(
            Request.post,
            ReportAPI.GET_YIELD_BY_COMPARE_BY_OPTIONS_LIST_URL,
            requestOptions
        );
        yield put(actions.getYieldByCompareByOptionSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(
                notificationActions.apiCallError(
                    error,
                    actions.getYieldByCompareByOption,
                    AppHelpers.getFailedActionString(
                        actions.REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_DATA
                    )
                )
            );
        }
        yield put(actions.getYieldByAnalysisCategoryFailed(error));
    }
}

export function* watchGetYieldByCompareByOption() {
    yield takeEvery(
        actions.REPORTS_GET_YIELD_BY_COMPARE_BY_OPTIONS_DATA,
        getYieldByCompareByOption
    );
}

function* updateYieldByCompareByOptions(action) {
    const model = action.payload;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: model };
    try {
        const response = yield call(
            Request.post,
            ReportAPI.UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_LIST_URL,
            requestOptions
        );
        yield put(actions.updateYieldByCompareByOptionsSuccess(response));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(
                notificationActions.apiCallError(
                    error,
                    actions.updateYieldByCompareByOptions,
                    AppHelpers.getFailedActionString(
                        actions.REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_DATA
                    )
                )
            );
        }
        yield put(actions.updateYieldByCompareByOptionsFailed(error));
    }
}

export function* watchUpdateYieldByCompareByOptions() {
    yield takeEvery(
        actions.REPORTS_UPDATE_YIELD_BY_COMPARE_BY_OPTIONS_DATA,
        updateYieldByCompareByOptions
    );
}

function* processSendEmailReport(action) {
    const model = action.payload;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: model };
    try {
        yield call(Request.post, ReportAPI.EMAIL_REPORT_LINK_LIST_URL, requestOptions);
        yield put(actions.sendEmailSuccess());
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(
                notificationActions.apiCallError(
                    error,
                    actions.REPORTS_SEND_EMAIL_DATA,
                    AppHelpers.getFailedActionString(actions.REPORTS_SEND_EMAIL_DATA)
                )
            );
        }
        yield put(actions.sendEmailFailed(error));
    }
}

export function* watchSendEmailReport() {
    yield takeEvery(actions.REPORTS_SEND_EMAIL_DATA, processSendEmailReport);
}

function* processPrintReports(action) {
    const model = action.payload;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: model };
    try {
        yield call(Request.post, ReportAPI.PRINT_REPORT_LIST, requestOptions);
        yield put(actions.printReportsSuccess());
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(
                notificationActions.apiCallError(
                    error,
                    actions.REPORTS_PRINT_DATA,
                    AppHelpers.getFailedActionString(actions.REPORTS_PRINT_DATA)
                )
            );
        }
        yield put(actions.printReportsFailed(error));
    }
}

export function* watchPrintReport() {
    yield takeEvery(actions.REPORTS_PRINT_DATA, processPrintReports);
}

function* processDownloadReport(action) {
    const { model } = action.payload;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: model };
    try {
        const response = yield call(
            Request.post,
            ReportAPI.BATCH_DOWNLOAD_FILE_LIST,
            requestOptions
        );
        if (response.fileUrl && response.fileName) {
            const resp = yield call(fetch, response.fileUrl);
            if (resp.status === 200) {
                // Response is OK
                resp.blob().then((blob) => fileSaver.saveAs(blob, response.fileName));
                yield put(actions.downloadReportsSuccess());
            } else {
                yield put(
                    actions.downloadReportsFailed(
                        new Error(`Download Report Response Status: ${resp.status}`)
                    )
                );
            }
        } else {
            yield put(actions.downloadReportsFailed(new Error("Download Report missing file.")));
        }
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(
                notificationActions.apiCallError(
                    error,
                    actions.REPORTS_DOWNLOAD_DATA,
                    AppHelpers.getFailedActionString(actions.REPORTS_DOWNLOAD_DATA)
                )
            );
        }
        yield put(actions.downloadReportsFailed(error));
    }
}

export function* watchDownloadReport() {
    yield takeEvery(actions.REPORTS_DOWNLOAD_DATA, processDownloadReport);
}

function* processMergeReport(action) {
    const { model } = action.payload;
    const UserGuid = yield select(getTheUserGuid);
    const requestOptions = { UserGuid, Model: model };
    try {
        const response = yield call(ReportAPI.mergeReportList, requestOptions);
        if (response.fileUrl && response.fileName) {
            const resp = yield call(fetch, response.fileUrl);
            if (resp.status === 200) {
                // Response is OK
                resp.blob().then((blob) => fileSaver.saveAs(blob, response.fileName));
                yield put(actions.mergeReportsSuccess());
            } else {
                yield put(
                    actions.mergeReportsFailed(
                        new Error(`Merge Report Response Status: ${resp.status}`)
                    )
                );
            }
        } else {
            yield put(actions.mergeReportsFailed(new Error("Merge Report missing file.")));
        }
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(
                notificationActions.apiCallError(
                    error,
                    actions.REPORTS_MERGE_DATA,
                    AppHelpers.getFailedActionString(actions.REPORTS_MERGE_DATA)
                )
            );
        }
        yield put(actions.mergeReportsFailed(error));
    }
}

function* watchMergeReport() {
    yield takeEvery(actions.REPORTS_MERGE_DATA, processMergeReport);
}

function* processSelectedFields() {
    const selectedFieldGuids = yield select(cdSelectors.getSelectedFieldGuids);
    const request: ReportAPI.IFieldRequest = { field: selectedFieldGuids };
    try {
        const response: ReportAPI.IField[] = yield call(ReportAPI.getFields, request);
        yield put(actions.setFields({ fields: response }));
    } catch (error) {
        if (error instanceof APIError) {
            yield put(setApiResult(error));
        } else {
            yield put(notificationActions.apiCallError(error));
        }
    }
}

function* watchSelectedFields() {
    yield takeEvery(
        [
            cdActions.ADD_SELECTED_FIELDS,
            cdActions.CLEAR_ALL_SELECTED_FIELDS,
            cdActions.CLEAR_SELECTED_FIELDS,
            cdActions.MODIFY_CUSTOMERS,
            cdActions.MODIFY_FIELDS,
        ],
        processSelectedFields
    );
}

export function* reportCoreSagas() {
    yield all([
        chromelessMessageSubscriptions(),
        fork(watchFetchCrop),
        fork(watchFetchReportTypes),
        fork(watchFetchFileNameFormat),
        fork(watchFetchReportPrefData),
        fork(watchFetchReportTypePages),
        fork(watchCreateReport),
        fork(watchCreateReports),
        fork(reportTableSagas),
        fork(watchGetUserFileFormatPreference),
        fork(watchGetYieldByCompareByOption),
        fork(watchUpdateYieldByCompareByOptions),
        fork(watchDeleteSelectedReport),
        fork(watchSendEmailReport),
        fork(watchDownloadReport),
        fork(watchMergeReport),
        fork(watchPrintReport),
        fork(watchSelectedFields),
        messageSubscriptions(),
    ]);
}
