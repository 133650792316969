import { ReportAPI } from "@ai360/core";
import { intlShape } from "react-intl";

import AquaLenz from "./aqualenz";
import FieldReferenceList from "./field-reference-list";
import SkyGrowerSummaryPlanting from "./sky-grower-summary-planting";
import SkyHarvestSummary from "./sky-harvest-summary";
import SkyPlantingSummary from "./sky-planting-summary";
import SkyYieldBy from "./sky-yield-by";
import YieldMatrix from "./yield-matrix";

/* Report-specific options components can be added below
 * by adding the ReportName as the key and the component
 * for the report as the component. The ReportOptionsContainer
 * will automatically pick up the component and begin using it.
 */
export default {
    [ReportAPI.ReportNames.AQUALENZ]: AquaLenz,
    [ReportAPI.ReportNames.AGVANCE_SKY_FIELD_REFERENCE_LIST]: FieldReferenceList,
    [ReportAPI.ReportNames.AGVANCE_SKY_GROWER_SUMMARY_PLANTING]: SkyGrowerSummaryPlanting,
    [ReportAPI.ReportNames.AGVANCE_SKY_HARVEST_SUMMARY]: SkyHarvestSummary,
    [ReportAPI.ReportNames.AGVANCE_SKY_PLANTING_SUMMARY]: SkyPlantingSummary,
    [ReportAPI.ReportNames.YIELD_MATRIX_REPORT]: YieldMatrix,
    [ReportAPI.ReportNames.AGVANCE_SKY_YIELD_BY_REPORT]: SkyYieldBy,
} as { [key: string]: ReportOptionsComponent<any> };

export type ReportOptionsComponentProps = {
    hideDefaultOptions?: boolean;
    intl: intlShape;
    onInputChange: (key: string, value: any) => void;
    initialPreferences: string[];
};

export type ReportOptionsComponent<T extends ReportOptionsComponentProps> = React.FC<T> & {
    reportName: string;
    defaultReportOptions: Record<string, any>;
    reportPreferences: string[];
};

export interface IOption {
    label: string;
    value: string;
}
