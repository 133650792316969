// Model
import { SearchAPI } from "@ai360/core";
import { adminData } from "~/admin/data";

export const ACTIVE_YN = "activeYn";
export const ENROLLED_YN = "enrolledYn";
export const ENROLLED_YN_ORIGINAL = "enrolledYnOriginal";
export const PROPS_ADDRESS_TYPE_LIST = "addressTypeList";
export const PROPS_AGVANCE_CUSTOMER_GUID = "agvanceCustomerGuid";
export const PROPS_AGVANCE_CUSTOMER_LIST = "agvanceCustomerList";
export const PROPS_AGVANCE_GROWER_ID = "agvanceGrowerId";
export const PROPS_AGVANCE_GROWER_NAME = "agvanceGrowerName";
export const PROPS_AGVANCE_GUID = "agvanceGuid";
export const PROPS_BREADCRUMB_PARENT_NAME = "customerHeaderOverRideText";
export const PROPS_COUNTRIES_LIST = "countriesList";
export const PROPS_CUSTOMER_ADDRESS_LIST = "customerAddressList";
export const PROPS_CUSTOMER_AGVANCE_CROSS_REF_LIST = "customerAgvanceCrossRefList";
export const PROPS_CUSTOMER_ALIAS_NAME = "customerAliasName";
export const PROPS_CUSTOMER_EQUIPMENT_LIST = "customerEquipmentList";
export const PROPS_CUSTOMER_GUID = SearchAPI.Props.CUSTOMER_GUID;
export const PROPS_CUSTOMER_ID = "cutomerID";
export const PROPS_CUSTOMER_NAME = SearchAPI.Props.CUSTOMER_NAME;
export const PROPS_CUSTOMER_ORG_LEVEL_LIST = "customerOrgLevelList";
export const PROPS_DATA_POOLING = "dataPooling";
export const PROPS_DATA_POOLING_CHANGE = "dataPoolingChange";
export const PROPS_DATA_POOLING_ORIGINAL = "dataPoolingOriginal";
export const PROPS_ENROLLED_AUTO_YN = "enrolledAutoYn";
export const PROPS_ENROLLED_FORM_URL = "enrolledFormUrl";
export const PROPS_ENROLLED_MANUAL_CHANGE = "enrolledManualChange";
export const PROPS_ENROLLED_MODIFIED_BY_NAME = "enrolledModifiedByName";
export const PROPS_ENROLLED_MODIFIED_DATE = "enrolledModifiedDate";
export const PROPS_ENROLLMENT_EMAIL = "enrollmentEmail";
export const PROPS_ENROLLMENT_MOBILE_PHONE = "enrollmentMobilePhone";
export const PROPS_ENROLLMENT_SIGNER_NAME = "enrollmentSignerName";
export const PROPS_MYAGDATA_REGISTRATIONS = "myAgDataRegistrations";
export const PROPS_IS_PRIMARY = "isPrimary";
export const PROPS_NAME = "name";
export const PROPS_NOTES = "notes";
export const PROPS_ORACLE_ID = "oracleId";
export const PROPS_ORG_LEVEL_LIST = adminData.PROPS_ORG_LEVEL_LIST;
export const PROPS_PARTNER_ORG_ID = "partnerOrganizationId";
export const PROPS_SALESPERSON_CUSTOMER_LIST = "salespersonCustomerList";
export const PROPS_STATE_LIST = "stateList";
