import { createAction } from "redux-act";
import { CustomerAPI } from "@ai360/core";

export const FETCH_CUSTOMER = "FETCH_CUSTOMER_DATA";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILED = "FETCH_CUSTOMER_FAILED";

export const CREATE_CUSTOMER = "CREATE_CUSTOMER_DATA";
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS";
export const CREATE_CUSTOMER_FAILED = "CREATE_CUSTOMER_FAILED";

export const FETCH_CUSTOMER_EXISTS = "FETCH_CUSTOMER_EXISTS_DATA";
export const FETCH_CUSTOMER_EXISTS_SUCCESS = "FETCH_CUSTOMER_EXISTS_SUCCESS";
export const FETCH_CUSTOMER_EXISTS_FAILED = "FETCH_CUSTOMER_EXISTS_FAILED";

export const CREATE_CUSTOMER_ORACLE_ID = "CREATE_CUSTOMER_ORACLE_ID_DATA";
export const CREATE_CUSTOMER_ORACLE_ID_SUCCESS = "CREATE_CUSTOMER_ORACLE_ID_SUCCESS";
export const CREATE_CUSTOMER_ORACLE_ID_FAILED = "CREATE_CUSTOMER_ORACLE_ID_FAILED";

export const SET_ORACLE_CUSTOMER = "SET_ORACLE_CUSTOMER";

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER_DATA";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILED = "UPDATE_CUSTOMER_FAILED";

export const REMOVE_CUSTOMER_RECORD = "REMOVE_CUSTOMER_RECORD";

export const ADD_EQUIPMENT = "ADD_EQUIPMENT_DATA";
export const ADD_EQUIPMENT_SUCCESS = "ADD_EQUIPMENT_SUCCESS";
export const ADD_EQUIPMENT_FAILED = "ADD_EQUIPMENT_FAILED";

export const FETCH_CUSTOMER_EQUIPMENT_LIST = "FETCH_CUSTOMER_EQUIPMENT_LIST_DATA";
export const FETCH_CUSTOMER_EQUIPMENT_LIST_SUCCESS = "FETCH_CUSTOMER_EQUIPMENT_LIST_SUCCESS";
export const FETCH_CUSTOMER_EQUIPMENT_LIST_FAILED = "FETCH_CUSTOMER_EQUIPMENT_LIST_FAILED";

export const FETCH_AGVANCE_CUSTOMER = "FETCH_AGVANCE_CUSTOMER_DATA";
export const FETCH_AGVANCE_CUSTOMER_SUCCESS = "FETCH_AGVANCE_CUSTOMER_SUCCESS";
export const FETCH_AGVANCE_CUSTOMER_FAILED = "FETCH_AGVANCE_CUSTOMER_FAILED";

export const FETCH_ENROLLMENT_FORM = "FETCH_ENROLLMENT_FORM";
export const FETCH_ENROLLMENT_FORM_SUCCESS = "FETCH_ENROLLMENT_FORM_SUCCESS";
export const FETCH_ENROLLMENT_FORM_FAILED = "FETCH_ENROLLMENT_FORM_FAILED";

export const FETCH_MYAGDATA_REGISTRATION = "FETCH_MYAGDATA_REGISTRATION";
export const FETCH_MYAGDATA_REGISTRATION_SUCCESS = "FETCH_MYAGDATA_REGISTRATION_SUCCESS";
export const FETCH_MYAGDATA_REGISTRATION_FAILED = "FETCH_MYAGDATA_REGISTRATION_FAILED";

export const FETCH_OWNER_COMMUNITY_DATA = "FETCH_OWNER_COMMUNITY_DATA";
export const FETCH_OWNER_COMMUNITY_DATA_SUCCESS = "FETCH_OWNER_COMMUNITY_SUCCESS";
export const FETCH_OWNER_COMMUNITY_DATA_FAILED = "FETCH_OWNER_COMMUNITY_FAILED";

export const GET_AGVANCE_CUSTOMER = "GET_AGVANCE_CUSTOMER_DATA";
export const GET_AGVANCE_CUSTOMER_SUCCESS = "GET_AGVANCE_CUSTOMER_SUCCESS";
export const GET_AGVANCE_CUSTOMER_FAILURE = "GET_AGVANCE_CUSTOMER_FAILURE";

export const FETCH_SALESPERSON_LIST = "FETCH_SALESPERSON_LIST";
export const FETCH_SALESPERSON_LIST_SUCCESS = "FETCH_SALESPERSON_SUCCESS";
export const FETCH_SALESPERSON_LIST_FAILED = "FETCH_SALESPERSON_FAILED";

export const CLEAR_AGVANCE_CUSTOMER_LIST = "CLEAR_AGVANCE_CUSTOMER_LIST";

export const SEND_AUTHENTICATION = "SEND_AUTHENTICATION_DATA";
export const SEND_AUTHENTICATION_SUCCESS = "SEND_AUTHENTICATION_SUCCESS";
export const SEND_AUTHENTICATION_FAILED = "SEND_AUTHENTICATION_FAILED";

export const UPDATE_ENROLLMENT_FORM = "UPDATE_ENROLLMENT_FORM";
export const UPDATE_ENROLLMENT_FORM_SUCCESS = "UPDATE_ENROLLMENT_FORM_SUCCESS";
export const UPDATE_ENROLLMENT_FORM_FAILED = "UPDATE_ENROLLMENT_FORM_FAILED";

export const FETCH_AGVANCE_CUSTOMER_WITH_NAME_SEARCH =
    "FETCH_AGVANCE_CUSTOMER_WITH_NAME_SEARCH_DATA";
export const FETCH_AGVANCE_CUSTOMER_WITH_NAME_SEARCH_SUCCESS =
    "FETCH_AGVANCE_CUSTOMER_WITH_NAME_SEARCH_SUCCESS";
export const FETCH_AGVANCE_CUSTOMER_WITH_NAME_SEARCH_FAILED =
    "FETCH_AGVANCE_CUSTOMER_WITH_NAME_SEARCH_FAILED";

export const SET_AGVANCE_CUSTOMER_LIST_OUT_OF_BOUND = "SET_AGVANCE_CUSTOMER_LIST_OUT_OF_BOUND";

export const FLUSH_AGVANCE_CUSTOMER_LIST = "FLUSH_AGVANCE_CUSTOMER_LIST";
export const SET_CUSTOMER_SEARCH_IS_LOADING = "SET_CUSTOMER_SEARCH_IS_LOADING";

export const SET_ADDITIONAL_AGVANCE_CUSTOMER_FLAG = "SET_ADDITIONAL_AGVANCE_CUSTOMER_FLAG";

export const fetchCustomerEquipmentList = createAction<any>(FETCH_CUSTOMER_EQUIPMENT_LIST);
export const fetchCustomerEquipmentListSuccess = createAction<any>(
    FETCH_CUSTOMER_EQUIPMENT_LIST_SUCCESS
);
export const fetchCustomerEquipmentListFailed = createAction<any>(
    FETCH_CUSTOMER_EQUIPMENT_LIST_FAILED
);

export const fetchOwnerCommunityData = createAction<any>(FETCH_OWNER_COMMUNITY_DATA);
export const fetchOwnerCommunityDataSuccess = createAction<any>(FETCH_OWNER_COMMUNITY_DATA_SUCCESS);
export const fetchOwnerCommunityDataFailed = createAction<any>(FETCH_OWNER_COMMUNITY_DATA_FAILED);

export const createEquipment = createAction<any>(ADD_EQUIPMENT);
export const createEquipmentSuccess = createAction<any>(ADD_EQUIPMENT_SUCCESS);
export const createEquipmentFailed = createAction<any>(ADD_EQUIPMENT_FAILED);

export const removeCustomerRecord = createAction(REMOVE_CUSTOMER_RECORD);

export const updateCustomer = createAction<CustomerAPI.ICustomer>(UPDATE_CUSTOMER);
export const updateCustomerSuccess = createAction(UPDATE_CUSTOMER_SUCCESS);
export const updateCustomerFailure = createAction<any>(UPDATE_CUSTOMER_FAILED);

export const createCustomer = createAction<CustomerAPI.ICustomer>(CREATE_CUSTOMER);
export const createCustomerSuccess = createAction(CREATE_CUSTOMER_SUCCESS);
export const createCustomerFailure = createAction<any>(CREATE_CUSTOMER_FAILED);

export const fetchCustomerExists = createAction<any>(FETCH_CUSTOMER_EXISTS);
export const fetchCustomerExistsSuccess = createAction<boolean>(FETCH_CUSTOMER_EXISTS_SUCCESS);
export const fetchCustomerExistsFailure = createAction<any>(FETCH_CUSTOMER_EXISTS_FAILED);

export const createCustomerOracleId = createAction<any>(CREATE_CUSTOMER_ORACLE_ID);
export const createCustomerOracleIdSuccess = createAction(CREATE_CUSTOMER_ORACLE_ID_SUCCESS);
export const createCustomerOracleIdFailure = createAction<any>(CREATE_CUSTOMER_ORACLE_ID_FAILED);

export const setOracleCustomer = createAction<any>(SET_ORACLE_CUSTOMER);

export const fetchAgvanceCustomer = createAction<any>(FETCH_AGVANCE_CUSTOMER);
export const fetchAgvanceCustomerSuccess = createAction<{
    agvanceCustomers: any[];
    isAdditionalAgvanceCustomer?: boolean;
}>(FETCH_AGVANCE_CUSTOMER_SUCCESS);
export const fetchAgvanceCustomerFailure = createAction(FETCH_AGVANCE_CUSTOMER_FAILED);

export const fetchCustomer = createAction<string>(FETCH_CUSTOMER);
export const fetchCustomerSuccess = createAction<CustomerAPI.ICustomer>(FETCH_CUSTOMER_SUCCESS);
export const fetchCustomerFailure = createAction<any>(FETCH_CUSTOMER_FAILED);

export const getAgvanceCustomer = createAction<any>(GET_AGVANCE_CUSTOMER);
export const getAgvanceCustomerSuccess = createAction<any>(GET_AGVANCE_CUSTOMER_SUCCESS);
export const getAgvanceCustomerFailure = createAction<any>(GET_AGVANCE_CUSTOMER_FAILURE);

export const fetchSalespersonList = createAction(FETCH_SALESPERSON_LIST, (payload) => payload);
export const fetchSalespersonListSuccess = createAction<any>(FETCH_SALESPERSON_LIST_SUCCESS);
export const fetchSalespersonListFailed = createAction<any>(FETCH_SALESPERSON_LIST_FAILED);

export const fetchEnrollmentForm = createAction<{ customerName: string; enrolledFormUrl: string }>(
    FETCH_ENROLLMENT_FORM
);
export const fetchEnrollmentFormSuccess = createAction<any>(FETCH_ENROLLMENT_FORM_SUCCESS);
export const fetchEnrollmentFormFailed = createAction<any>(FETCH_ENROLLMENT_FORM_FAILED);

export const fetchMyAgDataRegistration = createAction<string>(FETCH_MYAGDATA_REGISTRATION);
export const fetchMyAgDataRegistrationSuccess = createAction<CustomerAPI.IMyAgDataRegistration[]>(
    FETCH_MYAGDATA_REGISTRATION_SUCCESS
);
export const fetchMyAgDataRegistrationFailed = createAction<any>(
    FETCH_MYAGDATA_REGISTRATION_FAILED
);

export const clearAgvanceCustomerList = createAction(CLEAR_AGVANCE_CUSTOMER_LIST);

export const sendAuthentication = createAction<any>(SEND_AUTHENTICATION);
export const sendAuthenticationSuccess = createAction<any>(SEND_AUTHENTICATION_SUCCESS);
export const sendAuthenticationFailed = createAction<any>(SEND_AUTHENTICATION_FAILED);

export const setAgvanceCustomerListOutOfBound = createAction<any>(
    SET_AGVANCE_CUSTOMER_LIST_OUT_OF_BOUND
);

export const updateEnrollmentForm = createAction<any>(UPDATE_ENROLLMENT_FORM);
export const updateEnrollmentFormSuccess = createAction<any>(UPDATE_ENROLLMENT_FORM_SUCCESS);
export const updateEnrollmentFormFailed = createAction<any>(UPDATE_ENROLLMENT_FORM_FAILED);

export const fetchAgvanceCustomerWithNameSearch = createAction<any>(
    FETCH_AGVANCE_CUSTOMER_WITH_NAME_SEARCH
);
export const fetchAgvanceCustomerWithNameSearchSuccess = createAction(
    FETCH_AGVANCE_CUSTOMER_WITH_NAME_SEARCH_SUCCESS
);
export const fetchAgvanceCustomerWithNameSearchFailed = createAction<any>(
    FETCH_AGVANCE_CUSTOMER_WITH_NAME_SEARCH_FAILED
);

export const flushAgvanceCustomerList = createAction(FLUSH_AGVANCE_CUSTOMER_LIST);
export const setCustomerSearchIsLoading = createAction<boolean>(SET_CUSTOMER_SEARCH_IS_LOADING);
export const setAdditionalAgvanceCustomerFlag = createAction<boolean>(
    SET_ADDITIONAL_AGVANCE_CUSTOMER_FLAG
);
