import { createReducer } from "redux-act";
import * as actions from "./actions";

export const ORG_LEVEL_SETUP_DATA_KEY = "ORG_LEVEL_SETUP_DATA";

const initialState = {
    equipmentList: [],
    hierarchyFilterList: [],
    orgLevelData: {},
    orgLevelGridData: {},
    orgLevelRecord: {},
    orgLevelWithGuid: [],
    orgTypeName: "",
    parentGridData: {
        gridRows: [],
        totalCount: 0,
    },
    parentLevelGuid: null,
};

export const orgLevelSetupReducer = createReducer(
    {
        [actions.getPartnerOrganizationsSuccess]: (state, partnerOrganizationsData) => ({
            ...state,
            partnerOrganizationsData,
        }),
        [actions.getAffiliationsParentSuccess]: (state, payload) => ({
            ...state,
            parentLevelGuid: payload,
        }),
        [actions.fetchCompanyRecordSuccess]: (state, parentGridData) => ({
            ...state,
            parentGridData,
        }),
        [actions.getOrgLevelDataSuccess]: (state, orgLevelData) => ({
            ...state,
            orgLevelData,
        }),
        [actions.getHierarchyFilterListSuccess]: (state, hierarchyFilterList) => ({
            ...state,
            hierarchyFilterList,
        }),
        [actions.getActiveOrgTypeNameSuccess]: (state, orgTypeName) => ({
            ...state,
            orgTypeName,
        }),
        [actions.getOrgLevelSuccess]: (state, orgLevelRecord) => ({
            ...state,
            orgLevelRecord,
        }),
        [actions.fetchOrgLevelEquipmentListSuccess]: (state, equipmentList) => ({
            ...state,
            equipmentList,
        }),
        [actions.getOrgLevelWithGuidSuccess]: (state, orgLevelWithGuid) => ({
            ...state,
            orgLevelWithGuid,
        }),
        [actions.getOrgLevelChildrenSuccess]: (state, orgLevelGridData) => ({
            ...state,
            orgLevelGridData,
        }),
        [actions.getOrgLevelWithAgvanceDatabaseIdSuccess]: (
            state,
            duplicateDatabaseIdOrgLevels
        ) => ({
            ...state,
            duplicateDatabaseIdOrgLevels,
        }),
        [actions.getAgvanceLocationsSuccess]: (state, agvanceLocations) => ({
            ...state,
            agvanceLocations,
        }),
    },
    initialState
);
