import { createReducer } from "redux-act";

import * as actions from "./actions";
import { CustomerAPI } from "@ai360/core";

export const CUSTOMER_DATA_KEY = "CUSTOMER_DATA";

export interface ICustomerState {
    agvanceCustomers: any[];
    communityDataDefault: boolean;
    customerNameExists: boolean;
    customerRecord: CustomerAPI.ICustomer | Record<string, any>;
    equipmentList: any[];
    newPrimaryCustomer: any;
    salespersonList: any[];
    oracleCustomer: any;
    additionalAgvanceCustomerList: any[];
    isAgvanceCustomerListOutOfBound: boolean;
    customerSearchIsLoading: boolean;
    isAdditionalAgvanceCustomerSearch: boolean;
}

const initialState: ICustomerState = {
    agvanceCustomers: [],
    communityDataDefault: false,
    customerNameExists: false,
    customerRecord: {},
    equipmentList: [],
    newPrimaryCustomer: null,
    salespersonList: [],
    oracleCustomer: null,
    additionalAgvanceCustomerList: [],
    customerSearchIsLoading: false,
    isAdditionalAgvanceCustomerSearch: false,
    isAgvanceCustomerListOutOfBound: false,
};

export const customerReducer = createReducer<ICustomerState>(
    {
        [actions.FETCH_CUSTOMER_EXISTS_SUCCESS]: (state, payload) => ({
            ...state,
            customerNameExists: payload,
        }),
        [actions.FETCH_CUSTOMER_SUCCESS]: (state, customerRecord) => ({
            ...state,
            customerRecord,
        }),
        [actions.SET_ADDITIONAL_AGVANCE_CUSTOMER_FLAG]: (state, payload) => {
            return { ...state, isAdditionalAgvanceCustomerSearch: payload };
        },
        [actions.FETCH_AGVANCE_CUSTOMER_SUCCESS]: (
            state,
            { agvanceCustomers, isAdditionalAgvanceCustomer }
        ) => {
            return isAdditionalAgvanceCustomer
                ? { ...state, additionalAgvanceCustomerList: agvanceCustomers }
                : { ...state, agvanceCustomers };
        },
        [actions.FETCH_CUSTOMER_EQUIPMENT_LIST_SUCCESS]: (state, equipmentList) => ({
            ...state,
            equipmentList,
        }),
        [actions.FETCH_OWNER_COMMUNITY_DATA_SUCCESS]: (state, communityDataDefault) => ({
            ...state,
            communityDataDefault,
        }),
        [actions.REMOVE_CUSTOMER_RECORD]: (state) => ({
            ...state,
            customerRecord: {},
            equipmentList: [],
        }),
        [actions.CREATE_CUSTOMER_SUCCESS]: (state) => ({
            ...state,
        }),

        [actions.UPDATE_CUSTOMER_SUCCESS]: (state) => ({
            ...state,
        }),
        [actions.UPDATE_ENROLLMENT_FORM_SUCCESS]: (state, { customerRecord }) => ({
            ...state,
            customerRecord,
        }),
        [actions.GET_AGVANCE_CUSTOMER_SUCCESS]: (state, payload) => ({
            ...state,
            newPrimaryCustomer: payload,
        }),
        [actions.FETCH_SALESPERSON_LIST_SUCCESS]: (state, salespersonList) => ({
            ...state,
            salespersonList,
        }),
        [actions.CLEAR_AGVANCE_CUSTOMER_LIST]: (state) => ({
            ...state,
            agvanceCustomers: [],
        }),
        [actions.SET_ORACLE_CUSTOMER]: (state, payload) => ({
            ...state,
            oracleCustomer: payload,
        }),
        [actions.SET_AGVANCE_CUSTOMER_LIST_OUT_OF_BOUND]: (state, payload) => ({
            ...state,
            isAgvanceCustomerListOutOfBound: payload.flag,
            agvanceCustomerListCount: payload.count,
        }),
        [actions.SET_CUSTOMER_SEARCH_IS_LOADING]: (state, payload) => ({
            ...state,
            customerSearchIsLoading: payload,
        }),
        [actions.FETCH_MYAGDATA_REGISTRATION_SUCCESS]: (state, payload) => ({
            ...state,
            customerRecord: {
                ...state.customerRecord,
                myAgDataRegistrations: payload,
            },
        }),
    },
    initialState
);
