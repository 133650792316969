import "./export-controller-custom-filename.css";
import { TextInput } from "~/core";
import { messages } from "~/action-panel/components/rec-module/components/i18n-messages";
import React, { useEffect, useState } from "react";
import { injectIntl, intlShape } from "react-intl";
import {
    REC_TYPE_NAME_EQUATION_APPLICATION,
    REC_TYPE_NAME_MANUAL_APPLICATION,
    RecSummary,
} from "~/recs-events/recs/model";
import { FileImportAPI, RecAPI } from "@ai360/core";
import { getTheUserGuid } from "~/login";
import { connect } from "react-redux";
import { groupBy, reduce, uniq, uniqBy } from "lodash";
import { ProductMix } from "~/action-panel/components/common/product-blend-modal/model";
import { IRecDetails } from "@ai360/core/dist/4x/es/api/rec/rec.4x";

interface IRecFilenames {
    customerName: string;
    farmName: string;
    fieldName: string;
    files: INutrientFilename[];
}

interface IRecProduct {
    recGeneralGuid: string | null;
    nutrient: string | null;
    productMixName: string | null;
}

interface INutrientFilename extends IRecProduct {
    filename: string | null;
}

interface IStateProps {
    userGuid: string;
    intl: intlShape;
}

interface IProps {
    recSummaries: RecSummary[];
    isGenericType: boolean;
    mergeFiles: boolean;
    multiProduct: boolean;
    exportFormat: string | null;
    showInputs: boolean;
    updateState: (
        newValue: Partial<FileImportAPI.ExportRecRequest>,
        filenamesValid: boolean
    ) => void;
}

const invalidCharacters = new RegExp(/[!@#$%^&*[\]{};:,\\/<>?|`~=+'"().]/g);

function isValid(filename: string | null): boolean {
    return (filename ?? "" !== "") && filename.replace(invalidCharacters, "") === filename;
}

function extractProductsEquationApplication(detail: IRecDetails): IRecProduct[] {
    return detail.recAreaList
        .filter((area) => area.applyRecToArea)
        .flatMap((area) => area.recs)
        .flatMap((rec) =>
            rec.recNutrientList.map((nutrient) => ({
                recGeneralGuid: detail.recGeneralGuid,
                nutrient: nutrient.equationName.replace(" Rec", "").trim(),
                productMixName: nutrient.recNutrientProductMix.name.trim(),
            }))
        );
}

function extractProductsManualApplication(detail: IRecDetails): IRecProduct[] {
    const uniqueMixes = uniqBy(
        (detail as any).zones
            .filter((zone) => zone.applyToArea)
            .flatMap((zone) => zone.details)
            .flatMap((detail) => detail.productMixList as ProductMix[]),
        (x: ProductMix) => x.name
    );

    return uniqueMixes.map((mix: ProductMix, index) => ({
        recGeneralGuid: detail.recGeneralGuid,
        nutrient: `Mix ${index + 1}`,
        productMixName: uniqueMixes.length > 1 ? `${mix.name} - Mix ${index + 1}` : mix.name,
    }));
}

function renderFilenameInput(
    recIndex: number,
    nutrientIndex: number,
    nutrient: INutrientFilename,
    erroredControls: Set<string>,
    formatMessage: (message: string) => string,
    updateFilename: (recIndex: number, nutrientIndex: number, filename: string | null) => void
) {
    return (
        <React.Fragment key={`${recIndex}-${nutrientIndex}`}>
            {nutrient.nutrient && <label>{nutrient.nutrient}</label>}
            <TextInput
                placeholderText="Rec File Name"
                required
                hasError={erroredControls.has(`file-${recIndex}-${nutrientIndex}`)}
                errorText={formatMessage(
                    nutrient.filename
                        ? messages.exportValidationWarningFilenameSpecialCharacters
                        : messages.exportValidationWarningFilenameRequired
                )}
                value={nutrient.filename}
                onChange={(filename) => updateFilename(recIndex, nutrientIndex, filename)}
                onBlur={() => updateFilename(recIndex, nutrientIndex, nutrient.filename.trim())}
            />
        </React.Fragment>
    );
}

function makeTemplateFormat(rawFormat: string | null): string | null {
    return rawFormat ? `{${rawFormat.split("/").join("}_{")}}` : null;
}

function ExportControllerCustomFilename_(props: IProps & IStateProps) {
    const {
        isGenericType,
        showInputs,
        recSummaries,
        mergeFiles,
        multiProduct,
        exportFormat,
        userGuid,
    } = props;

    const { formatMessage } = props.intl;
    const [erroredControls, setErroredControls] = useState(new Set<string>());
    const [zipFilename, setZipFilename] = useState<string>(null);
    const [recProducts, setRecProducts] = useState<IRecProduct[][]>([]);
    const [recFilenames, setRecFilenames] = useState<IRecFilenames[]>([]);
    const [recNameStart, setRecNameStart] = useState<string>(null);
    const [recNameEnd, setRecNameEnd] = useState<string>(null);
    const [templatedFormat, setTemplatedFormat] = useState<string>(null);

    useEffect(() => {
        let isMounted = true;
        if (
            recSummaries.some(
                (x) =>
                    x.recType === REC_TYPE_NAME_EQUATION_APPLICATION ||
                    x.recType === REC_TYPE_NAME_MANUAL_APPLICATION
            )
        ) {
            const recPromises = recSummaries.map((x) => RecAPI.getRec(userGuid, x.recGeneralGuid));
            Promise.all(recPromises).then((details) => {
                if (!isMounted) return;

                const recProducts: IRecProduct[][] = details.map((detail) => {
                    if (detail.recType === REC_TYPE_NAME_EQUATION_APPLICATION) {
                        return extractProductsEquationApplication(detail);
                    } else if (detail.recType === REC_TYPE_NAME_MANUAL_APPLICATION) {
                        return extractProductsManualApplication(detail);
                    }

                    return [];
                });
                setRecProducts(recProducts);
            });
        }
        return () => {
            isMounted = false;
        };
    }, [recSummaries, userGuid]);
    useEffect(() => {
        rebuildRecFiles(true);
    }, [recProducts, multiProduct, mergeFiles, templatedFormat, showInputs]);
    useEffect(() => {
        rebuildRecFiles(false);
    }, [recNameStart, recNameEnd]);
    useEffect(() => {
        setTemplatedFormat(makeTemplateFormat(exportFormat));
    }, [exportFormat]);
    useEffect(() => {
        props.updateState(
            {
                zipFilename,
                exportFilenames: recFilenames.flatMap((rec) =>
                    rec.files.map((product) => ({
                        fieldName: rec.fieldName,
                        customerName: rec.customerName,
                        recGeneralGuid: product.recGeneralGuid,
                        productMixName:
                            product.nutrient?.startsWith("Mix ") ?? false
                                ? product.productMixName.replace(` - ${product.nutrient}`, "")
                                : product.productMixName,
                        filename: product.filename,
                    }))
                ),
            },
            erroredControls.size === 0
        );
    }, [zipFilename, recFilenames, erroredControls]);

    if (!showInputs) {
        return null;
    }

    function rebuildRecFiles(updateZipFile = true) {
        let zipFilename: string;
        let recFilenames: IRecFilenames[];
        if (recSummaries?.length < 1) {
            return;
        }
        const customerName = recSummaries.every(
            (x) => x.customerName === recSummaries[0].customerName
        )
            ? recSummaries[0].customerName.trim()
            : "Multiple";
        const farmName = recSummaries.every((x) => x.farmName === recSummaries[0].farmName)
            ? recSummaries[0].farmName.trim()
            : "Multiple";
        const fieldName = recSummaries.every((x) => x.fieldName === recSummaries[0].fieldName)
            ? recSummaries[0].fieldName.trim()
            : "Multiple";
        const firstProductMixName =
            recProducts.find((x) => x.length > 0)?.[0].productMixName ?? null;
        const productName = recProducts
            .flatMap((x) => x)
            .every((x) => x.productMixName === firstProductMixName)
            ? firstProductMixName?.trim()
            : "Multiple";

        if (mergeFiles && multiProduct) {
            const productNames = uniq(
                recProducts.flatMap((x) => x).flatMap((x) => x.productMixName)
            );
            zipFilename = buildFilenameFromFormat(
                customerName,
                farmName,
                fieldName,
                productName,
                "Merged_MultiProduct_",
                true
            );

            recFilenames = [
                {
                    farmName,
                    customerName,
                    fieldName,
                    files: [
                        {
                            recGeneralGuid: null,
                            nutrient: null,
                            productMixName: "",
                            filename: buildFilenameFromFormat(
                                customerName,
                                farmName,
                                fieldName,
                                productNames.length === 1 ? productNames[0] : "Multiple"
                            ),
                        },
                    ],
                },
            ];
        } else if (multiProduct) {
            const byField = Object.values(groupBy(recSummaries, (x) => x.fieldGuid));
            zipFilename =
                byField.length > 1
                    ? "BatchExport"
                    : buildFilenameFromFormat(
                          customerName,
                          farmName,
                          fieldName,
                          productName,
                          "MultiProduct_",
                          true
                      );

            recFilenames = byField.map((summaries) => {
                const customerName = summaries.every(
                    (x) => x.customerName === summaries[0].customerName
                )
                    ? summaries[0].customerName.trim()
                    : "Multiple";
                const farmName = summaries.every((x) => x.farmName === summaries[0].farmName)
                    ? summaries[0].farmName.trim()
                    : "Multiple";
                const fieldName = summaries.every((x) => x.fieldName === summaries[0].fieldName)
                    ? summaries[0].fieldName.trim()
                    : "Multiple";
                const productNames = uniq(
                    recProducts.flatMap((x) => x).flatMap((x) => x.productMixName)
                );
                const recGuid = summaries.length === 1 ? summaries[0].recGeneralGuid : null;

                return {
                    customerName,
                    farmName,
                    fieldName,
                    files: [
                        {
                            recGeneralGuid: recGuid,
                            nutrient: null,
                            productMixName: null,
                            filename: buildFilenameFromFormat(
                                customerName,
                                farmName,
                                fieldName,
                                productNames.length === 1 ? productNames[0] : "Multiple"
                            ),
                        },
                    ],
                };
            });
        } else if (mergeFiles) {
            zipFilename = buildFilenameFromFormat(
                customerName,
                farmName,
                fieldName,
                productName,
                "Merged_",
                true
            );

            const files: INutrientFilename[] = uniqBy(
                recProducts
                    .flatMap((x) => x)
                    .map((product) => ({
                        recGeneralGuid: null,
                        nutrient: null,
                        productMixName: product.productMixName,
                        filename: buildFilenameFromFormat(
                            customerName,
                            farmName,
                            fieldName,
                            product.productMixName
                        ),
                    })),
                "productMixName"
            );

            recFilenames = [
                {
                    customerName,
                    fieldName,
                    farmName,
                    files,
                },
            ];
        } else {
            zipFilename =
                recProducts.length > 1
                    ? "BatchExport"
                    : buildFilenameFromFormat(
                          customerName,
                          farmName,
                          fieldName,
                          productName,
                          "",
                          true
                      );
            recFilenames = recProducts.map((products, recIndex) => {
                const customerName = recSummaries[recIndex].customerName.trim();
                const farmName = recSummaries[recIndex].farmName.trim();
                const fieldName = recSummaries[recIndex].fieldName.trim();
                const files = products.map((product) => ({
                    ...product,
                    filename: buildFilenameFromFormat(
                        customerName,
                        farmName,
                        fieldName,
                        product.productMixName
                    ),
                }));

                return {
                    customerName,
                    farmName,
                    fieldName,
                    customerGuid: recSummaries[recIndex].customerGuid,
                    fieldGuid: recSummaries[recIndex].fieldGuid,
                    recGeneralGuid: recSummaries[recIndex].recGeneralGuid,
                    files: uniqBy(files, "productMixName"),
                };
            });
        }

        setRecFilenames(recFilenames);
        if (updateZipFile) {
            setZipFilename(zipFilename);
        }
        setErroredControls(new Set<string>());
    }

    function updateZipName(newName: string | null) {
        if (isValid(newName)) {
            erroredControls.delete("zipFilename");
        } else {
            erroredControls.add("zipFilename");
        }
        setZipFilename(newName);
        setErroredControls(erroredControls);
    }

    function updateFilename(recIndex: number, nutrientIndex: number, filename: string | null) {
        recFilenames[recIndex].files[nutrientIndex].filename = filename;
        if (isValid(filename)) {
            erroredControls.delete(`file-${recIndex}-${nutrientIndex}`);
        } else {
            erroredControls.add(`file-${recIndex}-${nutrientIndex}`);
        }
        setRecFilenames([...recFilenames]);
        setErroredControls(erroredControls);
    }

    function buildFilenameFromFormat(
        grower: string,
        farm: string,
        field: string,
        product: string,
        extraPrefix = "",
        isZipFile = false
    ): string {
        // this comes from the export controller in ai360.python
        return (
            (isZipFile ? "" : recNameStart ?? "") +
            (!extraPrefix && mergeFiles ? "M_" : extraPrefix) +
            (templatedFormat
                ?.replace("{Grower}", grower)
                .replace("{Farm}", farm ?? "")
                .replace("{Field}", field)
                .replace("{Product}", product ?? "")
                .replaceAll("/", "_")
                .replaceAll(" - Mix", " Mix")
                .trim()
                .replaceAll(invalidCharacters, "")
                .replaceAll(" ", isZipFile ? "" : "_")
                .replaceAll(/_{2,}/g, "_")
                .replaceAll(/^_|_$/gm, "") ?? "") +
            (isZipFile ? "" : recNameEnd ?? "")
        );
    }

    const showPrePostfix = reduce(recFilenames, (prev, curr) => prev + curr.files.length, 0) > 1;

    const sectionClass =
        recFilenames[0]?.files[0]?.nutrient ?? false ? "file-list" : "file-list one-column";

    function renderRec(rec: IRecFilenames, recIndex: number) {
        return (
            rec.files.length > 0 && (
                <React.Fragment key={recIndex}>
                    {!mergeFiles && recFilenames.length > 1 && (
                        <div className="field">
                            {rec.customerName} {rec.farmName ? `> ${rec.farmName} ` : ""}
                            &gt; {rec.fieldName.trim()}
                        </div>
                    )}
                    {recFilenames[recIndex].files.map((nutrient, nutrientIndex) =>
                        renderFilenameInput(
                            recIndex,
                            nutrientIndex,
                            nutrient,
                            erroredControls,
                            formatMessage,
                            updateFilename
                        )
                    )}
                </React.Fragment>
            )
        );
    }

    return (
        <div className="export-controller-custom-filename">
            <div className="zip-name">
                <TextInput
                    placeholderText="Zip File Name"
                    value={zipFilename}
                    hasError={erroredControls.has("zipFilename")}
                    required={true}
                    errorText={formatMessage(
                        zipFilename
                            ? messages.exportValidationWarningFilenameSpecialCharacters
                            : messages.exportValidationWarningFilenameRequired
                    )}
                    onChange={(newName) => updateZipName(newName)}
                    onBlur={() => updateZipName(zipFilename?.trim())}
                />
            </div>
            {isGenericType && showPrePostfix && (
                <React.Fragment>
                    <TextInput
                        placeholderText="Start All Rec File Names With"
                        value={recNameStart}
                        hasError={erroredControls.has("recNameStart")}
                        errorText={formatMessage(
                            messages.exportValidationWarningFilenameSpecialCharacters
                        )}
                        onChange={(recNameStart) => setRecNameStart(recNameStart)}
                    />
                    <TextInput
                        placeholderText="End All Rec File Names With"
                        value={recNameEnd}
                        hasError={erroredControls.has("recNameEnd")}
                        errorText={formatMessage(
                            messages.exportValidationWarningFilenameSpecialCharacters
                        )}
                        onChange={(recNameEnd) => setRecNameEnd(recNameEnd)}
                    />
                </React.Fragment>
            )}
            {isGenericType && (
                <div className={sectionClass}>
                    {recFilenames?.map((rec, recIndex) => renderRec(rec, recIndex))}
                </div>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    userGuid: getTheUserGuid(state),
});

export const ExportControllerCustomFilename = connect<any, IStateProps, IProps>(mapStateToProps)(
    injectIntl(ExportControllerCustomFilename_)
);
