import "./reset.css";

import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch, withRouter } from "react-router-dom";

import { urlBase, urlJoin } from "@ai360/core";
import store from "./store/store";
import { ConnectedIntlProvider } from "./intl-provider";
import { RootComponent } from "./root-component";
import { getUser, LoginPage, GetMobileApp, EnrollmentPage } from "./login";
import { ReceiveAuthentication } from "~/authentication/receive-authentication";
import { MyAgDataRegistration } from "~/login/components/myagdata-registration";

let appEntry = "";

const EnsureLoggedIn = ({ history }) => {
    if (!getUser(store.getState())) {
        appEntry = history.location.pathname;
        history.replace(urlJoin(urlBase, "login"));
        return null;
    }

    const enteredFromMobile = appEntry.toLowerCase().indexOf("getmobileapp") > -1;
    const onMobilePage = history.location.pathname.toLowerCase().indexOf("getmobileapp") > -1;
    if (enteredFromMobile && !onMobilePage) {
        history.replace(urlJoin(urlBase, "GetMobileApp"));
        return null;
    } else if (onMobilePage) {
        appEntry = "";
        return <GetMobileApp />;
    }

    return <RootComponent />;
};

EnsureLoggedIn.propTypes = {
    history: PropTypes.any.isRequired,
};

ReactDOM.render(
    <Provider store={store}>
        <ConnectedIntlProvider>
            <Router>
                <Switch>
                    <Route exact path={urlBase} component={withRouter(EnsureLoggedIn)} />
                    <Route path={urlJoin(urlBase, "login")} component={LoginPage} />
                    <Route
                        path={urlJoin(urlBase, "getmobileapp")}
                        component={withRouter(EnsureLoggedIn)}
                    />
                    <Route path={urlJoin(urlBase, "enrollment")} component={EnrollmentPage} />
                    <Route path={urlJoin(urlBase, "myagdata")} component={MyAgDataRegistration} />
                    <Route
                        path={urlJoin(urlBase, "auth/callback")}
                        component={ReceiveAuthentication}
                    />
                </Switch>
            </Router>
        </ConnectedIntlProvider>
    </Provider>,
    document.getElementById("root")
);
