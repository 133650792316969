import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "~/core";
import "./field-information-button-bar.css";

interface IProps {
    saveIsEnabled: boolean;
    onSave: () => void;
    onCancel: () => void;
}

export const FieldInformationButtonBar = (props: IProps): JSX.Element => {
    /// ----------------------------------------- ///

    /// Event Handlers ///

    /// ------------- ///

    return (
        <div className={classNames("information-button-bar", "field-information-button-bar")}>
            <Button
                type="save"
                disabled={!props.saveIsEnabled}
                onClick={() => {
                    props.onSave();
                }}
            />
            <Button
                type="cancel"
                onClick={() => {
                    props.onCancel();
                }}
            />
        </div>
    );
};

FieldInformationButtonBar.defaultProps = {};
