import { adminData } from "~/admin/data";
// Model
export const PROPS_ACTIVE_INACTIVE = adminData.PROPS_ACTIVE_INACTIVE;
export const PROPS_ACTIVE_YN = adminData.PROPS_ACTIVE_YN;
export const PROPS_ADDRESS_LIST = "addressList";
export const PROPS_AUTO_CREATE_REPORTS_LIST = "autoCreateReportsList";
export const PROPS_CITY = "city";
export const PROPS_COUNTRIES_LIST = "countriesList";
export const PROPS_EMAIL_ADDRESS = "emailAddress";
export const PROPS_GLN_IDENTIFIER = "glnIdentifier";
export const PROPS_IS_ACTIVE = adminData.PROPS_IS_ACTIVE;
export const PROPS_LAB_IDENTIFIER = "labIdentifier";
export const PROPS_NOTES = "notes";
export const PROPS_ORG_LEVEL_GUID = "orgLevelGuid";
export const PROPS_ORG_LEVEL_LIST = adminData.PROPS_ORG_LEVEL_LIST;
export const PROPS_OWNER_OPERATOR_CITY = "ownerOperatorCity";
export const PROPS_OWNER_OPERATOR_STATE_ABBREVIATION = "ownerOperatorStateAbbreviation";
export const PROPS_PERSON_ADDRESS_LIST = "personAddressList";
export const PROPS_SUBMITTER_ID = "submitterId";
export const PROPS_STATES_LIST = "stateList";
export const PROPS_STATE_ABBREVIATION = adminData.PROPS_STATE_ABBREVIATION;
export const PROPS_TESTING_LAB = "setupVendorTestingLab";
export const PROPS_TESTING_LAB_FROM_EMAIL_LIST = "testingLabFromEmailList";
export const PROPS_TESTING_LAB_TO_EMAIL_LIST = "testingLabToEmailList";
export const PROPS_TEST_PACKAGE_LIST = "testPackageList";
export const PROPS_USER_GUID = "userGuid";
export const PROPS_VENDOR_ADDRESS_LIST = "vendorAddressList";
export const PROPS_VENDOR_EQUIPMENT_LIST = "vendorEquipmentList";
export const PROPS_VENDOR_GUID = "vendorGuid";
export const PROPS_VENDOR_NAME = "vendorName";
export const PROPS_VENDOR_PHONE_NUMBER_LIST = "vendorPhoneNumberList";
export const PROPS_VENDOR_TYPE_GUID = "vendorTypeGuid";
export const PROPS_VENDOR_TYPE_LIST = "vendorTypeList";
export const PROPS_VENDOR_TYPE_NAME = "vendorTypeName";
export const PROPS_VENDOR_URL_LIST = "vendorUrlList";
