import React, { useState } from "react";
import { injectIntl, intlShape } from "react-intl";
import classNames from "classnames";
import { Menu } from "~/core";
import { withUser } from "~/hocs";
import { messages } from "../../../../i18n-messages";
interface ContextMenuProps {
    showSampleZoneInfo: (isRequired: boolean) => void;
    hideSampleZoneInfo: (isRequired: boolean) => void;
    intl: intlShape.isRequired;
}
const ContextMenu_ = (props: ContextMenuProps): JSX.Element => {
    const { showSampleZoneInfo, hideSampleZoneInfo, intl } = props;
    const [isContextMenuExpanded, setIsContextMenuExpanded] = useState<boolean>(false);
    function _getMenuItems() {
        const { formatMessage } = intl;
        const menuItems = [
            {
                key: 0,
                label: formatMessage(messages.editSampleZoneInfoLayerProperties),
                action: () => showSampleZoneInfo(true),
            },
        ];
        if (hideSampleZoneInfo) {
            menuItems.push({
                key: 1,
                label: formatMessage(messages.removeText),
                action: () => hideSampleZoneInfo(true),
            });
        }
        return menuItems;
    }
    function _onClick(event) {
        // prevent expand/collapse of accordion item
        event.preventDefault();
    }
    const contextMenuClassNames = classNames("context-menu", {
        expanded: isContextMenuExpanded,
    });
    return (
        <div className="context-menu-container" onClick={(event) => _onClick(event)}>
            <Menu
                className={contextMenuClassNames}
                isDotMenu={true}
                getMenuItems={() => _getMenuItems()}
                onExpand={() => setIsContextMenuExpanded(true)}
                onCollapse={() => setIsContextMenuExpanded(false)}
            />
        </div>
    );
};
export const ContextMenu = injectIntl(withUser(ContextMenu_));
