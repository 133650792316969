import React from "react";
import { injectIntl, intlShape } from "react-intl";

import { DialogBox, DialogBoxFooterType } from "~/core";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { getIsFieldBoundaryOverlapOpen } from "~/action-panel/components/field-module/selectors";
import { setFieldBoundaryOverlapAction } from "~/action-panel/components/field-module/actions";
import messages from "./i18n-messages";
import "./field-boundary-overlap.css";
import { FieldBoundaryOverlapAction } from "~/action-panel/components/field-module/interfaces";
import { IMultiActionList } from "~/core/components/dialog-box/dialog-box";

function FieldBoundaryOverlap({ intl: { formatMessage } }) {
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector(getIsFieldBoundaryOverlapOpen);

    const actionList: IMultiActionList[] = [
        {
            action: formatMessage(messages.clipToOtherField),
            onAction: () => {
                dispatch(
                    setFieldBoundaryOverlapAction(FieldBoundaryOverlapAction.CLIP_TO_OTHER_FIELD)
                );
            },
        },
        {
            action: formatMessage(messages.updateOtherField),
            onAction: () => {
                dispatch(
                    setFieldBoundaryOverlapAction(FieldBoundaryOverlapAction.UPDATE_OTHER_FIELD)
                );
            },
        },
        {
            action: formatMessage(messages.cancel),
            isCancelStyle: true,
            onAction: () => {
                dispatch(setFieldBoundaryOverlapAction(FieldBoundaryOverlapAction.CANCEL));
            },
        },
    ];
    return (
        <div className="field-boundary-overlap">
            <DialogBox
                draggable={true}
                multiActionList={actionList}
                footerType={DialogBoxFooterType.MULTI_ACTION}
                isOpen={isOpen}
                title={formatMessage(messages.title)}
            >
                <div className="field-boundary-overlap-description">
                    {formatMessage(messages.promptDescription)}
                    <div className="field-boundary-overlap-option-description">
                        {formatMessage(messages.promptOptionOneDescription)}
                    </div>
                    <div className="field-boundary-overlap-option-description">
                        {formatMessage(messages.promptOptionTwoDescription)}
                    </div>
                    <div className="field-boundary-overlap-option-description">
                        {formatMessage(messages.promptOptionCancelDescription)}
                    </div>
                    {formatMessage(messages.undoOptionDescription)}
                </div>
            </DialogBox>
        </div>
    );
}

FieldBoundaryOverlap.propTypes = {
    intl: intlShape,
};

export default injectIntl(FieldBoundaryOverlap);
