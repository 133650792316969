import React from "react";
import { Toolbar, ToolbarButton, ToolbarMenu, ToolbarTool } from "~/core";
import { IconType, ToolbarIcon } from "../../assets/toolbar-icon";
import { Tool, ZoneTools, MapConfig } from "@ai360/core";
import { logFirebaseEvent } from "~/utils/firebase";

export class ZoneToolSet extends ZoneTools {
    getToolbar(disabled = false): JSX.Element {
        this.setGettingToolbar(true);
        const { formatMessage } = this.options;
        const messages = this.options.messages;

        const toolsDisabled = this.mapView.scale > MapConfig.layers.fields.minScale;

        if ((disabled || toolsDisabled) !== this.disabled) {
            this.toggleTool({ selected: false }, false);
        }
        this.disabled = disabled || toolsDisabled;

        let fieldPartCount = 0;
        this.entireFieldZones.forEach((zone) => {
            zone.geometry.rings.forEach((points) => {
                if (this.isPolygonRingAHole(points, zone.geometry.spatialReference)) {
                    fieldPartCount++;
                }
            });
        });

        const noZonesToEdit = this.zones.length === 1 || this.zones.length <= fieldPartCount;
        const canDeleteZone = this.canDeleteZone();
        if (
            (this.activeTool && this.activeTool.toString() === Tool.ZoneEdit && noZonesToEdit) ||
            (this.activeTool &&
                this.activeTool.toString() === Tool.ZoneUnion &&
                (noZonesToEdit || (!this.isInclusionSelect && !canDeleteZone)))
        ) {
            setTimeout(() => {
                this.unSelectTools();
                this.resetGraphics();
            });
        }
        const recEventDetails = this.options.getCurrentDetails();
        const areas = recEventDetails
            ? recEventDetails.eventAreaList || recEventDetails.recAreaList || []
            : [];
        const hasClassBreaks = areas.some((ea) => ea.eventAreaClassBreak || ea.recAreaClassBreak);
        const classes = [
            ...new Set(
                areas
                    .map((ea) => (ea.eventAreaClassBreak || ea.recAreaClassBreak)?.classId)
                    .filter((x) => x)
            ),
        ];
        const canClassify = hasClassBreaks && this.zones.length > classes.length;
        this.setGettingToolbar(false);
        return (
            <ToolbarMenu
                key="zones"
                className="zones"
                icon={ToolbarIcon(IconType.SPLIT)}
                disabled={this.disabled}
                label={formatMessage(messages.zonesLbl)}
                ref={(menu) => (this.menu = menu)}
            >
                <Toolbar className="zone-tools">
                    <ToolbarTool
                        key="zone-create"
                        className="zone-create"
                        icon={ToolbarIcon(IconType.ZONE_CREATE)}
                        disabled={toolsDisabled}
                        label={formatMessage(messages.createLbl)}
                        onToggle={(evt) => {
                            logFirebaseEvent("zones_create");
                            this.toggleTool(evt);
                        }}
                        ref={(tool) => (this.zoneCreate = tool)}
                    />
                    <ToolbarTool
                        key="zone-circle"
                        className="zone-circle"
                        icon={ToolbarIcon(IconType.CIRCLE)}
                        disabled={toolsDisabled}
                        label={formatMessage(messages.drawCircleLbl)}
                        onToggle={(evt) => {
                            logFirebaseEvent("zones_circle");
                            this.toggleTool(evt);
                        }}
                        ref={(tool) => (this.zoneCircle = tool)}
                    />
                    <ToolbarTool
                        key="zone-edit"
                        className="zone-edit"
                        icon={ToolbarIcon(IconType.ZONE_EDIT)}
                        disabled={toolsDisabled || noZonesToEdit}
                        label={formatMessage(messages.editLbl)}
                        onToggle={(evt) => {
                            logFirebaseEvent("zones_edit");
                            this.toggleTool(evt);
                        }}
                        ref={(tool) => (this.zoneEdit = tool)}
                    />
                    <ToolbarTool
                        key="zone-union"
                        icon={ToolbarIcon(IconType.UNION)}
                        disabled={
                            toolsDisabled ||
                            noZonesToEdit ||
                            (!this.isInclusionSelect && !canDeleteZone)
                        }
                        label={formatMessage(messages.drawUnionLbl)}
                        onToggle={(evt) => {
                            logFirebaseEvent("zones_union");
                            this.toggleTool(evt);
                        }}
                        ref={(tool) => (this.zoneUnion = tool)}
                    />
                    <ToolbarButton
                        key="zone-delete"
                        className="zone-delete"
                        icon={ToolbarIcon(IconType.DELETE)}
                        disabled={toolsDisabled || !canDeleteZone}
                        label={formatMessage(messages.deleteLbl)}
                        onClick={() => {
                            logFirebaseEvent("zones_delete");
                            this.deleteActiveZone();
                        }}
                    />
                    <ToolbarButton
                        className="zone-load"
                        icon={ToolbarIcon(IconType.ZONE_LOAD)}
                        disabled={toolsDisabled || this.recEventDetails == null}
                        label={formatMessage(messages.loadLbl)}
                        onClick={() => {
                            logFirebaseEvent("zones_load");
                            this.openLoadZonesModal();
                        }}
                    />
                    <ToolbarButton
                        className="zone-buffer"
                        icon={ToolbarIcon(IconType.ZONE_BUFFER)}
                        disabled={toolsDisabled}
                        label={formatMessage(messages.bufferLbl)}
                        onClick={() => {
                            logFirebaseEvent("zones_buffer");
                            this.options.onSetBufferOptions({
                                open: true,
                            });
                        }}
                    />
                    <ToolbarButton
                        key="zone-explode"
                        className="zone-explode"
                        icon={ToolbarIcon(IconType.ZONE_EXPLODE)}
                        disabled={toolsDisabled || !this.isMultipleMultiPartZones(this.zones)}
                        label={formatMessage(messages.explodeLbl)}
                        onClick={() => this.splitClassifiedMultipartZones()}
                    />
                    {!canClassify ? (
                        []
                    ) : (
                        <ToolbarButton
                            key="zone-classify"
                            className="zone-classify"
                            icon={ToolbarIcon(IconType.ZONE_CLASSIFY)}
                            disabled={toolsDisabled || !hasClassBreaks}
                            label={formatMessage(messages.classifyLbl)}
                            onClick={() => this.classify()}
                        />
                    )}
                    <ToolbarButton
                        className="zone-reset"
                        icon={ToolbarIcon(IconType.RESET)}
                        disabled={
                            toolsDisabled ||
                            this.zones.length === 1 ||
                            this.entireFieldZones.length === 0
                        }
                        label={formatMessage(messages.resetLbl)}
                        onClick={() => {
                            logFirebaseEvent("zones_reset");
                            this.resetZones();
                        }}
                    />
                </Toolbar>
            </ToolbarMenu>
        );
    }
}
