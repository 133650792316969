import React, { useEffect, useState } from "react";
import { injectIntl, intlShape } from "react-intl";
import { messages } from "../../../../i18n-messages";
import { messages as mainMessages, createAddLinkLabelText } from "~/i18n-messages";

// Components
import { ZeroToInfiniteUnsafe, Section } from "~/core";
import EmailItem from "./email-item";

//Style
import "./email.css";
import { IAddEditPanel, IEmailItem } from "~/admin/interfaces";
import { MessageDescriptor } from "@ai360/core";

export const EMAIL_ERROR_CODE_LIST = [2085, 17];

export interface IEmailProps {
    addEditPanel: IAddEditPanel;
    apiErrors: string[];
    apiErrorModel: any;
    className: string;
    emailAddText: MessageDescriptor;
    emailAlias: string;
    emailHeaderText: MessageDescriptor;
    emailList: IEmailItem[];
    formKey: string;
    intl: intlShape;
    noHeader: boolean;
    onChildComponentChange?: (key: string, emailData: IEmailItem[]) => void;
    onAdd?: (emailData: IEmailItem[]) => void;
    onDelete?: (emailData: IEmailItem[]) => void;
    required: boolean;
}

const Email = (props: IEmailProps): JSX.Element => {
    const {
        addEditPanel,
        apiErrors,
        apiErrorModel,
        className,
        emailAddText,
        emailAlias,
        emailHeaderText,
        emailList,
        formKey,
        intl,
        noHeader,
        onChildComponentChange,
        onAdd,
        onDelete,
        required,
    } = props;
    const { formatMessage } = intl;

    const [email, setEmail] = useState<IEmailItem[]>([]);

    useEffect(() => {
        if (!apiErrors) {
            return;
        }
        const containsError = EMAIL_ERROR_CODE_LIST.some((errorCode) =>
            apiErrors.includes(errorCode.toString())
        );
        if (containsError) {
            const adjustedEmails = [...email];
            adjustedEmails.forEach((record) => {
                record.isError = containsError;
            });
            setEmail(adjustedEmails);
        }
    }, [apiErrors]);

    useEffect(() => {
        if (addEditPanel.mode === "EDIT" && emailList !== email) {
            setEmail(emailList);
        }
    }, [emailList]);

    const addItem = (e, items) => {
        setEmail(items);
        if (onAdd) {
            onAdd(items);
        }
    };

    const deleteItem = (e, items) => {
        setEmail(items);
        if (onDelete) {
            onDelete(items);
        }
    };

    const onChange = ({ type }, value, index) => {
        const newEmailItems = [...email];
        if (type) {
            newEmailItems[index][emailAlias] = value;
            newEmailItems[index].isError = false;
        }
        setEmail(newEmailItems);
        onChildComponentChange(formKey, newEmailItems);
    };

    const initialValue = {
        [emailAlias]: "",
    };
    const emailItemProps = {
        apiErrors,
        apiErrorModel,
        onChange,
        email,
        emailAlias,
        required,
    };
    let sectionProps = {};
    if (!noHeader) {
        const emailHeaderTextSelection = emailHeaderText || messages.emailHeading;
        sectionProps = {
            headerText: formatMessage(emailHeaderTextSelection),
        };
    }
    const emailAddButtonTxt = emailAddText || messages.email;

    return (
        <Section {...sectionProps} className={`z-to-n-header ${className || ""}`}>
            <ZeroToInfiniteUnsafe
                items={email}
                initialValue={initialValue}
                getChildProps={() => null}
                onDelete={deleteItem}
                addItem={addItem}
                deleteText={formatMessage(mainMessages.delete)}
                addText={createAddLinkLabelText(formatMessage, emailAddButtonTxt)}
                formatMessage={formatMessage}
                required={required}
            >
                <EmailItem {...emailItemProps} />
            </ZeroToInfiniteUnsafe>
        </Section>
    );
};

export default injectIntl(Email);
